import React from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Form, Col, Row, Image, textarea, Popover } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Cookies from "universal-cookie";
import UserTopbar from "../user-topbar/UserTopbar";
import UserSidebar from "../user-sidebar/UserSidebar";
import { URLS } from "../../urls";
import Project from "./UserContract";

class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.updateProjectsList = this.updateProjectsList.bind(this);
    this.state = {
      projects: [], //update this whenever you add/update project in backend.
      editProject: "", // id or index of the project which is to be edited
      clientList: [],
      employeesList: [],
    };
  }

  editProject = (_id) => {
    this.setState({ editProject: _id });
  };

  getEditProjectDataFromId = (id) => {
    const { projects } = this.state;
    return projects.find((item) => id === item._id);
  };

  componentWillMount() {
    const cookies = new Cookies();
    const id = cookies.get("userId");

    const token = "dummy token";
    fetch(`${URLS.backendProjects}/getProjectsByUserId/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          projects: result.projects,
        });
      });

    fetch(`${URLS.backendClients}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          clientList: result.clients,
        });
      });

    fetch(`${URLS.backendEmployees}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          employeesList: result.employees,
        });
      });
  }

  updateProjectsList = (project) => {
    let { projects } = this.state;
    const id = project && project._id;
    if (id) {
      const token = "dummy token";
      fetch(`${URLS.backendProjects}/${id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          let isExists = false;
          for (let index = projects.length - 1; index >= 0; --index) {
            if (projects[index]._id === id) {
              projects.splice(index, 1);
              projects.splice(index, 0, result.project);
              isExists = true;
              break;
            }
          }
          if (isExists) {
            this.setState({ projects: projects });
          } else {
            this.setState({ projects: projects.concat(result.project) });
          }
        });
    }
  };

  render() {
    const { projects, editProject, employeesList, clientList } = this.state;
    const editProjectData = this.getEditProjectDataFromId(editProject);
    return (
      <body>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex, nofollow"></meta>
          <title>InvexERP</title>
        </Helmet>
        <div className="main-wrapper">
          <UserTopbar />
          <UserSidebar />
          {/*<!-- Page Wrapper -->*/}
          <div className="page-wrapper">
            {/*<!-- Page Content -->*/}
            <div className="content container-fluid">
              {/*<!-- Page Title -->*/}
              <div className="row">
                <div className="col">
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <Link to={`${URLS.userDashboardProfile}`}>Dashboard</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Projects</BreadcrumbItem>
                  </Breadcrumb>
                  {/* <h4 className="page-title">Projects</h4> */}
                </div>
              </div>
              {/*<!-- /Page Title -->*/}

              {/*<!-- Search Filter -->*/}
              <div className="row filter-row">
                <div className="col-sm-6 col-md-3">
                  <div className="form-group form-focus">
                    <input type="text" className="form-control floating" />
                    <label className="focus-label">Project Name</label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3">
                  <div className="form-group form-focus">
                    <input type="text" className="form-control floating" />
                    <label className="focus-label">Employee Name</label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3">
                  <div className="form-group form-focus">
                    <Form.Control as="select">
                      <option>Select Roll</option>
                      <option>Web Developer</option>
                      <option>Web Designer</option>
                      <option>Android Developer</option>
                      <option>Ios Developer</option>
                    </Form.Control>
                    <label className="focus-label">Designation</label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3">
                  <a
                    href="Javascript:void(0);"
                    className="btn btn-success btn-block"
                  >
                    {" "}
                    Search{" "}
                  </a>
                </div>
              </div>
              {/*<!-- Search Filter -->*/}

              <div className="row staff-grid-row">
                {projects.length
                  ? projects.map((project) => {
                      let projectTeam = [];
                      let projectLeader = [];
                      if (project.team && project.team.length) {
                        projectTeam = project.team.map((employee) => {
                          let name = employee.personalInfo.name.first;
                          name += employee.personalInfo.name.middle
                            ? " " + employee.personalInfo.name.middle
                            : "";
                          name += employee.personalInfo.name.last
                            ? " " + employee.personalInfo.name.last
                            : "";
                          const id = employee._id;
                          const image = employee.emergencyInfo.image;
                          return { name: name, id: id, image: image };
                        });
                      }
                      if (
                        project.projectLeader &&
                        project.projectLeader.length
                      ) {
                        projectLeader = project.projectLeader.map(
                          (employee) => {
                            let name = employee.personalInfo.name.first;
                            name += employee.personalInfo.name.middle
                              ? " " + employee.personalInfo.name.middle
                              : "";
                            name += employee.personalInfo.name.last
                              ? " " + employee.personalInfo.name.last
                              : "";
                            const id = employee._id;
                            const image = employee.emergencyInfo.image;
                            return { name: name, id: id, image: image };
                          }
                        );
                      }
                      return (
                        <Project
                          key={project._id}
                          project={project}
                          projectTeamList={projectTeam}
                          projectLeaderList={projectLeader}
                          onEdit={this.editProject}
                          canShowControls={false}
                        />
                      );
                    })
                  : ""}
              </div>
            </div>
            {/*<!-- /Page Content -->*/}
          </div>
          {/*<!-- /Page Wrapper -->*/}
        </div>
        {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
        <div className="sidebar-overlay" data-reff=""></div>
      </body>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Projects));
