import React from 'react';
import { URLS } from '../urls';

export default class AddMemberModal extends React.Component {
    constructor(props) {
		super(props);
        this.state = {
            team : [],
            selectedMember : '',
            projectTeam : [],
            projectLeader : []
        };
    }
    componentWillReceiveProps(props){
        if(props.team && props.team.length){
            this.setState({
                team : props.team
            });
        }
        
        if(props.projectTeam && props.projectTeam.length){
            this.setState({
                projectTeam : props.projectTeam
            });
        }
        if(props.projectLeader && props.projectLeader.length){
            this.setState({
                projectLeader : props.projectLeader
            });
        }
    }
    changeSelectedMember = (id) => (evt) =>{
        this.setState({
            selectedMember : id
        })
    }
    addLeader = () => {
        const {selectedMember} = this.state
        if(selectedMember !== ''){
            window.$('#assign_leader').modal('toggle');
            this.props.addNewLeader(selectedMember);
        }else
            alert("Please select a team member.");
    }
    searchFun(e) {
        if(e.target.value.length !== 0) {
            let enteredValue = e.target.value.toLowerCase();
            let presentValue = this.props.team.filter(function(employee) {
                let name = employee.personalInfo.name.first;
				name += employee.personalInfo.name.middle ? (' ' + employee.personalInfo.name.middle) : '';
				name += employee.personalInfo.name.last ? (' ' + employee.personalInfo.name.last) : '';
                return name.toLowerCase().indexOf(enteredValue) > -1;
            })
            this.setState({team: presentValue})
        } else {
            this.setState({team: this.props.team})
        }
    }
    render(){
        const {team,selectedMember,projectLeader,projectTeam} = this.state;
        return(
				<div id='assign_leader' className='modal custom-modal fade' role='dialog'>
                <div className='modal-dialog modal-dialog-centered' role='document'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Assign the user to this project</h5>
                            <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                                <span aria-hidden='true'>&times;</span>
                            </button>
                        </div>
                        <div className='modal-body'>
                            <div className='input-group m-b-30'>
                                <input placeholder='Search to add' className='form-control search-input' type='text' onChange={e => this.searchFun(e)}/>
                                <span className='input-group-append'>
                                    <button className='btn btn-primary'>Search</button>
                                </span>
                            </div>
                            <div>
                                <ul className='chat-user-list'>
                                    {
                                        team.length && team.map((employee) => {
                                            if(projectLeader.find(rm => (rm.id === employee._id))){
                                                return null;
                                            }else if(projectTeam.find(rm => (rm.id === employee._id))){
                                                return null;
                                            }else{
                                                let name = employee.personalInfo.name.first;
											name += employee.personalInfo.name.middle ? (' ' + employee.personalInfo.name.middle) : '';
											name += employee.personalInfo.name.last ? (' ' + employee.personalInfo.name.last) : '';
											const id = employee._id;
                                            const image = employee.emergencyInfo.image;
                                            const designation = employee.jobInfo.title;
                                            return (
                                                <li key={id} onClick={this.changeSelectedMember(id)} >
                                                     <div className={id ===selectedMember ? 'media active' : 'media'} style={{cursor : 'pointer'}}>
                                                            <span className='avatar'><img alt='' src={image ? (URLS.backendStatic + '/' + image) : 'assets/img/profiles/avatar-09.jpg'} /></span>
                                                            <div className='media-body align-self-center text-nowrap'>
                                                                <div className='user-name'>{name}</div>
                                                                <span className='designation'>{designation}</span>
                                                            </div>
                                                        </div>
                                                </li>
                                            );
                                            }
                                            
                                        })
                                    }
                                </ul>
                            </div>
                            <div className='submit-section'>
                                <button className='btn btn-primary submit-btn' onClick={this.addLeader}>submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}