import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import asyncComponent from './AsyncComponent';
import { URLS } from './urls';
import { updateUser } from './api/ApiAction';

class CheckAPI extends Component {
    constructor(props) {
        super(props);
        this.state = {
            verifyDetails: false,
            showLoader: false, // required??
        };
        const self = this; // use this

        const token = sessionStorage.getItem('token');
        if (token) { // validate in frontend
            fetch(`${URLS.backendUsers}/`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'some-header': 'yes',
                    'Content-Type': 'application/json',
                    Authorization: `Token ${token}`,
                },
                body: JSON.stringify({ portal: 'preparation' }),
            })
                .then((response) => {
                    if (response.ok) { // status = 200
                        response.json().then((responseJson) => {
                            if (window.location.pathname === '/') self.props.history.push(URLS.dashboard);
                            if (!responseJson.user.username) {
                                self.setState({ email: responseJson.user.email });
                                self.getUserData(responseJson.user);
                                // self.props.updateUserData(responseJson.user);
                            } else {
                                self.setState({ showLoader: false });
                                self.props.updateUserData(responseJson.user);
                            }
                            self.props.updateUserData2({
                                topics: responseJson.topics,
                                difficulty: responseJson.difficulty,
                                feeds: responseJson.feeds,
                                leaderboard: responseJson.leaderboard,
                                completedAssessments: responseJson.completedAssessments,
                                assessments: responseJson.assessments,
                            });
                        });
                    } else if (response.status === 401) { // some backend error
                        response.json().then((responseJson) => {
                            if (responseJson.message === 'Unauthorized') {
                                sessionStorage.removeItem('token');
                                sessionStorage.setItem('isLoggedIn', false);
                                this.props.history.push(URLS.landingPage);
                            }
                        });
                    }
                })
                .catch(error => {console.log('yooo. check json2', error)}) // network error
        } else {
            if (window.location.pathname !== '/') {
                this.props.history.push(URLS.landingPage)
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.User._id &&
            (!this.props.User._id ||
                nextProps.User._id !== this.props.User._id)
        ) {
            //extract token
            if (!nextProps.UserData.username) {
                this.setState({ email: nextProps.UserData.email });
                this.getUserData(nextProps.UserData);
            } else {
                this.setState({ showLoader: false });
            }
        }
    }

    onVerify = UserData => {
        this.setState({ verifyDetails: false });
        console.log('user verified!!');
        this.props.updateUserData(UserData); // change to updateUserData2
    }

    render() {
        let { verifyDetails } = this.state
        return (
            <div>
                
            </div>
        )
    }
}

//{verifyDetails && <AsyncVerifyDetails onVerify={this.onVerify} />}

const mapStateToProps = (state) => {
    return {
        User: state.api.User,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateUser: user => dispatch(updateUser(user)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(CheckAPI));
