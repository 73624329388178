import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { Dropdown, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { URLS } from "../urls";
import DatePicker from "react-datetime";
import {
  branch_id,
  checkDates,
  checkDays,
  fetchActionsUtil,
} from "../../utils";
import { read, utils } from "xlsx";

import { converArrayToObject } from "../../utils";

import { toast } from "react-toastify";
import ModalLoader from "../ModalLoader";
import moment from "moment";
import currency from "currency.js";
// import { upperFirst } from "lodash";
import SendForApprovalModal from "./SendForApproval";
import { AddOvertimeModal } from "./AddOverTime";
import { ExcelIcon } from "../icons";

function Overtime({ allowance }) {
  const [showAddOvertimeModal, setShowAddOvertimeModal] = useState(false);
  const [overtimes, setOvertimes] = useState([]);
  const [overtimeIdToDelete, setOvertimeIdToDelete] = useState();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    startDate: "",
    endDate: "",
    status: "",
  });
  const [selected, setSelected] = useState({});
  const [showForApproval, setShowForApproval] = useState(false);
  const [statsOvertime, setStatsOvertime] = useState({
    employee: 0,
    success: 0,
    pending: 0,
    reject: 0,
    sent: 0,
    paid: 0,
  });

  const getOvertimes = () => {
    setLoading(true);
    const { startDate, endDate, status } = filter;
    return new Promise(async (resolve, reject) => {
      const token = "dummy token";
      fetch(
        `${URLS.backendOvertime}/all/${branch_id}?startDate=${startDate}&endDate=${endDate}&status=${status}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          setLoading(false);
          setOvertimes([...result.overtimes]);
          resolve();
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          // toast("Unable to get, please refresh your browser", {
          //   type: "error",
          // });
          reject();
        });
    });
  };

  const setUp = async () => {
    try {
      await Promise.all([getOvertimes()]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setUp();
    return () => {};
  }, []);

  const deleteOvertime = () => {
    window.$("#delete_overtime").modal("toggle");
    const token = "dummy token";
    setLoading(true);
    fetch(`${URLS.backendOvertime}/delete/${overtimeIdToDelete}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          const removedDeleted = overtimes.filter(
            (overtime) => overtime._id !== overtimeIdToDelete
          );
          setOvertimes(removedDeleted);
          toast("Deleted", {
            type: "success",
          });
        } else {
          throw Error();
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast("Unable to delete", {
          type: "error",
        });
        setLoading(false);
      });
  };

  const changeStatus = ({ overtimeId, status }) => {
    const token = "dummy token";
    setLoading(true);
    fetch(`${URLS.backendOvertime}/change-status`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ overtimeId, status }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          const updatedOvertimes = overtimes.map((el) => {
            if (el._id === overtimeId) {
              return { ...el, status };
            }
            return el;
          });
          setOvertimes(updatedOvertimes);
          toast("Status changed", {
            type: "success",
          });
          status === "Approved" && setShowForApproval(true);
        } else {
          throw Error();
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast("Unable to change status", {
          type: "error",
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    const success = overtimes.filter((el) => el.status === "Approved").length;
    const pending = overtimes.filter((el) => el.status === "Pending").length;
    const reject = overtimes.filter((el) => el.status === "Declined").length;
    const sent = overtimes.filter(
      (el) => el.status === "Sent for Approval"
    ).length;
    const paid = overtimes.filter((el) => el.status === "Paid").length;
    setStatsOvertime({
      employee: overtimes.length,
      success,
      pending,
      reject,
      sent,
      paid,
    });
  }, [overtimes]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      getOvertimes();
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [filter]);

  const getAllowanceMoney = ({ bene, hours, employees, employeeId, ...el }) => {
    let employee = employees.find((el) => el?.Staff_ID === employeeId);
    let totalSum = 0;
    let sum = 0;
    let total = 0;

    if (!employee) {
      return {};
    }

    employee = {
      ...employee?.personalInfo,
      ...employee?.jobInfo,
      ...converArrayToObject(
        employee?.jobInfo?.usergrade?.custom,
        "value",
        "name",
        true
      ),
      ...employee?.jobInfo?.usergrade?.salary,
      userId: employee.userId,
    };

    let current = moment(new Date()).daysInMonth();

    if (employee?.startDate) {
      const startDate = moment(employee?.startDate, "DD/MM/YYYY");

      const endMonth = moment(new Date()).endOf("month");
      const curr = endMonth.diff(startDate, "days");
      if (curr < current) {
        current = curr;
      }
    }

    bene = {
      ...bene,
      ...converArrayToObject(bene["customSettings"], "status", "name"),
    };
    // eslint-disable-next-line no-unused-vars
    for (const key in bene) {
      // When its Percentage
      if (
        key !== "enabled" &&
        key !== "earned" &&
        bene["paymentType"] === "Percentage" &&
        bene[key] === true
      ) {
        sum += parseFloat(employee[key] ? employee[key] : 0);
      }

      // When is Static
      if (bene["paymentType"] === "Static") {
        total = bene.percentage;
      }
    }

    // Calculating total by Percentage
    if (bene["paymentType"] === "Percentage") {
      if (bene["calculatePer"] === "hour" || bene["calculatePer"] === "day") {
        total = ((bene.percentage / 100) * sum).toFixed(2);
        total = total * hours;
        totalSum += parseFloat(total);
      } else {
        total = ((bene.percentage / 100) * sum).toFixed(2);
        totalSum += parseFloat(total);
      }
    }

    // Calculating total by Static Amount
    if (bene["paymentType"] === "Static") {
      if (bene["calculatePer"] === "hour" || bene["calculatePer"] === "day") {
        total = total * hours;
        totalSum = parseFloat(total);
      } else {
        totalSum = parseFloat(total);
      }
    }

    //console.log(employee);
    return { totalSum, employeeId: employee?.userId, current, ...el };
  };

  const getAllowanceForUser = ({ id, employees, contracts, allowances }) => {
    let usersAllowance = [];
    let maturity = true;
    let duration = true;

    // Find the selected user
    const user = employees.find((el) => el?.Staff_ID === id);

    if (user) {
      const jobInfo = user?.jobInfo;

      // get the contract name from the selected user
      const usedcontract = contracts.find(
        (el) => el._id === jobInfo?.contractType
      )?.contractName;

      // console.log({ user, usedcontract, jobInfo });

      user.usedcontract = usedcontract !== undefined ? usedcontract : "";

      allowances.forEach((el) => {
        // Check the maturity period of the allowance
        if (el["paymentPeriod"] === "CustomPeriod") {
          const getTime = el["maturityPeriod"]?.split(" ");
          maturity = getTime
            ? checkDates(jobInfo["startDate"], getTime[0], getTime[1])
            : false;
        }

        // Check for Duration Period of the allowance
        if (el["durationType"] === "CustomDuration") {
          duration = checkDays({
            startDate: el["dateRange"]["startDate"],
            endDate: el["dateRange"]["endDate"],
          });
        }

        if (maturity && duration) {
          if (
            el["DeductType"] === "Employees" &&
            el["selectedDeducts"]?.includes(user["userId"])
          ) {
            usersAllowance.push(el);
          } else if (
            el["DeductType"] === "Job Grade" &&
            el["selectedDeducts"]?.includes(jobInfo["grade"]?._id)
          ) {
            usersAllowance.push(el);
          } else if (
            el["DeductType"] === "Contract" &&
            el["selectedDeducts"]?.includes(jobInfo["contractType"])
          ) {
            usersAllowance.push(el);
          } else if (el["DeductType"] === "All") {
            usersAllowance.push(el);
          } else if (
            el["DeductType"] === "Permanent" &&
            user["usedcontract"] === undefined
          ) {
            usersAllowance.push(el);
          }
        }
      });
    }
    return usersAllowance;

    //console.log(usersAllowance);
  };

  async function handleFile({ eventData: e }) {
    setLoading(true);
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async function (e) {
        const workbook = read(e.target.result, {
          cellDates: true,
          dateNF: "yyyy-MM-dd HH:mm:ss.SSS",
        });
        let rows = utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]],
          {
            defval: "",
            dateNF: "yyyy-MM-dd HH:mm:ss.SSS",
          }
        );
        // console.log("excel", rows);

        // TODO - validate excel

        try {
          let { allowance } = await fetchActionsUtil(
            `${URLS.backendAllowance}/enabled-earned/${branch_id}`
          );

          let { employees } = await fetchActionsUtil(
            `${URLS.backendEmployees}/get/${branch_id}`
          );

          let { contracts } = await fetchActionsUtil(
            `${URLS.backendContracts}/get`
          );

          allowance = allowance.filter(
            (el) => el.AttachedTo !== "" && el.AttachedTo !== undefined
          );

          // console.log(allowance);
          let allowUser = [];
          const nowDate = new Date();
          const overtime = [];
          const users = [];
          rows.forEach((el) => {
            allowUser = getAllowanceForUser({
              id: `${el["Employee ID"]}`.trim(),
              employees,
              contracts,
              allowances: allowance,
            });
            // console.log(allowUser);
            allowUser.forEach((all) => {
              const data = getAllowanceMoney({
                bene: all,
                hours: el[all?.AttachedTo],
                employees,
                employeeId: `${el["Employee ID"]}`.trim(),
                ...el,
              });

              if (Object.values(data).length > 0) {
                if (data.totalSum > 0) {
                  const state = {
                    allowanceId: all._id,
                    branch_id,
                    description: el["COMMENT"],
                    employeeId: data?.employeeId,
                    hours: el[all?.AttachedTo],
                    overtimeDate: new Date(),
                    total: data.totalSum,
                    status: "Approved",
                    approvedDate: new Date(),
                  };

                  const addedArray = [
                    {
                      allowanceId: all._id,
                      amount: data.totalSum,
                      days: el[all?.AttachedTo],
                      description: el["COMMENT"],
                      job: "",
                      name: all?.name,
                      overtimeDate: new Date(),
                      overtimeDateTo: new Date(),
                      tittle: "",
                    },
                  ];

                  overtime.push({ state, addedArray });
                  const already = users.find(
                    (ft) => ft.employeeId === data["Employee ID"]
                  );

                  if (!already) {
                    users.push({
                      DaysCommitted: data.current,
                      DayOn: data["DAYS ON"],
                      TransitDays: data["TRANSIT DAYS"],
                      OverStayDays: data["OVERSTAY DAYS"],
                      ArrearsDays: data["AREARS DAYS"],
                      employeeId: data["Employee ID"],
                      month: nowDate.getMonth() + 1,
                      year: nowDate.getFullYear(),
                      branch_id,
                    });
                  }
                }
              }
            });
          });

          if (overtime.length < 1 && users.length < 1) {
            setLoading(false);
            return toast.success("Nothing to add");
          }
          const save = await fetchActionsUtil(
            `${URLS.backendOvertime}/multiple-save`,
            "POST",
            "",
            { overtime, users }
          );
          if (save.success) {
            setLoading(false);
            toast.success("Successfully Added");
            getOvertimes();
          } else {
            setLoading(false);
            toast.error("Error!!!");
          }

          // console.log({ overtime, users });
        } catch (err) {
          setLoading(false);
          console.log(err);
          toast.error("Error!!!");
        }
      };
      reader.readAsArrayBuffer(file);
      e.target.value = "";
    }
  }

  return (
    <>
      <body>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex, nofollow"></meta>
          <title>InvexERP</title>
        </Helmet>
        <div className="main-wrapper">
          <Topbar />
          <Sidebar />
          {/*<!-- Page Wrapper -->*/}
          <div className="page-wrapper">
            {/*<!-- Page Content -->*/}
            <div className="content container-fluid">
              {/*<!-- Page Title -->*/}
              <div className="row">
                <div className="col-sm-8 col-6">
                  <h4 className="page-title">Allowance Application</h4>
                </div>
                <div className="col-sm-4 col-6 text-right m-b-30">
                  <button
                    className="btn add-btn"
                    onClick={() => setShowAddOvertimeModal(true)}
                  >
                    <i className="fa fa-plus"></i> Apply
                  </button>
                </div>
              </div>
              {/*<!-- /Page Title -->*/}

              {/*<!-- Overtime Statistics -->*/}
              <section className="stats stats-3">
                <div className="stat">
                  <div className="icon icon-3">
                    <i class="fa fa-cubes"></i>
                  </div>
                  <div className="details">
                    <p>{statsOvertime.employee}</p>
                    <span className="dashboard-links">Total</span>
                  </div>
                </div>
                <div className="stat">
                  <div className="icon icon-1">
                    <i class="fa fa-cubes"></i>
                  </div>
                  <div className="details">
                    <p>{statsOvertime.success}</p>
                    <span className="dashboard-links">Approved Request </span>
                  </div>
                </div>
                <div className="stat">
                  <div className="icon">
                    <i class="fa fa-cubes"></i>
                  </div>
                  <div className="details">
                    <p>{statsOvertime.pending}</p>
                    <span className="dashboard-links">Pending Request</span>
                  </div>
                </div>
                <div className="stat">
                  <div className="icon icon-3">
                    <i class="fa fa-cubes"></i>
                  </div>
                  <div className="details">
                    <p>{statsOvertime.sent}</p>
                    <span className="dashboard-links">Sent For Approval</span>
                  </div>
                </div>
                <div className="stat">
                  <div className="icon icon-1">
                    <i class="fa fa-cubes"></i>
                  </div>
                  <div className="details">
                    <p>{statsOvertime.paid}</p>
                    <span className="dashboard-links">Paid</span>
                  </div>
                </div>
                <div className="stat">
                  <div className="icon icon-2">
                    <i class="fa fa-cubes"></i>
                  </div>
                  <div className="details">
                    <p>{statsOvertime.reject}</p>
                    <span className="dashboard-links">Rejected Reject</span>
                  </div>
                </div>
              </section>
              {/*<!-- /Overtime Statistics -->*/}
              <hr />
              {/*<!-- Search Filter -->*/}
              <div className="row filter-row mb-3">
                {/* <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus">
                    <input
                      type="text"
                      className="form-control floating"
                      onChange={(e) => setSearchInputs(e.target.value)}
                    />
                    <label className="focus-label">Employee Name</label>
                  </div>
                </div> */}

                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus">
                    <Form.Control
                      as="select"
                      value={filter.status}
                      onChange={(e) =>
                        setFilter({ ...filter, status: e.target.value })
                      }
                    >
                      <option value=""> -- Select -- </option>
                      <option value="Pending"> Pending </option>
                      <option value="Approved"> Approved </option>
                      <option value="Declined"> Declined </option>
                      <option value="Sent for Approval">
                        Sent for Approval
                      </option>
                      <option value="Paid"> Paid </option>
                    </Form.Control>
                    <label className="focus-label">Overtime Status</label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus">
                    <div className="cal-icon">
                      <DatePicker
                        value={filter.startDate}
                        dateFormat="DD MMM, YYYY"
                        onChange={(date) => {
                          setFilter({
                            ...filter,
                            startDate: date,
                          });
                        }}
                        closeOnSelect={true}
                        timeFormat={false}
                      />
                    </div>
                    <label className="focus-label">From</label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus">
                    <div className="cal-icon">
                      <DatePicker
                        value={filter.endDate}
                        dateFormat="DD MMM, YYYY"
                        onChange={(date) => {
                          setFilter({
                            ...filter,
                            endDate: date,
                          });
                        }}
                        closeOnSelect={true}
                        timeFormat={false}
                      />
                    </div>
                    <label className="focus-label">To</label>
                  </div>
                </div>

                <div className="">
                  <button
                    onClick={() => {
                      setFilter({
                        endDate: "",
                        startDate: "",
                        status: "",
                      });
                    }}
                    className="btn  btn-secondary"
                  >
                    Clear
                  </button>
                </div>

                <div className="mx-3">
                  <Dropdown>
                    <Dropdown.Toggle variant="" bsPrefix="">
                      Import <ExcelIcon color="#008000" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      popperConfig={{
                        strategy: "fixed",
                      }}
                      renderOnMount
                      className="text-center"
                    >
                      <Dropdown.Item
                        as="label"
                        className="p-cursor"
                        title="Import"
                      >
                        <input
                          type="file"
                          className="d-none"
                          onChange={(e) => handleFile({ eventData: e })}
                          accept=".xlsx"
                        />
                        Import
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              {/*<!-- /Search Filter -->*/}

              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr className="table-dark">
                          <th>Employee</th>
                          <th>Overtime</th>
                          <th className="text-center">From</th>
                          <th className="text-center">To</th>
                          <th>Days</th>
                          <th>Job Done</th>
                          <th>Amount</th>
                          <th className="text-center">Status</th>
                          <th className="text-right">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {overtimes?.length
                          ? overtimes.map((overtime) => {
                              const { employee } = overtime;
                              return (
                                <>
                                  <tr className="table-primary">
                                    <td>
                                      <h2 className="table-avatar blue-link">
                                        <Link
                                          to={`profile/${employee?._id}`}
                                          className="avatar"
                                        >
                                          <img
                                            alt=""
                                            src="assets/img/profiles/avatar-02.jpg"
                                          />
                                        </Link>
                                        <Link to={`profile/${employee?._id}`}>
                                          {employee?.personalInfo.name.first +
                                            " " +
                                            employee?.personalInfo.name.last}
                                        </Link>
                                      </h2>
                                    </td>
                                    <td>{overtime?.allowance?.name}</td>

                                    <td></td>
                                    <td></td>
                                    <td>
                                      {overtime.hours > 1
                                        ? overtime.hours + " days"
                                        : overtime.hours + " day"}
                                    </td>
                                    <td></td>
                                    <td>
                                      {currency(overtime?.total, {
                                        symbol: "",
                                      }).format()}
                                    </td>
                                    <td className="text-center">
                                      {overtime.status === "Paid" ? (
                                        <p className="btn btn-white btn-sm btn-rounded">
                                          <span className="fa fa-dot-circle-o text-success"></span>{" "}
                                          Paid
                                        </p>
                                      ) : (
                                        <Dropdown className="action-label">
                                          <Dropdown.Toggle
                                            className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                            variant="btn-white"
                                          >
                                            {overtime.status ===
                                            "Sent for Approval" ? (
                                              <i className="fa fa-dot-circle-o text-purple"></i>
                                            ) : overtime.status ===
                                              "Pending" ? (
                                              <i className="fa fa-dot-circle-o text-info"></i>
                                            ) : overtime.status ===
                                              "Approved" ? (
                                              <i className="fa fa-dot-circle-o text-success"></i>
                                            ) : (
                                              <i className="fa fa-dot-circle-o text-danger"></i>
                                            )}
                                            {overtime.status}
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu
                                            popperConfig={{
                                              strategy: "fixed",
                                            }}
                                            renderOnMount
                                            className="dropdown-with-icons"
                                          >
                                            {/* {overtime.status !== "Pending" &&
                                              overtime.status !== "Approved" &&
                                              overtime.status !== "Declined" &&
                                              overtime.status !==
                                                "Sent for Approval" && (
                                                <Dropdown.Item
                                                  as="button"
                                                  onClick={() =>
                                                    changeStatus({
                                                      overtimeId: overtime._id,
                                                      status: "Pending",
                                                    })
                                                  }
                                                >
                                                  <i className="fa fa-dot-circle-o text-info"></i>
                                                  Pending
                                                </Dropdown.Item>
                                              )}
                                            {overtime.status !== "Approved" &&
                                              overtime.status !== "Declined" &&
                                              overtime.status !== "Pending" &&
                                              overtime.status !==
                                                "Sent for Approval" && (
                                                <Dropdown.Item
                                                  as="button"
                                                  onClick={() => {
                                                    setSelected({
                                                      ...overtime,
                                                      newStatus:
                                                        "Sent for Approval",
                                                    });
                                                    changeStatus({
                                                      overtimeId: overtime._id,
                                                      status: "Approved",
                                                    });
                                                  }}
                                                >
                                                  <i className="fa fa-dot-circle-o text-success"></i>
                                                  Approved
                                                </Dropdown.Item>
                                              )}
                                            {overtime.status !== "Declined" &&
                                              overtime.status !== "Approved" &&
                                              overtime.status !== "Pending" &&
                                              overtime.status !==
                                                "Sent for Approval" && (
                                                <Dropdown.Item
                                                  as="button"
                                                  onClick={() =>
                                                    changeStatus({
                                                      overtimeId: overtime._id,
                                                      status: "Declined",
                                                    })
                                                  }
                                                >
                                                  <i className="fa fa-dot-circle-o text-danger"></i>
                                                  Declined
                                                </Dropdown.Item>
                                              )} */}
                                            {overtime.status === "Pending" &&
                                              overtime.status !==
                                                "Sent for Approval" && (
                                                <Dropdown.Item
                                                  as="button"
                                                  onClick={() => {
                                                    setSelected({
                                                      ...overtime,
                                                      newStatus:
                                                        "Sent for Approval",
                                                    });
                                                    setShowForApproval(true);
                                                  }}
                                                >
                                                  <i className="fa fa-dot-circle-o text-purple"></i>
                                                  Send for Approval
                                                </Dropdown.Item>
                                              )}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      )}
                                    </td>
                                    <td className="text-right">
                                      {overtime.status === "Pending" ? (
                                        <div className="dropdown dropdown-action">
                                          <a
                                            href="javaScript:void(0);"
                                            className="action-icon dropdown-toggle"
                                            data-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i className="material-icons">
                                              more_vert
                                            </i>
                                          </a>
                                          <div className="dropdown-menu dropdown-menu-right">
                                            <a
                                              className="dropdown-item"
                                              href="javaScript:void(0);"
                                              data-toggle="modal"
                                              data-target="#delete_overtime"
                                              onClick={() => {
                                                setOvertimeIdToDelete(
                                                  overtime._id
                                                );
                                              }}
                                            >
                                              <i className="fa fa-trash-o m-r-5"></i>{" "}
                                              Delete
                                            </a>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  </tr>
                                  {overtime?.details?.map((el) => (
                                    <tr className="table-light">
                                      <td></td>
                                      <td>{el?.name}</td>
                                      <td>
                                        {moment(el?.overtimeDate).format(
                                          "DD MMM, YYYY"
                                        )}
                                      </td>
                                      <td>
                                        {el?.overtimeDateTo
                                          ? moment(el?.overtimeDateTo).format(
                                              "DD MMM, YYYY"
                                            )
                                          : moment(el?.overtimeDate).format(
                                              "DD MMM, YYYY"
                                            )}
                                      </td>
                                      <td>
                                        {el?.days
                                          ? el?.days > 1
                                            ? el?.days + " days"
                                            : 1 + " day"
                                          : 1 + " day"}
                                      </td>
                                      <td>{el?.description}</td>
                                      <td>
                                        {currency(el?.amount, {
                                          symbol: "",
                                        }).format()}
                                      </td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  ))}
                                  <tr>
                                    <td></td>
                                  </tr>
                                </>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Page Content -->*/}
          </div>

          {/*<!-- /Page Wrapper -->*/}
        </div>
        {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
        <div className="sidebar-overlay" data-reff=""></div>
      </body>
      {/*<!-- Delete Overtime Modal -->*/}
      <div
        className="modal custom-modal fade"
        id="delete_overtime"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="form-header">
                <h3>Delete Overtime</h3>
                <p>Are you sure want to Delete this?</p>
              </div>
              <div className="modal-btn delete-action">
                <div className="row">
                  <div className="col-6">
                    <a
                      href="javascript:void(0);"
                      className="btn btn-danger continue-btn"
                      onClick={() => deleteOvertime()}
                    >
                      Delete
                    </a>
                  </div>
                  <div className="col-6">
                    <a
                      href="javascript:void(0);"
                      data-dismiss="modal"
                      className="btn btn-primary cancel-btn"
                    >
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<!-- /Delete Overtime Modal -->*/}

      {showAddOvertimeModal && (
        <AddOvertimeModal
          allowance={allowance}
          setShowAddOvertimeModal={setShowAddOvertimeModal}
          refetch={() => setUp()}
          setSelected={setSelected}
          openApproval={setShowForApproval}
        />
      )}
      <ModalLoader show={loading} />
      {showForApproval && selected && (
        <SendForApprovalModal
          show={showForApproval}
          onHide={() => setShowForApproval(false)}
          selected={selected}
          changeStatus={changeStatus}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    // User: state.api.User,
    deductionSettings: state.deductionSettings.deductions,
    bonusSettings: state.bonusSettings.bonus,
    allowance: state.allowanceReducer.allowances,
    benefit: state.BenefitReducer.benefits,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Overtime);
