function addAllAllowance(allowance) {
  return {
    type: "ADD_ALLOWANCES",
    payload: allowance,
  };
}

function addAllowance(allowance) {
  return {
    type: "ADD_ALLOWANCE",
    payload: allowance,
  };
}

function deleteAllowance(slug) {
  return {
    type: "DELETE_ALLOWANCE",
    payload: slug,
  };
}

export { addAllAllowance, addAllowance, deleteAllowance };
