function addAllBenefit(benefit) {
  return {
    type: "ADD_BENEFITS",
    payload: benefit,
  };
}

function addBenefit(benefit) {
  return {
    type: "ADD_BENEFIT",
    payload: benefit,
  };
}

function deleteBenefit(slug) {
  return {
    type: "DELETE_BENEFIT",
    payload: slug,
  };
}

export { addAllBenefit, addBenefit, deleteBenefit };
