import React from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import { Form, Col, Row, Image, textarea, Popover } from "react-bootstrap";
import { URLS } from "../urls";
import { Helmet } from "react-helmet";
import Cookies from "universal-cookie";
import Topbar from "../topbar/Topbar";
import AssignTaskModal from "./AssignTaskModal";
import AddProjectModal from "../projects/AddProjectModal";
import Multiselect from "../projects/Multiselect";
import ImageNameList from "../projects/ImageNameList";
import "../../../src/assets/css/bootstrap-datetimepicker.min.css";
import moment from "moment";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import DatePicker from "react-datetime";
import TaskFilter from "./TasksFilter";
import { getAllEmployees } from "../../services/taskServices";
import { getAllContracts } from "../../services/contractServices";
import { getAllProjects } from "../../services/projectServices";
import { getByUserId } from "../../services/employeeServices";

const allMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
class Tasks extends React.Component {
  constructor(props) {
    super(props);
    this.fetchTaskByProjectId = this.fetchTaskByProjectId.bind(this);
    this.changeStatusOfTask = this.changeStatusOfTask.bind(this);
    this.changeSelectedTask = this.changeSelectedTask.bind(this);
    this.assignTask = this.assignTask.bind(this);

    const cookies = new Cookies();
    const userId = cookies.get("userId");
    const isSuperAdmin = cookies.get("isSuperAdmin");

    this.state = {
      employee: null,
      newTask: {
        taskName: "",
        taskDetail: "",
        startDate: moment(new Date()).format("DD/MM/YYYY"),
        dueDate: "",
      },
      newComment: {
        employeeId: null,
        employeeImage: "",
        employeeName: "",
        date: "",
        time: "",
        comment: "",
      },
      newFile: {
        employeeId: null,
        employeeImage: "",
        employeeName: "",
        date: "",
        time: "",
        file: {
          message: "",
          original: "",
          unique: "",
          ext: "",
          size: "",
        },
      },
      fileObject: null,
      taskFilter: "ALL",
      projects: [],
      activeProjectId: "",
      tasks: {},
      userWiseTasks: {},
      selectedTask: null,
      teams: {},
      contractList: [],
      employeesList: [],
      showUserWise: false,
      leadersForNotification: {},
      isSubmitWait: false,
      submitText: "Submit",
      canAddToCalendar: false,
      userId: userId,
      isSuperAdmin: isSuperAdmin,
      activeTab: "",
      assignedTo: [],
    };
  }
  async componentWillMount() {
    const cookies = new Cookies();
    const id = cookies.get("userId");

    const token = "dummy token";
    const employeesByUserIdResponse = await getByUserId(token, id);
    const allEmployeesResponse = await getAllEmployees(token);
    const allContractsResponse = await getAllContracts(token);
    const allProjectsResponse = await getAllProjects(token);

    const employee = employeesByUserIdResponse
      ? employeesByUserIdResponse.employee
      : null;
    const employeesList = allEmployeesResponse
      ? allEmployeesResponse.employees
      : [];
    const contractList = allContractsResponse
      ? allContractsResponse.contracts
      : [];
    const projects = allProjectsResponse ? allProjectsResponse.projects : [];
    let activeTab = "";

    if (contractList && contractList.length) {
      activeTab = contractList[0]._id;
    }

    this.setState({
      employee,
      employeesList,
      contractList,
      projects,
      activeTab,
    });

    if (projects && projects.length) {
      const project = projects[0];
      this.setState({
        activeProjectId: project._id,
      });
      projects.forEach(
        function (_project) {
          const { teams, leadersForNotification } = this.state;
          leadersForNotification[_project._id] = _project.projectLeader;
          teams[_project._id] = _project.projectLeader.concat(_project.team);
          this.setState({
            teams: teams,
            leadersForNotification: leadersForNotification,
          });
        }.bind(this)
      );
      this.fetchTaskByProjectId(project._id);
      // this.fetchTeamByProjectId(project._id);
    }
  }
  fetchTaskByProjectId = (id) => {
    const token = "dummy token";
    fetch(`${URLS.backendTasks}/getByProjectId/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        const { tasks, activeProjectId, userWiseTasks } = this.state;
        tasks[activeProjectId] = result.tasks;
        this.setState({ tasks: tasks });
        if (result.tasks.length) {
          userWiseTasks[activeProjectId] = this.formatUserTasks(result.tasks);
          this.setState({ selectedTask: result.tasks[0], userWiseTasks });
        } else {
          userWiseTasks[activeProjectId] = null;
          this.setState({ selectedTask: null, userWiseTasks });
        }
      });
  };
  formatUserTasks = (pTasks) => {
    const result = pTasks.reduce(function (r, a) {
      r[a.assignedTo] = r[a.assignedTo] || [];
      r[a.assignedTo].push(a);
      return r;
    }, Object.create(null));
    const users = Object.keys(result);
    users.forEach((userId) => {
      let tasks = result[userId];
      if (userId === "undefined") {
        delete result[userId];
        userId = "NOT_ASSIGNED";
      }
      result[userId] = { userTasks: tasks };
      const totalTasksLength = tasks.length;
      const completed = tasks.filter((task) => {
        return task.status === "COMPLETE";
      });
      const completedTasksLength = completed.length;
      let delay = 0;
      if (completedTasksLength) {
        for (let i = 0; i < completedTasksLength; i++) {
          let item = completed[i];
          const diff = moment(item.completeDate, "DD/MM/YYYY").diff(
            moment(item.dueDate, "DD/MM/YYYY"),
            "days"
          );
          if (diff > 0) delay += diff;
        }
      }

      result[userId]["progress"] =
        totalTasksLength > 0
          ? ((completedTasksLength * 100) / totalTasksLength).toFixed(2)
          : 0;
      result[userId]["rate"] = completedTasksLength
        ? (completedTasksLength / (completedTasksLength + delay * 0.1)) * 100
        : 0;
    });
    return result;
  };
  fetchTeamByProjectId = (id) => {
    const token = "dummy token";
    fetch(`${URLS.backendProjects}/team/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        const { teams, activeProjectId } = this.state;
        teams[activeProjectId] = result.team;
        this.setState({
          teams: teams,
        });
      });
  };
  showNewTaskPanel = () => {
    let newTaskWrapperOffset = window.$(".new-task-wrapper").offset().top;
    window.$(this).toggleClass("visible");
    window.$(".new-task-wrapper").toggleClass("visible");
    window.$("#new-task").focus();
    window.$("body").animate(
      {
        scrollTop: newTaskWrapperOffset,
      },
      1000
    );
  };
  closeNewTaskPanel = () => {
    this.setState({
      newTask: {
        taskName: "",
        taskDetail: "",
        startDate: moment(new Date()).format("DD/MM/YYYY"),
        dueDate: "",
      },
    });
    // window.$('.add-task-btn').toggleClass('visible');
    // window.$('.new-task-wrapper').toggleClass('visible');
  };
  updateNewTaskName = (e) => {
    const { newTask } = this.state;
    newTask.taskName = e.target.value;
    this.setState({
      newTask,
    });
  };
  updateNewTaskDetail = (e) => {
    const { newTask } = this.state;
    newTask.taskDetail = e.target.value;
    this.setState({
      newTask,
    });
  };

  updateNewTaskStartDate = (event) => {
    const { newTask } = this.state;
    newTask.startDate = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    this.setState({
      newTask,
    });
  };
  updateNewTaskDueDate = (event) => {
    const { newTask } = this.state;
    newTask.dueDate = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    this.setState({
      newTask,
    });
  };
  updateNewTaskAssign = (event) => {
    const { newTask } = this.state;
    newTask.assignedTo = event.target.value;
    this.setState({
      newTask,
    });
  };
  addNewTask = (event) => {
    event.preventDefault();

    const token = "dummy token";
    let {
      newTask,
      activeProjectId,
      tasks,
      canAddToCalendar,
      userId,
      assignedTo,
    } = this.state;

    const date = new Date();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    if (newTask.taskName.trim() === "" || newTask.dueDate.trim() === "") {
      alert("Please fill required fields");
      return;
    }
    if (
      moment(newTask.dueDate, "DD/MM/YYYY").diff(
        moment(newTask.startDate, "DD/MM/YYYY"),
        "days"
      ) < 0
    ) {
      alert("Due Date should be greater than or equal to Start Date");
      return;
    }
    let checkTitleInAllTasks = tasks[activeProjectId];
    checkTitleInAllTasks = checkTitleInAllTasks.find(
      (t) =>
        t.taskName.trim().toLowerCase() ===
        newTask.taskName.trim().toLowerCase()
    );
    if (checkTitleInAllTasks) {
      alert("Task name already exists");
      return;
    }

    const task = {
      taskName: newTask.taskName,
      startDate: newTask.startDate,
      dueDate: newTask.dueDate,
      project: activeProjectId,
      status: "INCOMPLETE",
      taskDetail: newTask.taskDetail,
      createDate: date.getDate() + "/" + month + "/" + date.getFullYear(),
    };
    task.createdBy = userId;

    if (assignedTo || !assignedTo.length) {
      const arr = [];
      for (const item of assignedTo) {
        arr.push(item.id);
      }
      task.assignedTo = arr;
    } else {
      delete task["assignedTo"];
    }

    this.setState({
      isSubmitWait: true,
      submitText: "Submitting ...",
    });

    fetch(`${URLS.backendTasks}/add`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ task, canAddToCalendar }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (tasks[activeProjectId]) {
          tasks[activeProjectId].unshift(result.task);
          this.setState({
            tasks: tasks,
            selectedTask: result.task,
          });
        } else {
          tasks[activeProjectId] = [];
          tasks[activeProjectId].push(result.task);
          this.setState({
            tasks: tasks,
            selectedTask: result.task,
          });
        }
        window.$("#add_task").modal("toggle");
        this.closeNewTaskPanel();
        let newTask = {
          taskName: "",
          taskDetail: "",
          startDate: moment(new Date()).format("DD/MM/YYYY"),
          dueDate: "",
          assignedTo: "Select Employee",
        };
        this.setState({
          isSubmitWait: false,
          submitText: "Submit",
          canAddToCalendar: false,
          newTask,
        });
        if (
          result.task &&
          result.task.assignedTo &&
          result.task.assignedTo !== ""
        )
          this.generateTaskNotification("ASSIGN", null, result.task);
      })
      .catch((err) => {
        // alert(err);
        this.setState({
          isSubmitWait: false,
          submitText: "Submit",
        });
      });
  };
  changeStatusOfTaskFromButton = (task) => (event) => {
    if (task.status === "INCOMPLETE") this.changeStatusOfTask(task);
    else if (task.status === "UNDER_REVIEW") this.approveTask(task);
  };
  approveTask(task) {
    const token = "dummy token";
    const cookies = new Cookies();
    const id = cookies.get("userId");
    const taskId = task._id;
    const { activeProjectId, tasks } = this.state;
    if (!task.assignedTo || task.assignedTo === "") {
      alert("Task is not assigned to anyone. Can not change status.");
      return;
    }
    task.status = "COMPLETE";
    task.approvedBy = id;
    task.approvedDate = new Date();
    fetch(`${URLS.backendTasks}/approveTask`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ task: task }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.task) {
          tasks[activeProjectId].forEach((projectTask) => {
            if (projectTask._id === taskId)
              projectTask.status = result.task.status;
          });
          this.setState({ tasks: tasks, selectedTask: result.task });
          this.generateTaskNotification("APPROVE", null, task);
        }
      });
  }
  changeStatusOfTask = (task) => {
    const token = "dummy token";
    if (!task.assignedTo || task.assignedTo === "") {
      alert("Task is not assigned to anyone. Can not change status.");
      return;
    }
    const taskId = task._id;
    const { activeProjectId, tasks } = this.state;
    const taskStatusOld = task.status;
    task.status = task.status === "INCOMPLETE" ? "UNDER_REVIEW" : "INCOMPLETE";
    if (task.status === "UNDER_REVIEW")
      task.completeDate = moment(new Date()).format("DD/MM/YYYY");
    fetch(`${URLS.backendTasks}/update`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ task: task }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.task) {
          tasks[activeProjectId].forEach((projectTask) => {
            if (projectTask._id === taskId)
              projectTask.status = result.task.status;
          });
          this.setState({ tasks: tasks, selectedTask: result.task });
          this.generateTaskNotification("TASK_STATUS", taskStatusOld, task);
        }
      });
  };
  deleteTask = (task) => {
    let confirmation = window.confirm("Are you sure to delete?");
    const deletedTask = task;
    if (confirmation) {
      const token = "dummy token";
      const taskId = task._id;
      const { activeProjectId, tasks } = this.state;
      fetch(`${URLS.backendTasks}/delete/${taskId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          let pTasks = tasks[activeProjectId];
          let newSelectedTask = null;
          for (let index = 0; index < pTasks.length; index++) {
            if (pTasks[index]._id === taskId) {
              if (index < pTasks.length - 1) {
                newSelectedTask = pTasks[index + 1];
              } else if (index === pTasks.length - 1 && pTasks.length === 1) {
                newSelectedTask = null;
              } else {
                newSelectedTask = pTasks[index - 1];
              }
              pTasks.splice(index, 1);
              break;
            }
          }
          tasks[activeProjectId] = pTasks;
          this.setState({
            tasks: tasks,
            selectedTask: newSelectedTask,
          });
          if (deletedTask.assignedTo && deletedTask.assignedTo !== "")
            this.generateTaskNotification("DELETE", null, deletedTask);
        });
    }
  };
  changeSelectedTask = (task) => {
    this.setState({
      selectedTask: task,
    });
    console.log("XO", this.state.selectedTask);
  };
  changeActiveProject = (id) => (evt) => {
    this.setState({
      activeProjectId: id,
    });
    this.fetchTaskByProjectId(id);
    // this.fetchTeamByProjectId(id);
  };
  assignTask = (id) => {
    const token = "dummy token";
    const { activeProjectId, tasks, selectedTask } = this.state;
    const taskId = selectedTask._id;
    selectedTask.assignedTo = id;
    fetch(`${URLS.backendTasks}/update`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ task: selectedTask }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.task) {
          tasks[activeProjectId].forEach((projectTask) => {
            if (projectTask._id === taskId) {
              projectTask.assignedTo = id;
            }
          });
          this.setState({
            tasks: tasks,
            selectedTask: result.task,
          });
          this.generateTaskNotification("ASSIGN", null, result.task);
        }
      });
  };
  updateProjectsList = (project) => {
    let { projects } = this.state;
    const id = project && project._id;
    if (id) {
      const token = "dummy token";
      fetch(`${URLS.backendProjects}/${id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          const project = result.project;
          if (project) {
            const { teams } = this.state;
            teams[project._id] = project.projectLeader.concat(project.team);
            this.setState({
              teams: teams,
            });
            let isExists = false;
            for (let index = projects.length - 1; index >= 0; --index) {
              if (projects[index]._id === id) {
                projects.splice(index, 1);
                projects.splice(index, 0, result.project);
                isExists = true;
                break;
              }
            }
            if (isExists) {
              this.setState({ projects: projects });
            } else {
              this.setState({ projects: projects.concat(result.project) });
            }
          }
        });
    }
  };
  showDateInFormat = (date) => {
    date = date.split("/");
    return allMonths[date[1] - 1] + " " + date[0] + ", " + date[2];
  };

  updateTaskDueDate = (event) => {
    const token = "dummy token";
    const { activeProjectId, tasks, selectedTask } = this.state;
    const taskId = selectedTask._id;
    // const date = new Date();
    // const month = ('0'+date.getMonth()+1).slice(-2);
    const dueDate = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    // const startDate = date.getDate()+'/'+month+'/'+ date.getFullYear()
    selectedTask.dueDate = dueDate;
    // selectedTask.startDate = selectedTask.startDate ? selectedTask.startDate : startDate;
    fetch(`${URLS.backendTasks}/update`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ task: selectedTask }),
    })
      .then((res) => res.json())
      .then((result) => {
        tasks[activeProjectId].forEach((projectTask) => {
          if (projectTask._id === taskId) {
            projectTask.dueDate = dueDate;
            // projectTask.startDate = startDate;
          }
        });
        this.setState({
          tasks: tasks,
          selectedTask: result.task,
        });
      });
  };

  updateNewComment = (event) => {
    const { newComment } = this.state;
    newComment.comment = event.target.value;
    this.setState({
      newComment,
    });
  };
  submitComment = (event) => {
    const token = "dummy token";
    let { newComment, employee, selectedTask } = this.state;
    const taskId = selectedTask._id;
    const { activeProjectId, tasks } = this.state;

    newComment.employeeId = employee._id;
    newComment.employeeName =
      employee.personalInfo.name.first + " " + employee.personalInfo.name.last;
    newComment.employeeImage = employee.emergencyInfo.image;
    const date = new Date();
    newComment.date =
      date.getDate() +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear();
    newComment.time =
      date.getHours() + ":" + ("0" + date.getMinutes()).slice(-2);
    if (selectedTask.comments) {
      selectedTask.comments.push(newComment);
    } else {
      selectedTask.comments = [];
      selectedTask.comments.push(newComment);
    }
    console.log("inside submit commmet", newComment);
    fetch(`${URLS.backendTasks}/update`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ task: selectedTask }),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("inside submit commmet return", result);
        tasks[activeProjectId].forEach((projectTask) => {
          if (projectTask._id === taskId) {
            projectTask.comments = result.task.comments;
          }
        });
        this.setState({
          tasks: tasks,
          selectedTask: result.task,
          newComment: {
            employeeId: null,
            employeeImage: "",
            employeeName: "",
            date: "",
            time: "",
            comment: "",
          },
        });
      });
  };
  uploadCommentFile = (event) => {
    const { newFile } = this.state;
    let uploadedFile = event.target.files[0];
    const name = uploadedFile.name;
    const lastDot = name.lastIndexOf(".");
    const fileName = name.substring(0, lastDot);
    const ext = name.substring(lastDot + 1);
    const size = uploadedFile.size;
    let currentDate = new Date();
    currentDate = currentDate.getTime();
    newFile.file.original = fileName;
    newFile.file.unique = currentDate;
    newFile.file.ext = ext;
    if (size < 1024) {
      newFile.file.size = size + " B";
    } else if (size >= 1024 && size < 1024 * 1024) {
      newFile.file.size = (size / 1024).toFixed(2) + " KB";
    } else {
      newFile.file.size = (size / 1024 / 1024).toFixed(2) + " MB";
    }
    this.setState({
      fileObject: uploadedFile,
      newFile,
    });
    window.$("#add_file").modal("toggle");
  };
  updateNewFileMessage = (event) => {
    const { newFile } = this.state;
    newFile.file.message = event.target.value;
    this.setState({
      newFile,
    });
  };
  closeNewFilePanel = (e) => {
    window.$("#add_file").modal("toggle");
    this.setState({
      newFile: {
        employeeId: null,
        employeeImage: "",
        employeeName: "",
        date: "",
        time: "",
        file: {
          message: "",
          original: "",
          unique: "",
          ext: "",
          size: "",
        },
      },
      fileObject: null,
    });
  };
  addNewFile = (event) => {
    event.preventDefault();
    const token = "dummy token";
    let { newFile, employee, selectedTask, fileObject } = this.state;
    const taskId = selectedTask._id;
    const { activeProjectId, tasks } = this.state;

    newFile.employeeId = employee._id;
    newFile.employeeName =
      employee.personalInfo.name.first + " " + employee.personalInfo.name.last;
    newFile.employeeImage = employee.emergencyInfo.image;
    const date = new Date();
    newFile.date =
      date.getDate() +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear();
    newFile.time = date.getHours() + ":" + ("0" + date.getMinutes()).slice(-2);
    if (selectedTask.comments) {
      selectedTask.comments.push(newFile);
    } else {
      selectedTask.comments = [];
      selectedTask.comments.push(newFile);
    }
    let formData = new FormData();
    formData.append("task", JSON.stringify({ ...selectedTask }));
    if (fileObject)
      formData.append(
        "file",
        fileObject,
        newFile.file.unique + "." + newFile.file.ext
      );
    fetch(`${URLS.backendTasks}/updateTaskWithFile`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        window.$("#add_file").modal("toggle");
        tasks[activeProjectId].forEach((projectTask) => {
          if (projectTask._id === taskId) {
            projectTask.comments = result.task.comments;
          }
        });
        this.setState({
          tasks: tasks,
          selectedTask: result.task,
          newFile: {
            employeeId: null,
            employeeImage: "",
            employeeName: "",
            date: "",
            time: "",
            file: {
              message: "",
              original: "",
              unique: "",
              ext: "",
              size: "",
            },
          },
          fileObject: null,
        });
      });
  };
  changeFilterToCompleted = (e) => {
    this.setState({
      taskFilter: "COMPLETE",
    });
  };
  changeFilterToPanding = (e) => {
    this.setState({
      taskFilter: "INCOMPLETE",
    });
  };
  changeFilterToAll = (e) => {
    this.setState({
      taskFilter: "ALL",
    });
  };
  changeFilter = (e) => {
    const value = e.target.value;
    this.setState({
      taskFilter: value,
    });
    const { activeProjectId, tasks } = this.state;
    if (activeProjectId) {
      const projectTasks = tasks[activeProjectId];
      var selectedTask = null;
      if (projectTasks && projectTasks.length) {
        if (value === "COMPLETE")
          for (let i = 0; i < projectTasks.length; i++) {
            if (projectTasks[i]["status"] === "COMPLETE") {
              selectedTask = projectTasks[i];
              break;
            }
          }
        else if (value === "INCOMPLETE")
          for (let i = 0; i < projectTasks.length; i++) {
            if (projectTasks[i]["status"] === "INCOMPLETE") {
              selectedTask = projectTasks[i];
              break;
            }
          }
        else if (value === "ALL") selectedTask = projectTasks[0];
        else
          for (let i = 0; i < projectTasks.length; i++) {
            if (projectTasks[i]["status"] === "UNDER_REVIEW") {
              selectedTask = projectTasks[i];
              break;
            }
          }
      }
      this.setState({ selectedTask });
    }
  };
  updateSelectedTaskTaskDetail = (e) => {
    const { selectedTask } = this.state;
    selectedTask.taskDetail = e.target.value;
    this.setState({
      selectedTask,
    });
  };
  callSelectedTaskTaskDetailAPI = (task) => (e) => {
    const token = "dummy token";
    const taskId = task._id;
    const projectId = task.project;
    const { tasks } = this.state;
    fetch(`${URLS.backendTasks}/update`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ task: task }),
    })
      .then((res) => res.json())
      .then((result) => {
        tasks[projectId].forEach((projectTask) => {
          if (projectTask._id === taskId) {
            projectTask = result.task;
          }
        });
        this.setState({
          tasks: tasks,
        });
      });
  };
  validationStartDate = (currentDate) => {
    return currentDate.isAfter(moment(new Date()).add(-1, "days"));
  };
  validationDueDate = (currentDate) => {
    const { newTask } = this.state;
    return currentDate.isAfter(
      moment(newTask.startDate, "DD/MM/YYYY").add(-1, "days")
    );
  };
  changeShowUserWise = (event) => {
    const { showUserWise } = this.state;
    this.setState({ showUserWise: !showUserWise });
  };

  generateTaskNotification = (action, oldStatus, task) => {
    const { employee, leadersForNotification } = this.state;
    if (employee != null) {
      const employeeName =
        employee.personalInfo.name.first +
        " " +
        employee.personalInfo.name.last;
      if (action === "TASK_STATUS") {
        const newStatus = task.status;
        if (oldStatus === "INCOMPLETE" && newStatus === "UNDER_REVIEW") {
          const notification = {};
          const projectLeaders = leadersForNotification[task.project];
          notification.employeeFrom = task.assignedTo;
          notification.tag = "COMPLETED_TASK";
          notification.message =
            '<span class="noti-title"> <b>' +
            employeeName +
            '</b></span> marked task <span class="noti-title"><b>' +
            task.taskName +
            "</b></span> as completed";
          notification.target = "tasks";
          notification.createDate = new Date();
          projectLeaders.forEach((emp) => {
            notification.employeeTo = emp._id;
            this.submitNotificationOnServer(notification);
          });
        } else if (oldStatus === "UNDER_REVIEW" && newStatus === "INCOMPLETE") {
          const notification = {};
          const projectLeaders = leadersForNotification[task.project];
          notification.employeeFrom = task.assignedTo;
          notification.tag = "INCOMPLETED_TASK";
          notification.message =
            '<span class="noti-title"> <b>' +
            employeeName +
            '</b></span> marked task <span class="noti-title"><b>' +
            task.taskName +
            "</b></span> as incomplete";
          notification.target = "tasks";
          notification.createDate = new Date();
          projectLeaders.forEach((emp) => {
            notification.employeeTo = emp._id;
            this.submitNotificationOnServer(notification);
          });
        }
      } else if (action === "APPROVE") {
        const notification = {};
        const projectLeaders = leadersForNotification[task.project];
        notification.employeeFrom = employee._id;
        notification.tag = "APPROVED_TASK";
        notification.message =
          '<span class="noti-title"> <b>' +
          employeeName +
          '</b></span> approved task <span class="noti-title"><b>' +
          task.taskName +
          "</b></span> as completed";
        notification.target = "tasks";
        notification.createDate = new Date();
        notification.employeeTo = task.assignedTo;
        this.submitNotificationOnServer(notification);
        projectLeaders.forEach((emp) => {
          if (emp._id !== employee._id && emp._id !== task.assignedTo) {
            notification.employeeTo = emp._id;
            this.submitNotificationOnServer(notification);
          }
          // notification.employeeTo = emp._id;
          // this.submitNotificationOnServer(notification);
        });
      } else if (action === "ASSIGN") {
        const notification = {};
        notification.employeeFrom = employee._id;
        notification.tag = "ASSIGNED_TASK";
        notification.message =
          '<span class="noti-title"> <b>' +
          employeeName +
          '</b></span> assined a new task <span class="noti-title"><b>' +
          task.taskName +
          "</b></span>";
        notification.detail = task.taskDetail;
        notification.target = "tasks";
        notification.createDate = new Date();
        notification.employeeTo = task.assignedTo;
        this.submitNotificationOnServer(notification);
      } else if (action === "DELETE") {
        const notification = {};
        notification.employeeFrom = employee._id;
        notification.tag = "DELETED_TASK";
        notification.message =
          '<span class="noti-title"> <b>' +
          employeeName +
          '</b></span> deleted the task <span class="noti-title"><b>' +
          task.taskName +
          "</b></span>, which was assigned to you.";
        notification.detail = task.taskDetail;
        notification.target = "tasks";
        notification.createDate = new Date();
        notification.employeeTo = task.assignedTo;
        this.submitNotificationOnServer(notification);
      }
    }
  };
  submitNotificationOnServer = (notification) => {
    const token = "dummy token";
    fetch(`${URLS.backendNotifications}/addTaskNotification`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ notification: notification }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.notification) {
          console.log("Notification created successfully", result.notification);
        }
      });
  };

  handleCheckboxChanges = () => {
    let { canAddToCalendar } = this.state;
    canAddToCalendar = canAddToCalendar ? false : true;
    this.setState({ canAddToCalendar });
  };

  changeActiveTab = (tab) => {
    let { activeTab } = this.state;
    if (activeTab === tab) {
      activeTab = null;
    } else {
      activeTab = tab;
    }
    this.setState({
      activeTab,
    });
  };

  getContractProjects = (contractId) => {
    const { projects } = this.state;
    let projectsFound = [];
    if (projects && projects.length) {
      projectsFound = projects.filter(
        (project) => project.contract === contractId
      );
    }
    return projectsFound;
  };

  removeFormList = (item) => {
    let { assignedTo } = this.state;
    assignedTo = assignedTo.filter((member) => member.id !== item.id);
    this.setState({ assignedTo });
    this.forceUpdate();
  };

  updateTaskMember = (param) => {
    let { assignedTo } = this.state;
    assignedTo.push(param[0]);
    this.setState({ assignedTo });
  };

  getTaskDuration = (selectedTask) => {
    let totalDuration;
    const startDate = selectedTask.startDate;
    const completeDate = selectedTask.completeDate;

    totalDuration = moment(completeDate, "DD/MM/YYYY").diff(
      moment(startDate, "DD/MM/YYYY"),
      "days"
    );
    return totalDuration + " day(s)";
  };

  render() {
    const {
      canAddToCalendar,
      projects,
      activeProjectId,
      newTask,
      tasks,
      selectedTask,
      teams,
      contractList,
      employeesList,
      newComment,
      newFile,
      taskFilter,
      userWiseTasks,
      showUserWise,
      isSubmitWait,
      submitText,
      isSuperAdmin,
      userId,
      activeTab,
      assignedTo,
    } = this.state;

    const projectTasks = tasks[activeProjectId] ? tasks[activeProjectId] : null;
    const projectTasksUserWise = userWiseTasks[activeProjectId]
      ? userWiseTasks[activeProjectId]
      : null;
    const projectTasksUserWiseList = projectTasksUserWise
      ? Object.keys(projectTasksUserWise)
      : null;

    const currentProject =
      activeProjectId && projects && projects.length
        ? projects.find((project) => project._id === activeProjectId)
        : {};
    const currentProjectLeaders =
      currentProject && Object.entries(currentProject).length
        ? currentProject.projectLeader
        : [];
    const isProjectLeader =
      currentProjectLeaders && currentProjectLeaders.length
        ? currentProjectLeaders.find(
            (currentProjectLeader) => currentProjectLeader.userId === userId
          )
        : false;
    const canShowControls =
      activeProjectId && (isSuperAdmin || isProjectLeader) ? true : false;
    let team = teams ? teams[activeProjectId] : [];
    // const team = currentProject && currentProject["projectLeader"] && currentProject["team"] ? [...currentProject["projectLeader"], ...currentProject["team"]] : [];

    if (team && team.length) {
      team = team.map((employee) => {
        let name = employee.personalInfo.name.first;
        name += employee.personalInfo.name.middle
          ? " " + employee.personalInfo.name.middle
          : "";
        name += employee.personalInfo.name.last
          ? " " + employee.personalInfo.name.last
          : "";
        const id = employee._id;
        const image = employee.emergencyInfo.image;
        return { name: name, id: id, image: image };
      });
    }

    return (
      <body>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex, nofollow"></meta>
          <title>InvexERP</title>
        </Helmet>
        <div className="main-wrapper">
          <Topbar />
          <div className="sidebar" id="sidebar">
            <div className="sidebar-inner slimscroll">
              <div id="sidebar-menu" className="sidebar-menu">
                <ul>
                  <li>
                    <Link to={`${URLS.dashboard}`}>
                      <i className="la la-home"></i> <span>Back to Home</span>
                    </Link>
                  </li>
                  <li className="menu-title">
                    <Link style={{ marginLeft: "0" }} to={URLS.projects}>
                      Projects
                    </Link>{" "}
                    <a
                      href="Javascript:void(0);"
                      data-toggle="modal"
                      data-target="#create_project"
                    >
                      <i className="fa fa-plus"></i>
                    </a>
                  </li>
                  {contractList && contractList.length
                    ? contractList.map((contract) => {
                        return (
                          <li className="submenu">
                            <a href="javascript:void(0)">
                              {contract.contractName}
                              <span
                                class="menu-arrow"
                                onMouseDownCapture={() =>
                                  this.changeActiveTab(contract._id)
                                }
                              ></span>
                            </a>
                            <ul
                              style={
                                contract._id === activeTab
                                  ? { display: "block" }
                                  : { display: "none" }
                              }
                            >
                              {this.getContractProjects(contract._id) &&
                              this.getContractProjects(contract._id).length
                                ? this.getContractProjects(contract._id).map(
                                    (crt) => {
                                      return (
                                        <li
                                          className={
                                            activeProjectId === crt._id
                                              ? "active"
                                              : ""
                                          }
                                          onClick={this.changeActiveProject(
                                            crt._id
                                          )}
                                        >
                                          <a
                                            href="javascript:void(0)"
                                            style={{ paddingLeft: "30px" }}
                                          >
                                            {crt.name}
                                          </a>
                                        </li>
                                      );
                                    }
                                  )
                                : null}
                            </ul>
                          </li>
                        );
                      })
                    : null}
                </ul>
              </div>
            </div>
          </div>
          {/*<!-- Page Wrapper -->*/}
          <div className="page-wrapper">
            <div className="chat-main-row">
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                </BreadcrumbItem>
                {activeProjectId ? (
                  <BreadcrumbItem>
                    <Link
                      to={`${URLS.dashboard}/project-view/${activeProjectId}`}
                    >
                      {
                        projects.find(
                          (project) => project._id === activeProjectId
                        ).name
                      }
                    </Link>
                  </BreadcrumbItem>
                ) : null}

                <BreadcrumbItem active>Tasks</BreadcrumbItem>
              </Breadcrumb>
              <div className="chat-main-wrapper">
                <div className="col-lg-7 message-view task-view task-left-sidebar">
                  <div className="chat-window">
                    <div className="fixed-header">
                      <div className="navbar">
                        <div className="float-left">
                          <div className="add-task-btn-wrapper">
                            {canShowControls ? (
                              <span
                                className="add-task-btn btn btn-white btn-sm"
                                data-toggle="modal"
                                data-target="#add_task"
                              >
                                Add Task
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div>
                          <span>Show User Wise</span>
                          <div
                            className="onoffswitch"
                            style={{ margin: "auto" }}
                          >
                            <input
                              type="checkbox"
                              name="onoffswitch"
                              className="onoffswitch-checkbox"
                              id="switch_custom"
                              checked={showUserWise}
                              onChange={this.changeShowUserWise}
                            />
                            <label
                              className="onoffswitch-label"
                              for="switch_custom"
                            >
                              <span className="onoffswitch-inner"></span>
                              <span className="onoffswitch-switch"></span>
                            </label>
                          </div>
                        </div>

                        <div className="float-right custom-menu">
                          <Form.Control
                            as="select"
                            value={taskFilter}
                            onChange={this.changeFilter}
                            style={{ height: "35px" }}
                          >
                            <option value="ALL">All Task</option>
                            <option value="INCOMPLETE">Pending Task</option>
                            <option value="UNDER_REVIEW">
                              Under Reviewing Task
                            </option>
                            <option value="COMPLETE">Completed Task</option>
                          </Form.Control>
                        </div>
                        <a
                          className="task-chat profile-rightbar float-right"
                          id="task_chat"
                          href="#task_window"
                        >
                          <i className="fa fa fa-comment"></i>
                        </a>
                      </div>
                    </div>
                    <div className="chat-contents">
                      <div className="chat-content-wrap">
                        <div className="chat-wrap-inner">
                          <div className="chat-box">
                            <div className="task-wrapper">
                              <div className="task-list-container">
                                <div
                                  className="task-list-body"
                                  hidden={showUserWise}
                                >
                                  <TaskFilter
                                    projectTasks={projectTasks}
                                    taskFilter={taskFilter}
                                    changeSelectedTask={this.changeSelectedTask.bind(
                                      this
                                    )}
                                    changeStatusOfTask={this.changeStatusOfTask}
                                    deleteTask={this.deleteTask.bind(this)}
                                    canShowControls={canShowControls}
                                  />
                                </div>

                                <div
                                  className="task-list-body"
                                  hidden={!showUserWise}
                                >
                                  {projectTasksUserWiseList
                                    ? projectTasksUserWiseList.map(
                                        (userId, idx) => {
                                          const rate =
                                            projectTasksUserWise[userId][
                                              "rate"
                                            ];
                                          const progress =
                                            projectTasksUserWise[userId][
                                              "progress"
                                            ];
                                          const userTasks =
                                            projectTasksUserWise[userId][
                                              "userTasks"
                                            ];
                                          return (
                                            <div>
                                              {idx ? (
                                                <div
                                                  style={{
                                                    height: "10px",
                                                    backgroundColor: "#4DA0F9",
                                                  }}
                                                ></div>
                                              ) : (
                                                ""
                                              )}
                                              <table className="table table-striped custom-table mb-0 datatable">
                                                <tbody>
                                                  <tr
                                                    style={{
                                                      backgroundColor:
                                                        "#ffffff",
                                                    }}
                                                  >
                                                    <td>
                                                      <span className="avatar">
                                                        <img
                                                          alt=""
                                                          src={
                                                            teams[
                                                              activeProjectId
                                                            ] &&
                                                            teams[
                                                              activeProjectId
                                                            ].length &&
                                                            teams[
                                                              activeProjectId
                                                            ]
                                                              .filter(
                                                                (member) => {
                                                                  return (
                                                                    member._id ===
                                                                    userId
                                                                  );
                                                                }
                                                              )
                                                              .map((emp) =>
                                                                emp
                                                                  .emergencyInfo
                                                                  .image
                                                                  ? URLS.backendStatic +
                                                                    "/" +
                                                                    emp
                                                                      .emergencyInfo
                                                                      .image
                                                                  : "assets/img/profiles/avatar-09.jpg"
                                                              )
                                                          }
                                                        />
                                                      </span>
                                                      {userId ===
                                                      "NOT_ASSIGNED" ? (
                                                        "Not Assigned"
                                                      ) : (
                                                        <span className="task-assignee">
                                                          {teams[
                                                            activeProjectId
                                                          ] &&
                                                            teams[
                                                              activeProjectId
                                                            ].length &&
                                                            teams[
                                                              activeProjectId
                                                            ].map((member) => {
                                                              if (
                                                                member._id ===
                                                                userId
                                                              ) {
                                                                let name =
                                                                  member
                                                                    .personalInfo
                                                                    .name.first;
                                                                name += member
                                                                  .personalInfo
                                                                  .name.middle
                                                                  ? " " +
                                                                    member
                                                                      .personalInfo
                                                                      .name
                                                                      .middle
                                                                  : "";
                                                                name += member
                                                                  .personalInfo
                                                                  .name.last
                                                                  ? " " +
                                                                    member
                                                                      .personalInfo
                                                                      .name.last
                                                                  : "";
                                                                return name;
                                                              }
                                                            })}
                                                        </span>
                                                      )}
                                                    </td>
                                                    <td>
                                                      <div className="widget-subbody">
                                                        <div className="widget-subbody-header">
                                                          Progress{" "}
                                                          <span class="text-success float-right">
                                                            {progress.toString() +
                                                              "%"}
                                                          </span>
                                                        </div>
                                                        <div class="progress progress-xs mb-0">
                                                          <div
                                                            class="progress-bar bg-success"
                                                            role="progressbar"
                                                            data-toggle="tooltip"
                                                            title={
                                                              progress.toString() +
                                                              "%"
                                                            }
                                                            style={{
                                                              width:
                                                                progress + "%",
                                                            }}
                                                          ></div>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <div className="widget-subbody">
                                                        <div className="widget-subbody-header">
                                                          Rate{" "}
                                                          {parseFloat(rate) >=
                                                          95 ? (
                                                            <span class="text-success float-right">
                                                              {rate.toString() +
                                                                "%"}
                                                            </span>
                                                          ) : parseFloat(
                                                              rate
                                                            ) >= 75 ? (
                                                            <span class="text-primary float-right">
                                                              {rate.toString() +
                                                                "%"}
                                                            </span>
                                                          ) : parseFloat(
                                                              rate
                                                            ) >= 50 ? (
                                                            <span class="text-warning float-right">
                                                              {rate.toString() +
                                                                "%"}
                                                            </span>
                                                          ) : (
                                                            <span class="text-danger float-right">
                                                              {rate.toString() +
                                                                "%"}
                                                            </span>
                                                          )}
                                                        </div>
                                                        <div class="progress progress-xs mb-0">
                                                          {parseFloat(rate) >=
                                                          95 ? (
                                                            <div
                                                              class="progress-bar bg-success"
                                                              role="progressbar"
                                                              data-toggle="tooltip"
                                                              title={
                                                                rate.toString() +
                                                                "%"
                                                              }
                                                              style={{
                                                                width:
                                                                  rate + "%",
                                                              }}
                                                            ></div>
                                                          ) : parseFloat(
                                                              rate
                                                            ) >= 75 ? (
                                                            <div
                                                              class="progress-bar bg-primary"
                                                              role="progressbar"
                                                              data-toggle="tooltip"
                                                              title={
                                                                rate.toString() +
                                                                "%"
                                                              }
                                                              style={{
                                                                width:
                                                                  rate + "%",
                                                              }}
                                                            ></div>
                                                          ) : parseFloat(
                                                              rate
                                                            ) >= 50 ? (
                                                            <div
                                                              class="progress-bar bg-warning"
                                                              role="progressbar"
                                                              data-toggle="tooltip"
                                                              title={
                                                                rate.toString() +
                                                                "%"
                                                              }
                                                              style={{
                                                                width:
                                                                  rate + "%",
                                                              }}
                                                            ></div>
                                                          ) : (
                                                            <div
                                                              class="progress-bar bg-danger"
                                                              role="progressbar"
                                                              data-toggle="tooltip"
                                                              title={
                                                                rate.toString() +
                                                                "%"
                                                              }
                                                              style={{
                                                                width:
                                                                  rate + "%",
                                                              }}
                                                            ></div>
                                                          )}
                                                          {/* <div class="progress-bar bg-success" role="progressbar" data-toggle="tooltip" title={rate.toString() + '%'} style={{ width: rate + "%" }}></div> */}
                                                        </div>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                              <TaskFilter
                                                projectTasks={userTasks}
                                                taskFilter={taskFilter}
                                                changeSelectedTask={this.changeSelectedTask.bind(
                                                  this
                                                )}
                                                changeStatusOfTask={
                                                  this.changeStatusOfTask
                                                }
                                                deleteTask={this.deleteTask.bind(
                                                  this
                                                )}
                                                canShowControls={
                                                  canShowControls
                                                }
                                              />
                                            </div>
                                          );
                                        }
                                      )
                                    : null}
                                </div>

                                <div
                                  id="add_task"
                                  className="modal custom-modal fade"
                                  role="dialog"
                                >
                                  <div className="modal-dialog modal-dialog-centered modal-lg">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5 className="modal-title">
                                          Add Task
                                        </h5>
                                        <button
                                          type="button"
                                          className="close"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                          onClick={this.closeNewTaskPanel}
                                        >
                                          <span aria-hidden="true">
                                            &times;
                                          </span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                                        <form onSubmit={this.addNewTask}>
                                          <div className="form-group row">
                                            <div className="col-sm-12">
                                              <div
                                                className="form-label"
                                                style={{ fontSize: "14px" }}
                                              >
                                                Task Name{" "}
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </div>
                                              <input
                                                className="form-control"
                                                id="new-task"
                                                placeholder="Enter new task here. . ."
                                                value={newTask.taskName}
                                                onChange={this.updateNewTaskName.bind(
                                                  this
                                                )}
                                                required
                                              />
                                            </div>
                                          </div>
                                          <div className="form-group row">
                                            <div className="col-xs-12 col-sm-6">
                                              <div
                                                className="form-label"
                                                style={{ fontSize: "14px" }}
                                              >
                                                Task Detail{" "}
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </div>
                                              <textarea
                                                className="form-control"
                                                id="new-task"
                                                placeholder="Describe what the task is about. . ."
                                                value={newTask.taskDetail}
                                                onChange={this.updateNewTaskDetail.bind(
                                                  this
                                                )}
                                                required
                                              >
                                                {" "}
                                              </textarea>
                                            </div>

                                            <div className="col-xs-12 col-sm-6 mt-4">
                                              <div class="form-check mt-2">
                                                <input
                                                  type="checkbox"
                                                  class="form-check-input"
                                                  checked={canAddToCalendar}
                                                  onChange={() =>
                                                    this.handleCheckboxChanges()
                                                  }
                                                />
                                                <label
                                                  class="form-check-label"
                                                  onClick={() =>
                                                    this.handleCheckboxChanges()
                                                  }
                                                >
                                                  Add to Calendar
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="form-group row">
                                            <div className="col-sm-6">
                                              <div
                                                className="form-label"
                                                style={{ fontSize: "14px" }}
                                              >
                                                Start Date
                                              </div>
                                              <DatePicker
                                                value={newTask.startDate}
                                                closeOnSelect={true}
                                                onChange={
                                                  this.updateNewTaskStartDate
                                                }
                                                isValidDate={
                                                  this.validationStartDate
                                                }
                                                required={true}
                                              />
                                            </div>
                                            <div className="col-sm-6">
                                              <div
                                                className="form-label"
                                                style={{ fontSize: "14px" }}
                                              >
                                                Due Date{" "}
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </div>
                                              <DatePicker
                                                value={newTask.dueDate}
                                                closeOnSelect={true}
                                                onChange={
                                                  this.updateNewTaskDueDate
                                                }
                                                isValidDate={
                                                  this.validationDueDate
                                                }
                                                required={true}
                                              />
                                            </div>
                                          </div>
                                          <div className="form-group row">
                                            <div className="col-xs-12 col-sm-6">
                                              <div className="form-group form-focus mt-2">
                                                <Form.Label
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  Select Employees
                                                </Form.Label>
                                                <br />
                                                {team && team.length && (
                                                  <Multiselect
                                                    options={team.filter(
                                                      (ar) =>
                                                        !assignedTo.find(
                                                          (rm) =>
                                                            rm.id === ar.id
                                                        )
                                                    )}
                                                    onSelectOptions={(param) =>
                                                      this.updateTaskMember(
                                                        param
                                                      )
                                                    }
                                                  />
                                                )}
                                                <br />
                                              </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-6 mt-2">
                                              <div className="form-group form-focus">
                                                <Form.Label
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  Assigned To
                                                </Form.Label>
                                                <br />
                                                <ImageNameList
                                                  value={assignedTo}
                                                  removeFormList={
                                                    this.removeFormList
                                                  }
                                                />
                                                <br />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="submit-section mt-5">
                                            <button
                                              className="btn btn-primary submit-btn"
                                              disabled={isSubmitWait}
                                            >
                                              {submitText}
                                            </button>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="notification-popup hide">
                              <p>
                                <span className="task"></span>
                                <span className="notification-text"></span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-5 message-view task-chat-view task-right-sidebar"
                  id="task_window"
                >
                  <div className="chat-window">
                    {selectedTask && (
                      <div className="fixed-header">
                        <div className="navbar">
                          <div
                            className="task-assign"
                            style={
                              selectedTask.status === "COMPLETE"
                                ? { pointerEvents: "none" }
                                : {}
                            }
                          >
                            <a
                              className={
                                selectedTask.status === "INCOMPLETE"
                                  ? "task-complete-btn"
                                  : selectedTask.status === "UNDER_REVIEW"
                                  ? "task-complete-btn task-under-review"
                                  : "task-complete-btn task-completed"
                              }
                              href="Javascript:void(0);"
                              onClick={this.changeStatusOfTaskFromButton(
                                selectedTask
                              )}
                            >
                              <i className="material-icons">check</i>{" "}
                              {selectedTask.status === "INCOMPLETE"
                                ? "Mark Completed"
                                : selectedTask.status === "UNDER_REVIEW"
                                ? "Approve as Completed"
                                : "Completed"}
                            </a>
                          </div>
                          {/* <ul className="nav float-right custom-menu">
                            <li className="dropdown dropdown-action">
                              <a
                                href="Javascript:void(0)"
                                className="dropdown-toggle"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="material-icons">more_vert</i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a
                                  className="dropdown-item"
                                  href="Javascript:void(0)"
                                >
                                  Delete Task
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="Javascript:void(0)"
                                >
                                  Settings
                                </a>
                              </div>
                            </li>
                          </ul> */}
                        </div>
                      </div>
                    )}
                    <div className="chat-contents task-chat-contents">
                      <div className="chat-content-wrap">
                        <div className="chat-wrap-inner">
                          <div className="chat-box">
                            {selectedTask && (
                              <div className="chats">
                                <h4>{selectedTask.taskName}</h4>
                                <div className="task-header d-flex flex-column">
                                  <div className="task-head-title mb-2">
                                    <b>Assigned To</b>
                                  </div>
                                  <div className="task-head-title">
                                    <div className="d-flex flex-row">
                                      {team && team.length
                                        ? team.map((member) => {
                                            if (
                                              selectedTask.assignedTo &&
                                              selectedTask.assignedTo.length &&
                                              selectedTask.assignedTo.find(
                                                (item) => item === member.id
                                              )
                                            ) {
                                              let image = member.image;
                                              let name = member.name;

                                              return (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <div className="avatar">
                                                    <img
                                                      alt=""
                                                      src={
                                                        image
                                                          ? URLS.backendStatic +
                                                            "/" +
                                                            image
                                                          : "assets/img/profiles/avatar-09.jpg"
                                                      }
                                                    />
                                                  </div>
                                                  <span
                                                    style={{
                                                      fontSize: "12px",
                                                      display: "block",
                                                      width: "60px",
                                                      wordWrap: "break-word",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {name}
                                                  </span>
                                                </div>
                                              );
                                            }
                                          })
                                        : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="task-header">
                                  <div
                                    class="task-due-date"
                                    style={{
                                      cursor: "not-allowed",
                                      margin: "0",
                                      padding: "0",
                                      flex: "0 0 130px",
                                    }}
                                  >
                                    <div
                                      class="due-icon"
                                      style={{
                                        margin: "10px",
                                        display: "inline-block",
                                      }}
                                    >
                                      <div className="cal-icon-custom">
                                        <input
                                          style={{ cursor: "not-allowed" }}
                                        />
                                      </div>
                                    </div>
                                    <div class="due-info">
                                      <div class="task-head-title">
                                        Start Date
                                      </div>
                                      <div class="due-date">
                                        {this.showDateInFormat(
                                          selectedTask.startDate
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  {selectedTask.status === "COMPLETE" &&
                                  selectedTask.completeDate ? (
                                    <div style={{ display: "flex" }}>
                                      <div
                                        class="task-due-date"
                                        style={{
                                          cursor: "not-allowed",
                                          margin: "0",
                                          padding: "0",
                                          flex: "0 0 130px",
                                        }}
                                      >
                                        <div
                                          class="due-icon"
                                          style={{
                                            margin: "10px",
                                            display: "inline-block",
                                          }}
                                        >
                                          <div className="cal-icon-custom">
                                            <input
                                              style={{
                                                cursor: "not-allowed",
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div class="due-info">
                                          <div class="task-head-title">
                                            Due Date
                                          </div>
                                          <div class="due-date">
                                            {this.showDateInFormat(
                                              selectedTask.dueDate
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="task-due-date"
                                        style={{
                                          cursor: "not-allowed",
                                          margin: "0",
                                          padding: "0",
                                          flex: "0 0 140px",
                                        }}
                                      >
                                        <div
                                          class="due-icon"
                                          style={{
                                            margin: "10px",
                                            display: "inline-block",
                                          }}
                                        >
                                          <div className="cal-icon-custom">
                                            <input
                                              style={{
                                                cursor: "not-allowed",
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div class="due-info">
                                          <div class="task-head-title">
                                            Complete Date
                                          </div>
                                          <div class="due-date">
                                            {this.showDateInFormat(
                                              selectedTask.completeDate
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      className="task-due-date"
                                      id="datetimepicker1"
                                      style={{
                                        margin: "0",
                                        padding: "0",
                                        flex: "0 0 130px",
                                      }}
                                    >
                                      <label
                                        style={{
                                          cursor: "pointer",
                                          width: "100%",
                                          margin: "0",
                                        }}
                                      >
                                        <div
                                          className="due-icon"
                                          style={{
                                            margin: "10px",
                                            display: "inline-block",
                                          }}
                                        >
                                          <div className="cal-icon-custom">
                                            <DatePicker
                                              selected={selectedTask.dueDate}
                                              onChange={this.updateTaskDueDate}
                                            />
                                          </div>
                                        </div>
                                        <div
                                          className="due-info"
                                          style={{
                                            verticalAlign: "middle",
                                            display: "inline-block",
                                          }}
                                        >
                                          <div className="task-head-title">
                                            Due Date
                                          </div>
                                          <div className="due-date">
                                            {this.showDateInFormat(
                                              selectedTask.dueDate
                                            )}
                                          </div>
                                        </div>
                                      </label>
                                    </div>
                                  )}
                                </div>

                                {selectedTask && selectedTask.completeDate && (
                                  <div
                                    style={{
                                      paddingLeft: "20px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <span style={{ fontSize: "13px" }}>
                                      Duration:
                                    </span>
                                    <span
                                      style={{
                                        fontSize: "13px",
                                        opacity: 0.8,
                                        marginLeft: "10px",
                                      }}
                                    >
                                      {this.getTaskDuration(selectedTask)}
                                    </span>
                                  </div>
                                )}
                                <hr class="task-line" />

                                <div class="task-desc">
                                  <div class="task-desc-icon">
                                    <i class="material-icons">subject</i>
                                  </div>
                                  <div class="task-textarea">
                                    <textarea
                                      class="form-control"
                                      placeholder="Description"
                                      value={
                                        selectedTask.taskDetail
                                          ? selectedTask.taskDetail
                                          : ""
                                      }
                                      onChange={
                                        this.updateSelectedTaskTaskDetail
                                      }
                                      onBlur={this.callSelectedTaskTaskDetailAPI(
                                        selectedTask
                                      )}
                                    ></textarea>
                                  </div>
                                </div>

                                <hr className="task-line" />
                                {selectedTask.comments
                                  ? selectedTask.comments.map((comment) => {
                                      return (
                                        <div class="chat chat-left">
                                          <div class="chat-avatar">
                                            <a class="avatar">
                                              <img
                                                alt=""
                                                src={
                                                  comment.employeeImage
                                                    ? URLS.backendStatic +
                                                      "/" +
                                                      comment.employeeImage
                                                    : "assets/img/profiles/avatar-02.jpg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div class="chat-body">
                                            <div class="chat-bubble">
                                              <div class="chat-content">
                                                <span class="task-chat-user">
                                                  {comment.employeeName}
                                                </span>{" "}
                                                <span class="chat-time">
                                                  {this.showDateInFormat(
                                                    comment.date
                                                  )}{" "}
                                                  at {comment.time}
                                                </span>
                                                {comment.comment ? (
                                                  <p>{comment.comment}</p>
                                                ) : (
                                                  <p>
                                                    <a
                                                      href={
                                                        URLS.backendStatic +
                                                        "/" +
                                                        comment.file.unique +
                                                        "." +
                                                        comment.file.ext
                                                      }
                                                      target="_blank"
                                                    >
                                                      <span
                                                        style={{
                                                          marginBottom: "5px",
                                                          marginRight: "10px",
                                                          height: "22px",
                                                          lineHeight: "22px",
                                                          width: "22px",
                                                        }}
                                                        class="files-icon"
                                                      >
                                                        <i
                                                          style={{
                                                            fontSize: "14px",
                                                          }}
                                                          class="fa fa-file-pdf-o"
                                                        ></i>
                                                      </span>
                                                      {comment.file.original +
                                                        "." +
                                                        comment.file.ext}
                                                    </a>
                                                    <br />
                                                    {comment.file.message}
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                  : ""}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="chat-footer"
                      style={{ position: "sticky", bottom: "0" }}
                    >
                      <div class="message-bar">
                        <div class="message-inner">
                          <label
                            style={{
                              height: "44px",
                              margin: "0",
                              cursor: "pointer",
                            }}
                          >
                            <input
                              id="new-file"
                              style={{ display: "none" }}
                              id="upload-file"
                              type="file"
                              onChange={this.uploadCommentFile}
                            />
                            <img src={"/assets/img/attachment.png"} alt="" />
                          </label>

                          <div class="message-area">
                            <div class="input-group">
                              <textarea
                                class="form-control"
                                placeholder="Type message..."
                                value={newComment.comment}
                                onChange={this.updateNewComment}
                              ></textarea>
                              <span class="input-group-append">
                                <button
                                  class="btn btn-primary"
                                  type="button"
                                  onClick={this.submitComment}
                                >
                                  <i class="fa fa-send"></i>
                                </button>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="add_file"
                  className="modal custom-modal fade"
                  role="dialog"
                >
                  <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Upload new file</h5>
                        <button
                          type="button"
                          className="close"
                          aria-label="Close"
                          onClick={this.closeNewFilePanel}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <form onSubmit={this.addNewFile}>
                          <div className="form-group row">
                            <div className="col-sm-1"></div>
                            <div className="col-sm-10">
                              <textarea
                                class="form-control"
                                placeholder="Add a message about file..."
                                value={newFile.file.message}
                                onChange={this.updateNewFileMessage}
                              ></textarea>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-sm-1"></div>
                            <div className="col-sm-10">
                              <div
                                style={{
                                  display: "flex",
                                  padding: "12px",
                                  border: "1px solid #eaeaea",
                                  background: "#eeeeee",
                                  borderRadius: "5px",
                                }}
                              >
                                <div
                                  style={{
                                    position: "relative",
                                    background: "#ffffff",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <div
                                    style={{ display: "flex", padding: "12px" }}
                                  >
                                    <span
                                      style={{ marginRight: "10px" }}
                                      class="files-icon"
                                    >
                                      <i class="fa fa-file-pdf-o"></i>
                                    </span>
                                    <div class="c-pillow_file__description">
                                      <span class="c-pillow_file__title">
                                        {newFile.file.original
                                          ? newFile.file.original +
                                            "." +
                                            newFile.file.ext
                                          : ""}
                                      </span>
                                      {/* <div class="c-pillow_file__meta">
																		<button class="c-button-unstyled p-pending_file__edit_btn" type="button" aria-label="Edit file title">Edit</button>
																	</div> */}
                                      <div class="c-pillow_file__meta">
                                        <span>{newFile.file.size}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="submit-section">
                            <button className="btn btn-primary submit-btn">
                              Upload
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- Assign Task Modal -->*/}
            <AssignTaskModal
              team={teams[activeProjectId]}
              assignTask={this.assignTask}
            />
            {/*<!-- Assign Task Modal -->*/}

            {/*<!-- Create Project Modal -->*/}
            <AddProjectModal
              mode="add"
              updateProjectsList={this.updateProjectsList.bind(this)}
              contractList={contractList}
              employeesList={employeesList}
            />
            {/*<!-- Create Project Modal -->*/}

            {/*<!-- Task Followers Modal -->*/}
            <div
              id="task_followers"
              className="modal custom-modal fade"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Add followers to this task</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="input-group m-b-30">
                      <input
                        placeholder="Search to add"
                        className="form-control search-input"
                        type="text"
                      />
                      <span className="input-group-append">
                        <button className="btn btn-primary">Search</button>
                      </span>
                    </div>
                    <div>
                      <ul className="chat-user-list">
                        <li>
                          <a href="Javascript:void(0);">
                            <div className="media">
                              <span className="avatar">
                                <img
                                  alt=""
                                  src="assets/img/profiles/avatar-16.jpg"
                                />
                              </span>
                              <div className="media-body media-middle text-nowrap">
                                <div className="user-name">Jeffery Lalor</div>
                                <span className="designation">Team Leader</span>
                              </div>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="Javascript:void(0);">
                            <div className="media">
                              <span className="avatar">
                                <img
                                  alt=""
                                  src="assets/img/profiles/avatar-08.jpg"
                                />
                              </span>
                              <div className="media-body media-middle text-nowrap">
                                <div className="user-name">
                                  Catherine Manseau
                                </div>
                                <span className="designation">
                                  Android Developer
                                </span>
                              </div>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="Javascript:void(0);">
                            <div className="media">
                              <span className="avatar">
                                <img
                                  alt=""
                                  src="assets/img/profiles/avatar-26.jpg"
                                />
                              </span>
                              <div className="media-body media-middle text-nowrap">
                                <div className="user-name">Wilmer Deluna</div>
                                <span className="designation">Team Leader</span>
                              </div>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="submit-section">
                      <button className="btn btn-primary submit-btn">
                        Add to Follow
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Task Followers Modal -->*/}
          </div>
          {/*<!-- /Page Wrapper -->*/}
        </div>
        {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
        <div className="sidebar-overlay" data-reff=""></div>
      </body>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Tasks));
