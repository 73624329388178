import React, { useEffect, useState, useRef, useMemo } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import currency from "currency.js";
import { Helmet } from "react-helmet";
import {
  Breadcrumb,
  BreadcrumbItem,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import moment from "moment";
import { toast } from "react-toastify";

import classnames from "classnames";
import { Dropdown } from "react-bootstrap";

import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { SummaryChartIconUp, SummaryCloseIcon } from "../icons";
import { PDFIcon, ExcelIcon, ExportIcon } from "../icons";
import { useDownloadExcel } from "../useDownloadExcel";
import { URLS } from "../urls";
import ModalLoader from "../ModalLoader";
import TableComponent from "../TableComponent";
import {
  allMonths,
  branch_id,
  fetchActionsUtil,
  getQueryParams,
} from "../../utils";
//import { toast } from "react-toastify";

function PayHistory() {
  const query = getQueryParams();

  const date = new Date();
  const [activeTab, setActiveTab] = useState("1");
  const [payrollDate, setPayrollDate] = useState({
    payrollMonth: query?.month ? parseInt(query?.month) : date.getMonth() + 1,
    payrollYear: date.getFullYear(),
  });

  const [payroll, setPayroll] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [totalGross, setTotalGross] = useState(0);
  const [totalNet, setTotalNet] = useState(0);
  const [totalDeduct, setTotalDeduct] = useState(0);

  const [totalAllowance, setTotalAllowance] = useState(0);
  const [totalBonus, setTotalBonus] = useState(0);
  const [totalBenefit, setTotalBenefit] = useState(0);
  const [retaireIt, setRetaireIt] = useState(false);

  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [employeeType, setEmployeeType] = useState("All");
  const [companySettings, setcompanySettings] = useState({});

  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    getPayroll();

    return () => {};
  }, [payrollDate, employeeType]);

  async function getPayroll() {
    setIsLoading(true);
    try {
      const token = "dummy token";
      const payrollResonse = await fetch(
        `${URLS.backendPayroll}/get-payroll/${branch_id}?payrollMonth=${payrollDate.payrollMonth}&payrollYear=${payrollDate.payrollYear}&employeeType=${employeeType}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );

      const resSetting = await fetchActionsUtil(
        `${URLS.backendCompanySettings}/get-settings`,
        "POST"
      );

      setcompanySettings({
        ...resSetting.settings,
        extras: resSetting.settings.extras === "true" ? true : false,
      });

      const payrollResult = await payrollResonse.json();
      const { payroll } = payrollResult;
      if (payroll.length) {
        setPayroll(payroll);

        // console.log(payroll);

        payroll.map((el) => {
          setTotalGross((g) => currency(g).add(el.ogGrossPay));
          setTotalNet((n) => currency(n).add(el.netPay));
          setTotalDeduct((d) => currency(d).add(el.totalDeduction));

          setTotalAllowance((g) => currency(g).add(el.totalAllowances));
          setTotalBonus((n) => currency(n).add(el.totalBonus));
          setTotalBenefit((d) => currency(d).add(el.totalBenefits));
        });

        const retairement = payroll.find((el) => el.retairefee > 0);
        retairement && setRetaireIt(true);
      } else {
        setPayroll([]);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }
  const onDownloadExcelData = async () => {
    let exData = payroll.map((employee, index) => [
      index + 1,
      employee.user?.personalInfo?.name.first +
        " " +
        employee.user?.personalInfo?.name.last,
      employee.employeeId,
      employee.useExcel === "Use Timesheet" ? employee?.sfa_id : null,
      employee.gradeName,

      companySettings?.extras
        ? employee?.userExtras?.DaysCommitted === ""
          ? 0
          : employee?.userExtras?.DaysCommitted
        : "",

      companySettings?.extras
        ? employee?.userExtras?.DayOn === ""
          ? 0
          : employee?.userExtras?.DayOn
        : "",

      companySettings?.extras
        ? employee?.userExtras?.TransitDays === ""
          ? 0
          : employee?.userExtras?.TransitDays
        : "",

      companySettings?.extras
        ? employee?.userExtras?.OverStayDays === ""
          ? 0
          : employee?.userExtras?.OverStayDays
        : "",

      companySettings?.extras
        ? employee?.userExtras?.ArrearsDays === ""
          ? 0
          : employee?.userExtras?.ArrearsDays
        : "",

      employee.usedlocation,
      employee.usedcontract,
      employee.useExcel === "Use Timesheet" ? employee?.totalDaysWorked : null,
      employee.useExcel === "Use Timesheet"
        ? parseFloat(employee?.numProration).toFixed(2)
        : null,
      currency(employee.grossPay, {
        symbol: "",
      }).format(),
      currency(employee.basic, {
        symbol: "",
      }).format(),
      currency(employee.housing, {
        symbol: "",
      }).format(),
      currency(employee.transport, {
        symbol: "",
      }).format(),
      currency(employee.medical, {
        symbol: "",
      }).format(),
      currency(employee.utility, {
        symbol: "",
      }).format(),
      currency(employee.entertainment, {
        symbol: "",
      }).format(),
      currency(employee.dressing, {
        symbol: "",
      }).format(),
      // employee.bonuses,
      currency(employee.ogGrossPay, {
        symbol: "",
      }).format(),

      ...employee.deductions
        .filter((el) => !el.groupName)
        .map((deduction, index) => deduction.total),

      ...employee.DeductionGroupName.map((e, i) =>
        currency(
          employee.deductions
            ?.filter((el) => el.groupName === e)
            .map((el) => el.total)
            .reduce((a, b) => a + b, 0),
          {
            symbol: "",
          }
        ).format()
      ),

      ...employee.bonus
        .filter((el) => !el.groupName)
        .map((bonu, index) => bonu.total),

      ...employee.BonusGroupName.map((e, i) =>
        currency(
          employee.bonus
            ?.filter((el) => el.groupName === e)
            .map((el) => el.total)
            .reduce((a, b) => a + b, 0),
          {
            symbol: "",
          }
        ).format()
      ),

      ...employee.allowances
        .filter((el) => !el.groupName)
        .map((allowance, index) => allowance.total),

      ...employee.AllowanceGroupName.map((e, i) =>
        currency(
          employee.allowances
            ?.filter((el) => el.groupName === e)
            .map((el) => el.total)
            .reduce((a, b) => a + b, 0),
          {
            symbol: "",
          }
        ).format()
      ),

      ...employee.benefits
        .filter((el) => !el.groupName)
        .map((benefit, index) => benefit.total),

      ...employee.BenefitGroupName.map((e, i) =>
        currency(
          employee.benefits
            ?.filter((el) => el.groupName === e)
            .map((el) => el.total)
            .reduce((a, b) => a + b, 0),
          {
            symbol: "",
          }
        ).format()
      ),

      currency(employee.attendanceTotal, {
        symbol: "",
      }).format(),
      currency(employee.totalDeduction, {
        symbol: "",
      }).format(),
      currency(employee.totalBonus, {
        symbol: "",
      }).format(),

      currency(employee.totalAllowances, {
        symbol: "",
      }).format(),
      currency(employee.totalBenefits, {
        symbol: "",
      }).format(),
      currency(employee.loanDeduct, {
        symbol: "",
      }).format(),
      employee.remark,
      currency(employee.netPay, {
        symbol: "",
      }).format(),
    ]);

    const normalHead = [
      "No",
      "Name",
      "Employees ID",
      payroll[0].useExcel === "Use Timesheet" ? "SFA ID" : null,
      "Job Grade",
      companySettings?.extras ? "Days Committed" : "",
      companySettings?.extras ? "Days On" : "",
      companySettings?.extras ? "Transit Days" : "",
      companySettings?.extras ? "Overstay Days" : "",
      companySettings?.extras ? "Arrears Days" : "",
      "Location",
      "LSP",
      payroll[0].useExcel === "Use Timesheet" ? "Days Worked" : null,
      payroll[0].useExcel === "Use Timesheet" ? "Proration" : null,
      "Gross Salary",
      "Basic",
      "Housing",
      "Transport",
      "Medical",
      "Utility",
      "Entertainment",
      "Dressing",
      "Earned Gross",

      ...payroll[0]?.deductions
        .filter((el) => !el.groupName)
        .map((setting, index) => setting.name),

      ...payroll[0]?.DeductionGroupName.map((setting, index) => setting),

      ...payroll[0]?.bonus
        .filter((el) => !el.groupName)
        .map((setting, index) => setting.name),

      ...payroll[0]?.BonusGroupName.map((setting, index) => setting),

      ...payroll[0]?.allowances
        .filter((el) => !el.groupName)
        .map((setting, index) => setting.name),

      ...payroll[0]?.AllowanceGroupName.map((setting, index) => setting),

      ...payroll[0]?.benefits
        .filter((el) => !el.groupName)
        .map((setting, index) => setting.name),

      ...payroll[0]?.BenefitGroupName.map((setting, index) => setting),

      "Attendance Deduction",
      "Total Deductions",
      "Total Bonus",
      "Total Allowance",
      "Total Benefit",
      "Loan",
      "Remark",
      "Net Pay",
    ];

    const date = `Date Prepared: ${moment(new Date()).format(
      "MMMM DD yyyy k:mm:ss z"
    )}`;

    exData = [
      // [company],
      ["Payment History Report"],
      [date],
      [""],
      normalHead,
      ...exData,
      [""],
      ["Payhistory Summary"],
      [
        "Total Earned Gross ",
        currency(totalGross, {
          symbol: "",
        }).format(),
      ],
      [
        "Total Allowance",
        currency(totalAllowance, {
          symbol: "",
        }).format(),
      ],
      [
        "Total Bonus",
        currency(totalBonus, {
          symbol: "",
        }).format(),
      ],

      [
        "Total Benefit",
        currency(totalBenefit, {
          symbol: "",
        }).format(),
      ],
      [
        "Total Deduction",
        currency(totalDeduct, {
          symbol: "",
        }).format(),
      ],
      [
        "Total Net Amount",
        currency(totalNet, {
          symbol: "",
        }).format(),
      ],
    ];

    // console.log(exData);
    setExcelData(exData);
  };

  const onSelected = (userId) => {
    const theSelected = [...selectedData];
    if (theSelected.length > 0) {
      const index = theSelected.findIndex((i) => i === userId);

      if (index >= 0) {
        theSelected.splice(index, 1);
        setSelectedData(theSelected);
      } else {
        setSelectedData((d) => [...d, userId]);
      }
    } else {
      setSelectedData((d) => [...d, userId]);
    }
  };

  const emailPayslip = async () => {
    // if (
    //   await ConfirmDialog({
    //     title: `Approve Stock Count`,
    //     description: `Are you sure you want proceed?`,
    //   })
    // ) {
    // }
    setIsLoading(true);
    const emailPayroll = payroll
      .filter((e) => selectedData.find((d) => e.userId === d))
      .map((el) => ({
        email: el.user.personalInfo.email,
        userId: el.userId,
        _id: el._id,
        payrollId: el._id,
        payrollMonth: payrollDate.payrollMonth,
        payrollYear: payrollDate.payrollYear,
      }));

    const token = "dummy token";
    const data = await fetchExcelData(
      `${URLS.backendPayroll}/email-payrolls`,
      "POST",
      token,
      emailPayroll
    );

    if (data.success) {
      toast.success("Email sent sucessfuly");
    } else {
      toast.error("Error!!");
    }
    setIsLoading(false);
  };

  const payrollIds = useMemo(() => {
    const selectedIds = payroll
      .filter((e) => selectedData.find((d) => e.userId === d))
      .map((el) => el._id);
    return selectedIds;
    // setPayrollIds(sss);
  }, [selectedData, payroll]);

  const onSelectedAll = (e) => {
    if (!e.target.checked) {
      setSelectedData([]);
    } else {
      setSelectedData([]);
      setSelectedData(() => payroll.map((d) => d.userId));
    }
  };

  const tableHead = () => {
    payroll[0].settingHeaders = payroll[0]?.settingHeaders
      ? payroll[0]?.settingHeaders
      : {};

    return (
      <thead className="position-sticky top-0">
        <tr>
          <th>
            <input
              type="checkbox"
              onChange={onSelectedAll}
              checked={selectedData?.length === payroll?.length}
            />
          </th>
          <th>No</th>
          <th>Name</th>
          <th>Employees ID</th>
          {payroll[0].useExcel === "Use Timesheet" && <th>SFA ID</th>}
          <th>Job Grade</th>

          {companySettings?.extras ? (
            <>
              <th>Days Committed</th>
              <th>Days On</th>
              <th>Transit Days</th>
              <th>Overstay Days</th>
              <th>Arrears Days</th>
            </>
          ) : null}

          <th>Location</th>
          <th>LSP</th>
          {payroll[0].useExcel === "Use Timesheet" && (
            <>
              <th>Days Worked</th>
              <th>Proration</th>
            </>
          )}

          <th>Gross Salary</th>
          {Object.entries(payroll[0]?.settingHeaders).length === 0 ||
          payroll[0]?.settingHeaders?.basic ? (
            <th>Basic</th>
          ) : null}
          {Object.entries(payroll[0]?.settingHeaders).length === 0 ||
          payroll[0]?.settingHeaders?.housing ? (
            <th>Housing</th>
          ) : null}
          {Object.entries(payroll[0]?.settingHeaders).length === 0 ||
          payroll[0]?.settingHeaders?.transport ? (
            <th>Transport</th>
          ) : null}
          {Object.entries(payroll[0]?.settingHeaders).length === 0 ||
          payroll[0]?.settingHeaders?.medical ? (
            <th>Medical</th>
          ) : null}
          {Object.entries(payroll[0]?.settingHeaders).length === 0 ||
          payroll[0]?.settingHeaders?.utility ? (
            <th>Utility</th>
          ) : null}
          {Object.entries(payroll[0]?.settingHeaders).length === 0 ||
          payroll[0]?.settingHeaders?.entertainment ? (
            <th>Entertainment</th>
          ) : null}
          {Object.entries(payroll[0]?.settingHeaders).length === 0 ||
          payroll[0]?.settingHeaders?.dressing ? (
            <th>Dressing</th>
          ) : null}
          {payroll[0]?.customHeaders?.map((el) => (
            <th>{el} </th>
          ))}
          {/* <th>Bonuses</th> */}
          <th>Earned Gross </th>

          {payroll[0]?.deductions
            .filter((el) => !el.groupName)
            .map((setting, index) => {
              return (
                <th className="text-capitalize" key={index}>
                  {setting.name}
                </th>
              );
            })}

          {payroll[0]?.DeductionGroupName.map((setting, index) => {
            return (
              <th className="text-capitalize" key={index}>
                {setting}
                <br />{" "}
              </th>
            );
          })}

          {payroll[0]?.bonus
            .filter((el) => !el.groupName)
            .map((setting, index) => {
              return (
                <th className="text-capitalize" key={index}>
                  {setting.name}
                </th>
              );
            })}

          {payroll[0]?.BonusGroupName.map((setting, index) => {
            return (
              <th className="text-capitalize" key={index}>
                {setting}
                <br />{" "}
              </th>
            );
          })}

          {payroll[0]?.allowances
            .filter((el) => !el.groupName)
            .map((setting, index) => {
              return (
                <th className="text-capitalize" key={index}>
                  {setting.name}
                </th>
              );
            })}

          {payroll[0]?.AllowanceGroupName.map((setting, index) => {
            return (
              <th className="text-capitalize" key={index}>
                {setting}
                <br />{" "}
              </th>
            );
          })}

          {payroll[0]?.benefits
            .filter((el) => !el.groupName)
            .map((setting, index) => {
              return (
                <th className="text-capitalize" key={index}>
                  {setting.name}
                </th>
              );
            })}

          {payroll[0]?.BenefitGroupName.map((setting, index) => {
            return (
              <th className="text-capitalize" key={index}>
                {setting}
                <br />{" "}
              </th>
            );
          })}

          <th>Loan</th>
          {retaireIt && <th>IOU Deductions</th>}
          <th>Attendance Deduction</th>
          <th>Total Deductions</th>
          <th>Total Bonus</th>
          <th>Total Allowance</th>
          <th>Total Benefit</th>
          <th>Remark</th>
          <th>Net Pay</th>
          <th>Payslip</th>
        </tr>
      </thead>
    );
  };

  const tableBodyData = (employee, index) => {
    employee.settingHeaders = employee?.settingHeaders
      ? employee?.settingHeaders
      : {};
    return (
      <>
        <td>
          <input
            type="checkbox"
            onChange={() => onSelected(employee.userId)}
            checked={selectedData.find((id) => id === employee.userId)}
          />
        </td>
        <td>{index + 1}</td>
        <td>
          {employee.user?.personalInfo?.name.first +
            " " +
            employee.user?.personalInfo?.name.last}
        </td>
        <td>{employee.employeeId}</td>
        {employee.useExcel === "Use Timesheet" && <td>{employee.sfa_id}</td>}
        <td>{employee.gradeName}</td>

        {companySettings?.extras ? (
          <>
            <td>
              {employee?.userExtras?.DaysCommitted === ""
                ? 0
                : employee?.userExtras?.DaysCommitted}
            </td>
            <td>
              {employee?.userExtras?.DayOn === ""
                ? 0
                : employee?.userExtras?.DayOn}
            </td>
            <td>
              {employee?.userExtras?.TransitDays === ""
                ? 0
                : employee?.userExtras?.TransitDays}
            </td>
            <td>
              {employee?.userExtras?.OverStayDays === ""
                ? 0
                : employee?.userExtras?.OverStayDays}
            </td>
            <td>
              {employee?.userExtras?.ArrearsDays === ""
                ? 0
                : employee?.userExtras?.ArrearsDays}
            </td>
          </>
        ) : null}

        <td>{employee.usedlocation}</td>
        <td>{employee.usedcontract}</td>
        {employee.useExcel === "Use Timesheet" && (
          <>
            <td>{employee?.totalDaysWorked}</td>
            <td>{parseFloat(employee?.numProration).toFixed(2)}</td>
          </>
        )}
        <td>
          {currency(employee.grossPay, {
            symbol: "",
          }).format()}
        </td>
        {Object.entries(employee?.settingHeaders).length === 0 ||
        employee?.settingHeaders?.basic ? (
          <td>
            {currency(employee.basic, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {Object.entries(employee?.settingHeaders).length === 0 ||
        employee?.settingHeaders?.housing ? (
          <td>
            {currency(employee.housing, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {Object.entries(employee?.settingHeaders).length === 0 ||
        employee?.settingHeaders?.transport ? (
          <td>
            {currency(employee.transport, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {Object.entries(employee?.settingHeaders).length === 0 ||
        employee?.settingHeaders?.medical ? (
          <td>
            {currency(employee.medical, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {Object.entries(employee?.settingHeaders).length === 0 ||
        employee?.settingHeaders?.utility ? (
          <td>
            {currency(employee.utility, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {Object.entries(employee?.settingHeaders).length === 0 ||
        employee?.settingHeaders?.entertainment ? (
          <td>
            {currency(employee.entertainment, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {Object.entries(employee?.settingHeaders).length === 0 ||
        employee?.settingHeaders?.dressing ? (
          <td>
            {currency(employee.dressing, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {employee?.custom?.map((el) => (
          <td>
            {currency(el?.value, {
              symbol: "",
            }).format()}
          </td>
        ))}
        <td>
          {currency(employee.ogGrossPay, {
            symbol: "",
          }).format()}
        </td>

        {/* <td>{employee.bonuses}</td> */}

        {employee.deductions
          .filter((el) => !el.groupName)
          .map((deduction, index) => {
            return (
              <td key={index}>
                {currency(deduction.total, {
                  symbol: "",
                }).format()}
              </td>
            );
          })}

        {employee.DeductionGroupName.map((e, i) => {
          return (
            <td key={i}>
              {currency(
                employee.deductions
                  ?.filter((el) => el.groupName === e)
                  .map((el) => el.total)
                  .reduce((a, b) => a + b, 0),
                {
                  symbol: "",
                }
              ).format()}
            </td>
          );
        })}

        {employee.bonus
          .filter((el) => !el.groupName)
          .map((bouns, index) => {
            return (
              <td key={index}>
                {currency(bouns.total, {
                  symbol: "",
                }).format()}
              </td>
            );
          })}

        {employee.BonusGroupName.map((e, i) => {
          return (
            <td key={i}>
              {currency(
                employee.bouns
                  ?.filter((el) => el.groupName === e)
                  .map((el) => el.total)
                  .reduce((a, b) => a + b, 0),
                {
                  symbol: "",
                }
              ).format()}
            </td>
          );
        })}

        {employee.allowances
          .filter((el) => !el.groupName)
          .map((allowance, index) => {
            return (
              <td key={index}>
                {currency(allowance.total, {
                  symbol: "",
                }).format()}
              </td>
            );
          })}

        {employee.AllowanceGroupName.map((e, i) => {
          return (
            <td key={i}>
              {currency(
                employee.allowances
                  ?.filter((el) => el.groupName === e)
                  .map((el) => el.total)
                  .reduce((a, b) => a + b, 0),
                {
                  symbol: "",
                }
              ).format()}
            </td>
          );
        })}

        {employee.benefits
          .filter((el) => !el.groupName)
          .map((benefit, index) => {
            return (
              <td key={index}>
                {currency(benefit.total, {
                  symbol: "",
                }).format()}
              </td>
            );
          })}

        {employee.BenefitGroupName.map((e, i) => {
          return (
            <td key={i}>
              {currency(
                employee.benefits
                  ?.filter((el) => el.groupName === e)
                  .map((el) => el.total)
                  .reduce((a, b) => a + b, 0),
                {
                  symbol: "",
                }
              ).format()}
            </td>
          );
        })}

        <td>
          {currency(employee.loanDeduct, {
            symbol: "",
          }).format()}
        </td>
        {retaireIt && (
          <td>
            {employee.remainingRetairment
              ? currency(employee.remainingRetairment, {
                  symbol: "",
                }).format()
              : 0}
          </td>
        )}
        <td>
          {currency(employee.attendanceTotal, {
            symbol: "",
          }).format()}
        </td>
        <td>
          {currency(employee.allTotalDeduction, {
            symbol: "",
          }).format()}
        </td>
        <td>
          {currency(employee.totalBonus, {
            symbol: "",
          }).format()}
        </td>
        <td>
          {currency(employee.totalAllowances, {
            symbol: "",
          }).format()}
        </td>
        <td>
          {currency(employee.totalBenefits, {
            symbol: "",
          }).format()}
        </td>
        {/* <td>{employee.otherDeductions}</td> */}
        <td>{employee.remark}</td>
        <td>
          {currency(employee.netPay, {
            symbol: "",
          }).format()}
        </td>
        <td style={{ fontSize: "22px" }}>
          <a
            className="mx-2 text-primary"
            target="blank"
            href={URLS.backendPayroll + "/view-payslip/" + employee._id}
            title="View slip"
          >
            <i className="la la-eye"></i>
          </a>
          {/*
        <a
        className="mx-2 text-secondary"
        href={
          URLS.backendPayroll +
          "/download-payslip/" +
          employee._id
        }
        title="Download slip"
        download="true"
      >
        <i className="la la-download"></i>
      </a>
      */}
        </td>
      </>
    );
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>InvexERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Page Title -->*/}
            <div className="row">
              <div className="col-sm-12 col-12 text-capitalize">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Pay History</BreadcrumbItem>
                </Breadcrumb>
              </div>
              <div className="col-sm-5 col-5"></div>
              {/*<div className="col-sm-7 col-7 text-right m-b-30"></div>*/}
            </div>
            {/*<!-- /Page Title -->*/}
            {/*<!-- Content Starts -->*/}
            <div className="mt-3">
              <div className="d-flex justify-content-between my-4">
                <div className="d-flex">
                  <div className="form-inline">
                    <label>Month</label>
                    <select
                      className="form-control mx-2"
                      value={payrollDate.payrollMonth}
                      onChange={(e) =>
                        setPayrollDate({
                          payrollMonth: e.target.value,
                          payrollYear: payrollDate.payrollYear,
                        })
                      }
                    >
                      {[...Array(12)].map((e, index) => (
                        <option key={index}>{index + 1}</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-inline ml-3">
                    <label>Year</label>
                    <input
                      type="number"
                      style={{ width: "100px" }}
                      className="form-control mx-2"
                      value={payrollDate.payrollYear}
                      onChange={(e) =>
                        setPayrollDate({
                          payrollMonth: payrollDate.payrollMonth,
                          payrollYear: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="d-flex">
                  <select
                    className="form-control mx-2"
                    aria-label="Default select example"
                    value={employeeType}
                    onChange={(e) => setEmployeeType(e.target.value)}
                  >
                    <option value="All">All</option>
                    <option value="staff">Staff</option>
                    <option value="contract">Contract</option>
                  </select>
                  <div className="mx-4">
                    <CSVLink
                      className="btn print d-none"
                      filename={`Payhistory(${moment(new Date()).format(
                        "DD-MMM-yyyy hh:mm:ss a"
                      )}).csv`}
                      data={excelData}
                      ref={CSVLinkRef}
                    />

                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        className="print"
                        disabled={isfetchingExcel}
                        bsPrefix=""
                      >
                        <span className="mx-2">Export</span>
                        <ExportIcon color="#008000" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        popperConfig={{
                          strategy: "fixed",
                        }}
                        renderOnMount
                        className="text-center"
                      >
                        <Dropdown.Item
                          as="div"
                          onClick={onDownloadExcelData}
                          className="p-cursor"
                        >
                          Excel <ExcelIcon color="#008000" />
                        </Dropdown.Item>
                        {/* <Dropdown.Item as="div">
                          <a
                            href={`${backendUrl}/api/reports/pdf/sales-analysis?${queryString.stringify(
                              newQuery
                            )}`}
                            target="blank"
                          >
                            PDF
                            <PDFIcon color="#ff0000" />
                          </a>
                        </Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <button
                    onClick={() => setBtnSummaryActive(!btnSummryActive)}
                    className="btn summaryBtn "
                  >
                    Show Summary
                  </button>
                </div>
              </div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Salary Sheet
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Prorated
                  </NavLink>
                </NavItem> */}
              </Nav>
              {payroll.length ? (
                <TabContent activeTab={activeTab} className="pt-0">
                  <TabPane tabId="1">
                    <Row>
                      <TableComponent
                        responsive
                        striped
                        sticky
                        tableHeadsFunction={tableHead}
                        mainDataArray={payroll}
                        tableDataRowFunction={tableBodyData}
                        className="product-table text-nowrap"
                      />
                    </Row>
                  </TabPane>
                  {/* <TabPane tabId="2">
                    <Row>
                      <div className="col-md-12 w-100">
                        <div className="table-responsive">
                          <table className="table table-striped custom-table mb-0 datatable">
                            <thead>
                              <tr>
                                <th>No</th>
                                <th>Name</th>
                                <th>Job Grade</th>
                                <th>Basic</th>
                                <th>Housing</th>
                                <th>Transport</th>
                                <th>Medical</th>
                                <th>Utility</th>
                                <th>Paid Leave</th>
                                <th>Gratuity</th>

                                <th>Bonuses</th>
                                <th>Loan</th>

                                <th>Gross Pay</th>

                                {deductions.map((setting, index) => {
                                  return (
                                    <th className="text-capitalize" key={index}>
                                      {setting.name}
                                    </th>
                                  );
                                })}

                                <th>Other Deductions</th>
                                <th>Remark</th>
                                <th>Net Pay</th>
                                <th>Payslip</th>
                              </tr>
                            </thead>
                            <tbody>
                              {payroll.map((employee, index) => {
                                if (employee.type === "prorated")
                                  return (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>
                                        {employee.user?.personalInfo?.name
                                          .first +
                                          " " +
                                          employee.user?.personalInfo?.name
                                            .last}
                                      </td>
                                      <td>{employee.gradeName}</td>
                                      <td>{employee.basic}</td>
                                      <td>{employee.housing}</td>
                                      <td>{employee.transport}</td>
                                      <td>{employee.medical}</td>
                                      <td>{employee.utility}</td>
                                      <td>{employee.paidLeave}</td>
                                      <td>{employee.gratuity}</td>

                                      <td>{employee.bonuses}</td>
                                      <td>{employee.loanDeduct}</td>
                                      <td>{employee.grossPay}</td>

                                      {employee.deductions.map(
                                        (deduction, index) => {
                                          return (
                                            <td key={index}>
                                              {deduction.total}
                                            </td>
                                          );
                                        }
                                      )}

                                      <td>{employee.otherDeductions}</td>
                                      <td>{employee.remark}</td>
                                      <td>{employee.netPay}</td>
                                      <td style={{ fontSize: "22px" }}>
                                        <a
                                          className="mx-2 text-primary"
                                          target="blank"
                                          href={
                                            URLS.backendPayroll +
                                            "/view-payslip/" +
                                            employee._id
                                          }
                                          title="View slip"
                                        >
                                          <i className="la la-eye"></i>
                                        </a>
                                        <a
                                          className="mx-2 text-secondary"
                                          href={
                                            URLS.backendPayroll +
                                            "/download-payslip/" +
                                            employee._id
                                          }
                                          title="Download slip"
                                          download="true"
                                        >
                                          <i className="la la-download"></i>
                                        </a>
                                      </td>
                                    </tr>
                                  );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Row>
                  </TabPane> */}
                </TabContent>
              ) : (
                <div
                  className="w-100 d-flex justify-content-center align-items-center bg-white"
                  style={{ height: "250px" }}
                >
                  <p>{`No data on ${allMonths[payrollDate.payrollMonth - 1]} ${
                    payrollDate.payrollYear
                  }`}</p>
                </div>
              )}

              {selectedData.length > 0 && (
                <div className="d-flex justify-content-end my-4">
                  <a
                    target="blank"
                    href={`${URLS.backendPayroll}/view-payrolls-pdf?print=${payrollIds}`}
                    title="View slips"
                    className="btn btn-primary mx-4"
                  >
                    View Payslip
                  </a>
                  <button onClick={emailPayslip} className="btn btn-primary">
                    Email Payslip
                  </button>
                </div>
              )}

              <div
                className={
                  btnSummryActive
                    ? `summaryNew border-top open`
                    : `summaryNew border-top`
                }
              >
                <div className="row justify-content-between mt-4">
                  <div className="col">
                    <div className="my-3">
                      <div className="d-flex gap-3 align-items-center mb-4">
                        <h4 className="m-0">Payroll Summary</h4>{" "}
                        <hr className="flex-grow-1 m-0" />
                        {btnSummryActive && (
                          <button
                            onClick={() =>
                              setBtnSummaryActive(!btnSummryActive)
                            }
                            className="btn p-0"
                          >
                            <SummaryCloseIcon />
                          </button>
                        )}
                      </div>

                      <div
                        className="gridCont mb-3 three-col-grid"
                        style={{ gridTemplateRows: "unset" }}
                      >
                        {/*  */}
                        <div className="gridChild gridChildBorderLeftBlue">
                          <div className="gridChildLeft">
                            <p className="gridChld1">
                              {currency(totalGross, {
                                symbol: "",
                              }).format()}
                            </p>
                            <p className="gridChld2">Total Earned Gross </p>
                          </div>

                          <div className="gridChildRight">
                            <SummaryChartIconUp />
                            <p>5%</p>
                          </div>
                        </div>

                        {/*  */}
                        <div className="gridChild gridChildBorderLeftDarkerGreen">
                          <div className="gridChildLeft">
                            <p className="gridChld1">
                              {currency(totalAllowance, {
                                symbol: "",
                              }).format()}
                            </p>
                            <p className="gridChld2">Total Allowance</p>
                          </div>

                          <div className="gridChildRight">
                            <SummaryChartIconUp />
                            <p>5%</p>
                          </div>
                        </div>
                        {/*  */}
                        <div className="gridChild gridChildBorderLeftDarkerGreen">
                          <div className="gridChildLeft">
                            <p className="gridChld1">
                              {currency(totalBonus, {
                                symbol: "",
                              }).format()}
                            </p>
                            <p className="gridChld2">Total Bonus</p>
                          </div>

                          <div className="gridChildRight">
                            <SummaryChartIconUp />
                            <p>5%</p>
                          </div>
                        </div>

                        {/*  */}
                      </div>

                      {/* Second Layer */}

                      <div
                        className="gridCont mb-3 three-col-grid"
                        style={{ gridTemplateRows: "unset" }}
                      >
                        {/*  */}

                        <div className="gridChild gridChildBorderLeftDarkerGreen">
                          <div className="gridChildLeft">
                            <p className="gridChld1">
                              {currency(totalBenefit, {
                                symbol: "",
                              }).format()}
                            </p>
                            <p className="gridChld2">Total Benefit</p>
                          </div>

                          <div className="gridChildRight">
                            <SummaryChartIconUp />
                            <p>5%</p>
                          </div>
                        </div>
                        {/*  */}

                        <div className="gridChild gridChildBorderLeftOrange">
                          <div className="gridChildLeft">
                            <p className="gridChld1">
                              {currency(totalDeduct, {
                                symbol: "",
                              }).format()}
                            </p>
                            <p className="gridChld2">Total Deduction</p>
                          </div>

                          <div className="gridChildRight">
                            <SummaryChartIconUp />
                            <p>5%</p>
                          </div>
                        </div>
                        {/*  */}
                        <div className="gridChild gridChildBorderLeftDarkerGreen">
                          <div className="gridChildLeft">
                            <p className="gridChld1">
                              {currency(totalNet, {
                                symbol: "",
                              }).format()}
                            </p>
                            <p className="gridChld2">Total Net Amount</p>
                          </div>

                          <div className="gridChildRight">
                            <SummaryChartIconUp />
                            <p>5%</p>
                          </div>
                        </div>
                        {/*  */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Content End -->*/}
          </div>
          {/*<!-- /Page Content -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      <ModalLoader show={isLoading} />
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
    // deductionSettings: state.deductionSettings.deductions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PayHistory));
