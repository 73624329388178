import React from "react";
import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";

export default class Approvals extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <body>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex, nofollow"></meta>
          <title>InvexERP</title>
        </Helmet>
        <div className="main-wrapper">
          <Topbar />
          <div className="content container-fluid">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "600px" }}
            >
              <h3>Coming soon on 1st August</h3>
            </div>
          </div>
        </div>
        {/* <!-- /Main Wrapper --> */}

        {/* <!-- Sidebar Overlay --> */}
        <div className="sidebar-overlay" data-reff=""></div>
      </body>
    );
  }
}
