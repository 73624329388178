import React, { Component } from "react";
import { Col, Form, Row } from "react-bootstrap";
import $ from "jquery";
import Cookies from "universal-cookie";
import { URLS } from "../urls";
import "../../assets/css/jobgrade.css";
import { branch_id } from "../../utils";

export default class AddEmployeeDepartmentModal extends Component {
  constructor(props) {
    super(props);
    const cookies = new Cookies();
    this.state = {
      addDepartment: "",
      user_id: cookies.get("userId"),
      company_id: cookies.get("company_id"),
    };
  }

  submitAddForm = (e) => {
    e.preventDefault();
    const token = "dummy token";
    // const { addDepartment, create_public, create_group, company_id, user_id } =
    //   this.state;
    const { addDepartment, user_id, company_id } = this.state;

    fetch(`${URLS.backendDepartments}/add`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        // department: { name: addDepartment, company_id },
        department: { name: addDepartment, company_id, branch_id },
        // create_public,
        // create_group,
        user_id,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        // console.log(result);
        this.props.updateDepartmentsList(result.department);
        this.props.changeShowEmployeeDepartment();
      });
  };

  cancelDepartment = (e) => {
    e.preventDefault();
    this.props.changeShowEmployeeDepartment();
  };

  updateEmployeeDepartmentName = (event) => {
    this.setState({
      addDepartment: event.target.value,
    });
  };

  render() {
    const { addDepartment } = this.state;
    return (
      <div
        id="add_employeeDepartment"
        style={
          this.props.isShow
            ? {
                width: "50%",
                position: "fixed",
                left: "50%",
                marginLeft: "-25%",
                top: "20px",
                zIndex: "2000",
                display: "block",
                backgroundColor: "#eaecef",
                // height: '100%',
                borderRadius: "10px",
              }
            : {
                width: "50%",
                position: "fixed",
                left: "50%",
                marginLeft: "-25%",
                top: "20px",
                zIndex: "2000",
                display: "none",
                backgroundColor: "#eaecef",
                // height: '100%',
                borderRadius: "10px",
              }
        }
      >
        <Form
          onSubmit={this.submitAddForm}
          style={{
            // overflowY: 'scroll',
            maxHeight: "100%",
            // padding: '50px 30px',
            borderRadius: "10px",
          }}
        >
          <Form.Group
            as={Row}
            style={{
              backgroundColor: "#5378b2",
              alignItems: "left",
              justifyContent: "left",
              fontSize: "16px",
              color: "#ffffff",
              fontWeight: "bold",
              height: "60px",
              borderRadius: "10px 10px 0 0",
              padding: "10px 15px",
              marginLeft: "0",
              marginRight: "0",
              display: "block",
            }}
          >
            <span
              style={{
                display: "inline-block",
                height: "40px",
                lineHeight: "40px",
                float: "left",
              }}
            >
              Create New Department
            </span>
            <span
              style={{
                display: "inline-block",
                height: "40px",
                float: "right",
                padding: "2px 10px",
              }}
            >
              <button
                className="btn btn-info"
                style={{
                  padding: "2px 10px",
                  background: "#ffffff",
                  color: "#000000",
                  borderRadius: "20px",
                  border: "none",
                }}
                onClick={this.cancelDepartment}
              >
                x
              </button>
            </span>
          </Form.Group>
          <Form.Group as={Row} style={{ marginLeft: "0", marginRight: "0" }}>
            <Form.Label style={{ fontSize: "14px" }} column sm={2}>
              Department Name
            </Form.Label>
            <Col sm={6}>
              <Form.Control
                value={addDepartment}
                onChange={this.updateEmployeeDepartmentName}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            style={{
              marginLeft: "0",
              marginRight: "0",
              paddingRight: "15px",
              justifyContent: "flex-end",
            }}
          >
            <button
              style={{
                borderRadius: "7px",
                backgroundColor: "#5378b2",
                border: "none",
              }}
              className="btn btn-primary submit-btn"
            >
              Create Now
            </button>
          </Form.Group>
        </Form>
      </div>
    );
  }
}
