import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export function DialogModal(
  { show, proceed, handleClose, title, description, sendTitle },
  props
) {
  return (
    <Modal
      {...props}
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{description}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={proceed}>
          {sendTitle ? sendTitle : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
