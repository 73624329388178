import React from 'react';
import Cookies from "universal-cookie";
import { URLS } from '../../urls';

export default class AllTypeTasksUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projectTasks:[],
            inCompletedTask:[],
			completedTask:[],
			underReviewedTask:[],
            completedTaskCount : 0,
			inCompletedTaskCount : 0,
			underReviewedTaskCount:0,
			selectedTask : null,
			isLeader : false
        }
	}
	changeDateFormat = (date) =>{
		const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
		date = date.split('/');
		return date[0] + ' '+ months[parseInt(date[1])-1] + ' ' +date[2];
	}
    componentWillReceiveProps(props){
        if(props.projectTasks.length){
            const completedTask = props.projectTasks.filter(task => {return task.status === "COMPLETE"})
            const completedTaskCount = completedTask.length

            const inCompletedTask = props.projectTasks.filter(task => {return task.status === "INCOMPLETE"})
			const inCompletedTaskCount = inCompletedTask.length

			const underReviewedTask = props.projectTasks.filter(task => {return task.status === "UNDER_REVIEW"})
            const underReviewedTaskCount = underReviewedTask.length
            this.setState({
                completedTask,
				inCompletedTask,
				underReviewedTask,
                completedTaskCount,
				inCompletedTaskCount,
				underReviewedTaskCount,
                projectTasks: props.projectTasks
            })
            this.setState({
                projectTasks: props.projectTasks,
            });
		}
		if(props.isLeader){
			this.setState({
				isLeader : props.isLeader
			})
		}
    }
    changeSelectedTask = (task) => (evt) => {
		this.setState({
			selectedTask: task
		});
	}
	approveTask = (task) => (evt) =>{
		const token = 'dummy token';
		const cookies = new Cookies();
		const id = cookies.get('userId');
		
		const taskId = task._id;
		if(!task.assignedTo || task.assignedTo===''){
			alert("Task is not assigned to anyone. Can not change status.");
			return;
		}
	
		task.status = 'COMPLETE'
		task.approvedBy = id;
		task.approvedDate = new Date();
		fetch(`${URLS.backendTasks}/approveTask`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
			body : JSON.stringify({task : task})
		}).then(res => res.json())
			.then((result) => {
				if(result.task){
					this.updateTaskLists(task);
				}
			});
	}
	updateTaskLists(task){
		var {completedTask,underReviewedTask,completedTaskCount,underReviewedTaskCount} = this.state;
		
		for(let i=0; i<underReviewedTaskCount; i++){
			let uTask = underReviewedTask[i];
			if(uTask._id===task._id){
				underReviewedTask.splice(i,1);
				break;
			}
		}
		underReviewedTaskCount -= 1;
		completedTaskCount += 1;
		completedTask.push(task);
		this.setState({
			completedTask,underReviewedTask,completedTaskCount,underReviewedTaskCount
		})
	}
	
    render(){
        const {projectTasks,inCompletedTask,completedTask,underReviewedTask,isLeader} = this.state;
        return (
            <div className="tab-content">
				<div className="tab-pane show active" id="all_tasks">
					<div className="task-wrapper">
						<div className="task-list-container">
							<div className='task-list-body'>
								<ul id='task-list'>
									{
										projectTasks.length ? projectTasks.map(projectTask => {
											return <TaskEntry projectTask={projectTask} changeSelectedTask={this.changeSelectedTask} approveTask={this.approveTask} isLeader={isLeader}/>;
										}):''
									}
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="tab-pane" id="pending_tasks">
					<div className="task-wrapper">
						<div className="task-list-container">
							<div className='task-list-body'>
								<ul id='task-list'>
									{
										inCompletedTask.length ? inCompletedTask.map(projectTask => {
											return <TaskEntry projectTask={projectTask} changeSelectedTask={this.changeSelectedTask} approveTask={this.approveTask} isLeader={isLeader}/>;
										}) : ''
									}
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="tab-pane" id="under_reviewed_tasks">
					<div className="task-wrapper">
						<div className="task-list-container">
							<div className='task-list-body'>
								<ul id='task-list'>
									{
										underReviewedTask.length ? underReviewedTask.map(projectTask => {
											return <TaskEntry projectTask={projectTask} changeSelectedTask={this.changeSelectedTask} approveTask={this.approveTask} isLeader={isLeader}/>;
										}) : ''
									}
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="tab-pane" id="completed_tasks">
					<div className="task-wrapper">
						<div className="task-list-container">
							<div className='task-list-body'>
								<ul id='task-list'>
									{
										completedTask ? completedTask.map(projectTask => {
											return <TaskEntry projectTask={projectTask} changeSelectedTask={this.changeSelectedTask} approveTask={this.approveTask} isLeader={isLeader}/>;
										}) : ''
									}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
									
        )
    }
}
class TaskEntry extends React.Component{
	changeDateFormat = (date) =>{
		const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
		date = date.split('/');
		return date[0] + ' '+ months[parseInt(date[1])-1] + ' ' +date[2];
	}
	render(){
		const {projectTask,isLeader} = this.props;
		return(
			<li className={projectTask.status === 'COMPLETE' ? 'completed task' : projectTask.status === 'UNDER_REVIEW' ? 'under-reviewed task ':'task'}>
				<div className='task-container'>
					<span className='task-action-btn task-check'>
						<span className='action-circle large complete-btn' title={projectTask.status === 'COMPLETE' ? 'Mark Complete' : 'Mark Incomplete'} >
							<i className='material-icons'>check</i>
						</span>
					</span>
					<span className='task-label' onClick={this.props.changeSelectedTask(projectTask)}>{projectTask.taskName}</span>
					<span className='task-action-btn' style={{display:'block'}}>
						<span style={{display:'inline-block',padding:'0 15px'}}>
						<span style={{color: '#8e8e8e', fontSize: '90%'}}>Start Date</span> <span style={{display:'block'}}>{this.changeDateFormat(projectTask.startDate)}</span> 
						</span>
						<span style={{display:'inline-block',padding:'0 15px'}}>
						<span style={{color: '#8e8e8e', fontSize: '90%'}}>Due Date</span><span style={{display:'block'}}>{this.changeDateFormat(projectTask.dueDate)}</span>
						</span>
						{
							projectTask.status === 'COMPLETE' ? 
								<span style={{display:'inline-block',padding:'0 15px'}}>
									<span style={{color: '#8e8e8e', fontSize: '90%'}}>Complete Date</span> <span style={{display:'block'}}>{this.changeDateFormat(projectTask.completeDate)}</span>
								</span> 
								: 
							isLeader && projectTask.status === 'UNDER_REVIEW' ? 
								<span style={{display:'inline-block',padding:'0 15px',verticalAlign:'super'}}>
									<button style={{height:'30px',padding:'2px 12px'}} className='btn btn-primary' onClick={this.props.approveTask(projectTask)}>Approve as Completed</button>
								</span>
								:
							!isLeader && projectTask.status === 'UNDER_REVIEW' ?
								<span style={{display:'inline-block',padding:'0 15px'}}>
									<span style={{color: '#8e8e8e', fontSize: '90%'}}>Complete Date</span> <span style={{display:'block',color:'#00c5fb'}}>Under Review</span>
								</span>
								:
								<span style={{display:'inline-block',padding:'0 15px'}}>
									<span style={{color: '#8e8e8e', fontSize: '90%'}}>Complete Date</span> <span style={{display:'block'}}>NA</span>
								</span>

						}
					</span>
				</div>
			</li>
		)
	}
}