import React, { useState, useEffect } from "react";
import { Col, Form, Row, Modal } from "react-bootstrap";
import Select from "react-select";
import "../../assets/css/jobgrade.css";
import { URLS } from "../urls";
import { toast } from "react-toastify";
import CurrencyInput from "react-currency-input-field";
import { branch_id, termsValiable } from "../../utils";
import currency from "currency.js";

export const EditTermsModal = (props) => {
  const [staticAmount, setStaticAmount] = useState(
    props?.selectedLoan?.payments[0]
  );
  const [singleGrade, setSingleGrade] = useState("");
  const [aLoan, setALoan] = useState({});
  const [selected, setSelected] = useState({});

  const token = "dummy token";

  useEffect(() => {
    props?.selectedLoan?.motions && props?.selectedLoan?.loanmotion !== "Resume"
      ? setSingleGrade(props?.selectedLoan?.loanmotion)
      : setSingleGrade(props?.selectedLoan?.Term);
    setALoan(props?.selectedLoan);
    // console.log(props?.selectedLoan);
  }, [props.selectedLoan, props.loanmotion]);

  const fullName = (datas) => {
    return `${datas?.userID?.personalInfo?.name?.first} ${datas?.userID?.personalInfo?.name?.last}`;
  };

  const updateTerms = (terms) => {
    const { balance, Amount } = aLoan;
    const amount = balance ? balance : Amount;
    let motions = false;
    let divideLoan = [];

    if (terms === "Resume" || terms === "Pause") {
      motions = true;
    } else {
      motions = false;
      divideLoan =
        terms === "Monthly"
          ? currency(amount)
              .distribute(12)
              .map((el) => currency(el).value)
          : terms === "Quaterly"
          ? currency(amount)
              .distribute(4)
              .map((el) => currency(el).value)
          : terms === "Bi-Annual"
          ? currency(amount)
              .distribute(6)
              .map((el) => currency(el).value)
          : terms === "Annual"
          ? currency(amount)
              .distribute(1)
              .map((el) => currency(el).value)
          : terms === "Custom"
          ? [staticAmount]
          : 0;
    }

    setSelected({ terms, payments: divideLoan, motions });
  };

  const submitJob = async (e) => {
    e.preventDefault();

    if (selected?.terms === "Custom") {
      if (props?.selectedLoan?.balance) {
        if (
          parseFloat(staticAmount) > parseFloat(props?.selectedLoan?.balance)
        ) {
          return toast(
            `The Amount is higher than the balance\n Your balance is ${currency(
              props?.selectedLoan?.balance,
              { symbol: "" }
            ).format()}`,
            {
              type: "error",
              position: "top-right",
            }
          );
        } else {
          selected.payments = [parseFloat(staticAmount)];
        }
      } else {
        if (
          parseFloat(staticAmount) > parseFloat(props?.selectedLoan?.Amount)
        ) {
          return toast(
            `The Amount is higher than the loan amount\n The loan amount is ${currency(
              props?.selectedLoan?.Amount,
              { symbol: "" }
            ).format()}`,
            {
              type: "error",
              position: "top-right",
            }
          );
        } else {
          selected.payments = [parseFloat(staticAmount)];
        }
      }
    }

    try {
      const { requestid } = aLoan;

      // console.log(jobGrade);
      const res = await fetch(
        `${URLS.backendLoan}/term-by-branch/${requestid}/${branch_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
          body: JSON.stringify({ ...selected }),
        }
      );

      const result = await res.json();

      if (result.success) {
        props.refreshterm(result.loan);
        props.handleClose();
        toast("Loan terms updated successfully", {
          type: "success",
        });
      } else {
        const e = Error(result.err);
        console.log(e);
        toast("Unable to edit Loan terms", {
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      {...props}
      show={props.show}
      onHide={props.handleClose}
      aria-labelledby=""
      centered
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit {fullName(props.selectedLoan)} Loan Term</Modal.Title>
      </Modal.Header>
      <Form onSubmit={submitJob} className="px-3 mt-3">
        <Form.Group as={Row}>
          {/*  */}
          <Form.Label style={{ fontSize: "14px" }} column sm={3}>
            Loan Terms
          </Form.Label>
          <Col sm={8}>
            <Select
              closeMenuOnSelect={true}
              isSearchable={true}
              onChange={(selected) => {
                setSingleGrade(selected.value);
                updateTerms(selected.value);
              }}
              value={termsValiable.find((d) => singleGrade === d.value)}
              options={termsValiable}
            />
          </Col>
        </Form.Group>

        {singleGrade === "Custom" && (
          <Form.Group as={Row}>
            <Form.Label style={{ fontSize: "14px" }} column sm={3}>
              Amount
            </Form.Label>
            <Col sm={8}>
              <CurrencyInput
                className="form-control"
                value={staticAmount}
                allowNegativeValue={false}
                // allowDecimals={false}
                onValueChange={(value, name) => setStaticAmount(value)}
                max={props?.selectedLoan?.balance}
              />
            </Col>
          </Form.Group>
        )}

        <div className="d-flex justify-content-center p-4">
          <button
            style={{
              borderRadius: "7px",
              backgroundColor: "#5378b2",
              border: "none",
            }}
            className="btn btn-primary submit-btn"
          >
            Edit Term
          </button>
        </div>
      </Form>
    </Modal>
  );
};
