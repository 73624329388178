import React from "react";
import moment from "moment";
import ImageList from "./ImageList";
import "../../../assets/css/project.css";
import { URLS } from "../../urls";
import { Link } from "react-router-dom";

export default class Team extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  dateInFormat(date) {
    date = date.split("T")[0];
    return date;
  }
  render() {
    const { team, tasks, projects } = this.props;
    
    return (
      <div className="col-lg-4 col-sm-6 col-md-4 col-xl-4">
        <div className="card-box project-box">
          <div className="dropdown dropdown-action profile-action">
            <a
              href="Javascript:void(0)"
              className="action-icon dropdown-toggle"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="material-icons">more_vert</i>
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <a
                className="dropdown-item"
                href="Javascript:void(0)"
                data-toggle="modal"
                data-target="#edit_team"
                onClick={this.props.onEdit.bind(this, team._id)}
              >
                <i className="fa fa-pencil m-r-5"></i> Edit
              </a>
              <a
                className="dropdown-item"
                href="Javascript:void(0)"
                data-toggle="modal"
                data-target="#delete_team"
              >
                <i className="fa fa-trash-o m-r-5"></i> Delete
              </a>
            </div>
          </div>
          <h4 className="project-title">
            <Link to={`${URLS.userDashboardTeamView}/${team._id}`}>{team.name}</Link>
          </h4>
          <small
            className="block text-ellipsis"
            style={{ marginBottom: "5px" }}
          >
            <span className="text-xl" style={{ verticalAlign: "middle" }}>
              <i style={{ fontSize: "24px" }} className="la la-group" />{" "}
            </span>{" "}
            <span className="text-muted"> {team.totalSize} Members </span>
            <span className="text-xs" style={{ verticalAlign: "middle" }}>
              <i style={{ fontSize: "24px" }} className="la la-calendar"></i>{" "}
            </span>{" "}
            <span className="text-muted">
              {" "}
              Created: {this.dateInFormat(team.createdAt)}
            </span>
          </small>
          <small
            className="block text-ellipsis"
            style={{ marginBottom: "5px" }}
          >
            <span className="text-xl" style={{ verticalAlign: "middle" }}>
              <i style={{ fontSize: "24px" }} className="la la-rocket" />{" "}
            </span>
            <span class="text-xs">
              {projects && projects[team._id] && projects[team._id]["open"]}
            </span>{" "}
            <span class="text-muted">Open Projects, </span>
            <span class="text-xs">
              {projects &&
                projects[team._id] &&
                projects[team._id]["completed"]}
            </span>{" "}
            <span class="text-muted">Completed Projects</span>
          </small>
          <small
            className="block text-ellipsis"
            style={{ marginBottom: "5px" }}
          >
            <span className="text-xl" style={{ verticalAlign: "middle" }}>
              <i style={{ fontSize: "24px" }} className="la la-clock-o"></i>{" "}
            </span>
            <span class="text-xs">
              {tasks && tasks[team._id] && tasks[team._id]["open"]}
            </span>{" "}
            <span class="text-muted">Open Tasks, </span>
            <span class="text-xs">
              {tasks && tasks[team._id] && tasks[team._id]["completed"]}
            </span>{" "}
            <span class="text-muted">Completed Tasks</span>
          </small>
          <p className="text-muted">{team.description}</p>

          <div className="project-members m-b-15">
            <div>Team Leader:</div>
            <ul className="team-members">
              <ImageList value={team.leaders} />
            </ul>
          </div>
          <div className="project-members m-b-15">
            <div>Members:</div>
            <ul className="team-members">
              <ImageList
                value={
                  team.members.length > 3
                    ? team.members.slice(0, 3)
                    : team.members
                }
              />
              {team.members.length > 3 ? (
                <li>
                  <a href="Javascript:void(0)" className="all-users">
                    +{team.members.length - 3}
                  </a>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
          {/* <p className="m-b-5">
            Project Completion Rate{" "}
            <span className="text-success float-right">40%</span>
          </p> */}
          <div className="progress progress-xs mb-0">
            <div
              className="progress-bar bg-success"
              role="progressbar"
              data-toggle="tooltip"
              title="0%"
              style={{ width: "0%" }}
            ></div>
          </div>
          <p style={{ marginTop: "10px", fontSize: "14px" }}>
            <Link
              to={`${URLS.userDashboardTeamView}/${team._id}`}
              className="float-right"
            >
              {" "}
              View Team
            </Link>
          </p>
        </div>
      </div>
    );
  }
}
