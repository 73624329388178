import React from "react";
import moment from "moment";
import ImageList from "./ImageList";
import "../../assets/css/project.css";
import { URLS } from "../urls";
import { Link } from "react-router-dom";

class Project extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      completedTaskCount: 0,
      inCompletedTaskCount: 0,
      underReviewedTaskCount: 0,
    };
  }
  componentWillMount() {
    const token = "dummy token";
    fetch(`${URLS.backendTasks}/getByProjectId/${this.props.project._id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        const completedTaskCount = result.tasks.filter((task) => {
          return task.status === "COMPLETE";
        }).length;
        const inCompletedTaskCount = result.tasks.filter((task) => {
          return task.status === "INCOMPLETE";
        }).length;
        const underReviewedTaskCount = result.tasks.filter((task) => {
          return task.status === "UNDER_REVIEW";
        }).length;
        this.setState({
          completedTaskCount,
          inCompletedTaskCount,
          underReviewedTaskCount,
        });
      });
  }

  render() {
    const {
      project,
      projectTeamList,
      projectLeaderList,
      canShowControls,
    } = this.props;
    const {
      completedTaskCount,
      inCompletedTaskCount,
      underReviewedTaskCount,
    } = this.state;
    const progress =
      completedTaskCount + inCompletedTaskCount + underReviewedTaskCount > 0
        ? (
            (completedTaskCount * 100) /
            (completedTaskCount + inCompletedTaskCount + underReviewedTaskCount)
          ).toFixed(2)
        : 0;
    let endDate = (project.endDate || "").split("/");
    if (endDate.length !== 3) {
      endDate = ["a", "b", "c"];
    }
    return (
      <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
        <div className="widget">
          <div className="project-widget-header">
            <div className="widget-title">
              <Link to={`${URLS.projectView}/${project._id}`}>
                {project.name}
              </Link>
            </div>
            {project.projectNumber ? (
              <div>
                <small>{project.projectNumber}</small>
              </div>
            ) : null}
            <div className="widget-subtitle">
              <b>{inCompletedTaskCount + underReviewedTaskCount}</b> open tasks,{" "}
              <b>{completedTaskCount}</b> tasks completed
            </div>
            <div className="widget-body">
              <p>{project.description}</p>
            </div>
            <div className="widget-subbody">
              <div className="widget-subbody-header">Deadline:</div>
              <div className="widget-subbody-text">
                {moment(
                  endDate[1] + "/" + endDate[0] + "/" + endDate[2]
                ).format("Do MMM YY")}
              </div>
            </div>
            <div className="widget-subbody">
              <div className="widget-subbody-header">Project Leader :</div>
              <div className="widget-subbody-space">
                <ImageList value={projectLeaderList} />
              </div>
            </div>
            <div className="widget-subbody">
              <div className="widget-subbody-header">Team :</div>
              <div className="widget-subbody-space">
                <ImageList value={projectTeamList} />
              </div>
            </div>
            <div className="widget-subbody">
              <div className="widget-subbody-header">
                Progress{" "}
                <span class="text-success float-right">
                  {progress.toString() + "%"}
                </span>
              </div>
              <div class="progress progress-xs mb-0">
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  data-toggle="tooltip"
                  title={progress.toString() + "%"}
                  style={{ width: progress + "%" }}
                ></div>
              </div>
            </div>
          </div>
          {canShowControls && (
            <div className="dropdown profile-action">
              <a
                href="#"
                className="action-icon dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="material-icons">more_vert</i>
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <a
                  className="dropdown-item"
                  href="#"
                  data-toggle="modal"
                  data-target="#edit_project"
                  onClick={this.props.onEdit.bind(this, project._id)}
                >
                  <i className="fa fa-pencil m-r-5"></i> Edit
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  data-toggle="modal"
                  data-target="#delete_project"
                  onClick={this.props.onDelete.bind(this, project._id)}
                >
                  <i className="fa fa-trash-o m-r-5"></i> Delete
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Project;
