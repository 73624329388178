/* eslint-disable no-script-url */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { URLS } from "../urls";
import { Link } from "react-router-dom";
//import Cookies from "universal-cookie";
import { connect } from "react-redux";
import "../../assets/css/sidebar.css";
import { branch_id } from "../../utils";
import { DownArrowIcon } from "../icons";

const Sidebar = (props) => {
  const [activeTab, setActiveTab] = useState("");
  const [submenuOpen, setSubmenuOpen] = useState({
    employee: false,
    payroll: false,
    payrollLevelOne: false,
    bonus: false,
    jobs: false,
    reports: false,
    settings: false,
    allowance: false,
    benefit: false,
    settingsReports: false,
    allowanceIndex: null,
    benefitIndex: null,
    bonusIndex: null,
    deductionIndex: null,
    allowanceSmall: false,
    benefitSmall: false,
    bonusSmall: false,
    deductionSmall: false,
  });
  const [insideActiveTab, setInsideActiveTab] = useState("");

  //const cookies = new Cookies();
  //const isSuperAdmin = cookies.get("isSuperAdmin");

  const scrollParent = {
    height: "100%",
    overflow: "hidden",
    paddingBottom: "70px",
    // width: "240px",
    width: "14.688rem",
  };
  const scrollChild = {
    width: "100%",
    height: "100%",
    // overflow: "hidden",
    overflow: "scroll",
    // overflowX: "hidden",
    boxSizing: "content-box",
    // paddingRight: "15px",
  };

  return (
    <div className="sidebar" id="sidebar" style={scrollParent}>
      <div className="sidebar-inner" style={scrollChild}>
        <div
          id="sidebar-menu"
          className="sidebar-menu"
          style={{ width: "100%" }}
        >
          <ul style={{ width: "14.688rem;" }}>
            <li
              className={
                activeTab === "dashboard"
                  ? "submenu-sidebar side-active mt-4"
                  : "submenu-sidebar mt-4"
              }
              onClick={() => setActiveTab("dashboard")}
            >
              <Link
                to={`${URLS.dashboard}`}
                className="side-anchor"
                onClick={() => setActiveTab("dashboard")}
              >
                <span className="icon">
                  <i className="la la-dashboard"></i>
                </span>
                <span>Dashboard</span>
              </Link>
            </li>
            {/* {isSuperAdmin ||  isSuperAdmin ? ( */}
            <li
              className={
                activeTab === "employees" ? "submenu side-active" : "submenu"
              }
              onClick={() => setActiveTab("employees")}
            >
              <a
                href="#"
                onClick={() =>
                  setSubmenuOpen((p) => ({
                    ...p,
                    employee: !submenuOpen.employee,
                  }))
                }
                className="side-anchor"
              >
                <span className="icon">
                  <i className="la la-user "></i>
                </span>
                <span> Employees</span> <span className="menu-arrow"></span>
              </a>

              <ul
                style={
                  submenuOpen.employee
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <li
                  className={
                    insideActiveTab === "AllEmployees" ? "side-active" : ""
                  }
                  onClick={() => setInsideActiveTab("AllEmployees")}
                >
                  <Link to="/dashboard/employees">All Employees</Link>
                </li>

                <li>
                  <Link to="/dashboard/leaves">Leaves (Admin)</Link>
                </li>
                <li>
                  <Link to="/dashboard/leaveSettings">Leave Settings</Link>
                </li>

                <li>
                  <Link to="/dashboard/departments">Departments</Link>
                </li>
                <li>
                  <Link to="/dashboard/promotion">Promotion</Link>
                </li>
                <li>
                  <Link to="/dashboard/suspension">Suspension</Link>
                </li>
                <li>
                  <Link to="/dashboard/resignation">Resignation</Link>
                </li>
                <li>
                  <Link to="/dashboard/termination">Termination</Link>
                </li>
                <li>
                  <Link to="/dashboard/loan">Loans</Link>
                </li>
              </ul>
            </li>
            {/* ) :  null} */}

            {
              <li
                className={
                  activeTab === "payroll" ? "submenu side-active" : "submenu"
                }
                onClick={() => setActiveTab("payroll")}
              >
                <a
                  href="#"
                  onClick={() =>
                    setSubmenuOpen((p) => ({
                      ...p,
                      payroll: !submenuOpen.payroll,
                    }))
                  }
                  className="side-anchor"
                >
                  <span className="icon">
                    <i className="la la-money"></i>
                  </span>

                  <span> Payroll </span>
                  <span className="menu-arrow"></span>
                </a>
                <ul
                  style={
                    submenuOpen.payroll
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <li>
                    <Link to="/dashboard/process-payroll">Process Payroll</Link>
                  </li>
                  <li className="submenu">
                    <a
                      href="#"
                      onClick={() =>
                        setSubmenuOpen((p) => ({
                          ...p,
                          payrollLevelOne: !submenuOpen.payrollLevelOne,
                        }))
                      }
                    >
                      {" "}
                      <span className="ml-2"> Deduction Settings</span>{" "}
                      <span className="menu-arrow"></span>
                    </a>
                    <ul
                      style={
                        submenuOpen.payrollLevelOne
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <li>
                        <Link to="/dashboard/deduction-settings/paye-tax">
                          Paye Tax
                        </Link>
                      </li>
                      <li>
                        <Link to="/dashboard/deduction-settings/pension">
                          Employee Pension Contribution
                        </Link>
                      </li>
                      <li>
                        <Link to="/dashboard/deduction-settings/company-pension">
                          Employer Pension Contribution
                        </Link>
                      </li>
                      <li>
                        <Link to="/dashboard/deduction-settings/nsitf">
                          NSITF
                        </Link>
                      </li>
                      {props.deductionSettings
                        ?.filter((f) => f.branch_id === branch_id)
                        ?.filter((el) => !el.groupName)
                        ?.map((deduction) => {
                          if (
                            [
                              "paye tax",
                              "pension",
                              "nsitf",
                              "company pension",
                            ].includes(deduction.name)
                          ) {
                            return;
                          }
                          return (
                            <li
                              className="text-capitalize"
                              key={deduction.slug}
                            >
                              <Link
                                to={`/dashboard/deduction-settings/${deduction.slug}`}
                              >
                                {deduction.name}
                              </Link>
                            </li>
                          );
                        })}

                      {[
                        ...new Set(
                          props?.deductionSettings
                            ?.map((el) => el.groupName)
                            ?.filter((ft) => ft !== "" && ft !== undefined)
                        ),
                      ]?.map((all, i) => (
                        <li className="text-capitalize">
                          <a
                            href="#"
                            onClick={() =>
                              setSubmenuOpen((p) => ({
                                ...p,
                                deductionIndex: i,
                                deductionSmall: !submenuOpen.deductionSmall,
                              }))
                            }
                          >
                            <span> {all}</span>{" "}
                            <span className="menu-arrow"></span>
                          </a>

                          <ul
                            style={
                              submenuOpen.deductionIndex === i &&
                              submenuOpen.deductionSmall
                                ? { display: "block" }
                                : { display: "none" }
                            }
                          >
                            {props?.deductionSettings
                              ?.filter((f) => f.groupName === all)
                              ?.map((ele) => (
                                <li className="text-capitalize" key={ele.slug}>
                                  <Link
                                    to={`/dashboard/deduction-settings/${ele.slug}`}
                                  >
                                    {ele.name}
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </li>
                      ))}

                      <li className="">
                        <Link to="/dashboard/deduction-settings/add-new">
                          <i
                            className="la la-plus mr-1"
                            style={{ fontSize: "14px", marginBottom: "3px" }}
                          ></i>
                          Add New
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className="submenu">
                    <a
                      href="#"
                      onClick={() =>
                        setSubmenuOpen((p) => ({
                          ...p,
                          bonus: !submenuOpen.bonus,
                        }))
                      }
                    >
                      {" "}
                      <span className="ml-2"> Bonus Settings</span>{" "}
                      <span className="menu-arrow"></span>
                    </a>
                    <ul
                      style={
                        submenuOpen.bonus
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      {props.bonusSettings
                        ?.filter((f) => f.branch_id === branch_id)
                        ?.filter((el) => !el.groupName)
                        ?.map((bonus) => {
                          if (
                            [
                              "paye tax",
                              "pension",
                              "nsitf",
                              "company pension",
                            ].includes(bonus.name)
                          ) {
                            return;
                          }
                          return (
                            <li className="text-capitalize" key={bonus.slug}>
                              <Link
                                to={`/dashboard/bonus-settings/${bonus.slug}`}
                              >
                                {bonus.name}
                              </Link>
                            </li>
                          );
                        })}

                      {[
                        ...new Set(
                          props?.bonusSettings
                            ?.map((el) => el.groupName)
                            ?.filter((ft) => ft !== "" && ft !== undefined)
                        ),
                      ]?.map((all, i) => (
                        <li className="text-capitalize">
                          <a
                            href="#"
                            onClick={() =>
                              setSubmenuOpen((p) => ({
                                ...p,
                                bonusIndex: i,
                                bonusSmall: !submenuOpen.bonusSmall,
                              }))
                            }
                          >
                            <span> {all}</span>{" "}
                            <span className="menu-arrow"></span>
                          </a>

                          <ul
                            style={
                              submenuOpen.bonusIndex === i &&
                              submenuOpen.bonusSmall
                                ? { display: "block" }
                                : { display: "none" }
                            }
                          >
                            {props?.bonusSettings
                              ?.filter((f) => f.groupName === all)
                              ?.map((ele) => (
                                <li className="text-capitalize" key={ele.slug}>
                                  <Link
                                    to={`/dashboard/bonus-settings/${ele.slug}`}
                                  >
                                    {ele.name}
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </li>
                      ))}

                      <li className="">
                        <Link to="/dashboard/bonus-settings/add-new">
                          <i
                            className="la la-plus mr-1"
                            style={{ fontSize: "14px", marginBottom: "3px" }}
                          ></i>
                          Add New
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className="submenu">
                    <a
                      href="#"
                      onClick={() =>
                        setSubmenuOpen((p) => ({
                          ...p,
                          allowance: !submenuOpen.allowance,
                        }))
                      }
                    >
                      <span className="ml-2"> Allowance</span>{" "}
                      <span className="menu-arrow"></span>
                    </a>
                    <ul
                      style={
                        submenuOpen.allowance
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      {props?.allowance
                        ?.filter((f) => f.branch_id === branch_id)
                        ?.filter((el) => !el.groupName)
                        ?.map((allowan) => {
                          if (
                            [
                              "paye tax",
                              "pension",
                              "nsitf",
                              "company pension",
                            ].includes(allowan.name)
                          ) {
                            return "";
                          }
                          return (
                            <li className="text-capitalize" key={allowan.slug}>
                              <Link to={`/dashboard/allowance/${allowan.slug}`}>
                                {allowan.name}
                              </Link>
                            </li>
                          );
                        })}

                      {[
                        ...new Set(
                          props?.allowance
                            ?.map((el) => el.groupName)
                            ?.filter((ft) => ft !== "" && ft !== undefined)
                        ),
                      ]?.map((all, i) => (
                        <li className="text-capitalize">
                          <a
                            href="#"
                            onClick={() =>
                              setSubmenuOpen((p) => ({
                                ...p,
                                allowanceIndex: i,
                                allowanceSmall: !submenuOpen.allowanceSmall,
                              }))
                            }
                          >
                            <span> {all}</span>{" "}
                            <span className="menu-arrow"></span>
                          </a>

                          <ul
                            style={
                              submenuOpen.allowanceIndex === i &&
                              submenuOpen.allowanceSmall
                                ? { display: "block" }
                                : { display: "none" }
                            }
                          >
                            {props?.allowance
                              ?.filter((f) => f.groupName === all)
                              ?.map((ele) => (
                                <li className="text-capitalize" key={ele.slug}>
                                  <Link to={`/dashboard/allowance/${ele.slug}`}>
                                    {ele.name}
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </li>
                      ))}

                      <li className="">
                        <Link to="/dashboard/allowance/add-new">
                          <i
                            className="la la-plus mr-1"
                            style={{ fontSize: "14px", marginBottom: "3px" }}
                          ></i>
                          Add New
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className="submenu">
                    <a
                      href="#"
                      onClick={() =>
                        setSubmenuOpen((p) => ({
                          ...p,
                          benefit: !submenuOpen.benefit,
                        }))
                      }
                    >
                      <span className="ml-2"> Benefits</span>{" "}
                      <span className="menu-arrow"></span>
                    </a>
                    <ul
                      style={
                        submenuOpen.benefit
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      {props?.benefit
                        ?.filter((f) => f.branch_id === branch_id)
                        ?.filter((el) => !el.groupName)
                        ?.map((bene) => {
                          if (
                            [
                              "paye tax",
                              "pension",
                              "nsitf",
                              "company pension",
                            ].includes(bene.name)
                          ) {
                            return "";
                          }
                          return (
                            <li className="text-capitalize" key={bene.slug}>
                              <Link to={`/dashboard/benefit/${bene.slug}`}>
                                {bene.name}
                              </Link>
                            </li>
                          );
                        })}

                      {[
                        ...new Set(
                          props?.benefit
                            ?.map((el) => el.groupName)
                            ?.filter((ft) => ft !== "" && ft !== undefined)
                        ),
                      ]?.map((all, i) => (
                        <li className="text-capitalize">
                          <a
                            href="#"
                            onClick={() =>
                              setSubmenuOpen((p) => ({
                                ...p,
                                benefitIndex: i,
                                benefitSmall: !submenuOpen.benefitSmall,
                              }))
                            }
                          >
                            <span> {all}</span>{" "}
                            <span className="menu-arrow"></span>
                          </a>

                          <ul
                            style={
                              submenuOpen.benefitIndex === i &&
                              submenuOpen.benefitSmall
                                ? { display: "block" }
                                : { display: "none" }
                            }
                          >
                            {props?.benefit
                              ?.filter((f) => f.groupName === all)
                              ?.map((ele) => (
                                <li className="text-capitalize" key={ele.slug}>
                                  <Link to={`/dashboard/benefit/${ele.slug}`}>
                                    {ele.name}
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </li>
                      ))}

                      <li className="">
                        <Link to="/dashboard/benefit/add-new">
                          <i
                            className="la la-plus mr-1"
                            style={{ fontSize: "14px", marginBottom: "3px" }}
                          ></i>
                          Add New
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <Link to="/dashboard/job-grade"> Job Grade </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/change-job-grade">
                      Change Job Grade
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/allowance-application">
                      Allowance Application
                    </Link>
                  </li>

                  <li className="submenu">
                    <a
                      href="#"
                      onClick={() =>
                        setSubmenuOpen((p) => ({
                          ...p,
                          settingsReports: !submenuOpen.settingsReports,
                        }))
                      }
                    >
                      <span className="ml-2"> Reports</span>
                      <span className="menu-arrow"></span>
                    </a>
                    <ul
                      style={
                        submenuOpen.settingsReports
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <li>
                        <Link to="/dashboard/salary-summary">
                          Salary Schedule
                        </Link>
                      </li>
                      <li>
                        <Link to="/dashboard/pay-history">
                          {" "}
                          Payroll History{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/dashboard/deduction-reports">
                          Deduction Reports
                        </Link>
                      </li>
                      <li>
                        <Link to="/dashboard/bonus-reports">Bonus Reports</Link>
                      </li>
                      <li>
                        <Link to="/dashboard/allowance-reports">
                          Allowance Reports
                        </Link>
                      </li>
                      <li>
                        <Link to="/dashboard/benefit-reports">
                          Benefit Reports
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <Link to="/dashboard/pay-settings"> Payroll Settings </Link>
                  </li>
                </ul>
              </li>
            }

            <li
              className="submenu-sidebar"
              onClick={() => setActiveTab("clients")}
            >
              <Link to="/dashboard/clients" className="side-anchor">
                <span className="icon">
                  <i className="la la-users"></i>
                </span>
                <span>Clients</span>
              </Link>
            </li>
            <li
              className={
                activeTab === "contracts"
                  ? "submenu-sidebar side-active"
                  : "submenu-sidebar"
              }
              onClick={() => setActiveTab("contracts")}
            >
              <Link to="/dashboard/contracts" className="side-anchor">
                <span className="icon">
                  <i className="la la-briefcase"></i>
                </span>
                <span>Contracts</span>
              </Link>
            </li>

            <li
              className={
                activeTab === "timing"
                  ? "submenu-sidebar side-active"
                  : "submenu-sidebar"
              }
              onClick={() => setActiveTab("timing")}
            >
              <Link to="/dashboard/timesheet" className="side-anchor">
                <span className="icon">
                  <i className="la la-clock-o"></i>
                </span>
                <span>Timesheet</span>
              </Link>
            </li>

            <li
              className={
                activeTab === "attendance"
                  ? "submenu-sidebar side-active"
                  : "submenu-sidebar"
              }
              onClick={() => setActiveTab("attendance")}
            >
              <Link to="/dashboard/attendance" className="side-anchor">
                <span className="icon">
                  <i className="la la-clock-o"></i>
                </span>
                <span>Attendance</span>
              </Link>
            </li>

            <li
              className={
                activeTab === "permission"
                  ? "submenu-sidebar side-active"
                  : "submenu-sidebar"
              }
              onClick={() => setActiveTab("permission")}
            >
              <Link to="/dashboard/permission" className="side-anchor">
                <span className="icon">
                  <i className="la la-check-square-o"></i>
                </span>
                <span>Permission</span>
              </Link>
            </li>

            <li
              className={
                activeTab === "Forms/Approval"
                  ? "submenu-sidebar side-active"
                  : "submenu-sidebar"
              }
              onClick={() => setActiveTab("Forms/Approval")}
            >
              <a
                href={`${URLS.mainSite}/requisition/request`}
                className="side-anchor"
              >
                <span className="icon">
                  <i className="la la-check-square-o"></i>
                </span>
                <span>Forms/Approval</span>
              </a>
            </li>

            <li
              className={
                activeTab === "jobs" ? "submenu side-active" : "submenu"
              }
              onClick={() => setActiveTab("jobs")}
            >
              <a
                href="#"
                onClick={() =>
                  setSubmenuOpen((p) => ({ ...p, jobs: !submenuOpen.jobs }))
                }
                className="side-anchor"
              >
                <span className="icon">
                  <i className="la la-building"></i>
                </span>

                <span> Jobs </span>
                <span className="menu-arrow"></span>
              </a>
              <ul
                style={
                  submenuOpen.jobs ? { display: "block" } : { display: "none" }
                }
              >
                <li>
                  <Link to="/dashboard/jobs"> Manage Jobs </Link>
                </li>
                <li>
                  <Link to="/dashboard/job-applicants">
                    {" "}
                    Applied Candidates{" "}
                  </Link>
                </li>
              </ul>
            </li>

            <li
              className={
                activeTab === "policies"
                  ? "submenu-sidebar side-active"
                  : "submenu-sidebar"
              }
              onClick={() => setActiveTab("policies")}
            >
              <Link to="/dashboard/policies" className="side-anchor">
                <span className="icon">
                  <i className="la la-file-pdf-o"></i>
                </span>
                <span>Policies</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    // User: state.api.User,
    deductionSettings: state.deductionSettings.deductions,
    bonusSettings: state.bonusSettings.bonus,
    allowance: state.allowanceReducer.allowances,
    benefit: state.BenefitReducer.benefits,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
