import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";

import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { URLS } from "../urls";

import Cookies from "universal-cookie";
import UserTopbar from "../user-dashboard/user-topbar/UserTopbar";
import UserSidebar from "../user-dashboard/user-sidebar/UserSidebar";

function EmployeeDashboard() {
  const date = new Date();
  const cookies = new Cookies();
  const isFirstTime = cookies.get("isFirstTime");

  const [loggedEmployee, setLoggedEmployee] = useState({});
  const [userLeaveBalance, setuserLeaveBalance] = useState(null);
  const [counts, setCounts] = useState({
    projectCount: "...",
    taskIncompleteCount: "...",
    taskCount: "...",
  });

  useEffect(() => {
    getUser();
    getUpdatedLeaveBalance();
    getCounts();
    return () => {};
  }, []);

  const getUser = () => {
    const id = cookies.get("userId");
    const token = "dummy token";
    fetch(`${URLS.backendEmployees}/getShortInfoByUserId/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setLoggedEmployee({
          ...result.employee,
        });
      });
  };

  const getCounts = () => {
    // const id = cookies.get("userId");
    const token = "dummy token";
    fetch(`${URLS.backendApp}/employee-dashboard`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setCounts(result.counts);
      });
  };

  const getUpdatedLeaveBalance = () => {
    const token = "dummy token";
    const cookies = new Cookies();
    const id = cookies.get("userId");
    fetch(`${URLS.backendLeaveBalances}/getByUserId/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.leaveBalance) {
          setuserLeaveBalance({
            ...result.leaveBalance,
          });
        }
      });
  };

  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>InvexERP</title>
      </Helmet>
      <div classNameName="main-wrapper">
        <UserTopbar />
        <UserSidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="welcome-box">
                  <div className="welcome-img">
                    <img alt="" src="assets/img/profiles/avatar-02.jpg" />
                  </div>
                  <div className="welcome-det">
                    <h3>Welcome, {loggedEmployee.name}</h3>
                    <p>{date.toDateString()}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="dash-sidebar row">
                  <section className="col-md-6">
                    <h5 className="dash-title">Projects</h5>
                    <div className="card">
                      <div className="card-body">
                        <div className="time-list">
                          <div className="dash-stats-list">
                            <h4>{counts.taskCount}</h4>
                            <p>Total Tasks</p>
                          </div>
                          <div className="dash-stats-list">
                            <h4>{counts.taskIncompleteCount}</h4>
                            <p>Pending Tasks</p>
                          </div>
                        </div>
                        <div className="request-btn">
                          <div className="dash-stats-list">
                            <h4>{counts.projectCount}</h4>
                            <p>Total Projects</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="col-md-6">
                    <h5 className="dash-title">Your Leave</h5>
                    <div className="card">
                      <div className="card-body">
                        <div className="time-list">
                          <div className="dash-stats-list">
                            <h4>
                              <h4>
                                {userLeaveBalance
                                  ? userLeaveBalance.totalAvailable -
                                    userLeaveBalance.totalRemaining
                                  : null}
                              </h4>
                            </h4>
                            <p>Leave Taken</p>
                          </div>
                          <div className="dash-stats-list">
                            <h4>
                              {userLeaveBalance
                                ? userLeaveBalance.totalRemaining
                                : null}
                            </h4>
                            <p>Remaining</p>
                          </div>
                        </div>
                        <div className="request-btn">
                          <Link
                            className="btn btn-primary"
                            to={URLS.userDashboardLeaves}
                          >
                            Apply Leave
                          </Link>
                        </div>
                      </div>
                    </div>
                  </section>
                  {/*<section className="col-md-4">
                    <h5 className="dash-title">Your time off allowance</h5>
                    <div className="card">
                      <div className="card-body">
                        <div className="time-list">
                          <div className="dash-stats-list">
                            <h4>5.0 Hours</h4>
                            <p>Approved</p>
                          </div>
                          <div className="dash-stats-list">
                            <h4>15 Hours</h4>
                            <p>Remaining</p>
                          </div>
                        </div>
                        <div className="request-btn">
                          <a className="btn btn-primary" href="#">
                            Apply Time Off
                          </a>
                        </div>
                      </div>
                    </div>
                  </section>
                  */}
                  {/*<section className="col-md-4">
                    <h5 className="dash-title">Upcoming Holiday</h5>
                    <div className="card">
                      <div className="card-body text-center">
                        <h4 className="holiday-title mb-0">
                          Mon 20 May 2019 - Ramzan
                        </h4>
                      </div>
                    </div>
                  </section>
                */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div classNameName="sidebar-overlay" data-reff=""></div>
    </body>
  );
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EmployeeDashboard));
