const SettingsReducer = (
  state = {
    settings: {},
  },
  action
) => {
  switch (action.type) {
    case "ADD_SETTINGS":
      return { settings: action.payload };

    default:
      return state;
  }
};

export default SettingsReducer;
