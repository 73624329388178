import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import { isEnterKey, deepClone } from "../../utils";
import { URLS } from "../urls";

function Prorated(props) {
  const [proratedEmployees, setProratedEmployees] = useState([]);
  const [deductionSettings, setDeductionSettings] = useState([]);
  let isRendered = useRef(false);

  useEffect(() => {
    isRendered = true;
    if (isRendered) {
      getEmployees();
    }

    return () => {
      isRendered = false;
    };
  }, []);

  const getEmployees = async () => {
    try {
      const token = "dummy token";
      const res = await fetch(`${URLS.backendDeductionSettings}/enabled`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });
      const result = await res.json();
      setDeductionSettings(result.deductionSettings);

      const employeeRes = await fetch(
        `${URLS.backendPayroll}/get-employees?type=prorated`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );

      const employeesResult = await employeeRes.json();
      const { employees } = employeesResult;
      let proratedEmployees = employees.filter(
        (employee) => employee?.jobInfo?.grade?.type === "prorated"
      );
      proratedEmployees = proratedEmployees.map((employee) => {
        let deductionSum = 0;
        employee = {
          ...employee.personalInfo,
          ...employee.jobInfo,
          ...employee.jobInfo.grade.salary,
          bankDetails: employee.bankDetails ?? {},
          userId: employee.userId,
          gradeName: employee.jobInfo.grade.name,
        };
        const deductions = result.deductionSettings.map((deduction) => {
          let sum = 0;
          for (let key in deduction) {
            if (key !== "enabled" && deduction[key] === true) {
              sum += employee[key];
            }
          }
          const total = ((deduction.percentage / 100) * sum).toFixed(2);
          deductionSum += parseFloat(total);

          return {
            ...deduction,
            deductionSum,
            total: parseFloat(total),
          };
        });

        const {
          housing,
          basic,
          transport,
          medical,
          utility,
          paidLeave,
          gratuity,
          offshoreAllowances,
          transitAllowances,
          overTimeAllowances,
          bankDetails,
        } = employee;

        const grossWithoutAllowances =
          housing +
          basic +
          transport +
          medical +
          utility +
          paidLeave +
          gratuity;

        const grossPay =
          housing +
          basic +
          transport +
          medical +
          utility +
          paidLeave +
          gratuity +
          offshoreAllowances +
          overTimeAllowances;

        return {
          ...employee,
          grossPay,
          grossWithoutAllowances,
          ogGrossPay: grossPay,
          ogOffshoreAllowances: offshoreAllowances,
          ogOverTimeAllowances: overTimeAllowances,
          otherDeductions: 0,
          daysCommitted: 0,
          daysOn: 0,
          daysInTransit: 0,
          bonuses: 0,
          ogNetPay: grossPay - deductionSum,
          netPay: grossPay - deductionSum,
          deductions,
          creditAccountNo: bankDetails?.accountNumber,
          creditBankCode: bankDetails?.bankCode,
        };
      });
      setProratedEmployees(proratedEmployees);
      props.setProratedEmployees(proratedEmployees);
      // calculateData(proratedEmployees);
    } catch (err) {
      console.log(err);
    }
  };

  const calculateData = ({ e, employeeId }) => {
    if (isEnterKey(e)) {
      const index = proratedEmployees.findIndex(
        (employee) => employee.employeeId === employeeId
      );
      const employee = deepClone(proratedEmployees[index]);
      const {
        deductions,
        daysCommitted,
        daysOn,
        ogOffshoreAllowances,
        ogOverTimeAllowances,
        otherDeductions,
        grossWithoutAllowances,
        bonuses,
      } = employee;

      const finalOffshoreAllowance = daysOn * ogOffshoreAllowances;
      const finalOvertimeAllowance = daysOn * ogOverTimeAllowances;
      const newGrossPay =
        grossWithoutAllowances +
        finalOffshoreAllowance +
        finalOvertimeAllowance +
        bonuses;

      const date = new Date();
      const d = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      const daysInMonth = d.getDate();
      const daysWorked = daysCommitted / daysInMonth;
      const grossPay = parseFloat(daysWorked) * parseFloat(newGrossPay);

      let deductionSum = 0;
      /*const calculatedDeductions = deductions.map((deduction) => {
        const total = ((deduction.percentage / 100) * grossPay).toFixed(2);
        deductionSum += parseFloat(total);
        return {
          ...deduction,
          deductionSum,
          total: parseFloat(total),
        };
      });
      */

      // employee.ogOffshoreAllowances = finalOffshoreAllowance;
      // employee.ogOverTimeAllowances = finalOvertimeAllowance;

      const calculatedDeductions = deductionSettings.map((deduction) => {
        let sum = 0;
        for (let key in deduction) {
          if (key !== "enabled" && deduction[key] === true) {
            console.log(key);
            sum += employee[key];
          }
        }
        const total = ((deduction.percentage / 100) * sum).toFixed(2);
        deductionSum += parseFloat(total);

        return {
          ...deduction,
          total: parseFloat(total),
        };
      });

      const netPay = grossPay - (deductionSum + otherDeductions);

      proratedEmployees[index] = {
        ...employee,
        overTimeAllowances: finalOvertimeAllowance,
        offshoreAllowances: finalOffshoreAllowance,
        grossPay: grossPay.toFixed(2),
        deductions: calculatedDeductions,
        totalDeduction: deductionSum + otherDeductions,
        netPay: netPay.toFixed(2),
      };
      setProratedEmployees([...proratedEmployees]);
      props.setProratedEmployees([...proratedEmployees]);
      e.target.blur();
    }
  };

  const setOtherDeductions = ({ e, employeeId }) => {
    const newValue = e.target.value ? parseFloat(e.target.value) : 0;
    const index = proratedEmployees.findIndex(
      (employee) => employee.employeeId === employeeId
    );
    const employee = proratedEmployees[index];

    proratedEmployees[index] = {
      ...employee,
      otherDeductions: newValue,
    };

    setProratedEmployees([...proratedEmployees]);
  };

  const setBonuses = ({ e, employeeId }) => {
    const newValue = e.target.value ? parseFloat(e.target.value) : 0;
    const index = proratedEmployees.findIndex(
      (employee) => employee.employeeId === employeeId
    );
    const employee = proratedEmployees[index];

    proratedEmployees[index] = {
      ...employee,
      bonuses: newValue,
    };

    setProratedEmployees([...proratedEmployees]);
  };

  const setRemark = ({ e, employeeId }) => {
    const index = proratedEmployees.findIndex(
      (employee) => employee.employeeId === employeeId
    );
    const employee = proratedEmployees[index];

    proratedEmployees[index] = {
      ...employee,
      remark: e.target.value,
    };
    setProratedEmployees([...proratedEmployees]);
  };

  const setProratedData = ({ e, employeeId }) => {
    const index = proratedEmployees.findIndex(
      (employee) => employee.employeeId === employeeId
    );
    const employee = proratedEmployees[index];

    proratedEmployees[index] = {
      ...employee,
      [e.target.name]: e.target.value,
    };
    setProratedEmployees([...proratedEmployees]);
  };

  return (
    <div className="row">
      <div className="col-md-12 w-100">
        <div className="table-responsive">
          <table className="table table-striped custom-table mb-0 datatable">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Job Grade</th>

                <th>Days Commiited</th>
                <th>Days on</th>

                <th>Basic</th>
                <th>Housing</th>
                <th>Transport</th>
                <th>Medical</th>
                <th>Utility</th>
                <th>Paid Leave</th>
                <th>Gratuity</th>
                <th>Bonuses</th>

                <th>Offshore Allowances</th>
                <th>Overtime Allowances</th>

                <th>Gross Pay</th>

                {deductionSettings.map((setting, index) => {
                  return (
                    <th className="text-capitalize" key={index}>
                      {setting.name}
                    </th>
                  );
                })}

                <th>Other Deductions</th>
                <th>Remark</th>
                <th>Net Pay</th>
              </tr>
            </thead>
            <tbody>
              {proratedEmployees.map((employee, index) => {
                return (
                  <tr key={index}>
                    <td>{index}</td>
                    <td>{employee.name?.first + " " + employee.name?.last}</td>
                    <td>{employee.gradeName}</td>

                    <td>
                      <input
                        value={employee.daysCommitted}
                        name="daysCommitted"
                        onChange={(e) => {
                          setProratedData({
                            e,
                            employeeId: employee.employeeId,
                          });
                        }}
                        onKeyUp={(e) => {
                          calculateData({
                            e,
                            employeeId: employee.employeeId,
                          });
                        }}
                        className="form-control payroll-input"
                      />
                    </td>
                    <td>
                      <input
                        value={employee.daysOn}
                        name="daysOn"
                        onChange={(e) => {
                          setProratedData({
                            e,
                            employeeId: employee.employeeId,
                          });
                        }}
                        onKeyUp={(e) => {
                          calculateData({
                            e,
                            employeeId: employee.employeeId,
                          });
                        }}
                        className="form-control payroll-input"
                      />
                    </td>

                    <td>{employee.basic}</td>
                    <td>{employee.housing}</td>
                    <td>{employee.transport}</td>
                    <td>{employee.medical}</td>
                    <td>{employee.utility}</td>
                    <td>{employee.paidLeave}</td>
                    <td>{employee.gratuity}</td>

                    <td>
                      <input
                        value={employee.bonuses}
                        onChange={(e) => {
                          setBonuses({
                            e,
                            employeeId: employee.employeeId,
                          });
                        }}
                        onKeyUp={(e) => {
                          calculateData({
                            e,
                            employeeId: employee.employeeId,
                          });
                        }}
                        className="form-control payroll-input"
                      />
                    </td>

                    <td>{employee?.offshoreAllowances}</td>
                    <td>{employee?.overTimeAllowances}</td>
                    <td>{employee.grossPay}</td>

                    {employee.deductions.map((deduction, index) => {
                      return <td key={index}>{deduction.total}</td>;
                    })}

                    <td>
                      <input
                        name="otherDeductions"
                        value={employee.otherDeductions}
                        onChange={(e) => {
                          setOtherDeductions({
                            e,
                            employeeId: employee.employeeId,
                          });
                        }}
                        onKeyUp={(e) => {
                          calculateData({
                            e,
                            employeeId: employee.employeeId,
                          });
                        }}
                        className="form-control payroll-input"
                      />
                    </td>

                    <td>
                      <input
                        value={employee.remark ?? "..."}
                        onClick={(e) => e.target.select()}
                        onChange={(e) => {
                          setRemark({
                            e,
                            employeeId: employee.employeeId,
                          });
                        }}
                        className="form-control payroll-input"
                      />
                    </td>
                    <td>{employee.netPay}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
    settings: state.settingsReducer.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Prorated));
