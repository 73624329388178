import React from "react";
import { URLS } from "../urls";

export default class ImageListName extends React.Component {
  constructor(props) {
    super(props);
    this.removeFormList = this.removeFormList.bind(this);
    this.state = {
      value: [],
    };
  }
  componentWillReceiveProps(props) {
    if (props.value.length) {
      this.setState({
        value: props.value,
      });
    }
  }
  getNameOfEmployee(e) {
    const name = e.personalInfo.name;
    return (
      name.first +
      (name.middle
        ? " " + name.middle + name.last
          ? " " + name.last
          : ""
        : " " + name.last)
    );
  }
  removeFormList = (item) => (e) => {
    e.preventDefault();
    this.props.removeFormList(item);
  };
  render() {
    const value = this.props.value;
    return value && value.length ? (
      <div>
        {value.map((item) => {
          if (item.image)
            return (
              <div
                style={{
                  display: "inline-block",
                  marginRight: "8px",
                  marginBottom: "8px",
                  textAlign: "center",
                  position: "relative",
                  padding: "5px 10px",
                  backgroundColor: "#eeeeee",
                }}
              >
                <button
                  class="close"
                  aria-label="Close"
                  style={{ right: "-5px", top: "-5px" }}
                  onClick={this.removeFormList(item)}
                >
                  <span aria-hidden="true">×</span>
                </button>
                <div style={{ display: "inline" }}>
                  <a href="#" title={item.name} class="avatar">
                    <img
                      src={URLS.backendStatic + "/" + item.image}
                      alt={"picokdone"}
                    />
                  </a>
                </div>

                <div style={{ display: "block" }}>{item.name}</div>
              </div>
            );
          else
            return (
              <a href="#" title={item.name} class="avatar">
                <img
                  src="/assets/img/profiles/avatar-03.jpg"
                  alt={"picokdone"}
                />
              </a>
            );
        })}
      </div>
    ) : (
      <div></div>
    );
  }
}
