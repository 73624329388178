import React from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { URLS } from "../urls";
import moment from "moment";

class Performance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employeesClone: [],
      employees: [],
      projects: {},
      tasks: {},
      completedTasks: {},
      completionRate: {},
    };
  }
  componentWillMount() {
    const token = "dummy token";
    fetch(`${URLS.backendEmployees}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.employees.forEach((employee) => {
          this.fetchProjectsByEmployeeId(employee._id);
          this.fetchTasksByEmployeeId(employee._id);
        });
        this.setState({
          employees: result.employees,
          employeesClone: result.employees,
        });
      });
  }
  fetchProjectsByEmployeeId = (employeeId) => {
    const token = "dummy token";
    fetch(`${URLS.backendProjects}/getProjectsByEmployeeId/${employeeId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let { projects } = this.state;
        projects[employeeId] = result.projects.length;
        this.setState({
          projects,
        });
      });
  };
  fetchTasksByEmployeeId = (employeeId) => {
    const token = "dummy token";
    fetch(`${URLS.backendTasks}/tasks/${employeeId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let { tasks, completedTasks, completionRate } = this.state;
        tasks[employeeId] = result.tasks.length;
        const completed = result.tasks.filter((task) => {
          return task.status === "COMPLETE";
        });
        let delay = 0;
        if (completed.length) {
          for (let i = 0; i < completed.length; i++) {
            let item = completed[i];
            const diff = moment(item.completeDate, "DD/MM/YYYY").diff(
              moment(item.dueDate, "DD/MM/YYYY"),
              "days"
            );
            if (diff > 0) delay += diff;
          }
        }
        completedTasks[employeeId] = completed.length;
        completionRate[employeeId] = completed.length
          ? (completed.length / (completed.length + delay * 0.1)) * 100
          : 0;
        this.setState({
          tasks,
          completedTasks,
          completionRate,
        });
      });
  };

  searchFilterName = (e) => {
    e.preventDefault();
    const value = e.target.value.trim();
    let { employees, employeesClone } = this.state;

    if (value) {
      employees = employeesClone.filter((employee) => {
        let name;
        name =
          employee.personalInfo.name.first +
          "" +
          employee.personalInfo.name.middle +
          "" +
          employee.personalInfo.name.last;
        if (name.toLowerCase().includes(value)) {
          return employee;
        }
      });
    } else {
      employees = employeesClone;
    }
    this.setState({
      employees,
    });
  };

  render() {
    const { employees, projects, tasks, completedTasks, completionRate } =
      this.state;
    return (
      <body>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex, nofollow"></meta>
          <title>InvexERP</title>
        </Helmet>
        <div className="main-wrapper">
          <Topbar />
          <Sidebar />
          {/*<!-- Page Wrapper -->*/}
          <div className="page-wrapper">
            {/*<!-- Page Content -->*/}
            <div className="content container-fluid">
              {/*<!-- Page Title -->*/}
              <div className="row">
                <div className="col-sm-3 col-3">
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Performance</BreadcrumbItem>
                  </Breadcrumb>
                </div>
                <div className="col-sm-5 col-5" style={{ marginLeft: "auto" }}>
                  <input
                    type="text"
                    className="form-control floating"
                    onChange={this.searchFilterName}
                    placeholder="Search"
                  />
                </div>
              </div>
              {/*<!-- /Page Title -->*/}

              <div className="row">
                <div className="col-md-12">
                  <div>
                    <table className="table table-striped custom-table mb-0 datatable">
                      <thead>
                        <tr>
                          <th style={{ width: "80px" }}>#</th>
                          <th>Employee</th>
                          <th>Projects</th>
                          <th>Tasks</th>
                          <th>Completed Tasks</th>
                          <th>Progress</th>
                          <th>Completion Rate</th>
                          {/* <th className="text-right">Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {employees.length
                          ? employees.map((employee, index) => {
                              const employeeId = employee._id;
                              const image = employee.emergencyInfo.image;
                              const progress =
                                tasks[employeeId] > 0
                                  ? (
                                      (completedTasks[employeeId] * 100) /
                                      tasks[employeeId]
                                    ).toFixed(2)
                                  : 0;
                              const rate = completionRate[employeeId]
                                ? completionRate[employeeId].toFixed(2)
                                : 0;
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>
                                    <Link to={`${URLS.profile}/${employeeId}`}>
                                      <span className="avatar">
                                        <img
                                          alt=""
                                          src={
                                            image
                                              ? URLS.backendStatic + "/" + image
                                              : "assets/img/profiles/avatar-09.jpg"
                                          }
                                        />
                                      </span>
                                      {employee.personalInfo.name.first +
                                        " " +
                                        employee.personalInfo.name.middle +
                                        " " +
                                        employee.personalInfo.name.last}{" "}
                                    </Link>
                                  </td>
                                  <td>{projects[employeeId]}</td>
                                  <td>{tasks[employeeId]}</td>
                                  <td>{completedTasks[employeeId]}</td>
                                  <td>
                                    <div className="widget-subbody">
                                      <div className="widget-subbody-header">
                                        Progress{" "}
                                        <span class="text-success float-right">
                                          {progress.toString() + "%"}
                                        </span>
                                      </div>
                                      <div class="progress progress-xs mb-0">
                                        <div
                                          class="progress-bar bg-success"
                                          role="progressbar"
                                          data-toggle="tooltip"
                                          title={progress.toString() + "%"}
                                          style={{ width: progress + "%" }}
                                        ></div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="widget-subbody">
                                      <div className="widget-subbody-header">
                                        Rate{" "}
                                        {parseFloat(rate) >= 95 ? (
                                          <span class="text-success float-right">
                                            {rate.toString() + "%"}
                                          </span>
                                        ) : parseFloat(rate) >= 75 ? (
                                          <span class="text-primary float-right">
                                            {rate.toString() + "%"}
                                          </span>
                                        ) : parseFloat(rate) >= 50 ? (
                                          <span class="text-warning float-right">
                                            {rate.toString() + "%"}
                                          </span>
                                        ) : (
                                          <span class="text-danger float-right">
                                            {rate.toString() + "%"}
                                          </span>
                                        )}
                                      </div>
                                      <div class="progress progress-xs mb-0">
                                        {parseFloat(rate) >= 95 ? (
                                          <div
                                            class="progress-bar bg-success"
                                            role="progressbar"
                                            data-toggle="tooltip"
                                            title={rate.toString() + "%"}
                                            style={{ width: rate + "%" }}
                                          ></div>
                                        ) : parseFloat(rate) >= 75 ? (
                                          <div
                                            class="progress-bar bg-primary"
                                            role="progressbar"
                                            data-toggle="tooltip"
                                            title={rate.toString() + "%"}
                                            style={{ width: rate + "%" }}
                                          ></div>
                                        ) : parseFloat(rate) >= 50 ? (
                                          <div
                                            class="progress-bar bg-warning"
                                            role="progressbar"
                                            data-toggle="tooltip"
                                            title={rate.toString() + "%"}
                                            style={{ width: rate + "%" }}
                                          ></div>
                                        ) : (
                                          <div
                                            class="progress-bar bg-danger"
                                            role="progressbar"
                                            data-toggle="tooltip"
                                            title={rate.toString() + "%"}
                                            style={{ width: rate + "%" }}
                                          ></div>
                                        )}
                                        {/* <div class="progress-bar bg-success" role="progressbar" data-toggle="tooltip" title={rate.toString() + '%'} style={{ width: rate + "%" }}></div> */}
                                      </div>
                                    </div>
                                  </td>

                                  {/* <td className="text-right">
															<div className="dropdown dropdown-action">
																<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
																<div className="dropdown-menu dropdown-menu-right">
																	<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_department" onClick={this.updateEditDepartment(department)}><i className="fa fa-pencil m-r-5"></i> Edit</a>
																	<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_department"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
																</div>
															</div>
														</td> */}
                                </tr>
                              );
                            })
                          : ""}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Page Content -->*/}
          </div>
          {/*<!-- /Page Wrapper -->*/}
        </div>
        {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
        <div className="sidebar-overlay" data-reff=""></div>
      </body>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Performance));
