import React, { Fragment, useState, useEffect } from "react";
import { URLS } from "../urls";
import moment from "moment";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import CurrencyInput from "react-currency-input-field";
import { Modal } from "react-bootstrap";

const SendForApprovalModal = (props) => {
  const [state, setState] = useState({
    buttonText: "Submit",
    isWaiting: false,
    Amount: 0,
    userId: "",
    staff: "",
    departments: [],
    users: [],
    comment: "",
    appliedDate: "",
    title: "",
    description: "",
    employee: "",
    details: [],
    newStatus: "",
    overtimeId: "",
    requestBy: "",
  });

  const token = "dummy token";

  useEffect(() => {
    componentDidMount();
  }, [props?.selected]);

  const componentDidMount = async () => {
    if (process.env.REACT_APP_HAS_INVEX !== "true") {
      return alert("Please activate accountant module to use Allowance");
    }

    getDepartments();
    setState((ele) => ({
      ...ele,
      Amount: props?.selected?.total,
      employee: `${
        props?.selected?.employee?.personalInfo?.name?.first
          ? props?.selected?.employee?.personalInfo?.name?.first
          : ""
      } ${
        props?.selected?.employee?.personalInfo?.name?.middle
          ? props?.selected?.employee?.personalInfo?.name?.middle
          : ""
      } ${
        props?.selected?.employee?.personalInfo?.name?.last
          ? props?.selected?.employee?.personalInfo?.name?.last
          : ""
      }`,
      newStatus: props?.selected?.newStatus,
      details: props?.selected?.details,
      overtimeId: props?.selected?._id,
    }));

    const findStaff = await fetch(
      `${URLS.mainSiteBackend}/users/${props?.selected?.employee?.jobInfo?.employeeId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );

    const { data } = await findStaff.json();
    if (data) {
      setState((ele) => ({ ...ele, requestBy: data?.Staff_ID }));
    }
  };

  const getDepartments = async () => {
    // await waitFor(5000);
    if (process.env.REACT_APP_HAS_INVEX === "true") {
      let response = await fetch(`${URLS.mainSiteBackend}/users/departments`, {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        // credentials: "include",
      });

      if (!response.ok) {
        response = await response.json();

        throw new Error(response.message);
      }

      const { data } = await response.json();

      setState((ele) => ({ ...ele, departments: data?.departments }));
    }
  };

  const getUsersInDepartments = async (department) => {
    let response = await fetch(
      `${URLS.mainSiteBackend}/users/by-department/${department}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        // credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    setState((ele) => ({ ...ele, users: data?.users }));
  };

  const onChangeInputs = (e) => {
    const { name, value } = e.target;
    setState((ele) => ({ ...ele, [name]: value }));
  };

  const submit = (e) => {
    e.preventDefault();
    setState((ele) => ({
      ...ele,
      buttonText: "Submitting ...",
      isWaiting: true,
    }));
    const {
      title,
      description,
      comment,
      Amount,
      details,
      staff,
      employee,
      overtimeId,
      newStatus,
      requestBy,
    } = state;

    if (Object.entries(employee).length < 1) {
      return toast.error("Please select an employee", {
        position: "top-right",
      });
    }

    const requsion = {
      title,
      description,
      type: "Allowance",
      amount: Amount,
      requestto: staff,
      status: "Pending",
      regdate: moment(new Date()).format("YYYY-MM-DD"),
      requestby: requestBy ? requestBy : staff,
      vendor: "",
      comment,
      filledFormData: "",
      loanDetails: JSON.stringify({ employee, details, id: overtimeId }),
    };

    // console.log(requsion);

    const formData = new FormData();
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(requsion)) {
      formData.append(key, value);
    }
    const token = "dummy token";

    if (process.env.REACT_APP_HAS_INVEX === "true") {
      fetch(`${URLS.mainSiteBackend}/requisition/create`, {
        method: "POST",
        // credentials: "include",
        headers: {
          Authorization: `Token ${token}`,
        },
        body: formData,
      }).then((res) => {
        if (res.ok) {
          res.json().then((result) => {
            // console.log(result);
            const payload = {
              overtimeId,
              status: newStatus,
            };
            props?.changeStatus && props.changeStatus({ ...payload });
          });
          setState({
            isWaiting: false,
            staff: "",
            departments: [],
            users: [],
            comment: "",
            details: [],
            newStatus: "",
            overtimeId: "",
            title: "",
            description: "",
            Amount: 0,
            userId: "",
            employee: "",
            requestBy: "",
          });
          setState((ele) => ({
            ...ele,
            buttonText: "Submit",
            isWaiting: false,
          }));
          props.onHide();
        } else {
          res.json().then((responseJson) => {
            console.log(responseJson);
            toast("Error occurred, Check the form again.", {
              type: "error",
              position: "top-right",
            });
            setState((ele) => ({
              ...ele,
              buttonText: "Submit",
              isWaiting: false,
            }));
          });
        }
      });
    } else {
      return alert("Please activate accountant module to use Allowance");
    }
  };

  return (
    <Modal
      className="modal custom-modal fade"
      show={props.show}
      centered
      onHide={() => props.onHide()}
    >
      <Modal.Header closeButton>
        <Modal.Title>Send For Approval</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div>
            <form onSubmit={submit}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Employee <span className="text-danger">*</span> :
                </Form.Label>

                <Form.Control
                  name="employee"
                  placeholder="Enter a employee"
                  value={state?.employee}
                  required
                  disabled
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  Title <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  name="title"
                  placeholder="Enter a title"
                  value={state?.title}
                  onChange={onChangeInputs}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  Description <span className="text-danger">*</span> :
                </Form.Label>
                <label className="border p-0 rounded pb-2 message-area d-block">
                  <Form.Control
                    name="description"
                    value={state?.description}
                    onChange={onChangeInputs}
                    as="textarea"
                    className="w-100 border-0 p-3"
                    placeholder="Enter your detail description"
                    rows={4}
                    required
                  />
                </label>
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>
                  Allowance Amount <span className="text-danger">*</span> :
                </Form.Label>

                <CurrencyInput
                  className="form-control"
                  value={state?.Amount}
                  allowNegativeValue={false}
                  disabled
                />
              </Form.Group>

              <div className="d-flex align-items-center my-4">
                <h4 className="pr-2">Send Request To</h4>{" "}
                <hr className="flex-grow-1 m-0" />
              </div>

              <div className="row gap-3">
                <Form.Group className="col mb-3">
                  <Form.Label>
                    Department <span className="text-danger">*</span> :
                  </Form.Label>
                  <select
                    className="form-control"
                    onChange={(e) => getUsersInDepartments(e.target.value)}
                    required
                  >
                    <option value="">Select Recipient's Department</option>
                    {state?.departments &&
                      state?.departments.map((el, index) => (
                        <option key={index} value={el.Department}>
                          {el.Department}
                        </option>
                      ))}
                  </select>
                </Form.Group>

                {state?.users.length > 0 && (
                  <Form.Group className="col mb-3">
                    <Form.Label>
                      Recipient <span className="text-danger">*</span> :
                    </Form.Label>
                    <select
                      name="staff"
                      className="form-control"
                      value={state?.staff}
                      onChange={onChangeInputs}
                      required
                    >
                      <option value="">Select Recipient </option>
                      {state?.users.map((el, index) => (
                        <Fragment key={index}>
                          <option value={el.Staff_ID}>{el.Name}</option>
                        </Fragment>
                      ))}
                    </select>
                  </Form.Group>
                )}
              </div>

              <Form.Group className="mb-3">
                <Form.Label>Comment :</Form.Label>
                <label className="border p-0 rounded pb-2 message-area d-block">
                  <Form.Control
                    name="comment"
                    value={state?.comment}
                    onChange={onChangeInputs}
                    as="textarea"
                    className="w-100 border-0 p-3"
                    placeholder="Enter a comment"
                    rows={4}
                  />
                </label>
              </Form.Group>
              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                  disabled={state?.isWaiting}
                >
                  {state?.buttonText}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SendForApprovalModal;
