import React, { useState, useCallback, useEffect } from "react";
import { Button, Form, Modal, Nav, Table } from "react-bootstrap";
import moment from "moment";

import { URLS } from "../urls";
import ApprovalBadge from "../ApprovalBadge";
import { resolveApprovalBadgeBg } from "../../utils";
import {
  PendingCheckIcon,
  DisapprovedCheckIcon,
  ApproveCheckIcon,
} from "../icons";
import Avatar from "../../Avatar";

export default function LoanDetails(props) {
  const [requisition, setRequisition] = useState([]);

  const fetchRequi = useCallback(async () => {
    // await waitFor(5000);
    const token = "dummy token";
    let response = await fetch(
      `${URLS.mainSiteBackend}/requisition/${props.requestid}?Staff_ID=${props.staff}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          Authorization: `Token ${token}`,
        },
        // credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    setRequisition(data?.requisition);
  }, [props.requestid]);

  useEffect(() => {
    if (process.env.REACT_APP_HAS_INVEX === "true") {
      fetchRequi();
    }
  }, [fetchRequi]);

  //   console.log(requisition);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      dialogClassName="requisition-details-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
      //   fullscreen={styleHelper.isMobile}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>Loan Details</h1>
          <p>More information about the loan sent</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-end"></div>

        <>
          <div className="info p-3 my-3 border rounded">
            <p className="border-bottom pb-3 mb-3 d-flex gap-3 title">
              <span>From : </span>
              <span className="fw-5">
                {requisition?.requestbyUser?.Department || "..."}
              </span>
            </p>

            <h2>{requisition?.title}</h2>
            {/* <p className="btn btn-sm loanstyle">{requisition?.type}</p> */}
            <p>
              <ApprovalBadge
                text={requisition?.type}
                // className="approval"
                className="loanstyle"
              />
            </p>

            <div className="d-md-flex">
              <Table borderless className="info-table mb-0 mb-md-3">
                <tbody>
                  <tr>
                    <td>ID requisition :</td>
                    <td>#{props.requestid}</td>
                  </tr>
                  <tr>
                    <td>Date:</td>
                    <td>
                      {requisition?.regdate
                        ? moment(new Date(requisition?.regdate)).format(
                            "DD / MM / yyyy"
                          )
                        : "..."}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Table borderless className="info-table mb-0 mb-md-3">
                <tbody>
                  <tr>
                    <td>Status :</td>
                    <td>
                      <ApprovalBadge
                        text={requisition?.status}
                        // className="approval"
                        className={resolveApprovalBadgeBg(requisition?.status)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Recipient : </td>
                    <td className="fw-5">
                      {/* {requisition?.requesttoUser?.Department || "..."} */}

                      {requisition?.loanDetails
                        ? JSON.parse(requisition?.loanDetails)?.employeeName
                        : "..."}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>

          <div className="approval p-3 my-3 border rounded">
            <p className="border-bottom pb-3 mb-3 title">
              <span>Approval</span>
            </p>

            <div className="approval-history">
              {requisition?.requisitiontracks?.map((el, i) => (
                <div key={i} className="item">
                  {i !== 0 && <div className="line" />}
                  {el.status.toLowerCase() === "pending" ? (
                    <>
                      <div className="timeline border">
                        <PendingCheckIcon />
                      </div>

                      <div className="detail">
                        <Avatar
                          className="image"
                          name={
                            JSON.parse(requisition?.loanDetails)?.employeeName
                          }
                          userId={
                            JSON.parse(requisition?.loanDetails)?.employeeId
                          }
                        />

                        <div>
                          <ApprovalBadge
                            text={el.status}
                            className="approval"
                            bg="warning"
                          />
                          <p className="mb-1">
                            <span className="fw-5">
                              {JSON.parse(requisition?.loanDetails)
                                ?.employeeName || "..."}
                            </span>{" "}
                            is waiting for a response from{" "}
                            <span className="fw-5">
                              {el.receivedbyUser?.Name || "..."}
                            </span>
                          </p>
                          <i>{el?.comment}</i>
                          <p>
                            <em>{el?.regdate}</em>
                          </p>
                        </div>
                      </div>
                    </>
                  ) : el.status.toLowerCase() === "disapproved" ? (
                    <>
                      <div className="timeline border">
                        <DisapprovedCheckIcon />
                      </div>

                      <div className="detail">
                        <Avatar
                          className="image"
                          name={
                            JSON.parse(requisition?.loanDetails)?.employeeName
                          }
                          userId={
                            JSON.parse(requisition?.loanDetails)?.employeeId
                          }
                        />

                        <div>
                          <ApprovalBadge
                            text={el.status}
                            className="approval"
                            bg="danger"
                          />
                          <p className="mb-1">
                            <span className="fw-5">
                              {el.receivedbyUser?.Name || "..."}
                            </span>{" "}
                            disapproved a request from{" "}
                            <span className="fw-5">
                              {JSON.parse(requisition?.loanDetails)
                                ?.employeeName || "..."}
                            </span>
                          </p>
                          <i>{el?.comment}</i>
                          <p>
                            <em>{el?.regdate}</em>
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="timeline border">
                        <ApproveCheckIcon />{" "}
                      </div>

                      <div className="detail">
                        <Avatar
                          className="image"
                          name={
                            JSON.parse(requisition?.loanDetails)?.employeeName
                          }
                          userId={
                            JSON.parse(requisition?.loanDetails)?.employeeId
                          }
                        />

                        <div>
                          <ApprovalBadge
                            text={el.status}
                            className="approval"
                            bg="success"
                          />
                          <p className="mb-1">
                            {el.status === "Approved & Sent" ? (
                              <>
                                <span className="fw-5">
                                  {el.receivedbyUser?.Name || "..."}
                                </span>{" "}
                                has <span className="fw-5">approved</span> and
                                sent
                              </>
                            ) : (
                              <>
                                <span className="fw-5">
                                  {el.receivedbyUser?.Name || "..."}
                                </span>{" "}
                                has <span className="fw-5">approved</span> and
                                closed{" "}
                              </>
                            )}
                          </p>
                          <i>{el?.comment}</i>
                          <p>
                            <em>{el?.regdate}</em>
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      </Modal.Body>
    </Modal>
  );
}
