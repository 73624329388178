import React from 'react';
import { Form, Col, Row, Image } from 'react-bootstrap';
import { URLS } from '../urls';
import moment from 'moment';
import DatePicker from 'react-datetime'
export default class EditJobModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            job : {
                '_id' : '',
				'jobTitle' : '',
				'department' : '',
				'location' : '',
                'vacancies' : 0,
                'applications' : 0,
				'views' : 0,
				'experience' : '',
				'salaryFrom' : '',
				'salaryTo' : '',
				'jobType' : 'Full Time',
				'status' : 'Open',
				'startDate' : '',
				'expiredDate' : '',
				'description' : ''
            }
        };
    }
    componentWillReceiveProps(props){
        this.setState({
            job : props.editJob
        })
    }
    updateJobTextFields = (event) =>{
        const {job} = this.state;
        job[event.target.name] = event.target.value;
        this.setState({
            job
        })
    }
    updateJobStartDate = (event) =>{
        const {job} = this.state;
        job.startDate = event.target ? event.target.value : moment(event).format("DD/MM/YYYY");
        this.setState({
            job
        })
    }
    updateJobExpiredDate = (event) =>{
        const {job} = this.state;
        job.expiredDate = event.target ? event.target.value : moment(event).format("DD/MM/YYYY");
        this.setState({
            job
        })
    }
    submitForm = (e) => {
		e.preventDefault();
		const token = 'dummy token';
		const {job} = this.state;
		fetch(`${URLS.backendJobs}/update`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
			},
			body : JSON.stringify({job : job })
		}).then(res => res.json())
		.then((result) => {
			this.setState({
                job : {
                    '_id' : '',
                    'jobTitle' : '',
                    'department' : '',
                    'location' : '',
                    'vacancies' : 0,
                    'applications' : 0,
				    'views' : 0,
                    'experience' : '',
                    'salaryFrom' : '',
                    'salaryTo' : '',
                    'jobType' : 'Full Time',
                    'status' : 'Open',
                    'startDate' : '',
                    'expiredDate' : '',
                    'description' : ''
                }
            });
            this.props.updateJobsList(result.job);
			window.$('#edit_job').modal('toggle');
		});
	}

	render() {
        const {job} = this.state;
        const {departments} = this.props;
        return (
        <div id="edit_job" className="modal custom-modal fade" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Edit Job</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={this.submitForm}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Job Title</label>
                                        <input className="form-control" type="text"  value={job.jobTitle} name='jobTitle' onChange={this.updateJobTextFields.bind(this)}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Department</label>
                                        <Form.Control as='select' name='department' value={job.department}
                                            onChange={this.updateJobTextFields.bind(this)} required>
                                            <option value=''>Select</option>
                                            {
												departments.length && departments.map(department =>{
													return <option value={department.name}>{department.name}</option>
												})
											}
                                        </Form.Control>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Job Location</label>
                                        <input className="form-control" type="text" value={job.location} name='location' onChange={this.updateJobTextFields.bind(this)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>No of Vacancies</label>
                                        <input className="form-control" type="number" value={job.vacancies} name='vacancies' onChange={this.updateJobTextFields.bind(this)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Experience</label>
                                        <input className="form-control" type="text" value={job.experience} name='experience' onChange={this.updateJobTextFields.bind(this)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Salary From</label>
                                        <input type="text" className="form-control" value={job.salaryFrom} name='salaryFrom' onChange={this.updateJobTextFields.bind(this)}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Salary To</label>
                                        <input type="text" className="form-control" value={job.salaryTo} name='salaryTo' onChange={this.updateJobTextFields.bind(this)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Job Type</label>
                                        <Form.Control as='select' name='jobType' value={job.jobType}
                                            onChange={this.updateJobTextFields.bind(this)} required>
                                            <option value='Full Time'>Full Time</option>
                                            <option value='Part Time'>Part Time</option>
                                            <option value='Internship'>Internship</option>
                                            <option value='Temporary'>Temporary</option>
                                            <option value='Remote'>Remote</option>
                                            <option value='Others'>Others</option>
                                        </Form.Control>       
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Status</label>
                                        <Form.Control as='select' name='status' value={job.status}
                                            onChange={this.updateJobTextFields.bind(this)} required>
                                            <option value='Open'>Open</option>
                                            <option value='Closed'>Closed</option>
                                            <option value='Cancelled'>Cancelled</option>   
                                        </Form.Control>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Start Date</label>
                                        <DatePicker 
											value={job.startDate}
										    closeOnSelect={true}
										    onChange={this.updateJobStartDate} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Expired Date</label>
                                        <DatePicker 
											value={job.expiredDate}
										    closeOnSelect={true}
										    onChange={this.updateJobExpiredDate} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Description</label>
                                        <textarea className="form-control" rows='10' value={job.description} name='description' onChange={this.updateJobTextFields.bind(this)}></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="submit-section">
                                <button className="btn btn-primary submit-btn">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
    }
}