import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector,Cell } from 'recharts';

const COLORS = ['#00c5fb', '#0253cc','#81b3fe'];
const renderActiveShape = (props) => {
  const {
    cx, cy, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, value,
  } = props;
  
  return (
    <g>
      <text x={cx} y={cy-10} dy={8} style={{fontSize: '22px',fontWeight:'bold'}} textAnchor="middle" fill={payload.name==='Male' ? '#00c5fb' : payload.name==='Female' ? '#0253cc' : '#81b3fe'}>{payload.name}</text>
      <text x={cx} y={cy+20} dy={8} style={{fontSize: '18px'}} textAnchor="middle" fill={payload.name==='Male' ? '#00c5fb' : payload.name==='Female' ? '#0253cc' : '#81b3fe'}>{value}</text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      {/* <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={innerRadius - 8}
        outerRadius={innerRadius - 4}
        fill={fill}
      /> */}
    </g>
  );
};


export default class PieChartModel extends PureComponent {
  state = {
    activeIndex: 0,
    data : [
      { name: 'Male', value: 0 },
      { name: 'Female', value: 0 },
      { name : 'Others', value:0}
    ]
  };
  componentWillReceiveProps(props){
    this.setState({
      data : props.data
    })
  }
  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  render() {
    const {data} = this.state;
    return (
      <PieChart width={300} height={240}>
        <Pie
          activeIndex={this.state.activeIndex}
          activeShape={renderActiveShape}
          data={data}
          innerRadius={60}
          outerRadius={100}
          fill="#8884d8"
          dataKey="value"
          onMouseEnter={this.onPieEnter}
        >
        {
          data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
        }
          </Pie>
      </PieChart>
    );
  }
}
