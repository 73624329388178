import React from 'react';
import { URLS } from '../../urls';

export default class ImageList extends React.Component {
    render(){
        return (
            <>
                {
                    this.props.value && this.props.value.map(item => {
                    if(item.image)
                        return (
                            <li>
                            <a href="Javascript:void(0)" title={item.name} class="avatar">
								<img src={URLS.backendStatic + '/' + item.image} alt={'picokdone'} />
							</a>
                            </li>
                        )
                    else
                        return (
                            <li>
                            <a href="Javascript:void(0)" title={item.name} class="avatar">
                                <img src='/assets/img/profiles/avatar-03.jpg' alt={'picokdone'} />
                            </a>
                            </li>
                        )
                })}
            </>
        )
    }
}