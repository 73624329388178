import React from "react";
import { Form, Col, Row, Image, textarea } from "react-bootstrap";
import { URLS } from "../urls";
import Cookies from "universal-cookie";
import moment from "moment";
import Datepicker from "react-datetime";
import { branch_id } from "../../utils";

export default class ClientModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonText: "Submit",
      isWaiting: false,
      contract: {
        contractName: "",
        contractNo: "",
        contractDescription: "",
        startDate: moment(new Date()).format("DD/MM/YYYY"),
        endDate: "",
        client: null,
        files: [],
        branch_id,
      },
      clientList: [],
    };
  }

  componentWillReceiveProps(props) {
    if (props.clientList) {
      this.setState({
        clientList: props.clientList,
      });
    }
  }

  updateContractName = (event) => {
    const { contract } = this.state;
    contract.contractName = event.target.value;
    this.setState({
      contract,
    });
  };

  updateContractNo = (event) => {
    const { contract } = this.state;
    contract.contractNo = event.target.value;
    this.setState({
      contract,
    });
  };

  updateContractDescription = (event) => {
    const { contract } = this.state;
    contract.contractDescription = event.target.value;
    this.setState({
      contract,
    });
  };

  updateContractClient = (event) => {
    const { contract } = this.state;
    contract.client = event.target.value;
    this.setState({
      contract,
    });
  };

  updateStartDate = (event) => {
    const { contract } = this.state;
    contract.startDate = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    this.setState({
      contract,
    });
  };

  updateEndDate = (event) => {
    const { contract } = this.state;
    contract.endDate = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    this.setState({
      contract,
    });
  };

  validationStartDate = (currentDate) => {
    return currentDate.isAfter(moment(new Date()).add(-1, "days"));
  };

  validationEndDate = (currentDate) => {
    const { contract } = this.state;
    return currentDate.isAfter(
      moment(contract.startDate, "DD/MM/YYYY").add(-1, "days")
    );
  };

  submit = (e) => {
    e.preventDefault();
    this.setState({ buttonText: "Submitting ...", isWaiting: true });
    const { contract } = this.state;
    let formData = new FormData();
    formData.append("contract", JSON.stringify(contract));
    for (let fileIdx in contract.files) {
      const fileInfo = contract.files[fileIdx];
      formData.append(
        "file",
        fileInfo.file,
        fileInfo.uniqueName + "." + fileInfo.extension
      );
    }

    const token = "dummy token";
    fetch(`${URLS.backendContracts}/add`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
      body: formData,
    }).then((res) => {
      if (res.ok) {
        res.json().then((result) => {
          setTimeout(() => {
            window.$("#add_contract").modal("toggle");
            this.setState({ buttonText: "Submit", isWaiting: false });
            this.props.updateContractsList(result.contract);
          }, 500);
        });
      } else {
        res.json().then((responseJson) => {
          alert("Error occurred, Check the form again.");
          this.setState({ buttonText: "Submit", isWaiting: false });
        });
      }
    });
  };

  updateContractFile = (event) => {
    const { contract } = this.state;
    let fileInput = document.querySelector("#projectfiles");
    let files = fileInput.files;
    let fl = files.length;
    let i = 0;
    while (i < fl) {
      const contractFile = {
        originalName: "",
        uniqueName: "",
        extension: "",
        size: "",
      };
      const file = files[i];
      const name = file.name;
      const lastDot = name.lastIndexOf(".");
      const fileName = name.substring(0, lastDot);
      const ext = name.substring(lastDot + 1);
      const size = file.size;
      let currentDate = new Date();
      currentDate = currentDate.getTime();
      contractFile.originalName = fileName;
      contractFile.uniqueName = currentDate;
      contractFile.extension = ext;
      if (size < 1024) {
        contractFile.size = size + " B";
      } else if (size >= 1024 && size < 1024 * 1024) {
        contractFile.size = (size / 1024).toFixed(2) + " KB";
      } else {
        contractFile.size = (size / 1024 / 1024).toFixed(2) + " MB";
      }

      contractFile.file = file;
      contract.files = contract.files.concat(contractFile);
      this.setState({
        contract,
      });
      i++;
    }
  };

  removeContractFile = (idx) => (evt) => {
    const { contract } = this.state;
    contract.files.splice(idx, 1);
    this.setState({
      contract,
    });
  };

  render() {
    const { contract, clientList } = this.state;
    return (
      <div id="add_contract" className="modal custom-modal fade" role="dialog">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Contract</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={this.submit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Label style={{ fontSize: "14px" }}>
                        Contract Name <span className="text-danger">*</span>
                      </Form.Label>
                      <input
                        className="form-control"
                        type="text"
                        value={contract.contractName}
                        onChange={this.updateContractName.bind(this)}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Label style={{ fontSize: "14px" }}>
                        Contract No. <span className="text-danger">*</span>
                      </Form.Label>
                      <input
                        className="form-control"
                        type="text"
                        value={contract.contractNo}
                        onChange={this.updateContractNo.bind(this)}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Label style={{ fontSize: "14px" }}>
                        Start Date
                      </Form.Label>
                      <div classname="datePicker">
                        <i
                          class="fa fa-calendar fa-xs calendar-icon-3"
                          aria-hidden="true"
                        ></i>
                        <Datepicker
                          value={contract.startDate}
                          closeOnSelect={true}
                          onChange={this.updateStartDate}
                          // isValidDate={this.validationStartDate}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Label style={{ fontSize: "14px" }}>
                        End Date
                      </Form.Label>
                      <div classname="datePicker">
                        <i
                          class="fa fa-calendar fa-xs calendar-icon-3"
                          aria-hidden="true"
                        ></i>
                        <Datepicker
                          value={contract.endDate}
                          closeOnSelect={true}
                          onChange={this.updateEndDate}
                          isValidDate={this.validationEndDate}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Label style={{ fontSize: "14px" }}>
                        Contract Description
                      </Form.Label>
                      <textarea
                        rows="4"
                        class="form-control summernote"
                        onChange={this.updateContractDescription}
                        value={contract.contractDescription}
                      ></textarea>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <Form.Label style={{ fontSize: "14px" }}>
                        Client
                      </Form.Label>
                      <Form.Control
                        as="select"
                        value={contract.client}
                        onChange={this.updateContractClient}
                      >
                        <option>Select</option>
                        {/* <option value='add_job'>Add New</option> */}
                        <option disabled>__________________</option>
                        {clientList &&
                          clientList.length &&
                          clientList.map((client) => {
                            return (
                              <option value={client._id}>
                                {client.companyName}
                              </option>
                            );
                          })}
                      </Form.Control>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <Form.Label style={{ fontSize: "14px" }}>
                        Upload Files
                      </Form.Label>
                      <div>
                        {contract.files.length ? (
                          <div className="project-file-preview">
                            {contract.files.map((file, index) => {
                              return (
                                <div
                                  className="file-preview-frame krajee-default  kv-preview-thumb"
                                  id="thumb-input-ficons-4-817_ttt.html"
                                  data-fileindex="817_ttt.html"
                                  data-fileid="817_ttt.html"
                                  data-template="other"
                                  title="ttt.html"
                                >
                                  <button
                                    type="button"
                                    class="close fileinput-remove"
                                    aria-label="Close"
                                    onClick={this.removeContractFile(index)}
                                  >
                                    <span aria-hidden="true">×</span>
                                  </button>
                                  <div className="kv-file-content">
                                    <div
                                      className="kv-preview-data file-preview-other-frame"
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                      }}
                                    >
                                      <i
                                        className="fa fa-file-o"
                                        style={{ fontSize: "60px" }}
                                      />
                                    </div>
                                  </div>
                                  <div className="file-thumbnail-footer">
                                    <div
                                      className="file-footer-caption"
                                      title={
                                        file.originalName + "." + file.extension
                                      }
                                    >
                                      <div className="file-caption-info">
                                        {file.originalName +
                                          "." +
                                          file.extension}
                                      </div>
                                    </div>
                                    <div className="clearfix"></div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : null}
                        <div
                          className="project-upload-btn-wrapper"
                          style={{ height: "136px", margin: "8px" }}
                        >
                          <button
                            className="project-custom-upload-btn"
                            style={{ height: "136px" }}
                          >
                            Upload File
                          </button>
                          <input
                            id="projectfiles"
                            type="file"
                            multiple
                            label="Upload File"
                            onChange={this.updateContractFile}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Company Phone <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        value={client.companyPhone}
                        onChange={this.updateCompanyPhone.bind(this)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Contact Person First Name{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control floating"
                        type="text"
                        value={client.contactPersonFirstName}
                        onChange={this.updateContactPersonFirstName.bind(this)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Contact Person Last Name{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control floating"
                        type="text"
                        value={client.contactPersonLastName}
                        onChange={this.updateContactPersonLastName.bind(this)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Contact Person Email{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control floating"
                        type="text"
                        value={client.contactPersonEmail}
                        onChange={this.updateContactPersonEmail.bind(this)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Contact Person Phone{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control floating"
                        type="text"
                        value={client.contactPersonPhone}
                        onChange={this.updateContactPersonPhone.bind(this)}
                        required
                      />
                    </div>
                  </div>

                <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Client Image <span className="text-danger">*</span>
                      </label>
                      <div className="file-upload-div">
                        <div className="file-preview">
                          <img
                            style={{ height: "120px", width: "110px" }}
                            src={displayImageSrc}
                            alt=""
                          />
                        </div>

                        <div className="upload-btn-wrapper">
                          <button className="custom-upload-btn">Browse</button>
                          <input
                            type="file"
                            label="Upload File"
                            ref="emergency_image"
                            onChange={this.updateEmergencyInfoImage.bind(this)}
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}

                <div className="submit-section">
                  <button className="btn btn-primary submit-btn">
                    {this.state.buttonText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
