import React from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Helmet } from "react-helmet";
import Cookies from "universal-cookie";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { URLS } from "../urls";
import { branch_id } from "../../utils";

class Departments extends React.Component {
  constructor(props) {
    super(props);
    const cookies = new Cookies();
    const company_id = cookies.get("company_id");
    const userId = cookies.get("userId");
    this.updateEditDepartment = this.updateEditDepartment.bind(this);
    this.state = {
      addDepartment: "",
      editDepartment: {
        _id: "",
        name: "",
      },
      deleteDepartmentId: null,
      departments: [],
      create_public: false,
      create_group: false,
      company_id: company_id,
      user_id: userId,
      branch_id,
    };
  }
  updateAddDepartment = (e) => {
    this.setState({
      addDepartment: e.target.value,
    });
  };
  updateEditDepartmentName = (e) => {
    const { editDepartment } = this.state;
    editDepartment.name = e.target.value;
    this.setState({
      editDepartment,
    });
  };
  updateEditDepartment = (department) => (e) => {
    this.setState({
      editDepartment: department,
    });
  };
  updateDeleteDepartment = (department) => (e) => {
    this.setState({
      deleteDepartmentId: department._id,
    });
  };
  componentWillMount() {
    const token = "dummy token";
    fetch(`${URLS.backendDepartments}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          departments: result.departments,
        });
      });
  }
  submitAddForm = (e) => {
    e.preventDefault();
    const token = "dummy token";
    const { addDepartment, create_public, create_group, company_id, user_id } =
      this.state;

    fetch(`${URLS.backendDepartments}/add`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        department: { name: addDepartment, company_id, branch_id },
        create_public,
        create_group,
        user_id,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          departments: this.state.departments.concat(result.department),
          addDepartment: "",
        });
        window.$("#add_department").modal("toggle");
      });
  };
  submitEditForm = (e) => {
    e.preventDefault();
    const token = "dummy token";
    const { editDepartment } = this.state;
    fetch(`${URLS.backendDepartments}/update`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ department: editDepartment }),
    })
      .then((res) => res.json())
      .then((result) => {
        const departmentsTemp = this.state.departments.map((department) => {
          if (department._id === result.department._id) {
            department.name = result.department.name;
            return department;
          } else {
            return department;
          }
        });
        this.setState({
          departments: departmentsTemp,
          editDepartment: {
            _id: "",
            name: "",
          },
        });
        window.$("#edit_department").modal("toggle");
      });
  };
  deleteDepartmentConfirm = () => {
    const { deleteDepartmentId } = this.state;
    const token = "dummy token";
    fetch(`${URLS.backendDepartments}/delete/${deleteDepartmentId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        const departmentsTemp = this.state.departments.filter((department) => {
          return department._id !== result.department._id;
        });
        this.setState({
          departments: departmentsTemp,
          deleteDepartmentId: null,
        });
        alert("Deleted Succussfully.");
        window.$("#delete_department").modal("toggle");
      });
  };
  deleteDepartmentCancel = () => {
    this.setState({
      deleteDepartmentId: null,
    });
    window.$("#delete_department").modal("toggle");
  };

  handleCheckboxChanges = (type) => {
    let { create_public, create_group } = this.state;
    if (type === "public") {
      create_public = create_public ? false : true;
      this.setState({ create_public });
    } else if (type === "group") {
      create_group = create_group ? false : true;
      this.setState({ create_group });
    }
  };

  render() {
    const {
      departments,
      editDepartment,
      addDepartment,
      create_public,
      create_group,
    } = this.state;
    return (
      <body>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex, nofollow"></meta>
          <title>InvexERP</title>
        </Helmet>
        <div className="main-wrapper">
          <Topbar />
          <Sidebar />
          {/*<!-- Page Wrapper -->*/}
          <div className="page-wrapper">
            {/*<!-- Page Content -->*/}
            <div className="content container-fluid">
              {/*<!-- Page Title -->*/}
              <div className="row">
                <div className="col-sm-12 col-12">
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Departments</BreadcrumbItem>
                  </Breadcrumb>
                </div>
                <div className="col-sm-5 col-5"></div>
                <div className="col-sm-7 col-7 text-right m-b-30">
                  <button
                    className="btn add-btn"
                    data-toggle="modal"
                    data-target="#add_department"
                  >
                    <i className="fa fa-plus"></i> Add Department
                  </button>
                </div>
              </div>
              {/*<!-- /Page Title -->*/}

              <div className="row">
                <div className="col-md-12">
                  <div>
                    <table className="table table-striped custom-table mb-0 datatable">
                      <thead>
                        <tr>
                          <th style={{ width: "80px" }}>#</th>
                          <th>Department Name</th>
                          <th className="text-right">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {departments.length
                          ? departments.map((department, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{department.name}</td>
                                  <td className="text-right">
                                    <div className="dropdown dropdown-action">
                                      <a
                                        href="#"
                                        className="action-icon dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="material-icons">
                                          more_vert
                                        </i>
                                      </a>
                                      <div className="dropdown-menu dropdown-menu-right">
                                        <a
                                          className="dropdown-item"
                                          href="#"
                                          data-toggle="modal"
                                          data-target="#edit_department"
                                          onClick={this.updateEditDepartment(
                                            department
                                          )}
                                        >
                                          <i className="fa fa-pencil m-r-5"></i>{" "}
                                          Edit
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="#"
                                          data-toggle="modal"
                                          data-target="#delete_department"
                                          onClick={this.updateDeleteDepartment(
                                            department
                                          )}
                                        >
                                          <i className="fa fa-trash-o m-r-5"></i>{" "}
                                          Delete
                                        </a>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Page Content -->*/}

            {/*<!-- Add Department Modal -->*/}
            <div
              id="add_department"
              className="modal custom-modal fade"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Add Department</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form onSubmit={this.submitAddForm}>
                      <div className="form-group">
                        <label>
                          Department Name <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          value={addDepartment}
                          onChange={this.updateAddDepartment.bind(this)}
                          required
                        />
                      </div>
                      {/* <div class="form-check mb-2">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          checked={create_public}
                          onChange={() => this.handleCheckboxChanges("public")}
                        />
                        <label
                          class="form-check-label"
                          onClick={() => this.handleCheckboxChanges("public")}
                        >
                          Create Public Folder for this Department
                        </label>
                      </div> */}
                      {/* <div class="form-check mb-10">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          checked={create_group}
                          onChange={() => this.handleCheckboxChanges("group")}
                        />
                        <label
                          class="form-check-label"
                          onClick={() => this.handleCheckboxChanges("group")}
                        >
                          Create Group Folder for this Department
                        </label>
                      </div> */}
                      <div className="submit-section">
                        <button className="btn add-btn submit-btn">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Add Department Modal -->*/}

            {/*<!-- Edit Department Modal -->*/}
            <div
              id="edit_department"
              className="modal custom-modal fade"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Edit Department</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form onSubmit={this.submitEditForm}>
                      <div className="form-group">
                        <label>
                          Department Name <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          value={editDepartment.name}
                          onChange={this.updateEditDepartmentName.bind(this)}
                          type="text"
                          required
                        />
                      </div>
                      <div className="submit-section">
                        <button className="btn btn-primary submit-btn">
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Edit Department Modal -->*/}

            {/*<!-- Delete Department Modal -->*/}
            <div
              className="modal custom-modal fade"
              id="delete_department"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="form-header">
                      <h3>Delete Department</h3>
                      <p>Are you sure want to delete?</p>
                    </div>
                    <div className="modal-btn delete-action">
                      <div className="row">
                        <div className="col-6">
                          <a
                            href="javascript:void(0);"
                            className="btn btn-primary continue-btn"
                            onClick={this.deleteDepartmentConfirm.bind(this)}
                          >
                            Delete
                          </a>
                        </div>
                        <div className="col-6">
                          <a
                            href="javascript:void(0);"
                            className="btn btn-primary cancel-btn"
                            onClick={this.deleteDepartmentCancel.bind(this)}
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Delete Department Modal -->*/}
          </div>
          {/*<!-- /Page Wrapper -->*/}
        </div>
        {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
        <div className="sidebar-overlay" data-reff=""></div>
      </body>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Departments));
