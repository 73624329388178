import React from "react";
export default function Loader(props) {
  return (
    <div className="loader d-flex justify-content-around align-items-center">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  );
}
