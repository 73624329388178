import React from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import { URLS } from "../urls";
import { Helmet } from "react-helmet";
import Cookies from "universal-cookie";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { Form } from "react-bootstrap";
import ApplyLeaveModal from "./ApplyLeaveModal";
import EditLeaveModal from "./EditLeaveModal";
import DeleteLeaveModal from "./DeleteLeaveModal";
const allMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
class LeavesEmployee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appliedLeaves: [],
      loggedEmployee: null,
      searchFilter: {},
      selectedLeave: null,
      leaveTypes: [],
    };
  }
  componentDidMount() {
    const token = "dummy token";
    const cookies = new Cookies();
    const id = cookies.get("userId");

    fetch(`${URLS.backendApplyLeaves}/getByUserId/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          appliedLeaves: result.applyLeaves,
        });
        console.log("CCC", result);
      });

    fetch(`${URLS.backendEmployees}/getShortInfoByUserId/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          loggedEmployee: result.employee,
        });
      });

    fetch(`${URLS.backendLeaves}/get`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          leaveTypes: result.leaves,
        });
      });
  }
  updatdeSelectedLeave = (appliedLeave) => (event) => {
    this.setState({
      selectedLeave: appliedLeave,
    });
  };
  showDateInFormat = (date) => {
    date = date.split("/");
    return (
      ("0" + date[0]).slice(-2) + " " + allMonths[date[1] - 1] + " " + date[2]
    );
  };
  updateAppliedLeavesList = (appliedLeave) => {
    let { appliedLeaves } = this.state;
    const id = appliedLeave && appliedLeave._id;
    let isExists = false;
    for (let index = appliedLeaves.length - 1; index >= 0; --index) {
      if (appliedLeaves[index]._id === id) {
        appliedLeaves.splice(index, 1);
        appliedLeaves.splice(index, 0, appliedLeave);
        isExists = true;
        break;
      }
    }
    if (isExists) {
      this.setState({ appliedLeaves: appliedLeaves });
    } else {
      this.setState({ appliedLeaves: [appliedLeave].concat(appliedLeaves) });
    }
  };
  removeLeaveFormAppliedLeavesList = (deletedLeave) => {
    let { appliedLeaves } = this.state;
    const id = deletedLeave && deletedLeave._id;
    for (let index = appliedLeaves.length - 1; index >= 0; --index) {
      if (appliedLeaves[index]._id === id) {
        appliedLeaves.splice(index, 1);
        break;
      }
    }
    this.setState({ appliedLeaves: appliedLeaves });
  };

  render() {
    const {
      appliedLeaves,
      leaveTypes,
      searchFilter,
      selectedLeave,
      loggedEmployee,
    } = this.state;
    return (
      <body>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex, nofollow"></meta>
          <title>InvexERP</title>
        </Helmet>
        <div className="main-wrapper">
          <Topbar />
          <Sidebar />
          {/*<!-- Page Wrapper -->*/}
          <div className="page-wrapper">
            {/*<!-- Page Content -->*/}
            <div className="content container-fluid">
              {/*<!-- Page Title -->*/}
              <div className="row">
                <div className="col-sm-8 col-6">
                  <h4 className="page-title">Leaves</h4>
                </div>
                <div className="col-sm-4 col-6 text-right m-b-30">
                  <a
                    href="javaScript:void(0);"
                    className="btn add-btn"
                    data-toggle="modal"
                    data-target="#add_leave"
                  >
                    <i className="fa fa-plus"></i> Add Leave
                  </a>
                </div>
              </div>
              {/*<!-- /Page Title -->*/}

              {/*<!-- Leave Statistics -->*/}
              <div className="row">
                <div className="col-md-3">
                  <div className="stats-info">
                    <h6>Annual Leave</h6>
                    <h4>12</h4>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="stats-info">
                    <h6>Medical Leave</h6>
                    <h4>3</h4>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="stats-info">
                    <h6>Other Leave</h6>
                    <h4>4</h4>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="stats-info">
                    <h6>Remaining Leave</h6>
                    <h4>5</h4>
                  </div>
                </div>
              </div>
              {/*<!-- /Leave Statistics -->*/}

              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table table-striped custom-table mb-0 datatable">
                      <thead>
                        <tr>
                          <th>Leave Type</th>
                          <th>From</th>
                          <th>To</th>
                          <th>No of Days</th>
                          <th>Reason</th>
                          <th className="text-center">Status</th>
                          <th>Approved by</th>
                          <th className="text-right">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {appliedLeaves.length
                          ? appliedLeaves.map((appliedLeave) => {
                              return (
                                <tr
                                  onClick={this.updatdeSelectedLeave(
                                    appliedLeave
                                  )}
                                >
                                  <td>{appliedLeave.name}</td>
                                  <td>
                                    {this.showDateInFormat(appliedLeave.from)}
                                  </td>
                                  <td>
                                    {this.showDateInFormat(appliedLeave.to)}
                                  </td>
                                  <td>
                                    {appliedLeave.days > 1
                                      ? appliedLeave.days + " days"
                                      : appliedLeave.days + " day"}
                                  </td>
                                  <td>{appliedLeave.reason}</td>
                                  <td className="text-center">
                                    <div className="action-label">
                                      <a
                                        className="btn btn-white btn-sm btn-rounded"
                                        href="javaScript:void(0);"
                                      >
                                        {appliedLeave.status === "New" ? (
                                          <i className="fa fa-dot-circle-o text-purple"></i>
                                        ) : appliedLeave.status ===
                                          "Pending" ? (
                                          <i className="fa fa-dot-circle-o text-info"></i>
                                        ) : appliedLeave.status ===
                                          "Approved" ? (
                                          <i className="fa fa-dot-circle-o text-success"></i>
                                        ) : (
                                          <i className="fa fa-dot-circle-o text-danger"></i>
                                        )}
                                        {appliedLeave.status}
                                      </a>
                                    </div>
                                  </td>
                                  <td>
                                    {appliedLeave.status === "Approved" ||
                                    appliedLeave.status === "Declined" ? (
                                      <h2 className="table-avatar">
                                        <a
                                          href="javaScript:void(0);"
                                          className="avatar avatar-xs"
                                        >
                                          <img
                                            src={
                                              appliedLeave.approvedBy.image
                                                ? URLS.backendStatic +
                                                  "/" +
                                                  appliedLeave.approvedBy.image
                                                : "assets/img/profiles/avatar-09.jpg"
                                            }
                                            alt=""
                                          />
                                        </a>
                                        <a href="javaScript:void(0);">
                                          {appliedLeave.approvedBy.name}
                                        </a>
                                      </h2>
                                    ) : null}
                                  </td>
                                  <td className="text-right">
                                    <div className="dropdown dropdown-action">
                                      <a
                                        href="javaScript:void(0);"
                                        className="action-icon dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="material-icons">
                                          more_vert
                                        </i>
                                      </a>
                                      <div className="dropdown-menu dropdown-menu-right">
                                        <a
                                          className="dropdown-item"
                                          href="javaScript:void(0);"
                                          data-toggle="modal"
                                          data-target="#edit_leave"
                                        >
                                          <i className="fa fa-pencil m-r-5"></i>{" "}
                                          Edit
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javaScript:void(0);"
                                          data-toggle="modal"
                                          data-target="#delete_leave"
                                        >
                                          <i className="fa fa-trash-o m-r-5"></i>{" "}
                                          Delete
                                        </a>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          : ""}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Page Content -->*/}

            {/*<!-- Add Leave Modal -->*/}
            <ApplyLeaveModal
              updateAppliedLeavesList={this.updateAppliedLeavesList.bind(this)}
              employee={loggedEmployee}
              leaveTypes={leaveTypes}
            />
            {/*<!-- /Add Leave Modal -->*/}

            {/*<!-- Edit Leave Modal -->*/}
            <EditLeaveModal
              updateAppliedLeavesList={this.updateAppliedLeavesList.bind(this)}
              leaveTypes={leaveTypes}
              editLeave={selectedLeave}
            />
            {/*<!-- /Edit Leave Modal -->*/}

            {/*<!-- Delete Leave Modal -->*/}
            <DeleteLeaveModal
              removeLeaveFormAppliedLeavesList={
                this.removeLeaveFormAppliedLeavesList
              }
              deleteLeave={selectedLeave}
            />
            {/*<!-- /Delete Leave Modal -->*/}
          </div>
          {/*<!-- /Page Wrapper -->*/}
        </div>
        {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
        <div className="sidebar-overlay" data-reff=""></div>
      </body>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LeavesEmployee));
