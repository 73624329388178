import React, { Component } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { URLS } from '../../urls';
import '../../../assets/css/date-picker.css';

export default class EmployeeTraining extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trainings: [],
        }
    };
    componentWillReceiveProps(props) {
        const id = props.eId;
        if(id){
            const token = 'dummy token';
            fetch(`${URLS.backendTrainings}/trainings/${id}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Token ${token}`,
                },
            }).then(res => res.json())
                .then((result) => {
                    this.setState({
                        trainings: result.trainings,
                    });
            });
        }
    }
    render() {
        const {trainings} = this.state;
        return (
            <div className="content container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="table table-striped custom-table mb-0">
                                <thead>
                                    <tr>
                                        <th>Course ame</th>
                                        <th>Training Institute</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Description</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        trainings && trainings.length ? trainings.map((empTraining, idx) => {
                                            return (
                                                <tr className="holiday-completed">
                                                    <td>{empTraining.courseName}</td>
                                                    <td>{empTraining.trainingInstitute}</td>
                                                    <td>{empTraining.startDate}</td>
                                                    <td>{empTraining.endDate}</td>
                                                    <td>{empTraining.trainingDescription}</td>
                                                    <td>{empTraining.trainingStatus}</td>
                                                </tr>
                                            );
                                        }) : ''
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}