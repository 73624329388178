import React from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import { Form, Col, Row, Image, textarea, Popover } from "react-bootstrap";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { URLS } from "../urls";
import { branch_id } from "../../utils";

import Contract from "./Contract";
import AddContractModal from "./AddContractModal";
import EditContractModal from "./EditContractModal";

class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contracts: [],
      contractsClone: [],
      editContract: "",
      deleteContract: "",
      clientList: [],
    };
  }

  editContract = (_id) => {
    this.setState({ editContract: _id });
  };

  deleteContract = (_id) => {
    this.setState({ deleteContract: _id });
  };

  getEditContractDataFromId = (id) => {
    const { contracts } = this.state;
    return contracts?.find((item) => id === item._id);
  };

  componentWillMount() {
    const token = "dummy token";
    fetch(`${URLS.backendContracts}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          contracts: result.contracts,
          contractsClone: result.contracts,
        });
      });

    fetch(`${URLS.backendClients}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          clientList: result.clients,
        });
      });
  }

  updateContractsList = (contract, action) => {
    let { contractsClone } = this.state;
    const id = contract && contract._id;

    if (action === "add") {
      contractsClone = contractsClone?.concat(contract);
    } else if (action === "edit") {
      for (let index = contractsClone.length - 1; index >= 0; --index) {
        if (contractsClone[index]._id === id) {
          contractsClone.splice(index, 1);
          contractsClone.splice(index, 0, contract);
          break;
        }
      }
    }

    this.setState({ contractsClone, contracts: contractsClone });
  };

  handleSearchContracts = (e, type) => {
    e.preventDefault();
    const value = e.target.value.trim();
    let { contracts, contractsClone } = this.state;

    if (value) {
      if (type === "name") {
        contracts = contractsClone.filter((contract) => {
          if (contract.contractName.toLowerCase().includes(value)) {
            return contract;
          }
        });
      } else if (type === "number") {
        contracts = contractsClone.filter((contract) => {
          if (
            contract.contractNo &&
            contract.contractNo.toLowerCase().includes(value)
          ) {
            return contract;
          }
        });
      }
    } else {
      contracts = contractsClone;
    }
    this.setState({
      contracts,
    });
  };

  handleDeleteContract = () => {
    let { contracts, contractsClone } = this.state;
    const contractId = this.state.deleteContract;
    const token = "dummy token";

    fetch(`${URLS.backendContracts}/delete`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ contractId }),
    })
      .then((res) => res.json())
      .then((result) => {
        contractsClone = contractsClone.filter(
          (contract) => contract._id !== contractId
        );
        this.setState({ contracts: contractsClone, contractsClone });
      });

    window.$("#delete_contract").modal("toggle");
  };

  resetSearch = () => {
    let { contractsClone } = this.state;
    this.setState({
      contracts: contractsClone,
      contractsClone,
    });
  };

  render() {
    const { contracts, editContract, clientList } = this.state;
    const editContractData = this.getEditContractDataFromId(editContract);
    return (
      <body>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex, nofollow"></meta>
          <title>InvexERP</title>
        </Helmet>
        <div className="main-wrapper">
          <Topbar />
          <Sidebar />
          {/*<!-- Page Wrapper -->*/}
          <div className="page-wrapper">
            {/*<!-- Page Content -->*/}
            <div className="content container-fluid">
              {/*<!-- Page Title -->*/}
              <div className="row">
                <div className="col">
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Contracts</BreadcrumbItem>
                  </Breadcrumb>
                  {/* <h4 className="page-title">Projects</h4> */}
                </div>
                <div className="col-12 text-right m-b-30">
                  <div
                    className="btn add-btn p-cursor"
                    data-toggle="modal"
                    data-target="#add_contract"
                  >
                    <i className="fa fa-plus"></i> Create Contract
                  </div>
                  {/* <div className="view-icons">
                    <a
                      href="projects.html"
                      className="grid-view btn btn-link active"
                    >
                      <i className="fa fa-th"></i>
                    </a>
                    <a
                      href="project-list.html"
                      className="list-view btn btn-link"
                    >
                      <i className="fa fa-bars"></i>
                    </a>
                  </div> */}
                </div>
              </div>
              {/*<!-- /Page Title -->*/}

              {/*<!-- Search Filter -->*/}
              <div className="row filter-row">
                <div className="col-sm-6 col-md-4">
                  <div className="form-group form-focus">
                    <input
                      type="text"
                      className="form-control floating"
                      onChange={(e) => this.handleSearchContracts(e, "name")}
                    />
                    <label className="focus-label">Contract Name</label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4">
                  <div className="form-group form-focus">
                    <input
                      type="text"
                      className="form-control floating"
                      onChange={(e) => this.handleSearchContracts(e, "number")}
                    />
                    <label className="focus-label">Contract No.</label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4">
                  <a
                    href="Javascript:void(0);"
                    className="btn btn-success btn-block"
                    onClick={() => this.resetSearch()}
                  >
                    Search
                  </a>
                </div>
              </div>
              {/*<!-- Search Filter -->*/}

              <div className="row staff-grid-row">
                {contracts && contracts.length
                  ? contracts.map((contract) => {
                      return (
                        <Contract
                          key={contract._id}
                          contract={contract}
                          onEdit={this.editContract}
                          onDelete={this.deleteContract}
                          canShowControls={true}
                        />
                      );
                    })
                  : ""}
              </div>
            </div>
            {/*<!-- /Page Content -->*/}

            <AddContractModal
              clientList={clientList}
              updateContractsList={(client) =>
                this.updateContractsList(client, "add")
              }
            />
            <EditContractModal
              mode="edit"
              contract={editContractData}
              updateContractsList={(client) =>
                this.updateContractsList(client, "edit")
              }
              clientList={clientList}
            />
            {/*<!-- Delete Project Modal -->*/}
            <div
              className="modal custom-modal fade"
              id="delete_contract"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="form-header">
                      <h3>Delete Contract</h3>
                      <p>Are you sure want to delete?</p>
                      <br></br>
                      <p style={{ color: "red" }}>
                        Deleting this Contract will delete it's Projects and
                        Tasks too
                      </p>
                      <br></br>
                      <br></br>
                    </div>
                    <div className="modal-btn delete-action">
                      <div className="row">
                        <div className="col-6">
                          <a
                            href="javascript:void(0);"
                            className="btn btn-primary continue-btn"
                            onClick={this.handleDeleteContract}
                          >
                            Delete
                          </a>
                        </div>
                        <div className="col-6">
                          <a
                            href="javascript:void(0);"
                            data-dismiss="modal"
                            className="btn btn-primary cancel-btn"
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Delete Project Modal -->*/}
          </div>
          {/*<!-- /Page Wrapper -->*/}
        </div>
        {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
        <div className="sidebar-overlay" data-reff=""></div>
      </body>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Projects));
