import React from 'react';
import { URLS } from '../urls';

export default class ImageList extends React.Component {
    getNameOfEmployee(e){
        const name = e.personalInfo.name;
        return name.first + (name.middle ? ((' ' + name.middle) + name.last ? (' '+ name.last) : '') : (' '+ name.last));
    }
    render(){
        return (
            <>
                {
                    this.props.value && this.props.value.map(item => {
                    if(item.image)
                        return (
                            <a href="#" title={item.name} class="avatar">
								<img src={URLS.backendStatic + '/' + item.image} alt={'picokdone'} />
							</a>
                        )
                    else
                        return (
                            <a href="#" title={item.name} class="avatar">
                                <img src='/assets/img/profiles/avatar-03.jpg' alt={'picokdone'} />
                            </a>
                        )
                })}
            </>
        )
    }
}