function addAllDeductions(deductions) {
  return {
    type: "ADD_DEDUCTIONS",
    payload: deductions,
  };
}

function addDeduction(deduction) {
  return {
    type: "ADD_DEDUCTION",
    payload: deduction,
  };
}

function deleteDeduction(slug) {
  return {
    type: "DELETE_DEDUCTION",
    payload: slug,
  };
}

export { addAllDeductions, addDeduction, deleteDeduction };
