import React, { Fragment } from "react";
import { URLS } from "../../urls";
import moment from "moment";
import { Form } from "react-bootstrap";
import Cookies from "universal-cookie";
import CurrencyInput from "react-currency-input-field";
import { branch_id } from "../../../utils";

export default class ApplyLoanModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonText: "Submit",
      isWaiting: false,
      Amount: 0,
      userId: "",
      applyDate: moment(new Date()).format("DD/MM/YYYY"),
      staff: "",
      departments: [],
      users: [],
      comment: "",
      appliedDate: "",
      title: "",
      description: "",
    };
  }
  componentWillReceiveProps(props) {
    this.getDepartments();
  }

  getDepartments = async () => {
    // await waitFor(5000);
    if (process.env.REACT_APP_HAS_INVEX === "true") {
      let response = await fetch(`${URLS.mainSiteBackend}/users/departments`, {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        // credentials: "include",
      });
      console.log("response");
      if (!response.ok) {
        response = await response.json();

        throw new Error(response.message);
      }

      const { data } = await response.json();

      this.setState({
        departments: data?.departments,
      });
    }
  };

  getUsersInDepartments = async (department) => {
    let response = await fetch(
      `${URLS.mainSiteBackend}/users/by-department/${department}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        // credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    this.setState({
      users: data?.users,
    });
  };

  onChangeInputs = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  submit = (e) => {
    e.preventDefault();
    this.setState({ buttonText: "Submitting ...", isWaiting: true });
    const { applyDate, title, description, comment, Amount, staff } =
      this.state;
    const cookies = new Cookies();

    const employee = cookies.get("employee");
    const employeeName =
      employee.info.first +
      " " +
      employee.info.middle +
      " " +
      employee.info.last;
    const requsion = {
      title,
      description,
      type: "Loan",
      amount: Amount,
      requestto: staff,
      status: "Pending",
      regdate: moment(new Date()).format("YYYY-MM-DD"),
      requestby: staff,
      vendor: "",
      comment,
      filledFormData: "",
      loanDetails: JSON.stringify({ employeeName, employeeId: employee.id }),
    };

    const formData = new FormData();
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(requsion)) {
      formData.append(key, value);
    }
    // console.log(requsion);
    const token = "dummy token";

    fetch(`${URLS.backendLoan}/check/${employee.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((z) => {
      if (z?.status === 500) {
        return alert(
          "You have an active, You  can not request for another loan."
        );
      }

      if (process.env.REACT_APP_HAS_INVEX === "true") {
        fetch(`${URLS.mainSiteBackend}/requisition/create`, {
          method: "POST",
          // credentials: "include",
          headers: {
            Authorization: `Token ${token}`,
          },
          body: formData,
        }).then((res) => {
          if (res.ok) {
            res.json().then((result) => {
              console.log(result);
              fetch(`${URLS.backendLoan}`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Token ${token}`,
                },
                body: JSON.stringify({
                  Title: title,
                  Amount,
                  applyDate,
                  status: "Pending",
                  paymentsStatus: "Not paid",
                  userID: employee.id,
                  staff,
                  requestid: result.data.requisition.requestid,
                  description,
                  comment,
                  branch_id,
                }),
              }).then((el) => {
                if (el.ok) {
                  el.json().then((response) => {
                    // console.log(response);
                    alert("Loan Sucessfully Applied");
                    window.$("#add_leave").modal("toggle");
                    this.setState({
                      buttonText: "Submit",
                      isWaiting: false,
                      staff: "",
                      departments: [],
                      users: [],
                      comment: "",
                      appliedDate: "",
                      title: "",
                      description: "",
                      Amount: 0,
                      userId: "",
                    });
                  });
                } else {
                  el.json().then((json) => {
                    console.log(json);
                    alert("Error occurred, Check the form again.");
                    this.setState({
                      buttonText: "Submit",
                      isWaiting: false,
                    });
                  });
                }
              });
            });
          } else {
            res.json().then((responseJson) => {
              console.log(responseJson);
              alert("Error occurred, Check the form again.");
              this.setState({ buttonText: "Submit", isWaiting: false });
            });
          }
        });
      } else {
        return alert("Please activate accountant module to use Loan");
      }
    });
  };

  updateAmount = (value) => {
    this.setState({
      Amount: value,
    });
  };

  render() {
    const {
      departments,
      users,
      Amount,
      staff,
      buttonText,
      title,
      description,
      comment,
      isWaiting,
    } = this.state;
    return (
      <div id="add_leave" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Apply Loan</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={this.submit}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Title <span className="text-danger">*</span> :
                  </Form.Label>
                  <Form.Control
                    name="title"
                    placeholder="Enter a title"
                    value={title}
                    onChange={this.onChangeInputs}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    Description <span className="text-danger">*</span> :
                  </Form.Label>
                  <label className="border p-0 rounded pb-2 message-area d-block">
                    <Form.Control
                      name="description"
                      value={description}
                      onChange={this.onChangeInputs}
                      as="textarea"
                      className="w-100 border-0 p-3"
                      placeholder="Enter your detail description"
                      rows={4}
                      required
                    />
                  </label>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Loan Amount <span className="text-danger">*</span> :
                  </Form.Label>
                  {/* <Form.Control
                    className="form-control"
                    name="Amount"
                    value={Amount}
                    onChange={this.onChangeInputs}
                    type="number"
                    required
                  /> */}
                  <CurrencyInput
                    className="form-control"
                    value={Amount}
                    allowNegativeValue={false}
                    // allowDecimals={false}
                    onValueChange={(value, name) => this.updateAmount(value)}
                  />
                </Form.Group>

                <div className="row gap-3">
                  <Form.Group className="col mb-3">
                    <Form.Label>
                      Department <span className="text-danger">*</span> :
                    </Form.Label>
                    <select
                      className="form-control"
                      onChange={(e) =>
                        this.getUsersInDepartments(e.target.value)
                      }
                      required
                    >
                      <option value="">Select Recipient's Department</option>
                      {departments &&
                        departments.map((el, index) => (
                          <option key={index} value={el.Department}>
                            {el.Department}
                          </option>
                        ))}
                    </select>
                  </Form.Group>

                  {users.length > 0 && (
                    <Form.Group className="col mb-3">
                      <Form.Label>
                        Recipient <span className="text-danger">*</span> :
                      </Form.Label>
                      <select
                        name="staff"
                        className="form-control"
                        value={staff}
                        onChange={this.onChangeInputs}
                        required
                      >
                        <option value="">Select Recipient </option>
                        {users.map((el, index) => (
                          <Fragment key={index}>
                            <option value={el.Staff_ID}>{el.Name}</option>
                          </Fragment>
                        ))}
                      </select>
                    </Form.Group>
                  )}
                </div>

                <Form.Group className="mb-3">
                  <Form.Label>Comment :</Form.Label>
                  <label className="border p-0 rounded pb-2 message-area d-block">
                    <Form.Control
                      name="comment"
                      value={comment}
                      onChange={this.onChangeInputs}
                      as="textarea"
                      className="w-100 border-0 p-3"
                      placeholder="Enter a comment"
                      rows={4}
                    />
                  </label>
                </Form.Group>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    disabled={isWaiting}
                  >
                    {buttonText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
