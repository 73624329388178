import React, { PureComponent } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip,LabelList,Label
} from 'recharts';

export default class CompletionTaskChart extends PureComponent {
  state = {
    data : []
  };
  componentWillReceiveProps(props){
    this.setState({
      data : props.data
    })
  }
  render() {
    const {data} = this.state;
    var yMax = 0
    if(data.length){
      yMax = Math.max.apply(Math, data.map(function(o) { return o.tasks; })) + 10;
      yMax += yMax*0.1; 
    }
    return (
      <BarChart
        width={200}
        height={240}
        data={data}
        margin={{
          top: 30, right: 0, left: 0, bottom: 10,
        }}
      >
        {/* <CartesianGrid vertical={false}/> */}
        <XAxis dataKey="date" interval={0} textAnchor="end"/>
        <YAxis tick='true' domain={[0, yMax]}/>
        <Tooltip cursor={{fill: 'transparent'}} />
        <Bar dataKey="tasks" fill="#0253cc"/>
      </BarChart>
    );
  }
}
