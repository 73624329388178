import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import XLSX from "xlsx";
import { URLS } from "../urls";
import { branch_id } from "../../utils";
import { cloneDeep, isEmpty, size, uniqBy } from "lodash";
import Cookies from "universal-cookie";
import ModalLoader from "../ModalLoader";

export default function ImportPaylistFromExcel({
  payrollMonth = new Date().getMonth(),
  payrollYear = new Date().getFullYear(),
  handleEmployeesToPay,
}) {
  const [loading, setLoading] = useState(true);
  const [jobGradeHistorys, setJobGradeHistorys] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [jobGrades, setJobGrade] = useState([]);
  const [file, setFile] = useState();
  const [timesheet, setTimesheet] = useState();
  const [isReadingExcel, setIsReadingExcel] = useState(true);
  const [isGettingTimesheet, setIsGettingTimesheet] = useState(true);

  const setUp = async () => {
    try {
      setLoading(true);
      const resolvedPromises = await Promise.all([
        getJobGradeHistorys(),
        getEmployees(),
        getJobGrades(),
      ]);
      // console.log(resolvedPromises);
      // getJobGradeHistorys();
      // getEmployees();
      // getJobGrades();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setUp();
    return () => {};
  }, []);

  useEffect(() => {
    if (!loading) getTimesheetForMonth();
  }, [payrollMonth, payrollYear, loading]);

  useEffect(() => {
    readUploadedTimeSheet(timesheet);
  }, [timesheet]);

  const readUploadedTimeSheet = async (timesheet) => {
    try {
      setIsReadingExcel(true);
      if (timesheet) {
        const url = `${URLS.backendPayroll}/timesheets-file/${timesheet?._id}/${timesheet?.filename}`;
        const workbook = XLSX.read(await (await fetch(url)).arrayBuffer(), {
          WTF: 1,
        });
        /* DO SOMETHING WITH workbook HERE */
        const rows = XLSX.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]],
          {
            defval: "",
          }
        );
        // console.log("excel-from-url", rows);
        handleEmployees(rows);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsReadingExcel(false);
    }
  };

  const getEmployees = () => {
    return new Promise(async (resolve, reject) => {
      const token = "dummy token";
      fetch(`${URLS.backendEmployees}/get/${branch_id}?all=true`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          // console.log(result.employees);
          setEmployees(result.employees);
          resolve(result.employees);
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  };

  const getTimesheetForMonth = () => {
    return new Promise(async (resolve, reject) => {
      setIsGettingTimesheet(true);
      const token = "dummy token";
      fetch(
        `${URLS.backendPayroll}/get-timesheets/${branch_id}?payrollMonth=${payrollMonth}&payrollYear=${payrollYear}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          // console.log(result);
          if (result.timesheets) {
            setTimesheet(result.timesheets[0]);
          }

          setIsGettingTimesheet(false);
          resolve();
        })
        .catch((err) => {
          console.log(err);
          setIsGettingTimesheet(false);
          reject();
        });
    });
  };

  const getJobGradeHistorys = () => {
    return new Promise(async (resolve, reject) => {
      const token = "dummy token";
      fetch(
        `${URLS.backendJobGradeHistory}/get-job-grade-history/${branch_id}?payrollMonth=${payrollMonth}&payrollYear=${payrollYear}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          if (result?.jobGradeHistorys) {
            setJobGradeHistorys(result.jobGradeHistorys);
          }
          resolve(result?.jobGradeHistorys);
        })
        .catch((err) => {
          console.log(err);
          toast(
            "Unable to get Job Grade History, please refresh your browser",
            {
              type: "error",
            }
          );
          reject();
        });
    });
  };

  const getJobGrades = () => {
    return new Promise(async (resolve, reject) => {
      const token = "dummy token";
      fetch(`${URLS.backendJobGrades}/get/${branch_id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          setJobGrade([...result.jobGrades]);
          resolve([...result.jobGrades]);
        })
        .catch((err) => {
          toast("Unable to get Job Grades, please refresh your browser", {
            type: "error",
          });
          reject();
        });
    });
  };

  const uploadTimeSheet = (file) => {
    setLoading(true);
    const cookies = new Cookies();
    const userId = cookies.get("userId");
    const formData = new FormData();
    formData.append("employeeId", userId);
    formData.append("branch_id", branch_id);
    formData.append("payrollMonth", payrollMonth);
    formData.append("payrollYear", payrollYear);
    formData.append("timesheet", file, file.name);

    const token = "dummy token";
    fetch(`${URLS.backendPayroll}/upload-time-sheet`, {
      method: "POST",
      headers: {
        /*   Accept: "application/json",
        "Content-Type": "application/json", */
        Authorization: `Token ${token}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          toast("Uploaded", {
            type: "success",
          });
          readLocalFile(file);
        } else {
          throw Error();
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast("Unable to Upload", {
          type: "error",
        });
      });
  };

  function handleFile(e) {
    const file = e.target.files[0];

    uploadTimeSheet(file);
  }

  function readLocalFile(file) {
    setFile(file);
    const reader = new FileReader();
    reader.onload = function (e) {
      const workbook = XLSX.read(e.target.result);
      const rows = XLSX.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]],
        {
          defval: "",
        }
      );
      console.log("excel", rows);
      handleEmployees(rows);
    };
    reader.readAsArrayBuffer(file);
  }

  const handleEmployees = (employeesFromExcel) => {
    try {
      employeesFromExcel = cloneDeep(employeesFromExcel).map((el) => ({
        ...el,
        nameAndEmployeeId: `${el["Login_Id"]}${el.User_Name}`,
      }));

      let employeesToPay = uniqBy(employeesFromExcel, "nameAndEmployeeId");

      employeesToPay = employeesToPay
        .map((employee) => {
          const fullname = (name) => `${name?.first} ${name?.last}`;
          // console.log("employees ===>", employees);
          const foundEmployeesData = cloneDeep(employees).find((el) => {
            return (
              fullname(el?.personalInfo?.name) === employee?.User_Name &&
              el?.jobInfo.sfa_id == employee["Login_Id"]
            );
          });

          // console.log(
          //   "foundEmployeesData ===>",
          //   foundEmployeesData
          //   // jobGradeHistorys
          // );

          //  total Previous  role
          const totalDaysWorkedInPreviousJobGrades = cloneDeep(jobGradeHistorys)
            .filter((el) => el?.employee.userId === foundEmployeesData?.userId)
            .map((el) => el.daysWorkedInPreviousJobGrade)
            .reduce((a, b) => a + b, 0);

          // console.log(totalDaysWorkedInPreviousJobGrades);

          return {
            excelData: employee,
            ...foundEmployeesData,
            totalDaysWorked:
              employee["Grand Total"] - totalDaysWorkedInPreviousJobGrades,
            // size(
            //   employeesFromExcel.filter(
            //     (el) =>
            //       el.Name ===
            //         fullname(foundEmployeesData?.personalInfo?.name) &&
            //       el["Employee Code"] ==
            //         foundEmployeesData?.jobInfo.employeeId &&
            //       el.Attendance === "Yes"
            //   )
            // )- totalDaysWorkedInPreviousJobGrades,
          };
        })
        .filter((el) => el?.jobInfo); //user might have  worked  in  other job Grade

      // console.log("main ==>", employeesToPay);

      for (let jobHistory of jobGradeHistorys) {
        // console.log(jobHistory, jobGrades);
        const foundEmployeesData = employees.find(
          (el) => el?.userId === jobHistory.employeeId
        );
        const foundJobGrade = jobGrades.find(
          (el) => el._id === jobHistory.jobGradeFrom
        );
        // console.log("found ===>", foundEmployeesData);

        // Grade this time  in  history was different
        foundEmployeesData.jobInfo.grade = foundJobGrade;

        foundEmployeesData.jobInfo.usergrade = { salary: jobHistory.salary };

        employeesToPay.push({
          // ...jobHistory,
          ...cloneDeep(foundEmployeesData),
          totalDaysWorked: jobHistory?.daysWorkedInPreviousJobGrade,
        });
      }
      // console.log("end", employeesToPay);
      if (handleEmployeesToPay) handleEmployeesToPay(employeesToPay);
      // alert(JSON.stringify(employeesToPay));
      return employeesToPay;
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  return (
    <>
      <label
        className="btn btn-light border"
        style={
          loading || isReadingExcel || isGettingTimesheet
            ? {
                pointerEvents: "none",
              }
            : {}
        }
      >
        <input
          type="file"
          className="d-none"
          onChange={handleFile}
          accept=".xlsx"
        />
        {loading || isReadingExcel || isGettingTimesheet
          ? `${isReadingExcel ? "Reading Excel..." : "Please wait..."}`
          : file?.name
          ? file?.name
          : timesheet
          ? timesheet?.filename
          : "Import"}{" "}
        <svg
          style={{
            width: `${22}px`,
            height: `${22}px`,
          }}
          viewBox="0 0 24 24"
        >
          <path
            fill={"#008000"}
            d="M21.17 3.25Q21.5 3.25 21.76 3.5 22 3.74 22 4.08V19.92Q22 20.26 21.76 20.5 21.5 20.75 21.17 20.75H7.83Q7.5 20.75 7.24 20.5 7 20.26 7 19.92V17H2.83Q2.5 17 2.24 16.76 2 16.5 2 16.17V7.83Q2 7.5 2.24 7.24 2.5 7 2.83 7H7V4.08Q7 3.74 7.24 3.5 7.5 3.25 7.83 3.25M7 13.06L8.18 15.28H9.97L8 12.06L9.93 8.89H8.22L7.13 10.9L7.09 10.96L7.06 11.03Q6.8 10.5 6.5 9.96 6.25 9.43 5.97 8.89H4.16L6.05 12.08L4 15.28H5.78M13.88 19.5V17H8.25V19.5M13.88 15.75V12.63H12V15.75M13.88 11.38V8.25H12V11.38M13.88 7V4.5H8.25V7M20.75 19.5V17H15.13V19.5M20.75 15.75V12.63H15.13V15.75M20.75 11.38V8.25H15.13V11.38M20.75 7V4.5H15.13V7Z"
          />
        </svg>
      </label>{" "}
      <ModalLoader
        show={loading || isReadingExcel || isGettingTimesheet}
        title="Please wait, processing excel data..."
      />
    </>
  );
}
