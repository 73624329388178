import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import Datepicker from "react-datetime";
import moment from "moment";
import Select from "react-select";

import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { URLS } from "../urls";
import { toast } from "react-toastify";
import ModalLoadear from "../ModalLoader";
import { branch_id } from "../../utils";

import "../../../src/assets/css/ga-styles.css";

function Promotion(props) {
  const [employees, setEmployees] = useState([]);
  const [mapEmployees, setMapEmployees] = useState([]);
  const [promotion, setPromotion] = useState({
    employeeId: "",
    promotionFrom: "",
    promotionTo: "",
    promotionDate: "",
    promotionFromText: "",
    departmentId: "",
    branch_id,
  });
  const [jobGrades, setJobGrade] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [promotionIdToDelete, setPromotionIdToDelete] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getJobGrades();
    getPromotions();
    return () => {};
  }, []);

  const getEmployeeGrade = (userId) => {
    const employee = employees.find((employee) => employee.userId === userId);

    // console.log(userId);
    // console.log(employee?.jobInfo);
    setPromotion({
      ...promotion,
      employeeId: userId,
      promotionFrom: employee?.jobInfo?.grade?._id,
      promotionFromText: employee?.jobInfo?.grade?.name,
      departmentId: employee?.jobInfo?.department?._id,
    });
  };

  const getPromotions = () => {
    setLoading(true);
    const token = "dummy token";
    fetch(`${URLS.backendPromotion}/get-promotions/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setPromotions(result.promotions);
        setLoading(false);
        // setJobGrade([...result.jobGrades]);
      })
      .catch((err) => {
        setLoading(false);
        toast("Unable to get Job Grades, please refresh your browser", {
          type: "error",
        });
      });
  };

  const getJobGrades = () => {
    setLoading(true);
    const token = "dummy token";
    fetch(`${URLS.backendJobGrades}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setJobGrade([...result.jobGrades]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast("Unable to get Job Grades, please refresh your browser", {
          type: "error",
        });
      });
  };

  const getEmployees = () => {
    // setLoading(true);
    const token = "dummy token";
    fetch(`${URLS.backendEmployees}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        const date = new Date();

        const emp = result.employees.map((em) => ({
          label: `${
            em.personalInfo.name.first ? em.personalInfo.name.first : ""
          } ${em.personalInfo.name.middle ? em.personalInfo.name.middle : ""} ${
            em.personalInfo.name.last ? em.personalInfo.name.last : ""
          }`,
          value: em.userId,
        }));

        setEmployees(result.employees);
        setMapEmployees(emp);
        setPromotion({
          ...promotion,
          /*promotionFrom: result.employees[0].jobInfo?.grade?._id,
          promotionFromText: result.employees[0].jobInfo?.grade?.name,
         
          departmentId: result.employees[0].jobInfo?.department,
          employeeId: result.employees[0].userId,
          */

          promotionTo: result.employees[0].jobInfo?.grade?._id,
          promotionDate: moment(date).format("DD/MM/YYYY"),
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const setPromotionToDelete = (id) => {};

  const savePromotion = (e) => {
    e.preventDefault(e);
    setLoading(true);
    const token = "dummy token";
    fetch(`${URLS.backendPromotion}/save-promotion`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ promotion }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          // console.log(result.promotion);
          setPromotions([...promotions, result.promotion]);
          window.$("#add_promotion").modal("toggle");
          toast("Employee Promoted", {
            type: "success",
          });
        } else {
          throw Error();
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast("Unable to save promotion", {
          type: "error",
        });
      });
  };

  const deletePromotion = () => {
    window.$("#delete_promotion").modal("toggle");
    const token = "dummy token";
    setLoading(true);
    fetch(`${URLS.backendPromotion}/delete-promotion`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ promotionId: promotionIdToDelete }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          const removedDeleted = promotions.filter(
            (promotion) => promotion._id !== promotionIdToDelete
          );
          setPromotions(removedDeleted);
          toast("Promotion deleted", {
            type: "success",
          });
        } else {
          throw Error();
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast("Unable to delete promotion", {
          type: "error",
        });
      });
  };

  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>InvexERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Page Title -->*/}
            {/*<!-- Page Title -->*/}
            <div className="row">
              <div className="col">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Promotion</BreadcrumbItem>
                </Breadcrumb>
                {/* <h4 className="page-title">Employee</h4> */}
              </div>
              <div className="col-auto text-right float-right ml-auto m-b-30">
                <button
                  className="btn add-btn"
                  data-toggle="modal"
                  data-target="#add_promotion"
                  onClick={() => getEmployees()}
                >
                  <i className="fa fa-plus"></i>Add Promotion
                </button>
              </div>
            </div>
            {/*<!-- /Page Title -->*/}
            {/*<!-- /Page Title -->*/}

            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  {/*<!-- Promotion Table -->*/}
                  {
                    <table className="table table-striped custom-table mb-0 datatable">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Promoted Employee </th>
                          <th>Department</th>
                          <th>Promotion Designation From </th>
                          <th>Promotion Designation To </th>
                          <th>Promotion Date </th>
                          <th className="text-right">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {promotions.map((promotion, index) => {
                          const { employee, promotedFrom, promotedTo } =
                            promotion;
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <h2 className="table-avatar blue-link">
                                  <Link
                                    to={`profile/${employee._id}`}
                                    className="avatar"
                                  >
                                    <img
                                      alt=""
                                      src="assets/img/profiles/avatar-02.jpg"
                                    />
                                  </Link>
                                  <Link to={`profile/${employee._id}`}>
                                    {employee.personalInfo.name.first +
                                      " " +
                                      employee.personalInfo.name.last}
                                  </Link>
                                </h2>
                              </td>
                              <td>{promotion?.department?.name}</td>
                              <td>{promotedFrom?.name}</td>
                              <td>{promotedTo?.name}</td>
                              <td>{promotion?.promotionDate}</td>
                              <td className="text-right">
                                <div className="dropdown dropdown-action p-cursor">
                                  <div
                                    className="action-icon dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="material-icons">more_vert</i>
                                  </div>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    {/*<a
                                      className="dropdown-item"
                                      href="#"
                                      data-toggle="modal"
                                      data-target="#edit_promotion"
                                    >
                                      <i className="fa fa-pencil m-r-5"></i>{" "}
                                      Edit
                                    </a>
																		*/}
                                    <div
                                      className="dropdown-item"
                                      data-toggle="modal"
                                      data-target="#delete_promotion"
                                      onClick={() => {
                                        setPromotionIdToDelete(promotion._id);
                                      }}
                                    >
                                      <i className="fa fa-trash-o m-r-5"></i>{" "}
                                      Delete
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  }
                  {/*<!-- /Promotion Table --> */}
                </div>
              </div>
            </div>
          </div>
          {/*<!-- /Page Content -->*/}

          {/*<!-- Add Promotion Modal -->*/}
          <div
            id="add_promotion"
            className="modal custom-modal fade"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Add Promotion</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form onSubmit={(e) => savePromotion(e)}>
                    <div className="form-group">
                      <label>
                        Promotion For <span className="text-danger">*</span>
                      </label>
                      {mapEmployees && (
                        <Select
                          closeMenuOnSelect={true}
                          isSearchable
                          required
                          classNamePrefix="form-control"
                          value={mapEmployees?.find(
                            (el) => el.value === promotion?.employeeId
                          )}
                          onChange={(selected) => {
                            if (!selected.value) return;
                            setPromotion({
                              ...promotion,
                              employeeId: selected.value,
                            });

                            getEmployeeGrade(selected.value);
                          }}
                          options={mapEmployees}
                        />
                      )}
                    </div>

                    <div className="form-group">
                      <label>
                        Promotion From <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control d-none"
                        required
                        value={promotion.promotionFrom || ""}
                        readOnly
                        name="promotionFrom"
                      />
                      <input
                        className="form-control"
                        value={promotion.promotionFromText || ""}
                        readOnly
                        name="promotionFromText"
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Promotion To <span className="text-danger">*</span>
                      </label>
                      {jobGrades && (
                        <select
                          className="form-control"
                          name="promotionTo"
                          required
                          value={promotion.promotionTo}
                          onChange={(e) => {
                            setPromotion({
                              ...promotion,
                              [e.target.name]: e.target.value,
                            });
                            // getEmployeeGrade(e.target.value);
                          }}
                        >
                          <option value="">Select Promotion</option>
                          {jobGrades.map((grade, index) => {
                            return (
                              <option key={index} value={grade._id}>
                                {grade.name}
                              </option>
                            );
                          })}
                        </select>
                      )}
                      {/*<select className="select">
                          <option>Web Developer</option>
                          <option>Web Designer</option>
                          <option>SEO Analyst</option>
                        </select>
									*/}
                    </div>
                    <div className="form-group">
                      <label>
                        Promotion Date <span className="text-danger">*</span>
                      </label>
                      <div classname="datePicker position-absolute">
                        {/*<i
                            class="fa fa-calendar fa-xs calendar-icon w-auto h-auto"
                            aria-hidden="true"
                          ></i>
													*/}
                        <Datepicker
                          value={promotion.promotionDate}
                          onChange={(e) => {
                            setPromotion({
                              ...promotion,
                              promotionDate: moment(e._d).format("DD/MM/YYYY"),
                            });
                          }}
                          closeOnSelect={true}
                        />
                      </div>
                    </div>
                    <div className="submit-section">
                      <button className="btn add-btn submit-btn">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/*<!-- /Add Promotion Modal -->*/}

          {/*<!-- Add Promotion Modal -->*/}
          <div
            id="edit_promotion"
            className="modal custom-modal fade"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Edit Promotion</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="form-group">
                      <label>
                        Promotion For <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        value="John Doe"
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Promotion From <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        value="Web Developer"
                        readonly
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Promotion To <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        value="Web Developer"
                        readonly
                      />
                    </div>

                    {/*<div className="form-group">
                        <label>
                          Promotion Date <span className="text-danger">*</span>
                        </label>

                        <div classname="datePicker">
                          <i
                            class="fa fa-calendar fa-xs calendar-icon"
                            aria-hidden="true"
                          ></i>
                          <Datepicker value="" closeOnSelect={true} />
                        </div>
                      </div>
											*/}

                    <div className="submit-section">
                      <button className="btn btn-primary submit-btn">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/*<!-- /Edit Promotion Modal -->*/}

          {/*<!-- Delete Promotion Modal -->*/}
          <div
            className="modal custom-modal fade"
            id="delete_promotion"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="form-header">
                    <h3>Delete Promotion</h3>
                    <p>Are you sure want to delete?</p>
                  </div>
                  <div className="modal-btn delete-action p-cursor">
                    <div className="row">
                      <div className="col-6">
                        <div
                          className="btn btn-primary continue-btn"
                          onClick={() => deletePromotion()}
                        >
                          Delete
                        </div>
                      </div>
                      <div className="col-6">
                        <div
                          data-dismiss="modal"
                          className="btn btn-primary cancel-btn"
                        >
                          Cancel
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ModalLoadear show={loading} />
          </div>
          {/*<!-- /Delete Promotion Modal -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
    </body>
  );
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Promotion));
