function addAllBonus(bonus) {
  return {
    type: "ADD_BONUS",
    payload: bonus,
  };
}

function addBonus(bonus) {
  return {
    type: "ADD_BONU",
    payload: bonus,
  };
}

function deleteBonus(slug) {
  return {
    type: "DELETE_BONUS",
    payload: slug,
  };
}

export { addAllBonus, addBonus, deleteBonus };
