import React from "react";
import { TabPane, Row } from "reactstrap";
import classnames from "classnames";
import currency from "currency.js";

const DeductionsTableReport = ({
  activeTab,
  deductions,
  payroll,
  groupName,
  IsGroup,
  checkActive,
  calculatePensionBal,
}) => {
  return (
    <TabPane tabId={activeTab}>
      <Row>
        <div className="col-md-12 w-100">
          <div className="table-responsive">
            <table className="table table-striped custom-table mb-0 datatable">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  {checkActive() && (
                    <>
                      <th>Pension Admin</th>
                      <th>Pension Number</th>
                    </>
                  )}

                  {IsGroup
                    ? groupName?.map((name, i) => {
                        return (
                          <th
                            className={classnames({
                              "d-none": activeTab !== name,
                            })}
                            key={i}
                          >
                            <span className="text-capitalize">
                              {checkActive() ? "Employee Contribution" : name}
                            </span>
                          </th>
                        );
                      })
                    : deductions?.map((setting, i) => {
                        return (
                          <th
                            className={classnames({
                              "d-none": activeTab !== setting.slug,
                            })}
                            key={i}
                          >
                            <span className="text-capitalize">
                              {activeTab === "pension"
                                ? "Employee Contribution"
                                : setting.name}
                            </span>
                          </th>
                        );
                      })}
                  {checkActive() && (
                    <>
                      <th>Employer Contribution</th>
                      <th>Total</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {payroll.map((employee, index) => {
                  return (
                    <tr key={index}>
                      <th>{index + 1}</th>
                      <th>
                        {employee.user?.personalInfo?.name.first +
                          " " +
                          employee.user?.personalInfo?.name.last}
                      </th>
                      {checkActive() && (
                        <>
                          <th>
                            {employee.user?.bankDetails?.pensionAdmin ?? "..."}
                          </th>
                          <th>
                            {employee.user?.bankDetails?.pensionNumber ?? "..."}
                          </th>
                        </>
                      )}
                      {IsGroup
                        ? groupName?.map((names, i) => {
                            return (
                              <th
                                key={i}
                                className={classnames({
                                  "d-none": activeTab !== names,
                                })}
                              >
                                {currency(
                                  employee.deductions
                                    .filter((el) => el.groupName === names)
                                    .map((el) => el.total)
                                    .reduce((a, b) => a + b, 0),
                                  {
                                    symbol: "",
                                  }
                                ).format()}
                              </th>
                            );
                          })
                        : employee.deductions.map((deduction, index) => {
                            return (
                              <th
                                key={index}
                                className={classnames({
                                  "d-none": activeTab !== deduction.slug,
                                })}
                              >
                                {currency(deduction.total, {
                                  symbol: "",
                                }).format()}
                              </th>
                            );
                          })}
                      {checkActive() &&
                        employee.deductions
                          .filter((el) => el.slug === "company-pension")
                          .map((deduction, index) => {
                            return (
                              <th key={index}>
                                {currency(deduction.total, {
                                  symbol: "",
                                }).format()}
                              </th>
                            );
                          })}

                      {checkActive() && (
                        <th>
                          {IsGroup
                            ? calculatePensionBal({ employee })
                            : employee.penTotal}
                        </th>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Row>
    </TabPane>
  );
  //   });
};

export default DeductionsTableReport;
