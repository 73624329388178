import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { CSVLink } from "react-csv";
import currency from "currency.js";
import moment from "moment";
import { Dropdown } from "react-bootstrap";

import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { URLS } from "../urls";
import { Row } from "reactstrap";
import Loader from "../Loader";
import { branch_id, allMonths, allBanks, getQueryParams } from "../../utils";
import { PDFIcon, ExcelIcon, ExportIcon } from "../icons";
import { useDownloadExcel } from "../useDownloadExcel";
import SentToAccount from "./SendToAccount";
import TableComponent from "../TableComponent";
// import { isFunction } from "chart.js/helpers";

function SalarySummary() {
  const query = getQueryParams();

  const date = new Date();
  const [payrollDate, setPayrollDate] = useState({
    payrollMonth: query?.month ? parseInt(query?.month) : date.getMonth() + 1,
    payrollYear: date.getFullYear(),
  });

  const [isLoading, setIsLoading] = useState(false);

  const [payroll, setPayroll] = useState([]);
  const [totalNetpay, setTotalNetPay] = useState(0);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [salaryDetails, setSalaryDetails] = useState([]);
  const [bank, setBank] = useState("");
  const [employeeType, setEmployeeType] = useState("All");

  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  useEffect(() => {
    getPayroll();
    getSalarydetails(payrollDate.payrollMonth, payrollDate.payrollYear);
    return () => {};
  }, [payrollDate, employeeType]);

  async function getPayroll() {
    try {
      setIsLoading(true);
      const token = "dummy token";
      const payrollResonse = await fetch(
        `${URLS.backendPayroll}/get-payroll/${branch_id}?payrollMonth=${payrollDate.payrollMonth}&payrollYear=${payrollDate.payrollYear}&employeeType=${employeeType}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      const payrollResult = await payrollResonse.json();
      const { payroll } = payrollResult;
      if (payroll.length) {
        payroll.map((el) => {
          setTotalNetPay((g) => currency(g).add(el.netPay));
        });
        // setEmployeeType(payroll[0].employeeType);
        setPayroll(payroll);
      } else {
        setPayroll([]);
        setTotalNetPay(0);
        // setEmployeeType("All");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  const getSalarydetails = async (month, year) => {
    const token = "dummy token";
    fetch(
      `${URLS.backendSalary}/single/${month}/${year}/${branch_id}?employeeType=${employeeType}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        setSalaryDetails(result.salary);
      });
  };

  const onDownloadExcelData = async () => {
    let normalExData = payroll.map((employee, index) => [
      index + 1,
      employee.netPay,
      `=""${employee.debitAccountNo}""`,
      `=""${employee.debitBankCode}"`,
      bank,
      `=""${employee.creditAccountNo}""`,
      `=""${employee.creditBankCode}""`,
      employee.user?.personalInfo?.name.first +
        " " +
        employee.user?.personalInfo?.name.last,
      new Date(employee.createdAt).toDateString(),
      `${allMonths[employee.payrollMonth - 1]} ${employee.payrollYear} Salary`,
    ]);

    const firstBankExData = payroll.map((employee, index) => [
      `=""${employee.debitAccountNo}""`,
      `=""${employee.creditAccountNo}""`,
      `=""${employee.creditBankCode}""`,
      employee.user?.personalInfo?.name.first +
        " " +
        employee.user?.personalInfo?.name.last,
      `${allMonths[employee.payrollMonth - 1]} ${employee.payrollYear} Salary`,
      employee.netPay,
    ]);

    let normalHead = [
      "No",
      "Net Amount",
      "Debit Account No",
      "Debit Bank Code",
      "Debit Bank",
      "Credit Account No",
      "Credit Bank Code",
      "Beneficiary",
      "Payment Date",
      "Narration",
    ];

    const FirstBankHead = [
      "DebitAccountNo",
      "CreditAccountNo",
      "CreditBankCode",
      "BeneficiaryName",
      "Narration",
      "Amount",
    ];

    if (bank === "First Bank") {
      normalExData = firstBankExData;
      normalHead = FirstBankHead;
    }

    const date = `Date Prepared: ${moment(new Date()).format(
      "MMMM DD yyyy k:mm:ss z"
    )}`;

    const exData = [
      // [company],
      ["Payment History Report"],
      [date],
      [""],
      normalHead,
      ...normalExData,
      [""],
      ["Salary Summary"],
      [
        "Total Net Amount",
        currency(totalNetpay, {
          symbol: "",
        }).format(),
      ],
    ];

    // console.log(exData);
    setExcelData(exData);
  };

  const tableHead = () => {
    return (
      <thead>
        <tr>
          <th>#</th>
          <th>Net Amount</th>
          <th>Debit Account No</th>
          <th>Debit Bank Code</th>
          <th>Debit Bank</th>
          <th>Credit Account No</th>
          <th>Credit Bank Code</th>
          <th>Beneficiary</th>
          <th>Payment Date</th>
          <th>Narration</th>
        </tr>
      </thead>
    );
  };

  const FirstBankTableHead = () => {
    return (
      <thead>
        <tr>
          <th>Debit Account No</th>
          <th>Credit Account No</th>
          <th>Credit Bank Code</th>
          <th>Beneficiary</th>
          <th>Narration</th>
          <th>Amount</th>
        </tr>
      </thead>
    );
  };

  const FirstBankTableBodyData = (employee, index) => {
    return (
      <>
        <td>{employee.debitAccountNo}</td>
        <td>{employee.creditAccountNo}</td>
        <td>{employee.creditBankCode}</td>
        <td>
          {employee.user?.personalInfo?.name.first +
            " " +
            employee.user?.personalInfo?.name.last}
        </td>
        <td>
          {`${allMonths[employee.payrollMonth - 1]} 
                                   
                                    ${employee.payrollYear} `}
          Salary
        </td>
        <td>
          {currency(employee.netPay, {
            symbol: "",
          }).format()}
        </td>
      </>
    );
  };

  const tableBodyData = (employee, index) => {
    return (
      <>
        <td>{index + 1}</td>
        <td>
          {currency(employee.netPay, {
            symbol: "",
          }).format()}
        </td>
        <td>{employee.debitAccountNo}</td>
        <td>{employee.debitBankCode}</td>
        <td>{bank}</td>
        <td>{employee.creditAccountNo}</td>
        <td>{employee.creditBankCode}</td>
        <td>
          {employee.user?.personalInfo?.name.first +
            " " +
            employee.user?.personalInfo?.name.last}
        </td>
        <td>{new Date(employee.createdAt).toDateString()}</td>
        <td>
          {`${allMonths[employee.payrollMonth - 1]} 
                                   
                                    ${employee.payrollYear} `}
          Salary
        </td>
      </>
    );
  };

  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>InvexERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Page Title -->*/}
            <div className="row">
              <div className="col-sm-12 col-12 text-capitalize">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Salary Schedule</BreadcrumbItem>
                </Breadcrumb>
              </div>
              <div className="col-sm-5 col-5"></div>
              {/*<div className="col-sm-7 col-7 text-right m-b-30"></div>*/}
            </div>
            {/*<!-- /Page Title -->*/}
            {/*<!-- Content Starts -->*/}
            <div className="mt-3">
              <div className="d-flex justify-content-between my-4">
                <div className="d-flex">
                  <div className="form-inline">
                    <label>Month</label>
                    <select
                      className="form-control mx-2"
                      value={payrollDate.payrollMonth}
                      onChange={(e) => {
                        setPayrollDate({
                          payrollMonth: e.target.value,
                          payrollYear: payrollDate.payrollYear,
                        });
                      }}
                    >
                      {[...Array(12)].map((e, index) => (
                        <option key={index}>{index + 1}</option>
                      ))}
                    </select>
                  </div>

                  <div className="form-inline ml-3">
                    <label>Year</label>
                    <input
                      type="number"
                      style={{ width: "100px" }}
                      className="form-control mx-2"
                      value={payrollDate.payrollYear}
                      onChange={(e) => {
                        setPayrollDate({
                          payrollMonth: payrollDate.payrollMonth,
                          payrollYear: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="form-inline">
                    <select
                      className="form-control"
                      aria-label="Default select example"
                      onChange={(e) => setBank(e.target.value)}
                    >
                      {allBanks.map((el) => (
                        <option value={el.value}>{el.label}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div>
                  <select
                    className="form-control mx-2"
                    aria-label="Default select example"
                    value={employeeType}
                    onChange={(e) => setEmployeeType(e.target.value)}
                  >
                    <option value="All">All</option>
                    <option value="staff">Staff</option>
                    <option value="contract">Contract</option>
                  </select>
                </div>

                <div className="d-flex justify-content-end">
                  {payroll.length > 0 && salaryDetails?.status && (
                    <>
                      {salaryDetails?.paymentsStatus === "Paid" ? (
                        <p className="px-3 py-2 mx-3 rounded fw-bold text-white bg-success">
                          {salaryDetails?.paymentsStatus}
                        </p>
                      ) : (
                        <p
                          className={`px-3 py-2 mx-3 rounded fw-bold text-white ${
                            salaryDetails?.status === "Disapproved"
                              ? "bg-danger"
                              : salaryDetails?.status === "Approved & Closed"
                              ? "bg-success"
                              : salaryDetails?.status === "Pending"
                              ? "bg-warning"
                              : "bg-secondary"
                          }`}
                        >
                          {salaryDetails?.status}
                        </p>
                      )}
                    </>
                  )}

                  {payroll.length > 0 && (
                    <>
                      {salaryDetails?.status !== "Approved & Closed" &&
                        salaryDetails?.status !== "Pending" && (
                          <p
                            className="px-3 py-2 mx-3 rounded fw-bold text-white bg-info p-cursor"
                            data-toggle="modal"
                            data-target="#add_leave"
                          >
                            Request for Payment
                          </p>
                        )}
                    </>
                  )}

                  <div className="mx-4">
                    <CSVLink
                      className="btn print d-none"
                      filename={`SalarySummary(${moment(new Date()).format(
                        "DD-MMM-yyyy hh:mm:ss a"
                      )}).csv`}
                      data={excelData}
                      ref={CSVLinkRef}
                    />

                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        className="print"
                        disabled={isfetchingExcel}
                        bsPrefix=""
                      >
                        <span className="mx-2">Export</span>
                        <ExportIcon color="#008000" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        popperConfig={{
                          strategy: "fixed",
                        }}
                        renderOnMount
                        className="text-center"
                      >
                        <Dropdown.Item
                          as="div"
                          onClick={onDownloadExcelData}
                          className="p-cursor"
                        >
                          Excel <ExcelIcon color="#008000" />
                        </Dropdown.Item>
                        {/* <Dropdown.Item as="div">
                          <a
                            href={`${backendUrl}/api/reports/pdf/sales-analysis?${queryString.stringify(
                              newQuery
                            )}`}
                            target="blank"
                          >
                            PDF
                            <PDFIcon color="#ff0000" />
                          </a>
                        </Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>

              <Row className="m-0">
                {isLoading && <Loader />}
                {payroll.length ? (
                  <div className="col-md-12 w-100">
                    <div className="table-responsive">
                      <TableComponent
                        responsive
                        striped
                        sticky
                        tableHeadsFunction={
                          bank === "First Bank" ? FirstBankTableHead : tableHead
                        }
                        mainDataArray={payroll}
                        tableDataRowFunction={
                          bank === "First Bank"
                            ? FirstBankTableBodyData
                            : tableBodyData
                        }
                        className="product-table text-nowrap"
                      />

                      {/* <table className="table table-striped custom-table mb-0 datatable">
                        <tbody>
                          {payroll.map((employee, index) => {
                            return <tr Key={index}></tr>;
                          })}
                        </tbody>
                      </table> */}
                    </div>
                  </div>
                ) : (
                  <div
                    className="w-100 d-flex justify-content-center align-items-center bg-white"
                    style={{ height: "250px" }}
                  >
                    <p>No Payroll processed</p>
                  </div>
                )}
              </Row>
            </div>

            <div
              className={
                btnSummryActive
                  ? `summaryNew border-top open`
                  : `summaryNew border-top`
              }
            >
              <div className="row justify-content-between mt-4">
                <div className="col">
                  <div className="my-3">
                    <div className="d-flex gap-3 align-items-center mb-4">
                      <h4 className="m-0">Total Net Amount</h4>{" "}
                      <hr className="flex-grow-1 m-0" />
                      {btnSummryActive && (
                        <button
                          onClick={() => setBtnSummaryActive(!btnSummryActive)}
                          className="btn p-0"
                        >
                          {/* <SummaryCloseIcon /> */}
                        </button>
                      )}
                    </div>

                    <div
                      className="gridCont mb-3 three-col-grid"
                      style={{ gridTemplateRows: "unset" }}
                    >
                      {/*  */}
                      <div className="gridChild gridChildBorderLeftBlue">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(totalNetpay, {
                              symbol: "",
                            }).format()}
                          </p>
                          <p className="gridChld2">Total Net Amount</p>
                        </div>
                      </div>
                      {/*  */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Content End -->*/}
          </div>
          {/*<!-- /Page Content -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}

        {payroll.length && (
          <SentToAccount
            payrollDate={payrollDate}
            Amount={currency(totalNetpay, {
              symbol: "",
            }).format()}
            employeeType={employeeType}
          />
        )}
      </div>
      {/*<!-- /Page Wrapper -->*/}
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
    </body>
  );
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
    // deductionSettings: state.deductionSettings.deductions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SalarySummary));
