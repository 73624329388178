import React, { Component } from "react";
import { Col, Form, Row } from "react-bootstrap";
import $ from "jquery";
import { URLS } from "../urls";

export default class AddLocationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: "",
      branches: [
        {
          name: "",
          phone: "",
          email: "",
          apartment: "",
          street: "",
          city: "",
          state: "",
          zip: "",
        },
      ],
    };
  }

  addMoreBranches = () => {
    this.setState({
      branches: this.state.branches.concat({
        name: "",
        phone: "",
        email: "",
        apartment: "",
        street: "",
        city: "",
        state: "",
        zip: "",
      }),
    });
  };

  updateLocationName = (event) => {
    let { location } = this.state;
    location = event?.target?.value;
    this.setState({
      location,
    });
  };

  removeBranch = (inx) => {
    let { branches } = this.state;
    branches = branches.filter((e, i) => i !== inx);
    this.setState({
      branches,
    });
  };

  handleLocationBranches = (idx) => (evt) => {
    const newLocationInfo = this.state.branches.map((info, eIdx) => {
      if (idx !== eIdx) return info;
      let tName = evt.target.name;
      const value = evt.target.value;
      return { ...info, [tName]: value };
    });
    this.setState({ branches: newLocationInfo });
  };

  submitLocation = (e) => {
    e.preventDefault();
    const { location, branches } = this.state;
    const br = branches.filter((el) => el.name !== "");
    const token = "dummy token";
    console.log(location, branches);
    fetch(`${URLS.backendLocations}/add`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ location: { location, branches: br } }),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("location Addition", result);
        this.props.updateLocationsList(result.location);
        // $('#add_location').hide();
        this.props.changeShowLocationState();
      });
  };

  cancelLocation = (e) => {
    e.preventDefault();
    this.props.changeShowLocationState();
    // $('#add_location').hide();
  };

  render() {
    const { location, branches } = this.state;
    return (
      <div
        id="add_location"
        style={
          this.props.isShow
            ? {
                width: "50%",
                position: "fixed",
                left: "50%",
                marginLeft: "-25%",
                top: "20px",
                zIndex: "2000",
                display: "block",
                backgroundColor: "#eaecef",
                // height: '100%',
                borderRadius: "10px",
                overflow: "auto",
              }
            : {
                width: "50%",
                position: "fixed",
                left: "50%",
                marginLeft: "-25%",
                top: "20px",
                zIndex: "2000",
                display: "none",
                backgroundColor: "#eaecef",
                // height: '100%',
                borderRadius: "10px",
              }
        }
      >
        <Form
          onSubmit={this.submitLocation}
          style={{
            // overflowY: "scroll",
            // maxHeight: "100%",
            // padding: '50px 30px',
            borderRadius: "10px",
            height: "80vh",
          }}
        >
          <Form.Group
            as={Row}
            style={{
              backgroundColor: "#5378b2",
              alignItems: "left",
              justifyContent: "left",
              fontSize: "16px",
              color: "#ffffff",
              fontWeight: "bold",
              height: "60px",
              borderRadius: "10px 10px 0 0",
              padding: "10px 15px",
              marginLeft: "0",
              marginRight: "0",
              display: "block",
            }}
          >
            <span
              style={{
                display: "inline-block",
                height: "40px",
                lineHeight: "40px",
                float: "left",
              }}
            >
              Create New Location
            </span>
            <span
              style={{
                display: "inline-block",
                height: "40px",
                float: "right",
                padding: "2px 10px",
              }}
            >
              <button
                className="btn btn-info"
                style={{
                  padding: "2px 10px",
                  background: "#ffffff",
                  color: "#000000",
                  borderRadius: "20px",
                  border: "none",
                }}
                onClick={this.cancelLocation}
              >
                x
              </button>
            </span>
          </Form.Group>
          <Form.Group as={Row} style={{ marginLeft: "0", marginRight: "0" }}>
            <Form.Label style={{ fontSize: "14px" }} column sm={2}>
              Location
            </Form.Label>
            <Col sm={6}>
              <Form.Control
                value={location}
                onChange={this.updateLocationName}
                required
              />
            </Col>
          </Form.Group>

          {branches &&
            branches?.map((el, ind) => (
              <div className="border border-5 mx-3 my-2 position-relative">
                <span
                  onClick={() => this.removeBranch(ind)}
                  className="position-absolute top-0 start-100  badge rounded-pill bg-danger text-white p-cursor"
                >
                  X
                </span>
                <div className="text-center my-2">
                  <h4>Branch</h4>
                </div>

                <Form.Group
                  as={Row}
                  style={{ marginLeft: "0", marginRight: "0" }}
                >
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Name
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      value={el.name}
                      name="name"
                      onChange={this.handleLocationBranches(ind)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  style={{ marginLeft: "0", marginRight: "0" }}
                >
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Phone
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      value={el.phone}
                      name="phone"
                      onChange={this.handleLocationBranches(ind)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  style={{ marginLeft: "0", marginRight: "0" }}
                >
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Email
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      value={el.email}
                      name="email"
                      onChange={this.handleLocationBranches(ind)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  style={{ marginLeft: "0", marginRight: "0" }}
                >
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Address
                  </Form.Label>
                  <Col sm={10}>
                    <Row>
                      <Col sm={6}>
                        <Form.Control
                          placeholder="Apartment"
                          value={el.apartment}
                          name="apartment"
                          onChange={this.handleLocationBranches(ind)}
                        />
                      </Col>
                      <Col sm={6}>
                        <Form.Control
                          placeholder="Street"
                          value={el.street}
                          name="street"
                          onChange={this.handleLocationBranches(ind)}
                        />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "10px" }}>
                      <Col sm={4}>
                        <Form.Control
                          placeholder="City"
                          value={el.city}
                          name="city"
                          onChange={this.handleLocationBranches(ind)}
                        />
                      </Col>
                      <Col sm={4}>
                        <Form.Control
                          placeholder="State"
                          value={el.state}
                          name="state"
                          onChange={this.handleLocationBranches(ind)}
                        />
                      </Col>
                      <Col sm={4}>
                        <Form.Control
                          placeholder="Zip"
                          value={el.zip}
                          name="zip"
                          onChange={this.handleLocationBranches(ind)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>
              </div>
            ))}

          <div className="mx-4" onClick={this.addMoreBranches}>
            <div className="btn btn-primary">
              <i className="fa fa-plus-circle" /> Add More
            </div>
          </div>

          <Form.Group
            as={Row}
            style={{
              marginLeft: "0",
              marginRight: "0",
              paddingRight: "15px",
              justifyContent: "flex-end",
            }}
            className="pb-3"
          >
            <button
              style={{
                borderRadius: "7px",
                backgroundColor: "#5378b2",
                border: "none",
              }}
              className="btn btn-primary submit-btn"
            >
              Create Now
            </button>
          </Form.Group>
        </Form>
      </div>
    );
  }
}
