import React from "react";
import { URLS } from "../urls";
import { Modal, Row, Form, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import DatePicker from "react-datetime";
import moment from "moment";

export const EditTermination = ({
  show,
  handleClose,
  terminationDataToEdit,
  terminations,
  setTerminations,
  setTerminationDataToEdit,
}) => {
  const handleTerminationEditSubmit = (e) => {
    e.preventDefault();
    const token = "dummy token";
    fetch(`${URLS.backendTermination}/edit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ terminationDataToEdit }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          const foundIndex = terminations.findIndex(
            (termination) =>
              termination?.employeeId === result.jobGrade.employeeId
          );
          terminations[foundIndex] = result.jobGrade;
          setTerminations([...terminations]);
          handleClose();
          toast("Termination edited", {
            type: "success",
          });
        } else {
          throw Error();
        }
      })
      .catch((err) => {
        console.log(err);
        toast("Unable to submit", {
          type: "error",
        });
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby=""
      centered
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Termination</Modal.Title>
      </Modal.Header>

      <div className="modal-body">
        <form onSubmit={(e) => handleTerminationEditSubmit(e)}>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Employee</Form.Label>
                <Form.Control
                  value={terminationDataToEdit.employeeName}
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group>
            <Form.Label>
              Termination Type <span className="text-danger">*</span>
            </Form.Label>
            <Row>
              {terminationDataToEdit.terminationType.map((type, index) => {
                return (
                  <Col md="6" key={index} className="mb-2 d-flex">
                    <select
                      value={type}
                      className="form-control"
                      required
                      onChange={(e) => {
                        const terminationType =
                          terminationDataToEdit.terminationType;
                        terminationType[index] = e.target.value;
                        setTerminationDataToEdit({
                          ...terminationDataToEdit,
                          terminationType,
                        });
                      }}
                    >
                      <option value="">Select Termination type</option>
                      <option value="misconduct">Misconduct</option>
                      <option value="abuse">Abuse</option>
                      <option value="others">Others</option>
                    </select>
                    <span
                      className="m-3"
                      title="remove"
                      onClick={() => {
                        if (terminationDataToEdit.terminationType.length > 1) {
                          const terminationType =
                            terminationDataToEdit.terminationType;
                          terminationType.splice(index, 1);
                          setTerminationDataToEdit({
                            ...terminationDataToEdit,
                            terminationType,
                          });
                        }
                      }}
                    >
                      <i className="fa fa-times"></i>
                    </span>
                  </Col>
                );
              })}
            </Row>
            <div
              className="btn btn-primary p-cursor"
              onClick={() => {
                setTerminationDataToEdit({
                  ...terminationDataToEdit,
                  terminationType: [
                    ...terminationDataToEdit.terminationType,
                    "",
                  ],
                });
              }}
            >
              <i className="fa fa-plus"></i> Add
            </div>
          </Form.Group>

          {/*  */}

          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  Termination Date <span className="text-danger">*</span>
                </Form.Label>
                <DatePicker
                  id={"terminationDate"}
                  value={terminationDataToEdit.terminationDate}
                  onChange={(e) => {
                    setTerminationDataToEdit({
                      ...terminationDataToEdit,
                      terminationDate: moment(e._d).format("DD/MM/YYYY"),
                    });
                  }}
                  closeOnSelect={true}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  Notice Date <span className="text-danger">*</span>
                </Form.Label>
                <DatePicker
                  id={"noticeDate"}
                  value={terminationDataToEdit.noticeDate}
                  onChange={(e) => {
                    setTerminationDataToEdit({
                      ...terminationDataToEdit,
                      noticeDate: moment(e._d).format("DD/MM/YYYY"),
                    });
                  }}
                  closeOnSelect={true}
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3">
            <Form.Label>
              <span className="text-danger">*</span> Reason
            </Form.Label>
            <Form.Control
              as="textarea"
              name="reason"
              value={terminationDataToEdit.reason}
              onChange={(e) => {
                setTerminationDataToEdit({
                  ...terminationDataToEdit,
                  reason: e.target.value,
                });
              }}
              rows={4}
              placeholder="..."
              required
            />
          </Form.Group>

          <div className="submit-section">
            <button className="btn btn-primary submit-btn">Submit</button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
