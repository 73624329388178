import React from "react";
import { URLS } from "../urls";
import moment from "moment";
export default class ApproveLeaveModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonText: "Submit",
      isWaiting: false,
      appliedLeave: null,
      employee: null,
    };
  }
  componentWillReceiveProps(props) {
    if (props.appliedLeave)
      this.setState({
        appliedLeave: props.appliedLeave,
      });
    if (props.employee)
      this.setState({
        employee: props.employee,
      });
  }

  approveLeave = (e) => {
    e.preventDefault();
    this.setState({ buttonText: "Submitting ...", isWaiting: true });
    const { appliedLeave, employee } = this.state;
    appliedLeave.approvedDate = moment(new Date());
    appliedLeave.approvedBy = employee;
    appliedLeave.status = "Approved";
    appliedLeave.to = moment(appliedLeave.to, "DD/MM/YYYY");
    appliedLeave.from = moment(appliedLeave.from, "DD/MM/YYYY");
    // console.log("YOOY", appliedLeave);
    const token = "dummy token";
    fetch(`${URLS.backendApplyLeaves}/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ applyLeave: appliedLeave }),
    }).then((res) => {
      if (res.ok) {
        res.json().then((result) => {
          setTimeout(() => {
            window.$("#approve_leave").modal("toggle");
            this.setState({ buttonText: "Submit", isWaiting: false });
            this.props.updateAppliedLeavesList(result.applyLeave);
          }, 500);
        });
      } else {
        res.json().then((responseJson) => {
          console.log(responseJson);
          alert("Error occurred, Check the form again.");
          this.setState({ buttonText: "Submit", isWaiting: false });
        });
      }
    });
  };

  render() {
    return (
      <div className="modal custom-modal fade" id="approve_leave" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="form-header">
                <h3>Leave Approve</h3>
                <p>Are you sure want to approve for this leave?</p>
              </div>
              <div className="modal-btn delete-action">
                <div className="row">
                  <div className="col-6">
                    <a
                      href="javaScript:void(0);"
                      className="btn btn-primary continue-btn"
                      onClick={this.approveLeave.bind(this)}
                    >
                      Approve
                    </a>
                  </div>
                  <div className="col-6">
                    <a
                      href="javaScript:void(0);"
                      data-dismiss="modal"
                      className="btn btn-primary cancel-btn"
                    >
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
