const BonusReducer = (
  state = {
    bonus: [],
  },
  action
) => {
  switch (action.type) {
    case "ADD_BONUS":
      return { bonus: action.payload };

    case "DELETE_BONUS":
      const slug = action.payload;
      let bonus = state.bonus;
      bonus = bonus.filter((deduction) => deduction.slug !== slug);
      return {
        ...state,
        bonus,
      };

    case "ADD_BONU":
      const states = [...state.bonus].filter(
        (el) => el._id !== action.payload._id
      );
      return {
        ...state,
        bonus: [...states, action.payload],
      };

    default:
      return state;
  }
};

export default BonusReducer;
