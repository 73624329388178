import React, { Component } from 'react';
import { URLS } from '../urls';
import {Link} from 'react-router-dom';
export default class Error404 extends Component {
	render() {
		return (
            <div style={{textAlign:'center', paddingTop:'100px'}}>
                <h1>Page not found</h1>
                <button className='btn btn-default'><Link to={URLS.login}>Go to login</Link></button>
            </div>
        )
    }
}