import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Helmet } from "react-helmet";
import Cookies from "universal-cookie";
import UserTopbar from "../user-topbar/UserTopbar";
import UserSidebar from "../user-sidebar/UserSidebar";
import { URLS } from "../../urls";
import Team from "./Team";

class Teams extends React.Component {
  constructor(props) {
    super(props);
    this.updateTeamsList = this.updateTeamsList.bind(this);
    this.state = {
      teams: [], //update this whenever you add/update team in backend.
      editTeam: "", // id or index of the team which is to be edited
      employeesList: [],
      projects: {},
      tasks: {},
    };
  }

  editTeam = (_id) => {
    this.setState({ editTeam: _id });
  };

  getEditTeamDataFromId = (id) => {
    const { teams } = this.state;
    return teams.find((item) => id === item._id);
  };

  componentWillMount() {
    const token = "dummy token";
    const cookies = new Cookies();
    const id = cookies.get("userId");
    fetch(`${URLS.backendTeams}/getByUserId/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        if (result.teams) {
          const { projects, tasks } = this.state;
          const teams = result.teams;
          teams.forEach((team) => {
            projects[team._id] = { open: 0, completed: 0 };
            tasks[team._id] = { open: 0, completed: 0 };
          });
          this.setState({
            teams,
            tasks,
            projects,
          });
          teams.forEach((team) => {
            this.fetchProjectsByTeamId(team._id);
            this.fetchTasksByTeamId(team._id);
          });
        }
      });

    fetch(`${URLS.backendEmployees}/getEmployeesForTeam`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          employeesList: result.employees,
        });
      });
  }
  fetchProjectsByTeamId = (id) => {
    const token = "dummy token";
    fetch(`${URLS.backendProjects}/getProjectsByTeamId/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        const { projects } = this.state;
        projects[id]["open"] = result.projects.length;
        this.setState({
          projects,
        });
      });
  };
  fetchTasksByTeamId = (id) => {
    const token = "dummy token";
    fetch(`${URLS.backendTasks}/getTasksByTeamId/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        const { tasks } = this.state;
        const allTasks = result.tasks;
        var open = 0,
          completed = 0;
        if (allTasks) {
          allTasks.forEach((task) => {
            if (task.completeDate && task.status === "COMPLETE") completed += 1;
            else open += 1;
          });
        }
        tasks[id]["open"] = open;
        tasks[id]["completed"] = completed;
        this.setState({ tasks });
      });
  };

  updateTeamsList = (team) => {
    let { teams, tasks, projects } = this.state;
    const id = team && team._id;
    if (id) {
      let isExists = false;
      for (let index = teams.length - 1; index >= 0; --index) {
        if (teams[index]._id === id) {
          teams.splice(index, 1);
          teams.splice(index, 0, team);
          isExists = true;
          break;
        }
      }
      if (isExists) this.setState({ teams: teams });
      else {
        tasks[team._id] = { open: 0, completed: 0 };
        projects[team._id] = { open: 0, completed: 0 };
        this.setState({ teams: teams.concat(team), tasks, projects });
      }
    }
  };

  render() {
    const { teams, editTeam, employeesList, tasks, projects } = this.state;
    const editTeamData = this.getEditTeamDataFromId(editTeam);
    return (
      <body>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex, nofollow"></meta>
          <title>InvexERP</title>
        </Helmet>
        <div className="main-wrapper">
          <UserTopbar />
          <UserSidebar />
          {/*<!-- Page Wrapper -->*/}
          <div className="page-wrapper">
            {/*<!-- Page Content -->*/}
            <div className="content container-fluid">
              {/*<!-- Page Title -->*/}
              <div className="row">
                <div className="col">
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Teams</BreadcrumbItem>
                  </Breadcrumb>
                  {/* <h4 className="page-title">Teams</h4> */}
                </div>
              </div>
              {/*<!-- /Page Title -->*/}

              {/*<!-- Search Filter -->*/}
              <div className="row filter-row">
                <div className="col-sm-6 col-md-3">
                  <div className="form-group form-focus">
                    <input type="text" className="form-control floating" />
                    <label className="focus-label">Team Name</label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3">
                  <div className="form-group form-focus">
                    <input type="text" className="form-control floating" />
                    <label className="focus-label">Employee Name</label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3">
                  <div className="form-group form-focus">
                    <Form.Control as="select">
                      <option>Select Roll</option>
                      <option>Web Developer</option>
                      <option>Web Designer</option>
                      <option>Android Developer</option>
                      <option>Ios Developer</option>
                    </Form.Control>
                    <label className="focus-label">Designation</label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3">
                  <a
                    href="Javascript:void(0);"
                    className="btn btn-success btn-block"
                  >
                    {" "}
                    Search{" "}
                  </a>
                </div>
              </div>
              {/*<!-- Search Filter -->*/}

              <div className="row staff-grid-row">
                {teams.length
                  ? teams.map((team) => {
                      return (
                        <Team
                          team={team}
                          onEdit={this.editTeam.bind(this)}
                          tasks={tasks}
                          projects={projects}
                        />
                      );
                    })
                  : ""}
              </div>
            </div>
            {/*<!-- /Page Content -->*/}

            {/*<!-- Delete Team Modal -->*/}
            <div
              className="modal custom-modal fade"
              id="delete_team"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="form-header">
                      <h3>Delete Team</h3>
                      <p>Are you sure want to delete?</p>
                    </div>
                    <div className="modal-btn delete-action">
                      <div className="row">
                        <div className="col-6">
                          <a
                            href="Javascript:void(0);"
                            className="btn btn-primary continue-btn"
                          >
                            Delete
                          </a>
                        </div>
                        <div className="col-6">
                          <a
                            href="Javascript:void(0);"
                            data-dismiss="modal"
                            className="btn btn-primary cancel-btn"
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Delete Team Modal -->*/}
          </div>
          {/*<!-- /Page Wrapper -->*/}
        </div>
        {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
        <div className="sidebar-overlay" data-reff=""></div>
      </body>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Teams));
