import React from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

export function InforModal(
  { show, handleClose, link, description, sendTitle, secondLink, secondTitle },
  props
) {
  return (
    <Modal
      {...props}
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{description}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <div className="mb-4"></div>

          <Link to={link} className="btn btn-primary text-center">
            {sendTitle ? sendTitle : "Click"}
          </Link>

          {secondLink && (
            <Link to={secondLink} className="btn btn-primary text-center mx-4">
              {secondTitle ? secondTitle : "Click"}
            </Link>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
