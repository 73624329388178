import React from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import { Form, Col, Row, Image, textarea, Popover } from "react-bootstrap";
import moment from "moment";
import { Helmet } from "react-helmet";
import UserTopbar from "../user-topbar/UserTopbar";
import UserSidebar from "../user-sidebar/UserSidebar";
import { URLS } from "../../urls";
const allMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

class UserPolicies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      policies: null,
    };
  }
  componentWillMount() {
    const token = "dummy token";
    fetch(`${URLS.backendPolicies}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          policies: result.policies,
        });
      });
  }
  showDateInFormat = (date) => {
    date = date.split("/");
    return date[0] + " " + allMonths[date[1] - 1] + " " + date[2];
  };

  render() {
    const { policies } = this.state;
    return (
      <body>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex, nofollow"></meta>
          <title>InvexERP</title>
        </Helmet>
        <div className="main-wrapper">
          <UserTopbar />
          <UserSidebar />
          {/*<!-- Page Wrapper -->*/}
          <div className="page-wrapper">
            {/*<!-- Page Content -->*/}
            <div className="content container-fluid">
              {/*<!-- Page Title -->*/}
              <div className="row">
                <div className="col-sm-5 col-5">
                  <h4 className="page-title">Policies</h4>
                </div>
              </div>
              {/*<!-- /Page Title -->*/}

              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table table-striped custom-table mb-0 datatable">
                      <thead>
                        <tr>
                          <th style={{ width: "80px" }}>#</th>
                          <th>Policy Name </th>
                          <th>Department </th>
                          <th>Description </th>
                          <th>Created </th>
                          <th className="text-right">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {policies &&
                          policies.map((policy, index) => {
                            const fileName = policy.policyFile.uniqueName
                              ? policy.policyFile.uniqueName +
                                "." +
                                policy.policyFile.extension
                              : null;
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{policy.policy}</td>
                                <td>{policy.department}</td>
                                <td>{policy.description}</td>
                                <td>{this.showDateInFormat(policy.created)}</td>
                                <td className="text-right">
                                  <div className="dropdown dropdown-action">
                                    <a
                                      href="#"
                                      className="action-icon dropdown-toggle"
                                      data-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="material-icons">
                                        more_vert
                                      </i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      {fileName ? (
                                        <a
                                          className="dropdown-item"
                                          href={
                                            URLS.backendStatic + "/" + fileName
                                          }
                                          target="_blank"
                                        >
                                          <i className="fa fa-download m-r-5"></i>{" "}
                                          Download
                                        </a>
                                      ) : (
                                        <a
                                          className="dropdown-item"
                                          href="JavaScript:void(0);"
                                        >
                                          <i className="fa fa-download m-r-5"></i>{" "}
                                          Download
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Page Content -->*/}
          </div>
          {/*<!-- /Page Wrapper -->*/}
        </div>
        {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
        <div className="sidebar-overlay" data-reff=""></div>
      </body>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserPolicies));
