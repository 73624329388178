import React, { Component } from "react";
import PropTypes from "prop-types";
import "../../assets/css/custom-multiselect-styles.css";
import { URLS } from "../urls.js";
export class Multiselect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      dropDownValue: [],
    };
    this.checkBox = this.checkBox.bind(this);
  }
  componentWillMount() {
      this.setState({
      dropDownValue: this.props.options,
    });
  }
  componentWillReceiveProps(props) {
    let selectedOptions = [];
    if (props.selectedOptions.length) {
      props.options.forEach((option) => {
        if (props.selectedOptions.indexOf(option.id) > -1)
          selectedOptions.push(option);
      });
      this.setState({
        dropDownValue: props.options,
        checked: selectedOptions,
      });
    } else {
      this.setState({
        dropDownValue: props.options,
        checked: selectedOptions,
      });
    }
  }
  removeChip(data) {
    this.checkBox(data, false);
  }
  checkBox(data, condition) {
    let checkedValue = this.state.checked;
    if (condition) {
      checkedValue.push(data);
    } else {
      let index = checkedValue.indexOf(data);
      checkedValue.splice(index, 1);
    }
    this.setState(
      {
        checked: checkedValue,
      },
      () => {
        this.props.onSelectOptions(this.state.checked);
      }
    );
  }
  searchFun(e) {
    if (e.target.value.length !== 0) {
      let enteredValue = e.target.value.toLowerCase();
      let presentValue = this.props.options.filter(function (data) {
        return data.name.toLowerCase().indexOf(enteredValue) > -1;
      });
      this.setState({ dropDownValue: presentValue });
    } else {
      this.setState({ dropDownValue: this.props.options });
    }
  }
  returnChip() {
    const chip = this.state.checked
      ? this.state.checked.map((data, index) => (
          <div className="chip-body" key={index}>
            <p className="chip-text">{data.name}</p>
            <button
              className="chip-close"
              onClick={(e) => this.removeChip(data)}
            >
              &times;
            </button>
          </div>
        ))
      : [];
    return chip;
  }
  returnList() {
    const list = this.state.dropDownValue
      ? this.state.dropDownValue.map((data, index) => (
          <label
            className="container custom-container"
            key={index}
            style={{ padding: 0 }}
          >
            <input
              type="checkbox"
              value={data.value}
              onChange={(e) => this.checkBox(data, e.target.checked)}
              checked={this.state.checked.includes(data) ? true : false}
            />
            <div className="checkdiv" style={{ padding: "5px 20px" }}>
              <div
                style={{
                  display: "inline-block",
                  verticalAlign: "text-bottom",
                }}
              >
                <a href="Javascript:void(0);" title={data.name} class="avatar">
                  <img
                    src={URLS.backendStatic + "/" + data.image}
                    alt={"picokdone"}
                  />
                </a>
              </div>
              <div style={{ display: "inline-block" }}>{data.name}</div>
            </div>
            {/* <span className="checkmark"></span> */}
          </label>
        ))
      : null;
    return list;
  }
  render() {
    return (
      <div className="multiSelect">
        {/* <div className="chip">
                    {this.returnChip()}
                </div> */}
        <input
          type="text"
          name="Search"
          placeholder="Search Data"
          className="input-box"
          onChange={(e) => this.searchFun(e)}
        />
        <div
          className="search-result"
          style={{ backgroundColor: "#fafafa" }}
        >
          <div className="list-result">{this.returnList()}</div>
        </div>
      </div>
    );
  }
}

Multiselect.defaultProps = {
  options: [],
  selectedOptions: [],
};

/** define proptypes including fields which is required */
Multiselect.prototypes = {
  options: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  onSelectOptions: PropTypes.func,
};

export default Multiselect;
