import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Helmet } from "react-helmet";
import Cookies from "universal-cookie";
import UserTopbar from "../user-topbar/UserTopbar";
import UserSidebar from "../user-sidebar/UserSidebar";
import { URLS } from "../../urls";
import moment from "moment";

class UserPerformance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employee: null,
      projects: 0,
      tasks: 0,
      completedTasks: 0,
      completionRate: 0,
    };
  }
  componentWillMount() {
    const cookies = new Cookies();
    const id = cookies.get("userId");
    const token = "dummy token";
    fetch(`${URLS.backendEmployees}/getByUserId/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          employee: result.employee,
        });
        this.fetchProjectsByEmployeeId(result.employee._id);
        this.fetchTasksByEmployeeId(result.employee._id);
      });
  }
  fetchProjectsByEmployeeId = (employeeId) => {
    const token = "dummy token";
    fetch(`${URLS.backendProjects}/getProjectsByEmployeeId/${employeeId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("......result.....", result);
        let { projects } = this.state;
        projects = result.projects.length;
        this.setState({
          projects,
        });
      });
  };
  fetchTasksByEmployeeId = (employeeId) => {
    const token = "dummy token";
    fetch(`${URLS.backendTasks}/tasks/${employeeId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let { tasks, completedTasks, completionRate } = this.state;
        tasks = result.tasks.length;
        const completed = result.tasks.filter((task) => {
          return task.status === "COMPLETE";
        });
        let delay = 0;
        if (completed.length) {
          for (let i = 0; i < completed.length; i++) {
            let item = completed[i];
            const diff = moment(item.completeDate, "DD/MM/YYYY").diff(
              moment(item.dueDate, "DD/MM/YYYY"),
              "days"
            );
            if (diff > 0) delay += diff;
          }
        }
        completedTasks = completed.length;
        completionRate = completed.length
          ? (completed.length / (completed.length + delay * 0.1)) * 100
          : 0;
        this.setState({
          tasks,
          completedTasks,
          completionRate,
        });
      });
  };

  render() {
    const { employee, projects, tasks, completedTasks, completionRate } =
      this.state;
    const employeeId = employee ? employee._id : null;
    const image = employee ? employee?.emergencyInfo?.image : null;
    const progress =
      tasks > 0 ? ((completedTasks * 100) / tasks).toFixed(2) : 0;
    const rate = completionRate ? completionRate.toFixed(2) : 0;
    return (
      <body>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex, nofollow"></meta>
          <title>InvexERP</title>
        </Helmet>
        <div className="main-wrapper">
          <UserTopbar />
          <UserSidebar />
          {/*<!-- Page Wrapper -->*/}
          <div className="page-wrapper">
            {/*<!-- Page Content -->*/}
            <div className="content container-fluid">
              {/*<!-- Page Title -->*/}
              <div className="row">
                <div className="col-sm-12 col-12">
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <Link to={`${URLS.userDashboardProfile}`}>Dashboard</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Performance</BreadcrumbItem>
                  </Breadcrumb>
                </div>
              </div>
              {/*<!-- /Page Title -->*/}

              <div className="row">
                <div className="col-md-12">
                  <div>
                    <table className="table table-striped custom-table mb-0 datatable">
                      <thead>
                        <tr>
                          <th>Employee</th>
                          <th>Projects</th>
                          <th>Tasks</th>
                          <th>Completed Tasks</th>
                          <th>Progress</th>
                          <th>Completion Rate</th>
                          {/* <th className="text-right">Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {employee ? (
                          <tr>
                            <td>
                              <Link to={`${URLS.userDashboardProfile}`}>
                                <span className="avatar">
                                  <img
                                    alt=""
                                    src={
                                      image
                                        ? URLS.backendStatic + "/" + image
                                        : "assets/img/profiles/avatar-09.jpg"
                                    }
                                  />
                                </span>
                                {employee.personalInfo.name.first +
                                  " " +
                                  employee.personalInfo.name.middle +
                                  " " +
                                  employee.personalInfo.name.last}{" "}
                              </Link>
                            </td>
                            <td>{projects}</td>
                            <td>{tasks}</td>
                            <td>{completedTasks}</td>
                            <td>
                              <div className="widget-subbody">
                                <div className="widget-subbody-header">
                                  Progress{" "}
                                  <span class="text-success float-right">
                                    {progress.toString() + "%"}
                                  </span>
                                </div>
                                <div class="progress progress-xs mb-0">
                                  <div
                                    class="progress-bar bg-success"
                                    role="progressbar"
                                    data-toggle="tooltip"
                                    title={progress.toString() + "%"}
                                    style={{ width: progress + "%" }}
                                  ></div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="widget-subbody">
                                <div className="widget-subbody-header">
                                  Rate{" "}
                                  {parseFloat(rate) >= 95 ? (
                                    <span class="text-success float-right">
                                      {rate.toString() + "%"}
                                    </span>
                                  ) : parseFloat(rate) >= 75 ? (
                                    <span class="text-primary float-right">
                                      {rate.toString() + "%"}
                                    </span>
                                  ) : parseFloat(rate) >= 50 ? (
                                    <span class="text-warning float-right">
                                      {rate.toString() + "%"}
                                    </span>
                                  ) : (
                                    <span class="text-danger float-right">
                                      {rate.toString() + "%"}
                                    </span>
                                  )}
                                </div>
                                <div class="progress progress-xs mb-0">
                                  {parseFloat(rate) >= 95 ? (
                                    <div
                                      class="progress-bar bg-success"
                                      role="progressbar"
                                      data-toggle="tooltip"
                                      title={rate.toString() + "%"}
                                      style={{ width: rate + "%" }}
                                    ></div>
                                  ) : parseFloat(rate) >= 75 ? (
                                    <div
                                      class="progress-bar bg-primary"
                                      role="progressbar"
                                      data-toggle="tooltip"
                                      title={rate.toString() + "%"}
                                      style={{ width: rate + "%" }}
                                    ></div>
                                  ) : parseFloat(rate) >= 50 ? (
                                    <div
                                      class="progress-bar bg-warning"
                                      role="progressbar"
                                      data-toggle="tooltip"
                                      title={rate.toString() + "%"}
                                      style={{ width: rate + "%" }}
                                    ></div>
                                  ) : (
                                    <div
                                      class="progress-bar bg-danger"
                                      role="progressbar"
                                      data-toggle="tooltip"
                                      title={rate.toString() + "%"}
                                      style={{ width: rate + "%" }}
                                    ></div>
                                  )}
                                  {/* <div class="progress-bar bg-success" role="progressbar" data-toggle="tooltip" title={rate.toString() + '%'} style={{ width: rate + "%" }}></div> */}
                                </div>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Page Content -->*/}
          </div>
          {/*<!-- /Page Wrapper -->*/}
        </div>
        {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
        <div className="sidebar-overlay" data-reff=""></div>
      </body>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserPerformance));
