import React, { Component } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import $ from 'jquery';
import { URLS } from '../urls';
import Datepicker from 'react-datetime'
import moment from 'moment'
import '../../../src/assets/css/date-picker.css';

export default class EmployeeSkills extends Component {
    constructor(props) {
        super(props);
        this.setStateData = this.setStateData.bind(this);
        this.state = {
            skill: {
                employee: null,
                skillName: '',
                institutionAcquired: '',
                certificateAcquired: '',
                skillDescription: '',
                certificatesUpload: '',
            },
            editSkill: {
                employee: null,
                skillName: '',
                institutionAcquired: '',
                certificateAcquired: '',
                skillDescription: '',
                certificatesUpload: '',
            },
            skills: [],
        }
    };
    componentWillMount() {
        const token = 'dummy token';
        const id = window.location.pathname.split('/')[3];
        fetch(`${URLS.backendSkills}/skills/${id}`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
        }).then(res => res.json())
            .then((result) => {
                this.setState({
                    skills: result.skills,
                });
            });
    }

    updateSkillSkillName = e => {
        let value = e.target.value;
        const { skill } = this.state;
        skill.skillName = value;
        this.setState(
            skill
        )
    };

    updateSkillInstitutionAcquired = e => {
        let value = e.target.value;
        const { skill } = this.state;
        skill.institutionAcquired = value;
        this.setState(
            skill
        )
    };

    updateSkillCertificateAcquired = e => {
        let value = e.target.value;
        const { skill } = this.state;
        skill.certificateAcquired = value;
        this.setState(
            skill
        )
    };

    updateSkillSkillDescription = e => {
        let value = e.target.value;
        const { skill } = this.state;
        skill.skillDescription = value;
        this.setState(
            skill
        )
    };
    updateSkillDateAcquired = e => {
        let value = e.target ? e.target.value : moment(e).format("DD/MM/YYYY");
        const { skill } = this.state;
        skill.dateAcquired = value;
        this.setState(
            skill
        )
    };

    updateSkillCertificateUpload = e => {
        let value = e.target.value;
        const { skill } = this.state;
        skill.certificatesUpload = value;
        this.setState(
            skill
        )
    };
    updateEditSkillSkillName = e => {
        let value = e.target.value;
        const { editSkill } = this.state;
        editSkill.skillName = value;
        this.setState(
            editSkill
        )
    };

    updateEditSkillInstitutionAcquired = e => {
        let value = e.target.value;
        const { editSkill } = this.state;
        editSkill.institutionAcquired = value;
        this.setState(
            editSkill
        )
    };

    updateEditSkillCertificateAcquired = e => {
        let value = e.target.value;
        const { editSkill } = this.state;
        editSkill.certificateAcquired = value;
        this.setState(
            editSkill
        )
    };

    updateEditSkillSkillDescription = e => {
        let value = e.target.value;
        const { editSkill } = this.state;
        editSkill.skillDescription = value;
        this.setState(
            editSkill
        )
    };
    updateEditSkillDateAcquired = e => {
        let value = e.target ? e.target.value : moment(e).format("DD/MM/YYYY");
        const { editSkill } = this.state;
        editSkill.dateAcquired = value;
        this.setState(
            editSkill
        )
    };

    updateEditSkillCertificateUpload = e => {
        let value = e.target.value;
        const { editSkill } = this.state;
        editSkill.certificatesUpload = value;
        this.setState(
            editSkill
        )
    };
    setStateData = idx => evt => {
        const { skills } = this.state;
        this.setState({
            editSkill : skills[idx]
        });
    };
    handleEditSkillsSubmit = e => {
        e.preventDefault();
        const token = 'dummy token';
        const { editSkill } = this.state;
        fetch(`${URLS.backendSkills}/update`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'some-header': 'yes',
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
            body: JSON.stringify({ skill: editSkill }),
        }).then(res => res.json())
            .then((result) => {
                const editSkill = result.skill;
                const { skills } = this.state;
                for (let i = 0; i < skills.length; i++) {
                    if (skills[i]._id === editSkill._id) {
                        skills[i] = editSkill;
                        break;
                    }
                }
                this.setState({
                    skills: skills
                })
            })
            .catch((error) => {
                console.log(error);
            });
    };
    handleSkillsSubmit = e => {
        e.preventDefault();
        const id = window.location.pathname.split('/')[3];
        const token = 'dummy token';
        const { skill } = this.state;
        skill.employee = id;

        fetch(`${URLS.backendSkills}/add`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'some-header': 'yes',
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
            body: JSON.stringify({ skill: skill }),
        }).then(res => res.json())
            .then((result) => {
                const skill = {
                    employee: null,
                    skillName: '',
                    institutionAcquired: '',
                    certificateAcquired: '',
                    dateAcquired: '',
                    skillDescription: '',
                    certificatesUpload: '',
                };
                const { skills } = this.state;
                this.setState({
                    skill: skill,
                    skills: skills.concat(result.skill)
                })
            })
            .catch((error) => {
                console.log(error);
            });
    };

    render() {
        const { skill, skills, editSkill } = this.state;
        return (
            <div className="content container-fluid">
                <div className="row">
                    <div className="col-auto text-right float-right ml-auto m-b-30">
                        <a href="Javascript:void(0);" className="btn add-btn" data-toggle="modal" data-target="#add_employee_skill">
                            <i className="fa fa-plus" /> Add New Skill
                        </a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="table table-striped custom-table mb-0">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Institution Acquired</th>
                                        <th>Certificate Acquired</th>
                                        <th>Description</th>
                                        <th>Date</th>
                                        <th className="text-right">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        skills.length > 0 ? skills.map((empSkill, idx) => {
                                            return (
                                                <tr className="holiday-completed">
                                                    <td>{empSkill.skillName}</td>
                                                    <td>{empSkill.institutionAcquired}</td>
                                                    <td>{empSkill.certificateAcquired}</td>
                                                    <td>{empSkill.skillDescription}</td>
                                                    <td>{empSkill.dateAcquired}</td>
                                                    <td>
                                                        <div className="dropdown profile-action" style={{ position: 'relative' }}>
                                                            <a href="Javascript:void(0);" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                <a className="dropdown-item" href="Javascript:void(0);" data-toggle="modal" data-target="#edit_employee_skill" onClick={this.setStateData(idx)}><i className="fa fa-pencil m-r-5" /> Edit</a>
                                                                <a className="dropdown-item" href="Javascript:void(0);" data-toggle="modal" data-target="#delete_employee"><i className="fa fa-trash-o m-r-5" /> Delete</a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        }) : ''
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div id='edit_employee_skill' className='modal custom-modal fade' role='dialog'>
                    <div className='modal-dialog modal-dialog-centered modal-lg'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h5 className='modal-title'>Edit Skill</h5>
                                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                                    <span aria-hidden='true'>&times;</span>
                                </button>
                            </div>
                            <div className='modal-body'>
                                <form>
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <div className='form-group'>
                                                <label className='col-form-label'>Skill Name <span
                                                    className='text-danger'>*</span></label>
                                                <div className='input-group'>
                                                    <input type='text' className='form-control' value={editSkill.skillName}
                                                        onChange={this.updateEditSkillSkillName} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='form-group'>
                                                <label className='col-form-label'>Institution Acquired</label>
                                                <div className='input-group'>
                                                    <input type='text' className='form-control' value={editSkill.institutionAcquired}
                                                        onChange={this.updateEditSkillInstitutionAcquired} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <div className='form-group'>
                                                <label className='col-form-label'>Certificate Acquired<span
                                                    className='text-danger'>*</span></label>
                                                <div className='input-group'>
                                                    <input type='text' className='form-control' value={editSkill.certificateAcquired}
                                                        onChange={this.updateEditSkillCertificateAcquired} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='form-group'>
                                                <label className='col-form-label'>Date Acquired</label>
                                                <div className='input-group'>
                                                    <div classname='datePicker'>
                                                        <i class="fa fa-calendar fa-xs calendar-icon-2" aria-hidden="true"></i>
                                                        <Datepicker value={editSkill.dateAcquired}
                                                            closeOnSelect={true}
                                                            onChange={this.updateEditSkillDateAcquired} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <div className='form-group'>
                                                <label className='col-form-label'>Skill Description<span
                                                    className='text-danger'>*</span></label>
                                                <div className='input-group'>

                                                    <input type='text' className='form-control' value={editSkill.skillDescription}
                                                        onChange={this.updateEditSkillSkillDescription} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='form-group'>
                                                <label className='col-form-label'>Certificates upload</label>
                                                <div className='input-group'>
                                                    <input type='file' className='form-control'
                                                        onChange={this.updateEditSkillCertificateUpload} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='submit-section'>
                                        <button className='btn btn-primary submit-btn' type='submit' data-toggle='modal'
                                            data-target='#edit_employee_skill'
                                            onClick={this.handleEditSkillsSubmit}>Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='add_employee_skill' className='modal custom-modal fade' role='dialog'>
                    <div className='modal-dialog modal-dialog-centered modal-lg'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h5 className='modal-title'>Add Skill</h5>
                                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                                    <span aria-hidden='true'>&times;</span>
                                </button>
                            </div>
                            <div className='modal-body'>
                                <form>
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <div className='form-group'>
                                                <label className='col-form-label'>Skill Name <span
                                                    className='text-danger'>*</span></label>
                                                <div className='input-group'>
                                                    <input type='text' className='form-control' value={skill.skillName}
                                                        onChange={this.updateSkillSkillName} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='form-group'>
                                                <label className='col-form-label'>Institution Acquired</label>
                                                <div className='input-group'>
                                                    <input type='text' className='form-control' value={skill.institutionAcquired}
                                                        onChange={this.updateSkillInstitutionAcquired} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <div className='form-group'>
                                                <label className='col-form-label'>Certificate Acquired<span
                                                    className='text-danger'>*</span></label>
                                                <div className='input-group'>
                                                    <input type='text' className='form-control' value={skill.certificateAcquired}
                                                        onChange={this.updateSkillCertificateAcquired} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='form-group'>
                                                <label className='col-form-label'>Date Acquired</label>
                                                <div className='input-group'>
                                                    <div classname='datePicker'>
                                                        <i class="fa fa-calendar fa-xs calendar-icon-2" aria-hidden="true"></i>
                                                        <Datepicker value={skill.dateAcquired}
                                                            closeOnSelect={true}
                                                            onChange={this.updateSkillDateAcquired} />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <div className='form-group'>
                                                <label className='col-form-label'>Skill Description<span
                                                    className='text-danger'>*</span></label>
                                                <div className='input-group'>
                                                    <input type='text' className='form-control' value={skill.skillDescription}
                                                        onChange={this.updateSkillSkillDescription} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='form-group'>
                                                <label className='col-form-label'>Certificates upload</label>
                                                <div className='input-group'>
                                                    <input type='file' className='form-control'
                                                        onChange={this.updateSkillCertificateUpload} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='submit-section'>
                                        <button className='btn btn-primary submit-btn' type='submit' data-toggle='modal'
                                            data-target='#add_employee_skill'
                                            onClick={this.handleSkillsSubmit}>Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}