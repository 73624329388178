import React, { Component } from "react";
import { URLS } from "../urls";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import { addAllDeductions } from "../deduction-settings/deductionAction";
import { addAllSettings } from "../settings/settingsAction";
import { NewLogo } from "../../utils";
import Avatar from "../../Avatar";
import { addAllBonus } from "../bonus-settings/bonusAction";
import { addAllAllowance } from "../allowance/AllowanceAction";
import { addAllBenefit } from "../benefit/BenefitAction";
import { DownArrowIcon, NotificationIcon } from "../icons";
// import { HamburgerIcon } from "../../utils";
class Topbar extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      employee: null,
      notifications: [],
      cloudUrl: process.env.REACT_APP_CLOUD_DRIVE_FRONTEND,
      collaborationUrl: process.env.REACT_APP_COLLABORATION_FRONTEND,
    };
  }
  componentWillMount() {
    this._isMounted = true;
    const cookies = new Cookies();
    const id = cookies.get("userId");

    const token = "dummy token";
    fetch(`${URLS.backendEmployees}/getByUserId/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          employee: result.employee,
        });
      });

    fetch(`${URLS.backendNotifications}/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          notifications: result.notifications || [],
        });
      });

    if (this._isMounted) {
      fetch(`${URLS.backendApp}/set-up`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          // console.log(result.allowance);
          this.props.addAllDeductions(result.deductionSettings);
          this.props.addAllSettings(result.settings);
          this.props.addAllBonus(result.bonusSettings);
          this.props.addAllAllowance(result.allowance);
          this.props.addAllBenefit(result.benefit);
        });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getDateDiff = (date) => {
    let createDate = moment(date);
    let now = moment(new Date());
    let diff = now.diff(createDate);
    diff = parseInt(diff / 1000);
    if (diff < 60) {
      return diff + " seconds ago";
    }
    diff = parseInt(diff / 60);
    if (diff < 60) {
      if (diff === 1) return "a min ago";
      else return diff + " mins ago";
    }

    diff = parseInt(diff / 60);
    if (diff < 24) {
      if (diff === 1) return "an hour ago";
      else return diff + " hours ago";
    }

    diff = parseInt(diff / 24);
    if (diff < 30) {
      if (diff === 1) return "a day ago";
      else return diff + " days ago";
    }

    diff = parseInt(diff / 30);
    if (diff === 1) return "a month ago";
    else return diff + " months ago";
  };

  handleLogOut = async () => {
    const cookies = new Cookies();
    const token = cookies.get("token");

    let res = await fetch(URLS.backendUsers + "/logOut", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    res = await res.json();

    if (res?.success) {
      if (process.env.REACT_APP_HAS_INVEX === "true") {
        let des = await fetch(URLS.mainSiteBackend + "/auth/logout", {
          method: "POST",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          body: JSON.stringify({ Staff_ID: "" }),
          credentials: "include",
        });

        await des.json();

        cookies.remove("token", { path: "/" });
        cookies.remove("company_id", { path: "/" });
        cookies.remove("user", { path: "/" });
        // cookies.remove("");
        cookies.remove("isSuperAdmin", { path: "/" });
        cookies.remove("isAdmin", { path: "/" });
        cookies.remove("userId", { path: "/" });
        cookies.remove("isLoggedIn", { path: "/" });
        cookies.remove("isFirstTime", { path: "/" });
        cookies.remove("branch_id", { path: "/" });
        cookies.remove("employee", { path: "/" });
        window.location.href = `${URLS.login}`;
      } else {
        cookies.remove("token", { path: "/" });
        cookies.remove("company_id", { path: "/" });
        cookies.remove("user", { path: "/" });
        // cookies.remove("");
        cookies.remove("isSuperAdmin", { path: "/" });
        cookies.remove("isAdmin", { path: "/" });
        cookies.remove("userId", { path: "/" });
        cookies.remove("isLoggedIn", { path: "/" });
        cookies.remove("isFirstTime", { path: "/" });
        cookies.remove("branch_id", { path: "/" });
        cookies.remove("employee", { path: "/" });
        window.location.href = `${URLS.login}`;
      }
    }
  };

  render() {
    const { employee, notifications, type, cloudUrl, collaborationUrl } =
      this.state;
    const employeeName = employee ? employee.personalInfo.name.first : "";
    const employeeImage =
      employee && employee.emergencyInfo && employee.emergencyInfo.image
        ? URLS.backendStatic + "/" + employee.emergencyInfo.image
        : "";
    return (
      <>
        <div className="header">
          {/* <!-- Logo --> */}
          <div className="header-left">
            {process.env.REACT_APP_HAS_INVEX === "true" ? (
              <a href={`${URLS.mainSite}/dashboard`} className="logo">
                <NewLogo className="logo" />
              </a>
            ) : (
              <Link to={`${URLS.dashboard}`} className="logo">
                <NewLogo className="logo" />
              </Link>
            )}
          </div>
          {/* <!-- /Logo --> */}

          {/* <!-- Header Title --> */}
          <div className="page-title-box d-flex"></div>
          {/* <!-- /Header Title --> */}

          {/* <!-- Header Menu --> */}
          <div className="d-flex justify-content-end align-item-baseline mx-4">
            <ul className="nav user-menu">
              {/* <!-- Notifications --> */}
              <li className="nav-item dropdown">
                <a
                  href="javaScript:Void(0);"
                  className="dropdown-toggle nav-link"
                  data-toggle="dropdown"
                  style={{
                    color: "#000",
                  }}
                >
                  <NotificationIcon />
                  {notifications && notifications.length ? (
                    <span class="badge badge-pill">{notifications.length}</span>
                  ) : null}
                </a>

                <div className="dropdown-menu notifications">
                  <div className="topnav-dropdown-header">
                    <span className="notification-title">Notifications</span>
                    {/* <a href='javaScript:void(0)' className='clear-noti'> Clear All </a> */}
                  </div>
                  <div className="noti-content">
                    <ul className="notification-list">
                      {notifications &&
                        notifications.map((notification) => {
                          return (
                            <li className="notification-message">
                              <a
                                href={
                                  URLS.dashboard + "/" + notification.target
                                }
                              >
                                <div className="media">
                                  <span
                                    style={{
                                      width: "38px",
                                      height: "38px",
                                      lineHeight: "38px",
                                    }}
                                  >
                                    {/* <img alt='' src={URLS.landingPage +'/assets/img/profiles/avatar-02.jpg'} /> */}
                                  </span>
                                  <div className="media-body">
                                    <p className="noti-details">
                                      {ReactHtmlParser(notification.message)}
                                    </p>
                                    <p className="noti-time">
                                      <span className="notification-time">
                                        {this.getDateDiff(
                                          notification.createDate
                                        )}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </li>
              {/* <!-- /Notifications --> */}

              {/* <!-- Message Notifications --> */}
              <li className="nav-item dropdown">
                <div>
                  <p></p>
                </div>
              </li>
              {/* <!-- /Message Notifications --> */}
            </ul>

            <div className="mt-3">
              <Dropdown>
                <Dropdown.Toggle className="cursor no-show-botton">
                  <div className="d-flex">
                    <div className="avatar-Nav">
                      <Avatar
                        image={employeeImage ? employeeImage : ""}
                        name={employeeName}
                      />
                    </div>

                    <DownArrowIcon />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  popperConfig={{
                    strategy: "fixed",
                  }}
                  renderOnMount
                >
                  <Dropdown.Item as="button">
                    <Link
                      className="dropdown-item"
                      to={employee ? URLS.profile + "/" + employee._id : ""}
                    >
                      My Profile
                    </Link>
                  </Dropdown.Item>

                  <Dropdown.Item as="button">
                    <Link className="dropdown-item" to={URLS.settings}>
                      Settings
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item as="button">
                    <span
                      style={{ cursor: "pointer" }}
                      className="dropdown-item"
                      onClick={() => this.handleLogOut()}
                    >
                      Logout
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Item as="button">
                    <span
                      style={{ cursor: "pointer" }}
                      className="dropdown-item"
                    >
                      Version {process.env?.REACT_APP_VERSION}
                    </span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {/* <!-- /Header Menu --> */}

          {/* <!-- Mobile Menu --> */}
          <div className="dropdown mobile-user-menu">
            <a
              href="javaScript:void(0)"
              className="nav-link dropdown-toggle"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <Link
                className="dropdown-item"
                to={employee ? URLS.profil + "/" + employee._id : ""}
              >
                My Profile
              </Link>
              <a className="dropdown-item" href={`${URLS.settings}`}>
                Settings
              </a>
              <div
                className="dropdown-item"
                onClick={() => this.handleLogOut()}
              >
                Logout
              </div>
              <div
                className="dropdown-item"
                onClick={() => this.handleLogOut()}
              >
                Version {process.env?.REACT_APP_VERSION}
              </div>
            </div>
          </div>
          {/* <!-- /Mobile Menu --> */}
        </div>
      </>
    );
  }
}

// export default Topbar;

const mapStateToProps = (state) => {
  return {
    settings: state.settingsReducer.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAllDeductions: (deductions) => dispatch(addAllDeductions(deductions)),
    addAllSettings: (settings) => dispatch(addAllSettings(settings)),
    addAllBonus: (bonus) => dispatch(addAllBonus(bonus)),
    addAllAllowance: (allowance) => dispatch(addAllAllowance(allowance)),
    addAllBenefit: (benefit) => dispatch(addAllBenefit(benefit)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
