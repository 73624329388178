import React from "react";
import moment from "moment";
import ImageList from "./ImageList";
import "../../assets/css/project.css";
import { URLS } from "../urls";
import { Link } from "react-router-dom";

class Project extends React.Component {
  constructor(props) {
    super(props);
  }

  getEndDate = (endDate) => {
    let value;
    if (endDate) {
      endDate = endDate ? endDate.split("/") : "";
      endDate = endDate ? endDate[1] + "/" + endDate[0] + "/" + endDate[2] : "";
      value = moment(endDate).format("Do MMM YYYY");
    } else {
      value = "N/A";
    }
    return value;
  };

  render() {
    const { canShowControls, contract } = this.props;
    let endDate = contract && contract.endDate ? contract.endDate : "";

    return (
      <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
        <div className="widget">
          <div className="project-widget-header">
            <div className="widget-title">
              <Link to={`${URLS.contractView}/${contract._id}`}>
                {contract.contractName}
              </Link>
            </div>
            <div className="widget-subtitle">
              <span>{contract.contractNo}</span>
            </div>
            <div className="widget-body">
              <p>{contract.contractDescription}</p>
            </div>
            <div className="widget-subbody">
              <div className="widget-subbody-header">End Date:</div>
              <div className="widget-subbody-text">
                {this.getEndDate(endDate)}
              </div>
            </div>
            {/* <div className="widget-subbody">
              <div className="widget-subbody-header">Project Leader :</div>
              <div className="widget-subbody-space">
                <ImageList value={projectLeaderList} />
              </div>
            </div>
            <div className="widget-subbody">
              <div className="widget-subbody-header">Team :</div>
              <div className="widget-subbody-space">
                <ImageList value={projectTeamList} />
              </div>
            </div>
            <div className="widget-subbody">
              <div className="widget-subbody-header">
                Progress{" "}
                <span class="text-success float-right">
                  {progress.toString() + "%"}
                </span>
              </div>
              <div class="progress progress-xs mb-0">
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  data-toggle="tooltip"
                  title={progress.toString() + "%"}
                  style={{ width: progress + "%" }}
                ></div>
              </div>
            </div> */}
          </div>
          {canShowControls && (
            <div className="dropdown profile-action">
              <a
                href="#"
                className="action-icon dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="material-icons">more_vert</i>
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <a
                  className="dropdown-item"
                  href="#"
                  data-toggle="modal"
                  data-target="#edit_contract"
                  onClick={this.props.onEdit.bind(this, contract._id)}
                >
                  <i className="fa fa-pencil m-r-5"></i> Edit
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  data-toggle="modal"
                  data-target="#delete_contract"
                  onClick={this.props.onDelete.bind(this, contract._id)}
                >
                  <i className="fa fa-trash-o m-r-5"></i> Delete
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Project;
