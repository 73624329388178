import React, { Component } from 'react';
import { URLS } from '../urls';
import {Link} from 'react-router-dom';

class TopbarGuest extends Component {
	render() {
		return (
            <div class="header">
				{/* <!-- Logo --> */}
				<div className='header-left'>
					<Link to={`${URLS.login}`} className='logo'>
                        <img src={URLS.landingPage +'/assets/img/white-logo.png'} alt='' style={{ width: "120%" }}/>
                    </Link>
                </div>
				{/* <!-- /Logo --> */}
				{/* <a id='toggle_btn' href='javascript:void(0);'>
                    <span className='bar-icon'>
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </a> */}
				{/* <!-- Header Title --> */}
                {/* <div class="page-title-box float-left">
					<h3>Mudiame HR</h3>
                </div> */}
				{/* <!-- /Header Title --> */}
				
				{/* <!-- Header Menu --> */}
				{/* <ul class="nav user-menu">
					<li class="nav-item">
						<a class="nav-link" href={`${URLS.login}`}>Login</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="register.html">Register</a>
					</li>
				</ul> */}
				{/* <!-- /Header Menu --> */}

				{/* <!-- Mobile Menu --> */}
				<div class="dropdown mobile-user-menu">
					<a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
					<div class="dropdown-menu dropdown-menu-right">
						<a class="dropdown-item" href="login.html">Login</a>
						<a class="dropdown-item" href="register.html">Register</a>
					</div>
				</div>
				{/* <!-- /Mobile Menu --> */}
				
            </div>
        )
    }
}

export default TopbarGuest;
