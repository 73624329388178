import React, { Component } from 'react';
import { URLS } from '../../urls';
import Datepicker from 'react-datetime'
import '../../../assets/css/date-picker.css';

export default class EmployeeSkills extends Component {
    constructor(props) {
        super(props);
        this.state = {
            skills: [],
        }
    };
    componentWillReceiveProps(props) {
        const id = props.eId;
        if(id){
            const token = 'dummy token';
            fetch(`${URLS.backendSkills}/skills/${id}`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Token ${token}`,
                },
            }).then(res => res.json())
                .then((result) => {
                    this.setState({
                        skills: result.skills,
                    });
            });
        }
    }

    render() {
        const {skills} = this.state;
        return (
            <div className="content container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="table table-striped custom-table mb-0">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Institution Acquired</th>
                                        <th>Certificate Acquired</th>
                                        <th>Description</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        skills && skills.length ? skills.map((empSkill, idx) => {
                                            return (
                                                <tr className="holiday-completed">
                                                    <td>{empSkill.skillName}</td>
                                                    <td>{empSkill.institutionAcquired}</td>
                                                    <td>{empSkill.certificateAcquired}</td>
                                                    <td>{empSkill.skillDescription}</td>
                                                    <td>{empSkill.dateAcquired}</td>
                                                </tr>
                                            );
                                        }) : ''
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}