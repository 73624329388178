import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function TimePicker(props) {
  const [state, setstate] = useState(new Date("1970-01-01T" + props.value));

  return (
    <ReactDatePicker
      selected={state}
      onChange={(date) => {
        setstate(date);
        props.ok(date);
      }}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={1}
      timeCaption="Time"
      dateFormat="HH:mm:ss"
      isClearable={true}
      {...props}
    />
  );
}
