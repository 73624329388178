import React from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import { Form, Col, Row, Image, textarea, Popover } from "react-bootstrap";
import moment from "moment";
import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { URLS } from "../urls";
import { branch_id } from "../../utils";
const allMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

class Policies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonText: "Submit",
      deletePolicyId: null,
      newPolicy: {
        policy: "",
        department: "All Department",
        description: "",
        created: moment(new Date()).format("DD/MM/YYYY"),
        modified: moment(new Date()).format("DD/MM/YYYY"),
        policyFile: {
          originalName: "",
          uniqueName: "",
          extension: "",
        },
        branch_id,
      },
      editPolicy: {
        _id: "",
        policy: "",
        department: "All Department",
        description: "",
        modified: moment(new Date()).format("DD/MM/YYYY"),
        policyFile: {
          originalName: "",
          uniqueName: "",
          extension: "",
        },
        branch_id,
      },
      policyFileData: null,
      policies: null,
      departments: null,
    };
  }
  componentWillMount() {
    const token = "dummy token";
    fetch(`${URLS.backendPolicies}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          policies: result.policies,
        });
      });

    fetch(`${URLS.backendDepartments}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          departments: result.departments,
        });
      });
  }
  showDateInFormat = (date) => {
    date = date.split("/");
    return date[0] + " " + allMonths[date[1] - 1] + " " + date[2];
  };
  updateNewPolicyPolicy = (e) => {
    const { newPolicy } = this.state;
    newPolicy.policy = e.target.value;
    this.setState({
      newPolicy,
    });
  };
  updateNewPolicyDescription = (e) => {
    const { newPolicy } = this.state;
    newPolicy.description = e.target.value;
    this.setState({
      newPolicy,
    });
  };
  updateNewPolicyDepartment = (e) => {
    const { newPolicy } = this.state;
    newPolicy.department = e.target.value;
    this.setState({
      newPolicy,
    });
  };
  updateNewPolicyFile = (event) => {
    const { newPolicy } = this.state;
    const file = event.target.files[0];
    const name = file.name;
    const lastDot = name.lastIndexOf(".");
    const fileName = name.substring(0, lastDot);
    const ext = name.substring(lastDot + 1);
    let currentDate = new Date();
    currentDate = currentDate.getTime();
    newPolicy.policyFile.originalName = fileName;
    newPolicy.policyFile.uniqueName = currentDate;
    newPolicy.policyFile.extension = ext;
    this.setState({
      policyFileData: file,
      newPolicy,
    });
  };
  updateEditPolicyFile = (event) => {
    const { editPolicy } = this.state;
    const file = event.target.files[0];
    const name = file.name;
    const lastDot = name.lastIndexOf(".");
    const fileName = name.substring(0, lastDot);
    const ext = name.substring(lastDot + 1);
    let currentDate = new Date();
    currentDate = currentDate.getTime();
    editPolicy.policyFile.originalName = fileName;
    editPolicy.policyFile.uniqueName = currentDate;
    editPolicy.policyFile.extension = ext;
    this.setState({
      policyFileData: file,
      editPolicy,
    });
  };
  updateEditPolicyPolicy = (e) => {
    const { editPolicy } = this.state;
    editPolicy.policy = e.target.value;
    this.setState({
      editPolicy,
    });
  };
  updateEditPolicyDescription = (e) => {
    const { editPolicy } = this.state;
    editPolicy.description = e.target.value;
    this.setState({
      editPolicy,
    });
  };
  updateEditPolicyDepartment = (e) => {
    const { editPolicy } = this.state;
    editPolicy.department = e.target.value;
    this.setState({
      editPolicy,
    });
  };
  setStateData = (idx) => (evt) => {
    const { policies } = this.state;
    this.setState({
      editPolicy: policies[idx],
    });
  };
  handlePolicySubmit = (e) => {
    e.preventDefault();
    const token = "dummy token";
    const { newPolicy, policyFileData } = this.state;
    this.setState({
      buttonText: "Submitting...",
    });
    let formData = new FormData();
    formData.append("policy", JSON.stringify({ ...newPolicy }));
    if (policyFileData)
      formData.append(
        "file",
        policyFileData,
        newPolicy.policyFile.uniqueName + "." + newPolicy.policyFile.extension
      );
    console.log(formData);
    fetch(`${URLS.backendPolicies}/add`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("result", result);
        const newPolicy2 = {
          policy: "",
          department: "All Department",
          description: "",
          created: moment(new Date()).format("DD/MM/YYYY"),
          policyFile: {
            originalName: "",
            uniqueName: "",
            extension: "",
          },
          branch_id,
        };
        const { policies } = this.state;
        setTimeout(() => {
          window.$("#add_policy").modal("toggle");
          this.setState({ buttonText: "Submit" });
          this.setState({
            newPolicy: newPolicy2,
            policies: policies.concat(result.policy),
            policyFileData: null,
          });
        }, 500);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleEditPolicySubmit = (e) => {
    e.preventDefault();
    const token = "dummy token";
    const { editPolicy, policyFileData } = this.state;
    this.setState({
      buttonText: "Submitting...",
    });
    editPolicy.modified = moment(new Date()).format("DD/MM/YYYY");
    let formData = new FormData();
    formData.append("policy", JSON.stringify({ ...editPolicy }));
    if (policyFileData)
      formData.append(
        "file",
        policyFileData,
        editPolicy.policyFile.uniqueName + "." + editPolicy.policyFile.extension
      );
    console.log(formData);
    fetch(`${URLS.backendPolicies}/update`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        const editPolicy2 = {
          policy: "",
          department: "All Department",
          description: "",
          created: moment(new Date()).format("DD/MM/YYYY"),
          policyFile: {
            originalName: "",
            uniqueName: "",
            extension: "",
          },
          branch_id,
        };
        const { policies } = this.state;
        const resultPolicy = result.policy;
        for (let i = 0; i < policies.length; i++) {
          if (policies[i]._id === resultPolicy._id) {
            policies[i] = resultPolicy;
            break;
          }
        }
        setTimeout(() => {
          window.$("#edit_policy").modal("toggle");
          this.setState({ buttonText: "Submit" });
          this.setState({
            editPolicy: editPolicy2,
            policies: policies,
            policyFileData: null,
          });
        }, 500);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateDeletePolicy = (policy) => (e) => {
    this.setState({
      deletePolicyId: policy._id,
    });
  };

  deletePolicyConfirm = () => {
    const { deletePolicyId } = this.state;
    const token = "dummy token";
    console.log(deletePolicyId);
    fetch(`${URLS.backendPolicies}/delete/${deletePolicyId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        const policiesTemp = this.state.policies.filter((policy) => {
          return policy._id !== result.policy._id;
        });
        this.setState({
          policies: policiesTemp,
          deletePolicyId: null,
        });
        alert("Deleted Succussfully.");
        window.$("#delete_policy").modal("toggle");
      });
  };
  deletePolicyCancel = () => {
    this.setState({
      deletePolicyId: null,
    });
    window.$("#delete_policy").modal("toggle");
  };

  render() {
    const { policies, departments, newPolicy, editPolicy } = this.state;
    return (
      <body>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex, nofollow"></meta>
          <title>InvexERP</title>
        </Helmet>
        <div className="main-wrapper">
          <Topbar />
          <Sidebar />
          {/*<!-- Page Wrapper -->*/}
          <div className="page-wrapper">
            {/*<!-- Page Content -->*/}
            <div className="content container-fluid">
              {/*<!-- Page Title -->*/}
              <div className="row">
                <div className="col-sm-5 col-5">
                  <h4 className="page-title">Policies</h4>
                </div>
                <div className="col-sm-7 col-7 text-right m-b-30">
                  <a
                    href="#"
                    className="btn add-btn"
                    data-toggle="modal"
                    data-target="#add_policy"
                  >
                    <i className="fa fa-plus"></i> Add Policy
                  </a>
                </div>
              </div>
              {/*<!-- /Page Title -->*/}

              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table table-striped custom-table mb-0 datatable">
                      <thead>
                        <tr>
                          <th style={{ width: "80px" }}>#</th>
                          <th>Policy Name </th>
                          <th>Department </th>
                          <th>Description </th>
                          <th>Created </th>
                          <th className="text-right">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {policies &&
                          policies.map((policy, index) => {
                            const fileName = policy.policyFile.uniqueName
                              ? policy.policyFile.uniqueName +
                                "." +
                                policy.policyFile.extension
                              : null;
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{policy.policy}</td>
                                <td>{policy.department}</td>
                                <td>{policy.description}</td>
                                <td>{this.showDateInFormat(policy.created)}</td>
                                <td className="text-right">
                                  <div className="dropdown dropdown-action">
                                    <a
                                      href="#"
                                      className="action-icon dropdown-toggle"
                                      data-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="material-icons">
                                        more_vert
                                      </i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      {fileName ? (
                                        <a
                                          className="dropdown-item"
                                          href={
                                            URLS.backendStatic + "/" + fileName
                                          }
                                          target="_blank"
                                        >
                                          <i className="fa fa-download m-r-5"></i>{" "}
                                          Download
                                        </a>
                                      ) : (
                                        <a
                                          className="dropdown-item"
                                          href="JavaScript:void(0);"
                                        >
                                          <i className="fa fa-download m-r-5"></i>{" "}
                                          Download
                                        </a>
                                      )}

                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#edit_policy"
                                        onClick={this.setStateData(index)}
                                      >
                                        <i className="fa fa-pencil m-r-5"></i>{" "}
                                        Edit
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#delete_policy"
                                        onClick={this.updateDeletePolicy(
                                          policy
                                        )}
                                      >
                                        <i className="fa fa-trash-o m-r-5"></i>{" "}
                                        Delete
                                      </a>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Page Content -->*/}

            {/*<!-- Add Policy Modal -->*/}
            <div
              id="add_policy"
              className="modal custom-modal fade"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Add Policy</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="form-group">
                        <label>
                          Policy Name <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          value={newPolicy.policy}
                          onChange={this.updateNewPolicyPolicy}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Description <span className="text-danger">*</span>
                        </label>
                        <textarea
                          className="form-control"
                          rows="4"
                          value={newPolicy.description}
                          onChange={this.updateNewPolicyDescription}
                        ></textarea>
                      </div>
                      <div className="form-group">
                        <label className="col-form-label">Department</label>
                        <Form.Control
                          as="select"
                          value={newPolicy.department}
                          onChange={this.updateNewPolicyDepartment}
                        >
                          <option value="All Department">All Department</option>
                          {departments &&
                            departments.map((department) => {
                              return (
                                <option value={department.name}>
                                  {department.name}
                                </option>
                              );
                            })}
                        </Form.Control>
                      </div>
                      <div className="form-group">
                        <label>
                          Upload Policy <span className="text-danger">*</span>
                        </label>
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="policy_upload"
                            onChange={this.updateNewPolicyFile}
                          />
                          <label
                            className="custom-file-label"
                            for="policy_upload"
                          >
                            Choose file
                          </label>
                          <p>
                            {newPolicy.policyFile.originalName +
                              "." +
                              newPolicy.policyFile.extension}
                          </p>
                        </div>
                      </div>
                      <div className="submit-section">
                        <button
                          className="btn btn-primary submit-btn"
                          da
                          onClick={this.handlePolicySubmit}
                        >
                          {this.state.buttonText}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Add Policy Modal -->*/}

            {/*<!-- Edit Policy Modal -->*/}
            <div
              id="edit_policy"
              className="modal custom-modal fade"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Edit Policy</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="form-group">
                        <label>
                          Policy Name <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          value={editPolicy.policy}
                          onChange={this.updateEditPolicyPolicy}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Description <span className="text-danger">*</span>
                        </label>
                        <textarea
                          className="form-control"
                          rows="4"
                          value={editPolicy.description}
                          onChange={this.updateEditPolicyDescription}
                        ></textarea>
                      </div>
                      <div className="form-group">
                        <label className="col-form-label">Department</label>
                        <Form.Control
                          as="select"
                          value={editPolicy.department}
                          onChange={this.updateEditPolicyDepartment}
                        >
                          <option value="All Department">All Department</option>
                          {departments &&
                            departments.map((department) => {
                              return (
                                <option value={department.name}>
                                  {department.name}
                                </option>
                              );
                            })}
                        </Form.Control>
                      </div>
                      <div className="form-group">
                        <label>
                          Upload Policy <span className="text-danger">*</span>
                        </label>
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="policy_upload"
                            onChange={this.updateEditPolicyFile}
                          />
                          <label
                            className="custom-file-label"
                            for="policy_upload"
                          >
                            Choose file
                          </label>
                          <p>
                            {editPolicy.policyFile
                              ? editPolicy.policyFile.originalName +
                                "." +
                                editPolicy.policyFile.extension
                              : null}
                          </p>
                        </div>
                      </div>
                      <div className="submit-section">
                        <button
                          className="btn btn-primary submit-btn"
                          da
                          onClick={this.handleEditPolicySubmit}
                        >
                          {this.state.buttonText}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Edit Policy Modal -->*/}

            {/*<!-- Delete Policy Modal -->*/}
            <div
              className="modal custom-modal fade"
              id="delete_policy"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="form-header">
                      <h3>Delete Policy</h3>
                      <p>Are you sure want to delete?</p>
                    </div>
                    <div className="modal-btn delete-action">
                      <div className="row">
                        <div className="col-6">
                          <a
                            href="javascript:void(0);"
                            className="btn btn-primary continue-btn"
                            onClick={this.deletePolicyConfirm}
                          >
                            Delete
                          </a>
                        </div>
                        <div className="col-6">
                          <a
                            href="javascript:void(0);"
                            data-dismiss="modal"
                            className="btn btn-primary cancel-btn"
                            onClick={this.deletePolicyCancel}
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Delete Policy Modal -->*/}
          </div>
          {/*<!-- /Page Wrapper -->*/}
        </div>
        {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
        <div className="sidebar-overlay" data-reff=""></div>
      </body>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Policies));
