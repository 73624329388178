import React from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { URLS } from "../urls";
class JobApplicants extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applications: [],
    };
  }
  changeDateFormat = (date) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    date = date.split("/");
    return date[0] + " " + months[parseInt(date[1]) - 1] + " " + date[2];
  };
  componentWillMount() {
    const urlSplit = window.location.pathname.split("/");
    const id = urlSplit.length > 3 ? urlSplit[3] : null;
    const token = "dummy token";
    console.log(
      "window.location.pathname.split('/')",
      window.location.pathname.split("/")
    );
    if (id) {
      fetch(`${URLS.backendApplications}/getByJobId/${id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          console.log(result);
          this.setState({
            applications: result.applications,
          });
        });
    } else {
      fetch(`${URLS.backendApplications}/get`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          console.log(result);
          this.setState({
            applications: result.applications,
          });
        });
    }
  }

  render() {
    const { applications } = this.state;
    const sfUrl = URLS.backendStatic + "/";
    return (
      <body>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex, nofollow"></meta>
          <title>InvexERP</title>
        </Helmet>
        <div className="main-wrapper">
          <Topbar />
          <Sidebar />
          {/*<!-- Page Wrapper -->*/}
          <div className="page-wrapper">
            {/*<!-- Page Content -->*/}
            <div className="content container-fluid">
              {/*<!-- Page Title -->*/}
              <div className="row">
                <div className="col-sm-12 col-12">
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <Link to={`${URLS.jobs}`}>Jobs</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Job Applicants</BreadcrumbItem>
                  </Breadcrumb>
                </div>
              </div>
              {/*<!-- /Page Title -->*/}

              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table table-striped custom-table mb-0 datatable">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Apply Date</th>
                          <th className="text-center">Status</th>
                          <th>Resume</th>
                          <th className="text-right">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {applications.length
                          ? applications.map((application, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{application.name}</td>
                                  <td>{application.email}</td>
                                  <td>{application.phone}</td>
                                  <td>
                                    {this.changeDateFormat(
                                      application.applyDate
                                    )}
                                  </td>
                                  <td className="text-center">
                                    <div className="dropdown action-label">
                                      <a
                                        className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                        href="#"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="fa fa-dot-circle-o text-info"></i>{" "}
                                        New
                                      </a>
                                      <div className="dropdown-menu dropdown-menu-right">
                                        <a className="dropdown-item" href="#">
                                          <i className="fa fa-dot-circle-o text-info"></i>{" "}
                                          New
                                        </a>
                                        <a className="dropdown-item" href="#">
                                          <i className="fa fa-dot-circle-o text-success"></i>{" "}
                                          Hired
                                        </a>
                                        <a className="dropdown-item" href="#">
                                          <i className="fa fa-dot-circle-o text-danger"></i>{" "}
                                          Rejected
                                        </a>
                                        <a className="dropdown-item" href="#">
                                          <i className="fa fa-dot-circle-o text-danger"></i>{" "}
                                          Interviewed
                                        </a>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <a
                                      href={sfUrl + application.resume.unique}
                                      target="_blank"
                                      className="btn btn-xs btn-primary"
                                    >
                                      <i className="fa fa-download"></i>{" "}
                                      Download
                                    </a>
                                  </td>
                                  <td className="text-right">
                                    <div className="dropdown dropdown-action">
                                      <a
                                        href="#"
                                        className="action-icon dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="material-icons">
                                          more_vert
                                        </i>
                                      </a>
                                      <div className="dropdown-menu dropdown-menu-right">
                                        <a className="dropdown-item" href="#">
                                          <i className="fa fa-clock-o m-r-5"></i>{" "}
                                          Schedule Interview
                                        </a>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          : ""}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Page Content -->*/}
          </div>
          {/*<!-- /Page Wrapper -->*/}
        </div>
        {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
        <div className="sidebar-overlay" data-reff=""></div>
      </body>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(JobApplicants));
