const AllowanceReducer = (
  state = {
    allowances: [],
  },
  action
) => {
  switch (action.type) {
    case "ADD_ALLOWANCES":
      return { allowances: action.payload };

    case "DELETE_ALLOWANCE":
      const slug = action.payload;
      let allowances = state.allowances;
      allowances = allowances.filter((allowance) => allowance.slug !== slug);
      return {
        ...state,
        allowances,
      };

    case "ADD_ALLOWANCE":
      const states = [...state.allowances].filter(
        (el) => el._id !== action.payload._id
      );
      return {
        ...state,
        allowances: [...states, action.payload],
      };

    default:
      return state;
  }
};

export default AllowanceReducer;
