import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import Cookies from "universal-cookie";
import DatePicker from "react-datetime";
import moment from "moment";
import CurrencyInput from "react-currency-input-field";

import { URLS } from "../urls";
import { addAllowance, deleteAllowance } from "../allowance/AllowanceAction";
import {
  branch_id,
  applyTo,
  maturityPeriod,
  payTypes,
  duration,
  maturityTime,
  payPer,
} from "../../utils";

export function LeaveAllowance(props) {
  const [settingsName, setSettingsName] = useState("add new");
  const [paymentType, setPaymentType] = useState("");
  const [durationType, setDurationType] = useState("");
  const [yearMonth, setYearMonth] = useState("");
  const [numberYearMonth, setNumberYearMonth] = useState("");
  const [calculatePer, setCalculatePer] = useState("");
  const [paymentPeriod, setPaymentPeriod] = useState("");
  const [btnText, setBtnText] = useState("Save Settings");
  const [deleteBtnText, setDeleteBtnText] = useState("Delete");
  const [newSettingsName, setNewSettingsName] = useState(
    props?.leaveName ? props?.leaveName : ""
  );
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
  });
  const history = useHistory();
  const [bonusSettings, setBonusSettings] = useState({
    percentage: 0,
    enabled: true,
    basic: false,
    housing: false,
    medical: false,
    transport: false,
    utility: false,
    entertainment: false,
    dressing: false,
    earned: true,
  });
  const [applySelected, setApplySelected] = useState("All");
  const [gradeEmployess, setGradeEmployess] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedgradeEmployess, setSelectedGradeEmployess] = useState([]);
  const [defaultgradeEmployess, setDefaultGradeEmployess] = useState([]);
  const [getAllSets, setGetAllSets] = useState([]);
  const [selectSettings, setSelectSettings] = useState([]);
  const [settingsDefault, setsettingsDefault] = useState([]);
  const [customSettings, setCustomSettings] = useState([]);

  const cookies = new Cookies();
  const token = "dummy token";

  //   useEffect(() => {
  //     const slug = window.location.pathname.split("/")[3];
  //     const name = slug?.replaceAll("-", " ");
  //     setSettingsName(name);

  //     const unlisten = history.listen((location) => {
  //       if (!location.pathname.includes("leaveSettings")) return;
  //       resetState();
  //       const slug = window.location.pathname.split("/")[3];
  //       const name = slug?.replaceAll("-", " ");
  //       setSettingsName(name);
  //     });

  //     return () => unlisten();
  //   }, [settingsName, history]);

  useEffect(() => {
    if (applySelected === "All") {
      setGradeEmployess([]);
      setSelectedGradeEmployess([]);
    } else if (applySelected === "Job Grade") {
      setIsFetching(true);
      fetch(`${URLS.backendJobGrades}/get/${branch_id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          const jg = [...result.jobGrades].map((d) => ({
            label: d.name,
            value: d._id,
          }));
          setGradeEmployess([]);
          setGradeEmployess(jg);
          setIsFetching(false);
        })
        .catch((err) => {
          setIsFetching(false);
          toast("Unable to get Job Grades, please refresh your browser", {
            type: "error",
          });
        });
    } else if (applySelected === "Employees") {
      setIsFetching(true);
      fetch(`${URLS.backendEmployees}/get/${branch_id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          const em = result.employees.map((d) => ({
            label: `${
              d?.personalInfo?.name?.first ? d?.personalInfo?.name?.first : ""
            } ${
              d?.personalInfo?.name?.middle ? d?.personalInfo?.name?.middle : ""
            } ${
              d?.personalInfo?.name?.last ? d?.personalInfo?.name?.last : ""
            }`,
            value: d.userId,
          }));
          setIsFetching(false);
          setGradeEmployess([]);
          setGradeEmployess(em);
        })
        .catch((err) => {
          setIsFetching(false);
          toast("Unable to get Job Grades, please refresh your browser", {
            type: "error",
          });
        });
    } else if (applySelected === "Contract") {
      fetch(`${URLS.backendContracts}/get/${branch_id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          // console.log(result);
          const jg = [...result.contracts].map((d) => ({
            label: d.contractName,
            value: d._id,
          }));
          setGradeEmployess([]);
          setGradeEmployess(jg);
          setIsFetching(false);
        })
        .catch((err) => {
          setIsFetching(false);
          toast("Unable to get Job Grades, please refresh your browser", {
            type: "error",
          });
        });
    }

    return () => {};
  }, [applySelected]);

  useEffect(() => {
    if (props?.assignees) {
      const def = props?.assignees?.map((el) => el?.userId);
      // console.log(def);
      setDefaultGradeEmployess(def);
      setNewSettingsName(`Leave Allownace(${props?.leaveName})`);
      setApplySelected("Employees");
      setPaymentType("Percentage");
    }
  }, [props?.assignees]);

  useEffect(() => {
    if (settingsName !== "") {
      fetch(`${URLS.backendApp}/set-up`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          const { allowance, benefit, bonusSettings, deductionSettings } =
            result;
          let all = [
            ...allowance,
            ...benefit,
            ...bonusSettings,
            ...deductionSettings,
          ];

          all = all
            .filter((el) => el.name !== settingsName)
            .map((el) => ({
              label: el.name,
              value: { name: el.name, level: el.level, slug: el.slug },
            }));

          setGetAllSets(all);
          setIsFetching(false);
        })
        .catch((err) => {
          setIsFetching(false);
          toast("Unable to get Job Grades, please refresh your browser", {
            type: "error",
          });
        });
      fetchSalarySettings();
    }
  }, [settingsName]);

  const fetchSalarySettings = () => {
    fetch(`${URLS.backendsalarySetting}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((grades) => {
        const custom = grades.jobGrades[0].custom.map((el) => ({
          name: el.name,
          status: false,
        }));
        setCustomSettings(custom);
      });
  };

  // const toggleModal = () => {
  //   window.$("#delete_employee").modal("toggle");
  // };

  // const resetState = () => {
  //   setSettingsName("");
  //   setBtnText("Save Settings");
  //   setDeleteBtnText("Delete");
  //   setNewSettingsName("add new");
  //   setBonusSettings({
  //     percentage: 0,
  //     basic: false,
  //     housing: false,
  //     medical: false,
  //     transport: false,
  //     utility: false,
  //     entertainment: false,
  //     dressing: false,
  //   });
  //   setSelectedGradeEmployess([]);
  //   setIsFetching(false);
  //   setGradeEmployess([]);
  //   setApplySelected("");
  //   setDefaultGradeEmployess([]);
  //   setGetAllSets([]);
  //   setsettingsDefault([]);
  //   setDurationType("");
  //   setYearMonth("");
  //   setNumberYearMonth(0);
  // };

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      const userId = cookies.get("userId");
      setBtnText("Please wait...");
      const token = "dummy token";

      const selected =
        selectedgradeEmployess.length > 0
          ? selectedgradeEmployess.map((d) => d.value)
          : defaultgradeEmployess;

      const allSettings =
        selectSettings.length > 0
          ? selectSettings.map((d) => d.value)
          : selectSettings.length === 0
          ? []
          : settingsDefault;

      let level;

      if (allSettings?.length < 1) {
        level = 1;
      } else {
        level = Math.max(...allSettings?.map((el) => el.level)) + 1;
      }

      // console.log(level);
      const thebonusSettings = {
        ...bonusSettings,
        // createdBy: props.User._id,
        createdBy: userId,
        DeductType: applySelected,
        selectedDeducts: selected,
        name: settingsName === "add new" ? newSettingsName : settingsName,
        branch_id,
        paymentType,
        paymentPeriod,
        dateRange,
        allSettings,
        level,
        maturityPeriod: `${numberYearMonth} ${yearMonth}`,
        durationType,
        calculatePer,
        leaveId: props?.leaveId,
        customSettings,
      };

      // console.log(thebonusSettings);
      const res = await fetch(`${URLS.backendAllowance}/add`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({
          allowance: thebonusSettings,
          isNew: settingsName === "add new",
        }),
      });

      const data = await res.json();
      if (!res.ok) {
        throw Error(data.message);
      } else {
        // console.log(data);
        toast("Settings saved", {
          type: "success",
        });
        setBonusSettings({
          ...data.allowance,
        });
        window.$("#add_leave_allowance").modal("toggle");
        // props.addAllowance({
        //   ...data.allowance,
        // });
        // if (settingsName === "add new") {
        //   props.history.push(`${data.allowance.slug}`);
        // }
      }
    } catch (err) {
      toast(err ? err.toString() : "Unable to submit, please try again", {
        type: "error",
      });
    } finally {
      setBtnText("Save Settings");
    }
  };

  const filterByDateRange = (event, name) => {
    setDateRange({
      ...dateRange,
      [name]: moment(event).format("MM/YYYY"),
    });
  };

  const validationEndDate = (currentDate) => {
    return currentDate.isAfter(
      moment(dateRange?.startDate, "MM/YYYY").add(0, "months")
    );
  };

  const changeCustom = (name) => {
    const all = customSettings.map((el, id) => {
      if (el.name === name) {
        return { ...el, status: !el?.status };
      } else {
        return el;
      }
    });
    setCustomSettings(all);
  };

  return (
    <div
      id="add_leave_allowance"
      className="modal custom-modal fade"
      role="dialog"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add Leave Allowance</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {/*<!-- Content Starts -->*/}
            <div class="p-3">
              <form onSubmit={(e) => submitForm(e)} autoComplete={false}>
                <div className="pl-0">
                  <div className="row">
                    {settingsName === "add new" && (
                      <div className="form-group col-md-6">
                        <label>Name</label>
                        <input
                          required
                          className="form-control"
                          type="text"
                          value={newSettingsName}
                          name="newSettingsName"
                          onChange={(e) => setNewSettingsName(e.target.value)}
                          disabled={props?.assignees ? true : false}
                        />
                      </div>
                    )}

                    <div className="col-md-4">
                      <div className="d-flex">
                        <div>
                          <label>Enable</label>
                          <div
                            className="onoffswitch ml-0"
                            onClick={(e) => {
                              setBonusSettings({
                                ...bonusSettings,
                                enabled: !bonusSettings.enabled,
                              });
                            }}
                          >
                            <input
                              type="checkbox"
                              name="onoffswitch"
                              className="onoffswitch-checkbox"
                              checked={bonusSettings.enabled}
                            />
                            <label className="onoffswitch-label">
                              <span className="onoffswitch-inner"></span>
                              <span className="onoffswitch-switch"></span>
                            </label>
                          </div>
                        </div>

                        {/* <div className="mx-3">
                          <label>Earned</label>
                          <div
                            className="onoffswitch ml-0"
                            onClick={(e) => {
                              setBonusSettings({
                                ...bonusSettings,
                                earned: !bonusSettings.earned,
                              });
                            }}
                          >
                            <input
                              type="checkbox"
                              name="onoffswitch"
                              className="onoffswitch-checkbox"
                              checked={bonusSettings.earned}
                              disabled
                            />
                            <label className="onoffswitch-label">
                              <span className="onoffswitch-inner"></span>
                              <span className="onoffswitch-switch"></span>
                            </label>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-4">
                      <label>Calculate By</label>
                      <select
                        className="form-control"
                        aria-label="Default select example"
                        value={paymentType}
                        onChange={(e) => setPaymentType(e.target.value)}
                      >
                        {payTypes.map((a, i) => (
                          <option key={i} value={a.value}>
                            {a.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    {paymentType === "Percentage" ? (
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Percentage</label>
                          <input
                            className="form-control"
                            type="text"
                            value={bonusSettings.percentage}
                            name="percentage"
                            onChange={(e) =>
                              setBonusSettings({
                                ...bonusSettings,
                                percentage: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    ) : paymentType === "Static" ? (
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Fixed </label>

                          <CurrencyInput
                            className="form-control"
                            value={bonusSettings.percentage}
                            allowNegativeValue={false}
                            // allowDecimals={false}
                            onValueChange={(value, name) =>
                              setBonusSettings({
                                ...bonusSettings,
                                percentage: value,
                              })
                            }
                          />
                        </div>
                      </div>
                    ) : null}
                    {/* <div className="col-md-4">
                      <label>Calculate Per</label>
                      <select
                        className="form-control"
                        aria-label="Default select example"
                        value={calculatePer}
                        onChange={(e) => setCalculatePer(e.target.value)}
                      >
                        {payPer.map((a, i) => (
                          <option key={i} value={a.value}>
                            {a.label}
                          </option>
                        ))}
                      </select>
                    </div> */}
                  </div>
                  {/*  */}

                  <div className="my-3">
                    <label>Apply allowance to :</label>
                    <div className="row">
                      <div className="col-6">
                        <select
                          className="form-control"
                          aria-label="Default select example"
                          value={applySelected}
                          onChange={(e) => setApplySelected(e.target.value)}
                          disabled={props?.assignees ? true : false}
                        >
                          {applyTo.map((a, i) => (
                            <option key={i} value={a.value}>
                              {a.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      {gradeEmployess.length > 0 && (
                        <div className="col-6">
                          <Select
                            closeMenuOnSelect={true}
                            isLoading={isFetching}
                            isMulti
                            isSearchable={true}
                            // defaultValue={
                            //   defaultgradeEmployess.length
                            //     ? gradeEmployess.filter((d) =>
                            //         defaultgradeEmployess.find(
                            //           (e) => e === d.value
                            //         )
                            //       )
                            //     : []
                            // }
                            value={gradeEmployess.filter((d) =>
                              defaultgradeEmployess.find((e) => e === d.value)
                            )}
                            onChange={(selected) => {
                              setSelectedGradeEmployess(selected);
                            }}
                            options={gradeEmployess}
                            isDisabled={props?.assignees ? true : false}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Maturity Period */}
                  <div className="row my-3">
                    <div className="col-md-6">
                      <label>Maturity Period (from joined date):</label>
                      <select
                        className="form-control"
                        aria-label="Default select example"
                        value={paymentPeriod}
                        onChange={(e) => setPaymentPeriod(e.target.value)}
                      >
                        {maturityPeriod.map((a, i) => (
                          <option key={i} value={a.value}>
                            {a.label}
                          </option>
                        ))}
                      </select>
                    </div>

                    {paymentPeriod === "CustomPeriod" && (
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6">
                            <label>Period:</label>
                            <input
                              type="number"
                              placeholder="Number of months/years"
                              value={numberYearMonth}
                              className="form-control"
                              onChange={(e) =>
                                setNumberYearMonth(e.target.value)
                              }
                              required={
                                paymentPeriod === "CustomPeriod" ? true : false
                              }
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Month/Year:</label>
                            <select
                              className="form-control"
                              aria-label="Default select example"
                              value={yearMonth}
                              onChange={(e) => setYearMonth(e.target.value)}
                              required={
                                paymentPeriod === "CustomPeriod" ? true : false
                              }
                            >
                              {maturityTime.map((a, i) => (
                                <option key={i} value={a.value}>
                                  {a.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/*Duration Time  */}

                  <div className="row my-3">
                    <div className="col-md-6">
                      <label>Duration:</label>
                      <select
                        className="form-control"
                        aria-label="Default select example"
                        value={durationType}
                        onChange={(e) => setDurationType(e.target.value)}
                      >
                        {duration.map((a, i) => (
                          <option key={i} value={a.value}>
                            {a.label}
                          </option>
                        ))}
                      </select>
                    </div>

                    {durationType === "CustomDuration" && (
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6">
                            <label>Start Date</label>

                            <div classname="datePicker">
                              <i
                                class="fa fa-calendar fa-xs calendar-icon"
                                aria-hidden="true"
                              ></i>
                              <DatePicker
                                value={dateRange.startDate}
                                name="startDate"
                                closeOnSelect={true}
                                dateFormat="MM/YYYY"
                                timeFormat={false}
                                onChange={(e) =>
                                  filterByDateRange(e, "startDate")
                                }
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <label>End Date</label>

                            <div classname="datePicker">
                              <i
                                class="fa fa-calendar fa-xs calendar-icon"
                                aria-hidden="true"
                              ></i>
                              <DatePicker
                                value={dateRange.endDate}
                                name="endDate"
                                closeOnSelect={true}
                                dateFormat="MM/YYYY"
                                timeFormat={false}
                                onChange={(e) =>
                                  filterByDateRange(e, "endDate")
                                }
                                isValidDate={validationEndDate}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {paymentType === "Percentage" && (
                    <>
                      <hr />
                      <p>{bonusSettings.percentage}% of </p>
                      <div className="row">
                        <div className="col-md-4">
                          <div
                            className="form-check"
                            style={{
                              height: "44px",
                              padding: "6px 0",
                              display: "inline-block",
                            }}
                          >
                            <input
                              style={{ height: "30px", width: "30px" }}
                              type="checkbox"
                              checked={bonusSettings.basic}
                              name="basic"
                              onChange={(e) =>
                                setBonusSettings({
                                  ...bonusSettings,
                                  basic: !bonusSettings.basic,
                                })
                              }
                            />
                            <label
                              style={{
                                margin: "10px",
                                verticalAlign: "bottom",
                              }}
                            >
                              Basic
                            </label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div
                            className="form-check d-block"
                            style={{
                              height: "44px",
                              padding: "6px 0",
                              display: "inline-block",
                            }}
                          >
                            <input
                              style={{ height: "30px", width: "30px" }}
                              type="checkbox"
                              checked={bonusSettings.housing}
                              name="housing"
                              onChange={(e) =>
                                setBonusSettings({
                                  ...bonusSettings,
                                  housing: !bonusSettings.housing,
                                })
                              }
                            />
                            <label
                              style={{
                                margin: "10px",
                                verticalAlign: "bottom",
                              }}
                            >
                              Housing
                            </label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div
                            className="form-check d-block"
                            style={{
                              height: "44px",
                              padding: "6px 0",
                              display: "inline-block",
                            }}
                          >
                            <input
                              style={{ height: "30px", width: "30px" }}
                              type="checkbox"
                              checked={bonusSettings.medical}
                              name="medical"
                              onChange={(e) =>
                                setBonusSettings({
                                  ...bonusSettings,
                                  medical: !bonusSettings.medical,
                                })
                              }
                            />
                            <label
                              style={{
                                margin: "10px",
                                verticalAlign: "bottom",
                              }}
                            >
                              Medical
                            </label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div
                            className="form-check d-block"
                            style={{
                              height: "44px",
                              padding: "6px 0",
                              display: "inline-block",
                            }}
                          >
                            <input
                              style={{ height: "30px", width: "30px" }}
                              type="checkbox"
                              checked={bonusSettings.transport}
                              name="transport"
                              onChange={(e) =>
                                setBonusSettings({
                                  ...bonusSettings,
                                  transport: !bonusSettings.transport,
                                })
                              }
                            />
                            <label
                              style={{
                                margin: "10px",
                                verticalAlign: "bottom",
                              }}
                            >
                              Transport
                            </label>
                          </div>
                        </div>

                        {/*  */}
                        <div className="col-md-4">
                          <div
                            className="form-check d-block"
                            style={{
                              height: "44px",
                              padding: "6px 0",
                              display: "inline-block",
                            }}
                          >
                            <input
                              style={{ height: "30px", width: "30px" }}
                              type="checkbox"
                              checked={bonusSettings.utility}
                              name="utility"
                              onChange={(e) =>
                                setBonusSettings({
                                  ...bonusSettings,
                                  utility: !bonusSettings.utility,
                                })
                              }
                            />
                            <label
                              style={{
                                margin: "10px",
                                verticalAlign: "bottom",
                              }}
                            >
                              Utility
                            </label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div
                            className="form-check d-block"
                            style={{
                              height: "44px",
                              padding: "6px 0",
                              display: "inline-block",
                            }}
                          >
                            <input
                              style={{ height: "30px", width: "30px" }}
                              type="checkbox"
                              checked={bonusSettings.entertainment}
                              onChange={(e) =>
                                setBonusSettings({
                                  ...bonusSettings,
                                  entertainment: !bonusSettings.entertainment,
                                })
                              }
                              name="entertainment"
                            />
                            <label
                              style={{
                                margin: "10px",
                                verticalAlign: "bottom",
                              }}
                            >
                              Entertainment
                            </label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div
                            className="form-check d-block"
                            style={{
                              height: "44px",
                              padding: "6px 0",
                              display: "inline-block",
                            }}
                          >
                            <input
                              style={{ height: "30px", width: "30px" }}
                              type="checkbox"
                              checked={bonusSettings.dressing}
                              onChange={(e) =>
                                setBonusSettings({
                                  ...bonusSettings,
                                  dressing: !bonusSettings.dressing,
                                })
                              }
                              name="dressing"
                            />
                            <label
                              style={{
                                margin: "10px",
                                verticalAlign: "bottom",
                              }}
                            >
                              Dressing
                            </label>
                          </div>
                        </div>

                        {customSettings?.map((el) => (
                          <div className="col-md-4">
                            <div
                              className="form-check d-block"
                              style={{
                                height: "44px",
                                padding: "6px 0",
                                display: "inline-block",
                              }}
                            >
                              <input
                                style={{ height: "30px", width: "30px" }}
                                type="checkbox"
                                checked={el?.status}
                                onChange={() => changeCustom(el?.name)}
                                // name={el?.name}
                              />
                              <label
                                style={{
                                  margin: "10px",
                                  verticalAlign: "bottom",
                                }}
                              >
                                {el?.name}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                      {getAllSets.length > 0 && (
                        <div className="col-8 my-4">
                          <label>Add More:</label>
                          <Select
                            closeMenuOnSelect={true}
                            isLoading={isFetching}
                            isMulti
                            isSearchable={true}
                            defaultValue={
                              settingsDefault.length
                                ? getAllSets.filter((d) =>
                                    settingsDefault.find(
                                      (e) => e.name === d.label
                                    )
                                  )
                                : []
                            }
                            onChange={(selected) => {
                              setSelectSettings(selected);
                            }}
                            options={getAllSets}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <button type="submit" className="btn btn-primary mt-3">
                      {btnText}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            {/*<!-- /Content End -->*/}
          </div>
        </div>
      </div>
    </div>
  );
}
