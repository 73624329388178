import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { URLS } from "../urls";

import "../../../src/assets/css/ga-styles.css";
import { toast } from "react-toastify";
import { branch_id } from "../../utils";
import { AddTerminationModal } from "./AddTerminationModal";
import ModalLoader from "../ModalLoader";
import { EditTermination } from "./EditTermination";

function Termination() {
  const [employees, setEmployees] = useState([]);

  const [terminationIdToDelete, setTerminationIdToDelete] = useState("");

  const [terminationDataToEdit, setTerminationDataToEdit] = useState({
    employeeName: "",
    employeeId: "",
    departmentId: "",
    terminationDate: "",
    reason: "",
    noticeDate: "",
    terminationType: ["misconduct"],
    branch_id,
  });

  const [terminations, setTerminations] = useState([]);
  const [showAddTermination, setShowAddTermination] = useState(false);
  const [reload, setReload] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showEditTermination, setShowEditTermination] = useState(false);

  useEffect(() => {
    getTerminations();
    return () => {};
  }, [reload]);

  const setTerminationDataForEdit = (termination) => {
    const employee = employees.find(
      (employee) => employee.userId === termination.employeeId
    );

    setTerminationDataToEdit({
      ...terminationDataToEdit,
      employeeName:
        employee?.personalInfo.name.first +
        " " +
        employee?.personalInfo.name.last,
      employeeId: termination.employeeId,
      departmentId: termination.departmentId,
      terminationDate: termination.terminationDate,
      reason: termination.reason,
      noticeDate: termination.noticeDate,
      terminationType: termination.terminationType,
    });
  };

  const getTerminations = async () => {
    const token = "dummy token";
    setLoading(true);
    const resTer = await fetch(
      `${URLS.backendTermination}/get-terminations/${branch_id}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );

    const res = await fetch(`${URLS.backendEmployees}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });

    const { employees } = await res.json();

    const { terminations } = await resTer.json();

    setTerminations(terminations);
    setEmployees(employees);
    setLoading(false);
  };

  const deleteTermination = () => {
    window.$("#delete_termination").modal("toggle");
    const token = "dummy token";
    setLoading(true);
    fetch(`${URLS.backendTermination}/delete`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ terminationId: terminationIdToDelete }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          const removedDeleted = terminations.filter(
            (termination) => termination._id !== terminationIdToDelete
          );
          setTerminations([...removedDeleted]);
          setLoading(false);
          toast("Termination deleted", {
            type: "success",
          });
        } else {
          setLoading(false);
          throw Error();
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast("Unable to delete termination", {
          type: "error",
        });
      });
  };

  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>InvexERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Page Title -->*/}
            <div className="row">
              <div className="col">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Termination</BreadcrumbItem>
                </Breadcrumb>
                {/* <h4 className="page-title">Employee</h4> */}
              </div>
              <div className="col-auto text-right float-right ml-auto m-b-30">
                <div
                  className="btn add-btn p-cursor"
                  onClick={() => setShowAddTermination(true)}
                >
                  <i className="fa fa-plus"></i>Add Termination
                </div>
              </div>
            </div>
            {/*<!-- /Page Title -->*/}

            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  {
                    <table className="table table-striped custom-table mb-0 datatable">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Terminated Employee </th>
                          <th>Department</th>
                          <th>Termination Type </th>
                          <th>Termination Date </th>
                          <th>Reason</th>
                          <th>Notice Date </th>
                          <th className="text-right">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {terminations.map((termination, index) => {
                          const { employee, department } = termination;

                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <h2 className="table-avatar blue-link">
                                  <Link
                                    to={`profile/${employee._id}`}
                                    className="avatar"
                                  >
                                    <img
                                      alt=""
                                      src="assets/img/profiles/avatar-02.jpg"
                                    />
                                  </Link>
                                  <Link to={`profile/${employee._id}`}>
                                    {employee.personalInfo.name.first +
                                      " " +
                                      employee.personalInfo.name.last}
                                  </Link>
                                </h2>
                              </td>
                              <td>{department?.name}</td>
                              <td>
                                {termination.terminationType.map(
                                  (type, index) => {
                                    return (
                                      <span key={index} className="mx-2">
                                        {type},
                                      </span>
                                    );
                                  }
                                )}
                              </td>
                              <td>{termination.terminationDate}</td>
                              <td>{termination.reason}</td>
                              <td>{termination.noticeDate}</td>
                              <td className="text-right">
                                <div className="dropdown dropdown-action">
                                  <div
                                    className="p-cursor action-icon dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="material-icons">more_vert</i>
                                  </div>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <div
                                      className="p-cursor dropdown-item"
                                      onClick={(e) => {
                                        setTerminationDataForEdit(termination);
                                        setShowEditTermination(true);
                                      }}
                                    >
                                      <i className="fa fa-pencil m-r-5"></i>{" "}
                                      Edit
                                    </div>
                                    <div
                                      className="dropdown-item p-cursor"
                                      data-toggle="modal"
                                      data-target="#delete_termination"
                                      onClick={(e) => {
                                        setTerminationIdToDelete(
                                          termination._id
                                        );
                                      }}
                                    >
                                      <i className="fa fa-trash-o m-r-5"></i>{" "}
                                      Delete
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  }
                </div>
              </div>
            </div>
          </div>
          {/*<!-- /Page Content -->*/}

          {/*<!-- Add Termination Modal -->*/}
          {showAddTermination && (
            <AddTerminationModal
              show={showAddTermination}
              handleClose={() => setShowAddTermination(false)}
              setReload={() => setReload(1 + Math.random() * (10000 - 1))}
            />
          )}
          {/*<!-- /Add Termination Modal -->*/}

          {/*<!-- Edit Termination Modal -->*/}
          {showEditTermination && (
            <EditTermination
              show={showEditTermination}
              handleClose={setShowEditTermination}
              terminationDataToEdit={terminationDataToEdit}
              terminations={terminations}
              setTerminations={setTerminations}
              setTerminationDataToEdit={setTerminationDataToEdit}
            />
          )}
          {/*<!-- /Edit Termination Modal -->*/}

          {/*<!-- Delete Termination Modal -->*/}
          <div
            className="modal custom-modal fade"
            id="delete_termination"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="form-header">
                    <h3>Delete Termination</h3>
                    <p>Are you sure want to delete?</p>
                  </div>
                  <div className="modal-btn delete-action">
                    <div className="row">
                      <div className="col-6">
                        <div
                          className="btn btn-primary continue-btn p-cursor"
                          onClick={() => deleteTermination()}
                        >
                          Delete
                        </div>
                      </div>
                      <div className="col-6">
                        <div
                          data-dismiss="modal"
                          className="btn btn-primary cancel-btn p-cursor"
                        >
                          Cancel
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*<!-- /Delete Termination Modal -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      <ModalLoader show={loading} />
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
    </body>
  );
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Termination));
