import React, { useState, useCallback, useEffect } from "react";
import { Modal, Table } from "react-bootstrap";
import moment from "moment";

import { URLS } from "../../urls";
import { NoSelectedItemIcon, ApproveCheckIcon } from "../../icons";
import Avatar from "../../../Avatar";

export default function LoanHistory(props) {
  const [requisition, setRequisition] = useState([]);

  const fetchRequi = useCallback(async () => {
    // await waitFor(5000);
    let response = await fetch(
      `${URLS.backendLoan}/history/${props.requestid}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const data = await response.json();
    setRequisition(data?.loan);
  }, [props.requestid]);

  useEffect(() => {
    fetchRequi();
  }, [fetchRequi]);

  //   console.log(requisition);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      dialogClassName="requisition-details-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
      //   fullscreen={styleHelper.isMobile}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>Loan History</h1>
          <p>More information about the loan repayment</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-end"></div>

        {requisition.length > 0 ? (
          <>
            <div className="info p-3 my-3 border rounded">
              <p className="border-bottom pb-3 mb-3 d-flex gap-3 title">
                <span>Loan Amount : </span>
                <span className="fw-5 mx-2">
                  {requisition[0]?.Amount || "..."}
                </span>
              </p>

              <div className="d-md-flex">
                <Table borderless className="info-table mb-0 mb-md-3">
                  <tbody>
                    {/* <tr>
                    <td>ID requisition : </td>
                    <td>#{props.requestid}</td>
                  </tr> */}
                    <tr>
                      <td>Loan Date:</td>
                      <td>
                        {requisition[0]?.applyDate
                          ? moment(new Date(requisition[0]?.applyDate)).format(
                              "DD / MM / yyyy"
                            )
                          : "..."}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Table borderless className="info-table mb-0 mb-md-3">
                  <tbody>
                    {/* <tr>
                    <td>Refrence Account :</td>
                    <td>#{requisition[0]?.refrenceAccount}</td>
                  </tr> */}
                    <tr>
                      <td>Recipient:</td>
                      <td className="fw-5">
                        {`${requisition[0]?.employeeID?.personalInfo?.name?.first} ${requisition[0]?.employeeID?.personalInfo?.name?.middle} ${requisition[0]?.employeeID?.personalInfo?.name?.last}` ||
                          "..."}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>

            <div className="approval p-3 my-3 border rounded">
              <p className="border-bottom pb-3 mb-3 title">
                <span>Deduct History</span>
              </p>

              <div className="approval-history">
                {requisition?.map((el, i) => (
                  <div key={i} className="item">
                    {i !== 0 && <div className="line" />}

                    <>
                      <div className="timeline border">
                        <ApproveCheckIcon />{" "}
                      </div>

                      <div className="detail">
                        <Avatar
                          className="image"
                          name={el?.employeeID?.personalInfo?.name?.first}
                          userId={el.receivedbyUser?.Staff_ID}
                        />

                        <div>
                          On {el.lastDeductDate}
                          <p className="mb-1">
                            <>
                              <span className="fw-5">
                                {el?.loanDeduct || "..."}
                              </span>{" "}
                              was <span className="fw-5">deducted</span> from
                              your salary
                            </>
                          </p>
                          <i> Your balance is {el?.balance}</i>
                          <p>
                            <em>{el?.Term} Term</em>
                          </p>
                        </div>
                      </div>
                    </>
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <div className="text-center">
            <NoSelectedItemIcon />
            <h4 className="my-2">There is no history yet</h4>
            <p>Check again later.</p>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
