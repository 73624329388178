import React from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";

import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";

class Taxes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <body>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex, nofollow"></meta>
          <title>InvexERP</title>
        </Helmet>
        <div className="main-wrapper">
          <Topbar />
          <Sidebar />
          {/*<!-- Page Wrapper -->*/}
          <div className="page-wrapper">
            {/*<!-- Page Content -->*/}
            <div className="content container-fluid">
              {/*<!-- Page Title -->*/}
              <div className="row">
                <div className="col-sm-8 col-6">
                  <h4 className="page-title">Taxes</h4>
                </div>
                <div className="col-sm-4 col-6 text-right m-b-30">
                  <a
                    href="#"
                    className="btn add-btn"
                    data-toggle="modal"
                    data-target="#add_tax"
                  >
                    <i className="fa fa-plus"></i> Add Tax
                  </a>
                </div>
              </div>
              {/*<!-- /Page Title -->*/}

              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table table-striped custom-table mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Tax Name </th>
                          <th>Tax Percentage (%) </th>
                          <th>Status</th>
                          <th className="text-right">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>VAT</td>
                          <td>14%</td>
                          <td>
                            <div className="dropdown action-label">
                              <a
                                className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                href="#"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa fa-dot-circle-o text-danger"></i>{" "}
                                Inactive
                              </a>
                              <div className="dropdown-menu">
                                <a className="dropdown-item" href="#">
                                  <i className="fa fa-dot-circle-o text-success"></i>{" "}
                                  Active
                                </a>
                                <a className="dropdown-item" href="#">
                                  <i className="fa fa-dot-circle-o text-danger"></i>{" "}
                                  Inactive
                                </a>
                              </div>
                            </div>
                          </td>
                          <td className="text-right">
                            <div className="dropdown dropdown-action">
                              <a
                                href="#"
                                className="action-icon dropdown-toggle"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="material-icons">more_vert</i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-toggle="modal"
                                  data-target="#edit_tax"
                                >
                                  <i className="fa fa-pencil m-r-5"></i> Edit
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-toggle="modal"
                                  data-target="#delete_tax"
                                >
                                  <i className="fa fa-trash-o m-r-5"></i> Delete
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>GST</td>
                          <td>30%</td>
                          <td>
                            <div className="dropdown action-label">
                              <a
                                className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                href="#"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa fa-dot-circle-o text-success"></i>{" "}
                                Active
                              </a>
                              <div className="dropdown-menu">
                                <a className="dropdown-item" href="#">
                                  <i className="fa fa-dot-circle-o text-success"></i>{" "}
                                  Active
                                </a>
                                <a className="dropdown-item" href="#">
                                  <i className="fa fa-dot-circle-o text-danger"></i>{" "}
                                  Inactive
                                </a>
                              </div>
                            </div>
                          </td>
                          <td className="text-right">
                            <div className="dropdown dropdown-action">
                              <a
                                href="#"
                                className="action-icon dropdown-toggle"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="material-icons">more_vert</i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-toggle="modal"
                                  data-target="#edit_tax"
                                >
                                  <i className="fa fa-pencil m-r-5"></i> Edit
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-toggle="modal"
                                  data-target="#delete_tax"
                                >
                                  <i className="fa fa-trash-o m-r-5"></i> Delete
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Page Content -->*/}

            {/*<!-- Add Tax Modal -->*/}
            <div id="add_tax" className="modal custom-modal fade" role="dialog">
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Add Tax</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="form-group">
                        <label>
                          Tax Name <span className="text-danger">*</span>
                        </label>
                        <input className="form-control" type="text" />
                      </div>
                      <div className="form-group">
                        <label>
                          Tax Percentage (%){" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input className="form-control" type="text" />
                      </div>
                      <div className="form-group">
                        <label>
                          Status <span className="text-danger">*</span>
                        </label>
                        <select className="select">
                          <option>Pending</option>
                          <option>Approved</option>
                        </select>
                      </div>
                      <div className="submit-section">
                        <button className="btn btn-primary submit-btn">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Add Tax Modal -->*/}

            {/*<!-- Edit Tax Modal -->*/}
            <div
              id="edit_tax"
              className="modal custom-modal fade"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Edit Tax</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="form-group">
                        <label>
                          Tax Name <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          value="VAT"
                          type="text"
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Tax Percentage (%){" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          value="14%"
                          type="text"
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Status <span className="text-danger">*</span>
                        </label>
                        <select className="select">
                          <option>Active</option>
                          <option>Inactive</option>
                        </select>
                      </div>
                      <div className="submit-section">
                        <button className="btn btn-primary submit-btn">
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Edit Tax Modal -->*/}

            {/*<!-- Delete Tax Modal -->*/}
            <div
              className="modal custom-modal fade"
              id="delete_tax"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="form-header">
                      <h3>Delete Tax</h3>
                      <p>Are you sure want to delete?</p>
                    </div>
                    <div className="modal-btn delete-action">
                      <div className="row">
                        <div className="col-6">
                          <a
                            href="javascript:void(0);"
                            className="btn btn-primary continue-btn"
                          >
                            Delete
                          </a>
                        </div>
                        <div className="col-6">
                          <a
                            href="javascript:void(0);"
                            data-dismiss="modal"
                            className="btn btn-primary cancel-btn"
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Delete Tax Modal -->*/}
          </div>
          {/*<!-- /Page Wrapper -->*/}
        </div>
        {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
        <div className="sidebar-overlay" data-reff=""></div>
      </body>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Taxes));
