const ApiReducer = (state = {
    User: {},
}, action) => {
    switch (action.type) {
    case 'UPDATE_USER':
        return Object.assign({}, state, { User: action.user });

    default:
        return state;
    }
};

export default ApiReducer;
