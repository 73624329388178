import { createStore, combineReducers } from "redux";
import ApiReducer from "./components/api/ApiReducer.js";
import DeductionReducer from "./components/deduction-settings/deductionReducer.js";
import SettingsReducer from "./components/settings/settingsReducer.js";
import BonusReducer from "./components/bonus-settings/bonusReducer.js";
import AllowanceReducer from "./components/allowance/AllowanceReducer.js";
import BenefitReducer from "./components/benefit/BenefitReducer.js";

const store = createStore(
  combineReducers({
    api: ApiReducer,
    deductionSettings: DeductionReducer,
    settingsReducer: SettingsReducer,
    bonusSettings: BonusReducer,
    allowanceReducer: AllowanceReducer,
    BenefitReducer: BenefitReducer,
  })
);

export default store;
