import { Modal } from "react-bootstrap";
import React from "react";

export default function ModalLoader({
  show,
  title = "Please wait, processing request...",
  ...modalProps
}) {
  return (
    <Modal
      size="sm"
      show={show}
      onHide={() => {}}
      centered
      aria-labelledby="example-modal-sizes-title-sm"
      backdrop="static"
      animation={false}
      {...modalProps}
    >
      <Modal.Body className="text-center my-3 d-flex align-items-center justify-content-center gap-2">
        {title}
      </Modal.Body>
    </Modal>
  );
}
