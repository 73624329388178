import React from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";

export default function RsDateRangePicker(props) {
  // console.log(
  //   props.startDate,
  //   moment(new Date(props.startDate)).format("MM/DD/YYYY")
  // );
  return (
    <DateRangePicker
      initialSettings={{
        //autoUpdateInput: false,
        // startDate: props.startDate
        //   ? props.startDate
        //   : moment(new Date()).format("DD/MM/yyyy"),
        // endDate: props?.endDate
        //   ? props?.endDate
        //   : moment(new Date()).format("DD/MM/yyyy"),
        locale: {
          cancelLabel: "Clear",
        },
      }}
      {...props}
      onApply={props.onOk}
      onCancel={props.onClean}
    >
      <input
        type="text"
        className="form-control bg-white"
        defaultValue=""
        placeholder="dd/mm/yyyy - dd/mm/yyyy"
        readOnly
      />
    </DateRangePicker>
  );
}
